import { useMemo } from "react";
import { CrmCompanyDto, CrmCompanyLocationDto } from "../api";
import useAppStore from "./useAppStore";

export default function useCrmCompanyLocationSet(): (CrmCompanyLocationDto & { company: CrmCompanyDto })[] {
  const app = useAppStore();
  return useMemo(
    () =>
      app.data.crm_company_set.reduce(
        (prev, curr) => prev.concat(curr.location_set.map((loc) => ({ ...loc, company: curr }))),
        [] as (CrmCompanyLocationDto & { company: CrmCompanyDto })[],
      ),
    [],
  );
}
