import React from "react";
import { Button, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import { DmzAuthenticatePageActions, useDmzAuthenticateStyles } from "./DmzAuthenticate";

type Props = {
  version: string;
  setScreen: DmzAuthenticatePageActions["setScreen"];
};

const DmzAuthenticateScreenNotifyVersion: React.FC<Props> = ({ version, setScreen }) => {
  const classes = useDmzAuthenticateStyles();

  return (
    <Card className={classes.Form}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Versieconflict
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          TagScore versie {window.DEFINED_VERSION} is verlopen.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Waarschuwing!</strong> Uw versie van TagScore is verlopen. Ververs de pagina om de laatste versie van
          TagScore in te laden.
        </Typography>
        <Typography variant="body1" paragraph>
          De laatste versie van TagScore is: {version}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => window.location.reload()} variant="contained" color="primary">
          Herladen
        </Button>
        <Button onClick={() => setScreen("session-identify")} variant="outlined" color="secondary">
          Toch doorgaan
        </Button>
      </CardActions>
    </Card>
  );
};

export default DmzAuthenticateScreenNotifyVersion;
