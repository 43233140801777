import { useMemo } from "react";
import { CrmCompanyDto, CrmCompanyLocationDto } from "../api";
import useCrmCompanyLocationSet from "./useCrmCompanyLocationSet";

export default function useCrmCompanyLocation(
  crm_company_location_id: number,
): CrmCompanyLocationDto & { company: CrmCompanyDto } {
  const crmCompanyLocationSet = useCrmCompanyLocationSet();
  return useMemo(() => {
    return crmCompanyLocationSet.find((x) => x.id === crm_company_location_id);
  }, [crm_company_location_id]);
}
