import React from "react";
import { CareDossierOneResponse } from "../../../api";
import DataTableExt from "../../../core/DataTableExt";

type Props = {
  dossier: CareDossierOneResponse;
  onClose?: () => void;
};

const CareDossierExtDataTable: React.FC<Props> = (props) => {
  const { dossier } = props;

  const data: [string, React.ReactNode][] = React.useMemo(() => {
    return [
      ["Persoon", dossier.person.name],
      ["Archief", dossier.is_archived && "Dit dossier is gearchiveerd."],
    ];
  }, [dossier]);

  return <DataTableExt data={data} />;
};

export default CareDossierExtDataTable;
