import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import { Edit, Stop } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { TableRowDto } from "../../../api";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import useNavigate from "../../../core/useNavigate";
import useTableStyles from "../../../core/useTableStyles";
import useCrmCompanyTable from "./useCrmCompanyTable";

const CrmCompanyTableRowButtonsStart: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const navigate = useNavigate();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Openen.">
        <IconButton onClick={() => navigate(`/crm/company/view/${row.record_id}`)}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

const CrmCompanyTableRowButtonsEnd: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const { actions } = useCrmCompanyTable();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <IconButtonDropExt
        items={[
          {
            icon: <Edit />,
            label: "Aanpassen",
            onClick: () => actions.setDrawer({ name: "Save", record_id: row.record_id }),
          },
          {
            icon: <Stop />,
            label: "Archiveren",
            onClick: () => actions.setDrawer({ name: "Archive", record_id: row.record_id }),
          },
        ]}
      />
    </TableCell>
  );
};

export { CrmCompanyTableRowButtonsStart, CrmCompanyTableRowButtonsEnd };
