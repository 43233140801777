import React from "react";
import CrmPersonDialogArchive from "../Drawer/CrmPersonDialogArchive";
import useCrmPersonTableStore from "./useCrmPersonTableStore";
import TableDrawerHeaders from "../../../core/TableDrawerHeaders";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { CrmPersonTableRowsRequest } from "../../../api";
import CrmPersonDrawerTableFilter from "../Drawer/CrmPersonDrawerTableFilter";
import CrmPersonDialogSignoutEverywhere from "../Drawer/CrmPersonDialogSignoutEverywhere";
import CrmPersonDialogTakeover from "../Drawer/CrmPersonDialogTakeover";
import CrmPersonDrawerView from "../Drawer/CrmPersonDrawerView";
import CrmPersonDrawerSave from "../Drawer/CrmPersonDrawerSave";
import { getApi } from "../../../core/useApi";

const CrmPersonTableDrawers: React.FC = () => {
  const table = useCrmPersonTableStore();

  return (
    <>
      {table.drawer?.name === "TableHeaders" && (
        <TableDrawerHeaders
          open={true}
          header_set={table.header_set}
          used_set={table.req.header_set || ["id", "name"]}
          onClose={() => table.actions.setDrawer(undefined)}
          onSave={(header_set) => {
            table.actions.setRequest({ header_set });
            table.actions.setDrawer(undefined);
          }}
        />
      )}
      {table.drawer?.name === "TableDownload" && (
        <TableDrawerDownload
          onClose={() => table.actions.setDrawer(undefined)}
          onDownloadStart={async () => (await getApi().crmPersonTableDownloadPost(table.req)).data}
        />
      )}
      {table.drawer?.name === "DrawerSave" && (
        <CrmPersonDrawerSave
          record_id={table.drawer.record_id}
          onClose={(success: boolean) => {
            table.actions.setDrawer(undefined);
            if (success) table.actions.load();
          }}
        />
      )}
      {table.drawer?.name === "DrawerFilter" && (
        <CrmPersonDrawerTableFilter
          req={table.req}
          onClose={(req: CrmPersonTableRowsRequest) => {
            table.actions.setDrawer(undefined);

            if (req) {
              table.actions.setRequest(req);
            }
          }}
        />
      )}
      {table.drawer?.name === "DialogSignoutEverywhere" && (
        <CrmPersonDialogSignoutEverywhere
          onClose={() => table.actions.setDrawer(undefined)}
          record_id={table.drawer.record_id}
        />
      )}
      {table.drawer?.name === "DialogTakeover" && (
        <CrmPersonDialogTakeover
          onClose={() => table.actions.setDrawer(undefined)}
          record_id={table.drawer.record_id}
        />
      )}
      {table.drawer?.name === "DialogArchive" && (
        <CrmPersonDialogArchive
          onClose={async () => {
            table.actions.setDrawer(undefined);
            await table.actions.load();
          }}
          record_id={table.drawer.record_id}
        />
      )}
      {table.drawer?.name === "DrawerView" && (
        <CrmPersonDrawerView onClose={() => table.actions.setDrawer(undefined)} record_id={table.drawer.record_id} />
      )}
    </>
  );
};

export default CrmPersonTableDrawers;
