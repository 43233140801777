import { Box, Card, CardContent, Container, Link, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import * as z from "zod";
import { TsCompanyDto, TsSessionSotpValidateRequest } from "../../api";
import BgImage from "../../assets/samantha-gades-BlIhVfXbi9s-unsplash.jpg";
import TagScoreWhiteSvg from "../../assets/tagscore-white.svg";
import TagScoreSvg from "../../assets/tagscore.svg";
import { getApi } from "../../core/useApi";
import useApp from "../../core/useAppStore";
import useAsyncEffect from "../../core/useAsyncEffect";
import useNavigate from "../../core/useNavigate";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../core/useRxjsStore";
import DmzAuthenticateScreenNotifyBooting from "./DmzAuthenticateScreenNotifyBooting";
import DmzAuthenticateScreenNotifyVersion from "./DmzAuthenticateScreenNotifyVersion";
import DmzAuthenticateScreenResetFinalize from "./DmzAuthenticateScreenResetFinalize";
import DmzAuthenticateScreenResetSetup from "./DmzAuthenticateScreenResetSetup";
import DmzAuthenticateScreenSessionIdentify from "./DmzAuthenticateScreenSessionIdentity";
import DmzAuthenticateScreenSessionLogin from "./DmzAuthenticateScreenSessionLogin";
import DmzAuthenticateScreenSessionPick from "./DmzAuthenticateScreenSessionPick";

type State = {
  version: string;
  screen:
    | "notify-booting"
    | "session-identify"
    | "session-pick"
    | "session-login"
    | "session-tfa"
    | "reset-setup"
    | "reset-finalize"
    | "notify-archived"
    | "notify-password"
    | "notify-version";

  email: string;
  company_id: number;
  company_set: TsCompanyDto[];
  redirect: string;
  sotp?: TsSessionSotpValidateRequest;
};

type BootSetup = {
  screen: State["screen"];
  email: string;
  company_id: number;
};

type Actions = ReturnType<typeof useActions>;

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>) {
  const navigate = useNavigate(true);

  const actions = {
    boot: async (setup?: BootSetup) => {
      const redirect = window.location.href.split(window.location.host, 2)[1];
      const res = (await getApi().apiWebVersionPost({ version: window.DEFINED_VERSION })).data;

      next((d) => {
        d.version = res.version;
        d.redirect = redirect;
      });
      console.info(window.DEFINED_VERSION, res.version, res.should_update);

      if (res.should_update) {
        actions.setScreen("notify-version");
      } else if (!setup) {
        actions.setScreen("session-identify");
      } else {
        next((d) => {
          d.email = setup.email;
          d.company_id = setup.company_id;
        });

        if (setup.email) {
          await actions.loadCompanySet();
        }

        actions.setScreen(setup.screen);
      }
    },
    setScreen: (v: State["screen"]) => {
      next((d) => (d.screen = v));

      actions.updateNavigation();
    },
    updateNavigation: () => {
      // Disabled for now, this is not that handy.
      // const state = getState();
      // const params = new URLSearchParams({
      //   screen: state.screen,
      //   email: state.email,
      //   company_id: state.company_id.toString(),
      //   redirect: state.redirect,
      // });
      // navigate(`/dmz/authenticate?${params.toString()}`);
    },
    onLogin: (email: string, company_set: TsCompanyDto[]) => {
      next((d) => {
        d.email = email;
        d.company_set = company_set;

        if (company_set.length === 1) {
          d.company_id = company_set[0].id;
          d.screen = "session-login";
        } else {
          d.screen = "session-pick";
        }
      });

      actions.updateNavigation();
    },
    onCompanySelect: (company_id) => {
      next((d) => {
        d.company_id = company_id;
        d.screen = "session-login";
      });

      actions.updateNavigation();
    },
    loadCompanySet: async () => {
      const email = getState().email;
      const res = (await getApi().tsCompanySetByEmailPost({ email })).data;

      next((d) => (d.company_set = res.company_set));
    },
    onSotpValidate: (sotp: TsSessionSotpValidateRequest) => {
      next((d) => {
        d.sotp = sotp;
        d.screen = "reset-finalize";
      });
    },
  };

  return actions;
}

const DmzAuthenticate: React.FC = () => {
  const classes = useDmzAuthenticateStyles();
  const app = useApp();
  const is_sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const page = useRxjsStore<State>({
    version: window.DEFINED_VERSION,
    screen: "notify-booting",
    email: "",
    company_id: -1,
    company_set: [],
    redirect: "/profile/dashboard",
  });

  const { state, next, getState } = page;
  const actions = useActions(next, getState);

  useAsyncEffect(async () => {
    const has_query = window.location.href.indexOf("?") > 0;
    let setup: BootSetup | undefined = undefined;

    if (has_query) {
      const query = new URLSearchParams(window.location.search);
      const data = {
        screen: query.get("screen") || "session-identify",
        email: query.get("email") || "",
        company_id: query.get("company_id") || "-1",
      };

      try {
        const parsed = z
          .object({
            screen: z.enum([
              "session-identify",
              "session-pick",
              "session-login",
              "session-tfa",
              "reset-setup",
              "reset-finalize",
              "notify-archived",
              "notify-password",
              "notify-version",
            ]),
            email: z.string(),
            company_id: z.string().refine((v) => parseInt(v)),
          })
          .parse(data);

        setup = {
          screen: parsed.screen,
          email: parsed.email,
          company_id: parseInt(parsed.company_id),
        };
      } catch (err) {
        // ignore errors and default to the normal login.
        if (err instanceof z.ZodError) {
          console.error("SetupBootValidationError", err.errors);
        } else {
          console.error(err);
        }
      }
    }

    console.info("Booting with", setup);

    await actions.boot(setup);
  }, []);

  return (
    <div className={classes.Container}>
      <div className={classes.Container_BackgroundImage} />
      <Container maxWidth="xs">
        <div className={classes.Container_Paper}>
          {!is_sm && <img src={app.theme.is_dark ? TagScoreWhiteSvg : TagScoreSvg} alt="TagScore" width={200} />}

          {state.screen === "notify-booting" && <DmzAuthenticateScreenNotifyBooting />}
          {state.screen === "notify-version" && (
            <DmzAuthenticateScreenNotifyVersion version={state.version} setScreen={actions.setScreen} />
          )}
          {state.screen === "session-identify" && <DmzAuthenticateScreenSessionIdentify actions={actions} />}
          {state.screen === "session-pick" && (
            <DmzAuthenticateScreenSessionPick email={state.email} company_set={state.company_set} actions={actions} />
          )}
          {state.screen === "session-login" && (
            <DmzAuthenticateScreenSessionLogin
              redirect={state.redirect}
              email={state.email}
              company_id={state.company_id}
              company_set={state.company_set}
              actions={actions}
            />
          )}
          {state.screen === "reset-setup" && (
            <DmzAuthenticateScreenResetSetup
              email={state.email}
              company_id={state.company_id}
              company_set={state.company_set}
              actions={actions}
            />
          )}
          {state.screen === "reset-finalize" && (
            <DmzAuthenticateScreenResetFinalize
              email={state.email}
              company_id={state.company_id}
              company_set={state.company_set}
              sotp={state.sotp}
              actions={actions}
            />
          )}
        </div>
        <Box mt={2} mb={3} zIndex={2} position="relative">
          <Card style={{ backgroundColor: "rgba(190, 190, 190, 0.8)" }}>
            <CardContent>
              <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                Redirect: {state.redirect}
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://tagscore.nl/">
                  TagScore
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

const useDmzAuthenticateStyles = makeStyles((theme) => ({
  Container: {
    position: "relative",
    minHeight: "calc(100vh - 64px)",
  },
  Container_BackgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${BgImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // filter: "blur(2px)",
    zIndex: 1,
  },
  Container_Paper: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2,
  },
  Form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

export default DmzAuthenticate;
export type { Actions as DmzAuthenticatePageActions };
export { useDmzAuthenticateStyles };
