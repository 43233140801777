import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { TableRowDto } from "../../api";
import useTableStyles from "../../core/useTableStyles";
import useCareDossierTriggersTableStore from "./useCareDossierTriggersTableStore";

const CareDossierTriggersTableRowButtonsEnd: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const { actions } = useCareDossierTriggersTableStore();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Aanpassen.">
        <IconButton onClick={() => actions.setDrawer({ name: "Toggle", record_id: row.record_id })}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export { CareDossierTriggersTableRowButtonsEnd };
