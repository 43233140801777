import React, { useMemo } from "react";
import useAppStore, { getAppState } from "./useAppStore";

type Props = {
  name: string;
  negate?: boolean;
};

const HasPlugin: React.FC<Props> = (props) => {
  const has = useHasPlugin(props.name);
  const value = useMemo(() => (props.negate ? !has : has), [has, props.negate]);

  if (!value) return null;

  return <React.Fragment>{props.children}</React.Fragment>;
};

function useHasPlugin(name: string) {
  const app = useAppStore();
  return useMemo(() => app.session?.company.plugins.indexOf(name) !== -1, [app.session?.token]);
}

function hasPlugin(name: string) {
  const state = getAppState();

  return (state.session?.company.plugins || []).includes(name);
}

export default HasPlugin;
export { hasPlugin, useHasPlugin };
