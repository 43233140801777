import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  colors,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  CheckBoxOutlined,
  DoneOutline,
  ExpandMore,
  HelpRounded,
  ReportProblem,
  Save,
  SkipNext,
  SkipPrevious,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import {
  addDays,
  addHours,
  addMinutes,
  eachDayOfInterval,
  format,
  getISODay,
  isMonday,
  isSameDay,
  isToday,
  nextSunday,
  parse,
  previousMonday,
  startOfMonth,
  subDays,
} from "date-fns";
import { nl } from "date-fns/locale";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { act } from "react-dom/test-utils";
import { RouteComponentProps } from "react-router-dom";
import _ from "underscore";
import { CdDataCheckupLocationItemDto, CdDataRegistryDataDto, CrmCompanyDto, CrmCompanyLocationDto } from "../../api";
import FormatDate from "../../core/FormatDate";
import { getApi } from "../../core/useApi";
import useApiResult from "../../core/useApiResult";
import useAppStore from "../../core/useAppStore";
import useGenericStyles from "../../core/useGenericStyles";
import useNavigate from "../../core/useNavigate";
import useRxjsForm from "../../core/useRxjsForm";
import waiting_failed_lookup from "../../core/waiting_failed_lookup";
import RadioFieldExt from "../../form/RadioFieldExt";
import SwitchFieldExt from "../../form/SwitchFieldExt";
import TextFieldExt from "../../form/TextFieldExt";
import SkeletonPage from "../../skeleton/SkeletonPage";
import { getCrmCompanyLocationShortCode } from "../CrmCompany/Ext/CrmCompanyLocationShortCode";

type Props = RouteComponentProps<{ crm_company_location_id?: string; day?: string }> & {};

const CapDashData: React.FC<Props> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    form,
    calculated,
    previous,
    current,
    iteration,
    day,
    setDay,
    setPreviousDay,
    setNextDay,
    dayIsToday,
    location_set,
    location,
    setLocationById,
  } = useComponent(props);

  return (
    <SkeletonPage>
      <Grid container spacing={2}>
        <Grid item xs={4} style={{ maxHeight: "calc(100vh - 100px)" }}>
          <Card style={{ height: "100%", overflow: "auto" }}>
            <List component="nav" aria-label="main mailbox folders">
              {location_set
                .filter((x) => !x.is_archived)
                .map((loc) => (
                  <ListItem
                    button
                    key={loc.id}
                    selected={loc.id === location.id}
                    onClick={() => {
                      navigate(`/capdash/data/${loc.id}`);
                      setLocationById(loc.id);
                    }}
                  >
                    <ListItemIcon>
                      <CheckBoxOutlined />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${loc.company.name} - ${loc.name}`}
                      secondary={getCrmCompanyLocationShortCode(loc)}
                    />
                  </ListItem>
                ))}
            </List>
          </Card>
        </Grid>
        <Grid item xs style={{ maxHeight: "calc(100vh - 100px)" }}>
          <form onSubmit={form.actions.submit} noValidate style={{ height: "100%" }}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Tooltip title={`Ga terug naar dag ${format(subDays(day, 1), "PP")}.`}>
                      <IconButton onClick={setPreviousDay}>
                        <SkipPrevious />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item md>
                    <Typography variant="h5" component="h2">
                      <FormatDate value={day} fmt="RRRR / I" /> - {location.name}
                    </Typography>
                    <Typography color="textSecondary">
                      Week <FormatDate value={day} fmt="w" /> loopt van <FormatDate value={day} fmt="dd MMM yyyy" /> t/m{" "}
                      <FormatDate value={nextSunday(day)} fmt="dd MMM yyyy" />
                    </Typography>
                    <Typography color="textSecondary">
                      Geef aan of er in deze week veranderingen zijn geweest welke genoteerd moeten worden.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Tooltip
                      title={current.res === null ? "Deze week is nog niet verwerkt." : "Deze week is opgeslagen."}
                    >
                      <Typography color={current.res === null ? "error" : "primary"}>
                        {current.res === null ? <ReportProblem /> : <DoneOutline />}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Button
                      startIcon={<Save />}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={form.submitting || !current.res}
                    >
                      Opslaan
                    </Button>
                  </Grid>

                  <Grid item>
                    <Tooltip title={`Ga verder naar dag ....`}>
                      <IconButton disabled={dayIsToday} onClick={setNextDay}>
                        <SkipNext />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent style={{ maxHeight: "calc(100% - 161px)", overflow: "auto" }}>
                <div style={{ marginBottom: "1rem" }}>
                  <CapDashDataVisualDays location={location} activeDay={day} setDay={setDay} iteration={iteration} />
                </div>

                {current.busy && <LinearProgress style={{ width: "100%", height: 3 }} variant="indeterminate" />}
                {!current.busy && (
                  <Grid container spacing={2} className={classes.grid}>
                    {current.res?.auto_stamped && (
                      <Grid item md={12}>
                        <Alert severity="info">
                          Deze data is automatisch aangemaakt door CapDash, hierbij gaat CapDash altijd voor de optie om
                          geen data te registreren en nemen we daarmee de wachtlijstduur en het aantal gereserveerde
                          bedden over. Wanneer u opslaat markeren we deze dag als ingevoerd.
                        </Alert>
                      </Grid>
                    )}

                    {!!current.res ? (
                      <>
                        <CapDashDataForm form={form} calculated={calculated} previous={previous} current={current} />
                        <CapDashDataStatistics
                          form={form}
                          calculated={calculated}
                          previous={previous}
                          current={current}
                        />
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Geblokkeerd</Typography>
                        <Typography variant="body2" color="textSecondary">
                          Deze week is nog geblokkeerd door CapDash, hij wordt automatisch vrijgegeven op de maandag
                          wanneer de week start.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>
    </SkeletonPage>
  );
};

function CapDashDataForm(props: Pick<UseComponentAsProps, "form" | "calculated" | "previous" | "current">) {
  const gc = useGenericStyles();
  const { form, calculated, previous, current } = props;

  return (
    <Grid container item xl={6} lg={8} xs={12} spacing={2}>
      <Grid item md={12}>
        <RadioFieldExt
          form={form}
          name="has_data"
          label="Is er data voor deze dag?"
          items={[
            { value: false, label: "Nee, er is geen data om te registreren." },
            { value: true, label: "Ja, er moet data geregistreerd worden." },
          ]}
        />
      </Grid>
      {form.values.has_data && (
        <>
          <Grid item md={12}>
            <Typography variant="subtitle1">Wachtlijstomvang</Typography>
            <Typography variant="body2" color="textSecondary">
              We starten aan de instroomkant, hierbij werken we met de wachtlijst als centraal punt.
            </Typography>
          </Grid>

          <Grid item md={12}>
            <TextFieldExt
              form={form}
              name="waiting_duration"
              type="number"
              label="Wachtlijstduur in dagen"
              InputProps={{
                endAdornment: (
                  <Tooltip title="De werkwijze is dat op de eerste werkdag van de maand er 2 maanden terug wordt gekeken. De wachttijd is het aantal dagen tussen de aanmelding en de datum van opname. Het cijfer geeft het gemiddelde aan van alle cliënten per type zorg crisis, regulier, langdurig. Alle patiënten per type zorg worden meegenomen in de telling welke direct plaatsbaar zijn. (wanneer iemand nog in detentie zit of de uitspraak nog niet onherroepelijk is telt deze persoon dus niet mee). De financieringsgrond of de justitiële titel zijn niet van belang, alles wordt meegeteld.">
                    <HelpRounded />
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Vorige wachtlijstomvang"
              value={previous.res?.waiting || 0}
              InputProps={{
                readOnly: true,
              }}
              helperText="Het totaal aantal cliënten wat de vorige keer op de wachtlijst geregistreerd stond."
            />
          </Grid>
          <Grid item md={12}>
            <TextFieldExt
              form={form}
              name="waiting_new"
              type="number"
              label="Nieuw op wachtlijst"
              helperText="Het aantal cliënten wat nieuw is op de wachtlijst."
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      <>
                        Met dit veld kun je het aantal cliënten op de wachtlijst laten toenemen door een getal in te
                        voeren. (bijvoorbeeld 1 of 2 ). Het kan echter voorkomen dat een client van de wachtlijst af
                        gaat zonder dat er sprake is van instroom. Bijvoorbeeld in geval van overplaatsing of overlijden
                        etc. In een dergelijke situatie vul je een negatief getal in. ( bijvoorbeeld -1) De
                        wachtlijstomvang wordt na opslaan automatisch aangepast. <strong>Let op!</strong> Doe dit
                        laatste alleen indien de betreffende wachtlijstclient niet instroomt. In alle andere gevallen
                        wordt de berekening tussen instroom en wachtlijstomvang automatisch berekend.
                        <br />
                        <br />
                        De wachtlijst wordt per type zorg opgeleverd crisis, regulier, langdurig. Patiënten worden op de
                        wachtlijst geplaatst als de opnemende instantie voldoende informatie heeft om de inschatting te
                        maken dat iemand plaatsbaar is. De richtlijnen kunnen per instelling verschillen, maar men kan
                        denken aan de wettelijke eisen (geldige verwijsbrief, verzekering, IFZO, vonnis) en voldoende
                        inhoudelijke informatie.
                      </>
                    }
                  >
                    <HelpRounded />
                  </Tooltip>
                ),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <TextFieldExt
              form={form}
              name="waiting_to_intake"
              type="number"
              label="Naar instroom"
              helperText="Het aantal cliënten van de wachtlijst af welke zijn ingestroomd."
            />
          </Grid>

          <Grid item md={12}>
            <RadioFieldExt
              form={form}
              name="has_waiting_failed"
              label="Zijn er niet gelukte aanmeldingen?"
              items={[
                { value: false, label: "Nee, er is geen data om te registreren." },
                { value: true, label: "Ja, er zijn niet gelukte aanmeldingen om te registreren." },
              ]}
            />
          </Grid>

          <Grid item md={12}>
            {form.values.has_waiting_failed && (
              <>
                <Accordion
                  expanded={form.values.has_waiting_failed_content}
                  onChange={() =>
                    form.actions.setValue("has_waiting_failed_content", !form.values.has_waiting_failed_content)
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
                      Inhoudelijk
                    </Typography>
                    <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
                      er zijn niet gelukte inhoudelijke aanmeldingen
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Bijvoorbeeld: we hebben geen behandelaanbod voor deze patiënt, de groepsdynamiek en de veiligheid
                      op de groep maken het niet mogelijk deze patiënt op te nemen of het is somatisch te ingewikkeld om
                      deze patiënt op te nemen.
                    </Typography>
                  </AccordionDetails>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {waiting_failed_lookup.content.map((item) => (
                        <Grid item md={12}>
                          <TextFieldExt
                            form={form}
                            name={`waiting_failed_content_setup.${item.value}`}
                            type="number"
                            label={item.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={form.values.has_waiting_failed_financial}
                  onChange={() =>
                    form.actions.setValue("has_waiting_failed_financial", !form.values.has_waiting_failed_financial)
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
                      Financieel
                    </Typography>
                    <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
                      er zijn niet gelukte financiele aanmeldingen
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Bijvoorbeeld: er is geen contact, dit type bed is niet ingekocht, de financiering of het tarief is
                      niet dekkend, er is sprake van een omzetplafond of er is geen regionale binding en dus geen
                      financiering beschikbaar.
                    </Typography>
                  </AccordionDetails>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      {waiting_failed_lookup.financial.map((item) => (
                        <Grid item md={12}>
                          <TextFieldExt
                            form={form}
                            name={`waiting_failed_financial_setup.${item.value}`}
                            type="number"
                            label={item.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={form.values.has_waiting_failed_system}
                  onChange={() =>
                    form.actions.setValue("has_waiting_failed_system", !form.values.has_waiting_failed_system)
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
                      Systemisch
                    </Typography>
                    <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
                      er zijn niet gelukte systemische aanmeldingen
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Er zijn belemmerende omgevingsfactoren, zoals een afspraak met de gemeente over contra-indicaties,
                      de veiligheid op de afdeling is vanwege personeelsgebrek niet te garanderen, het is niet mogelijk
                      deze patiënt op te nemen vanwege slachtofferbelangen, er wordt geen terugnamegarantie afgegeven,
                      er is geen uitstroomperspectief of er is sprake van een getraumatiseerd team.
                    </Typography>
                  </AccordionDetails>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {waiting_failed_lookup.system.map((item) => (
                        <Grid item md={12}>
                          <TextFieldExt
                            form={form}
                            name={`waiting_failed_system_setup.${item.value}`}
                            type="number"
                            label={item.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Grid>

          <Grid item md={12}>
            <TextFieldExt
              form={form}
              name="outflow"
              type="number"
              label="Uitstroom"
              helperText="Het aantal cliënten wat is uitgestroomd."
            />
          </Grid>

          <Grid item md={12}>
            <TextFieldExt
              form={form}
              name="reserved"
              type="number"
              label="Gereserveerd"
              helperText="Het aantal bedden wat is gereserveerd, zijnde niet bezig, maar ook niet beschikbaar."
            />
          </Grid>

          {calculated.available <= 0 && (
            <Grid item md={12}>
              <SwitchFieldExt
                form={form}
                name="may_receive_opening"
                onLabel="Binnen nu en twee weken kan er plek ontstaan (mogelijk verwachte capaciteit)."
                offLabel="Er zal binnen nu en twee weken geen plek ontstaan (geen verwachte capaciteit)."
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

function CapDashDataStatistics(props: Pick<UseComponentAsProps, "form" | "calculated" | "previous" | "current">) {
  const { form, calculated, previous, current } = props;

  return (
    <Grid item xl={6} lg={4} xs={12} spacing={2}>
      <Typography variant="subtitle1">Samenvatting</Typography>
      <Typography variant="body2" color="textSecondary">
        De onderstaande gegevens zijn automatisch berekend aan de hand van de hierboven opgegeven cijfers, en de cijfers
        van de afgelopen dag.
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Wachtlijstomvang</TableCell>
            <TableCell>{calculated.waiting}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Wachtlijstduur</TableCell>
            <TableCell>
              {form.values.has_data ? form.values.waiting_duration : previous.res?.waiting_duration} dagen
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Capaciteit</TableCell>
            <TableCell>{calculated.capacity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Instroom</TableCell>
            <TableCell>{calculated.intake}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Uitstroom</TableCell>
            <TableCell>{calculated.outflow}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Gereserveerd</TableCell>
            <TableCell>{calculated.reserved}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bezet</TableCell>
            <TableCell>{calculated.occupied}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Beschikbaar</TableCell>
            <TableCell>{calculated.available}</TableCell>
          </TableRow>
          {current.res?.created_on && (
            <TableRow>
              <TableCell>Gemaakt op</TableCell>
              <TableCell>
                <FormatDate value={current.res.created_on} fmt="PP p" />
                {current.res.created_by && (
                  <>
                    {" door "}
                    {current.res.created_by.name}
                  </>
                )}
              </TableCell>
            </TableRow>
          )}
          {current.res?.updated_on && (
            <TableRow>
              <TableCell>Aangepast op</TableCell>
              <TableCell>
                <FormatDate value={current.res.updated_on} fmt="PP p" />
                {current.res.updated_by && (
                  <>
                    {" door "}
                    {current.res.updated_by.name}
                  </>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
}

const useWeekStyles = makeStyles((theme) => ({
  weekContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  weekButton: {
    minWidth: 50,
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2px",
    cursor: "pointer",
    border: "2px solid white",
    "&:hover": {
      borderColor: colors.blue[300],
    },
  },
  weekButtonActive: {
    borderColor: theme.palette.primary.light,
    borderWidth: 4,
  },
  isCurrentWeek: {
    borderColor: theme.palette.primary.dark,
    borderWidth: 4,
  },
}));

function CapDashDataVisualDays({
  location,
  activeDay,
  setDay,
  iteration,
}: {
  location: CrmCompanyLocationDto;
  activeDay: Date;
  setDay: (d: Date) => void;
  iteration: number;
}) {
  const styles = useWeekStyles();

  const currentYear = parseInt(format(activeDay, "RRRR", { locale: nl }));

  console.info(activeDay, format(activeDay, "RRRR / I", { locale: nl }));

  const day_after = useMemo(() => new Date(currentYear, 0, 1), [currentYear]);
  const day_before = useMemo(() => new Date(currentYear, 11, 31), [currentYear]);
  const day_after_s = useMemo(() => format(day_after, "yyyy-MM-dd"), [day_after]);
  const day_before_s = useMemo(() => format(day_before, "yyyy-MM-dd"), [day_before]);

  const firstMonday = isMonday(day_after) ? day_after : previousMonday(day_after);
  const lastMonday = isMonday(day_before) ? day_before : previousMonday(day_before);

  const [checkup, checkupReload] = useApiResult(async () => {
    try {
      return (
        await getApi().cdDataCheckupPost({
          crm_company_location_id_set: [location.id],
          day_after: format(firstMonday, "yyyy-MM-dd", { locale: nl }),
          day_before: format(lastMonday, "yyyy-MM-dd", { locale: nl }),
        })
      ).data;
    } catch (e) {
      return null;
    }
  }, [location.id, iteration, day_after_s, day_before_s]);

  const daySet = checkup.res?.location_set[0]?.day_set;

  const weekMap = (daySet ? _.object(daySet.map((x) => [x.key, x])) : {}) as {
    [id: string]: CdDataCheckupLocationItemDto;
  };

  const currentDay = new Date();

  return (
    <div>
      <div className={styles.weekContainer}>
        {eachDayOfInterval({ start: firstMonday, end: lastMonday }, { step: 7 })
          .filter((x) => format(x, "RRRR", { locale: nl }) === format(activeDay, "RRRR", { locale: nl }))
          .map((x) => {
            const item = weekMap[format(x, "RRRR / I", { locale: nl })];
            const isCurrentWeek =
              format(x, "RRRR / I", { locale: nl }) === format(currentDay, "RRRR / I", { locale: nl });

            return (
              <Tooltip title={`Datum is ${format(x, "PP p", { locale: nl })} ${format(x, "RRRR / I", { locale: nl })}`}>
                <div
                  className={clsx(
                    styles.weekButton,
                    isSameDay(x, activeDay) && styles.weekButtonActive,
                    isCurrentWeek && styles.isCurrentWeek,
                  )}
                  style={{
                    backgroundColor: !item
                      ? colors.red[50]
                      : item.auto_stamped
                      ? colors.red[300]
                      : item.has_data
                      ? colors.green[500]
                      : colors.lightGreen[500],
                  }}
                  onClick={() => setDay(x)}
                >
                  {format(x, "w", { locale: nl })}
                </div>
              </Tooltip>
            );
          })}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

const initial: CdDataRegistryDataDto = {
  has_data: false,
  waiting_duration: 0,
  waiting_new: 0,
  waiting_to_intake: 0,
  has_waiting_failed: false,
  has_waiting_failed_content: false,
  waiting_failed_content_setup: {},
  has_waiting_failed_financial: false,
  waiting_failed_financial_setup: {},
  has_waiting_failed_system: false,
  waiting_failed_system_setup: {},
  outflow: 0,
  reserved: 0,
  may_receive_opening: false,
};

type UseComponentAsProps = ReturnType<typeof useComponent>;

function getMondayOfDate(v: Date) {
  return isMonday(v) ? v : previousMonday(v);
}

function useComponent(props: Props) {
  const navigate = useNavigate(true);
  const app = useAppStore();

  const crm_company_location_id = useMemo(
    () => props.match.params.crm_company_location_id,
    [props.match.params.crm_company_location_id],
  );

  const [iteration, setIteration] = useState(0);
  const [day, setDayNative] = useState<Date>(
    props.match.params.day ? parse(props.match.params.day, "RRRR-I", new Date()) : getMondayOfDate(new Date()),
  );
  const dayIsToday = useMemo(() => isToday(day), [day]);

  const setDay = useCallback(
    (date: Date) => {
      setDayNative(date);
      navigate(`/capdash/data/${crm_company_location_id}/${format(date, "RRRR-I", { locale: nl })}`);
    },
    [crm_company_location_id],
  );

  const setPreviousDay = useCallback(() => {
    setDay(subDays(day, 7));
  }, [day]);
  const setNextDay = useCallback(() => {
    setDay(addDays(day, 7));
  }, [day]);
  const hasRole2 = app.session.person.role_id_set.includes(2);

  const location_set = useMemo(
    () =>
      app.data.crm_company_set
        .reduce(
          (prev, curr) => prev.concat(curr.location_set.map((loc) => ({ ...loc, company: curr }))),
          [] as (CrmCompanyLocationDto & { company: CrmCompanyDto })[],
        )
        .filter((x) => (hasRole2 || app.session.person.location_id_set.includes(x.id)) && !x.is_archived),
    [],
  );

  const [location, setLocation] = useState<CrmCompanyLocationDto>(
    (crm_company_location_id && location_set.find((x) => x.id.toString() === crm_company_location_id)) ||
      location_set[0],
  );
  const setLocationById = useCallback((value: number) => {
    setLocation(location_set.find((x) => x.id === value));
  }, []);

  const [previous] = useApiResult(async () => {
    try {
      return (await getApi().cdDataByDayPost({ location_id: location.id, day: format(subDays(day, 7), "yyyy-MM-dd") }))
        .data;
    } catch (e) {
      return null;
    }
  }, [location?.id, day]);
  const [current] = useApiResult(async () => {
    try {
      return (await getApi().cdDataByDayPost({ location_id: location.id, day: format(day, "yyyy-MM-dd") })).data;
    } catch (e) {
      return null;
    }
  }, [location?.id, day, iteration]);

  const form = useRxjsForm<CdDataRegistryDataDto>({
    initial: initial,
    submit: async (values) => {
      try {
        const res = await getApi().cdDataRegisterPost({
          location_id: location.id,
          day: format(day, "yyyy-MM-dd"),
          ...values,
          auto_stamped: false,
        });

        setIteration((x) => x + 1);
      } catch (err) {
        if (err) app.actions.getApiError(err);
        console.error(err);
      }
    },
  });

  useEffect(() => {
    if (current.res?.id) {
      form.actions.setValues(current.res.data);
    } else {
      form.actions.setValues({
        ...initial,
        waiting_duration: previous.res?.waiting_duration || 0,
        reserved: previous.res?.reserved || 0,
      });
    }
  }, [current.res?.id, previous.res?.waiting_duration, day]);

  const calculated = useMemo(() => {
    const capacity = current.res?.capacity || 0;
    const occupied_previous = previous.res?.occupied || 0;
    const occupied = occupied_previous + form.values.waiting_to_intake - form.values.outflow;

    return {
      capacity,
      waiting: (previous.res?.waiting || 0) + form.values.waiting_new - form.values.waiting_to_intake,
      intake: form.values.waiting_to_intake,
      outflow: form.values.outflow,
      reserved: (current.res ? form.values.reserved : previous.res?.reserved) || 0,
      occupied,
      available: capacity - occupied,
    };
  }, [form.values, previous.res?.id, current.res]);

  return {
    app,
    form,
    calculated,
    previous,
    current,
    iteration,
    day,
    setDay,
    setPreviousDay,
    setNextDay,
    dayIsToday,
    location_set,
    location,
    setLocationById,
  };
}

export default CapDashData;
