import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { CrmCompanyLocationDto, CrmCompanyLocationSaveDto } from "../../../api";
import capdash_data from "../../../core/capdash_data";
import DialogBusy from "../../../core/DialogBusy";
import HasPlugin from "../../../core/HasPlugin";
import provinces from "../../../core/provinces";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import SelectFieldExt from "../../../form/SelectFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";

type Props = {
  record_id?: number;
  company_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const CrmCompanyLocationSaveDrawer: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const gc = useGenericStyles();
  const { state, form, actions } = useComponent(props);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>
          {_t("Locatie")} {state.record ? "aanpassen" : "toevoegen"}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldExt form={form} name="name" label="Naam" />
            </Grid>
            <HasPlugin name="dossier">
              <Grid item xs={6}>
                <TextFieldExt
                  form={form}
                  type="number"
                  name="capacity_total"
                  label="Bed capaciteit totaal beschikbaar"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldExt
                  form={form}
                  type="number"
                  name="capacity_external"
                  label="Bed capaciteit bezet door externen"
                />
              </Grid>
            </HasPlugin>
            <HasPlugin name="capdash">
              <Grid item xs={12}>
                <Typography variant="subtitle1">Zorginformatie</Typography>
                <Typography variant="body2" color="textSecondary">
                  Informatie over de primaire zorgfunctie van deze {_t("ketenpartner")} {_t("locatie")}.
                </Typography>
              </Grid>

              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_life_course_region"
                  label="Levensloopregio"
                  items={capdash_data.cd_life_course_region.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
              <Grid item md={6}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_care_type"
                  label="Type zorg"
                  items={capdash_data.cd_care_type.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
              <Grid item md={6}>
                <TextFieldExt form={form} type="number" name="cd_care_capacity" label="Bed capaciteit" />
              </Grid>
              <Grid item md={4}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_security_level"
                  label="Beveiligingsniveau"
                  items={capdash_data.cd_security_level.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
              <Grid item md={8}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_legal_regime_set"
                  label="Rechtsregime"
                  multiple
                  items={capdash_data.cd_legal_regime.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
              <Grid item md={6}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_primary_specialism"
                  label="Primair specialisme"
                  items={capdash_data.cd_primary_specialism.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
              <Grid item md={6}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_characteristic_specialism"
                  label="Kenmerk specialisme"
                  items={capdash_data.cd_characteristic_specialism.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
              <Grid item md={12}>
                <TextFieldExt
                  form={form}
                  name="cd_characteristic_specialism_explanation"
                  label="Toelichting specialisme"
                  multiline
                />
              </Grid>
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="cd_contraindication_set"
                  label="Contra-indicatie(s)"
                  multiple
                  items={capdash_data.cd_contraindication.map((x) => ({ value: x, label: x }))}
                />
              </Grid>
            </HasPlugin>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => actions.onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>

        <DialogBusy busy={state.busy} />
      </form>
    </Drawer>
  );
};

type State = {
  busy: boolean;
  record?: CrmCompanyLocationDto;
};

function useComponent(props: Props) {
  const app = useAppStore();
  const { record_id, company_id, onClose } = props;
  const { state, getState, next } = useRxjsStore<State>({
    busy: false,
  });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));
        const record = await callApi(
          app,
          async (api) => record_id && (await api.crmCompanyLocationOnePost({ id: record_id })).data,
        );
        next((d) => {
          d.busy = false;
          d.record = record;
        });
      },

      save: async (values: CrmCompanyLocationSaveDto) => {
        const res = await callApi(app, async (api) => await api.crmCompanyLocationSavePost(values));

        if (res.data?.id) await actions.onClose(true);
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));

        try {
          await onClose(success);
        } catch (e) {
          console.error(e);
          next((d) => (d.busy = false));
        }
      },
    }),
    [record_id],
  );

  const form = useRxjsForm<CrmCompanyLocationSaveDto>({
    initial: {
      id: record_id,
      company_id,
      name: "",
      capacity_total: 0,
      capacity_external: 0,
      cd_life_course_region: "",
      cd_care_type: "",
      cd_care_capacity: "",
      cd_security_level: "",
      cd_legal_regime_set: [],
      cd_primary_specialism: "",
      cd_characteristic_specialism: "",
      cd_characteristic_specialism_explanation: "",
      cd_contraindication_set: [],
    },
    submit: (values) => actions.save(values),
  });

  useAsyncEffect(actions.load, [record_id]);

  useEffect(() => {
    console.info(form.initial, state.record);
    form.actions.setValues(state.record ? _.pick(state.record, Object.keys(form.initial) as any) : form.initial);
  }, [state.record?.id]);

  return { state, form, actions };
}

export default CrmCompanyLocationSaveDrawer;
