import { Chip, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { CareDossierDataDto, CrmDataScreenDto, CrmDataScreenFieldDto, CrmDataScreenFieldTypeEnum } from "../../../api";
import FormatDate from "../../../core/FormatDate";
import useGenericStyles from "../../../core/useGenericStyles";
import { FormatCrmPerson } from "../../CrmPersonTable/Ext/CrmPersonExtDataTable";

type Props = {
  screen: CrmDataScreenDto;
  data: CareDossierDataDto;
};

const CrmDataScreenExtDisplay: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { screen, data } = props;

  if (!data) {
    return <Typography variant="body2">Er zijn nog geen gegevens ingevoerd bij dit onderdeel</Typography>;
  }

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={gc.TableCell_Shrink}>
            <strong>Geschreven op</strong>
          </TableCell>
          <TableCell>
            #{data.id} <FormatDate value={data.created_at} />
            {" door "}
            <FormatCrmPerson person_id={data.created_by_id} />
          </TableCell>
        </TableRow>

        {screen.fields_json.field_set
          .map((field) => {
            const value = data?.fields_json[field.identifier];
            return { field, value };
          })
          .filter((x) => [undefined, null, ""].indexOf(x.value) === -1)
          .map(({ field, value }, rx) => {
            return (
              <TableRow key={rx}>
                <TableCell className={gc.TableCell_Shrink}>{field.name}</TableCell>
                <TableCell>
                  <CrmDataScreenExtDisplayFactory field={field} value={value} />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

const CrmDataScreenExtDisplayFactory: React.FC<{ field: CrmDataScreenFieldDto; value: any }> = (props) => {
  const { field, value } = props;

  switch (field.type) {
    case CrmDataScreenFieldTypeEnum.STATICTEXT:
      break;
    case CrmDataScreenFieldTypeEnum.TEXTSHORT:
      return value;
    case CrmDataScreenFieldTypeEnum.TEXTLONG:
      return value;
    case CrmDataScreenFieldTypeEnum.CHOICESINGLE:
      return field.option_set.find((x) => x.value === value).label;
    case CrmDataScreenFieldTypeEnum.CHOICEMULTIPLE:
      return (
        <>
          {field.option_set
            .filter((x) => value.indexOf(x.value) !== -1)
            .map((opt, rx) => (
              <Chip key={rx} label={opt.label} variant="outlined" style={{ marginRight: 4 }} />
            ))}
        </>
      );
    case CrmDataScreenFieldTypeEnum.DATEPAST:
      break;
    case CrmDataScreenFieldTypeEnum.DATEALL:
      break;
    case CrmDataScreenFieldTypeEnum.UPLOAD:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALNAME:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALADDRESS:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALPHONE:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALEMAIL:
      break;
  }

  return (
    <>
      Onbekend veld-type: <strong>{field.type}</strong>.
    </>
  );
};

export default CrmDataScreenExtDisplay;
