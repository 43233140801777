import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { TableRowDto } from "../../api";
import useNavigate from "../../core/useNavigate";
import useTableStyles from "../../core/useTableStyles";
import useCareDossierTableStore from "./useCareDossierTableStore";

const CareDossierTableRowButtonsStart: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const navigate = useNavigate();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Openen.">
        <IconButton onClick={() => navigate(`/care/dossier/view/${row.record_id}`)}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

const CareDossierTableRowButtonsEnd: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const { actions } = useCareDossierTableStore();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Aanpassen.">
        <IconButton onClick={() => actions.setDrawer({ name: "DrawerSave", record_id: row.record_id })}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export { CareDossierTableRowButtonsStart, CareDossierTableRowButtonsEnd };
