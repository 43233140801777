import { SkeletonDrawerMenuItemButton } from "./SkeletonDrawerTypes";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import React from "react";
import useSkeletonDrawerStyles from "./useSkeletonDrawerStyles";
import clsx from "clsx";
import { useHistory, useRouteMatch } from "react-router-dom";
import useNavigate from "../../core/useNavigate";

type Props = {
  item: SkeletonDrawerMenuItemButton;
  depth?: number;
};

export default function SkeletonDrawerMenuListItem(props: Props) {
  const { item, depth } = props;
  const path = item.path!;
  const navigate = useNavigate();
  const match = useRouteMatch(path);
  const classes = useSkeletonDrawerStyles();
  const selected = match !== null;
  const class_name = (classes as any)[`ListItem_Depth_${depth || 0}`];

  function onClick() {
    navigate(path);
  }

  return (
    <ListItem
      button
      onClick={onClick}
      className={clsx([class_name, selected && classes.ListItem_Selected])}
    >
      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
      <ListItemText inset={!item.icon} primary={item.label} />
      {!!item.count && (
        <ListItemSecondaryAction>
          <Chip size="small" variant="outlined" label={item.count} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
