import React from "react";
import { CareDossierTableRowsRequest } from "../../api";
import TableDrawerDownload from "../../core/TableDrawerDownload";
import TableDrawerHeaders from "../../core/TableDrawerHeaders";
import { getApi } from "../../core/useApi";
import CareDossierDrawerCreate from "../CareDossier/Drawers/CareDossierDrawerCreate";
import CareDossierDrawerSave from "../CareDossier/Drawers/CareDossierDrawerSave";
import CareDossierDrawerView from "../CareDossier/Drawers/CareDossierDrawerView";
import CareDossierDrawerTableFilter from "./CareDossierTableDrawerFilter";
import useCareDossierTableStore from "./useCareDossierTableStore";

const CareDossierTableDrawers: React.FC = () => {
  const table = useCareDossierTableStore();

  return (
    <>
      {table.drawer?.name === "TableHeaders" && (
        <TableDrawerHeaders
          open={true}
          header_set={table.header_set}
          used_set={table.req.header_set || ["id", "name"]}
          onClose={() => table.actions.setDrawer(undefined)}
          onSave={(header_set) => {
            table.actions.setRequest({ header_set });
            table.actions.setDrawer(undefined);
          }}
        />
      )}
      {table.drawer?.name === "TableDownload" && (
        <TableDrawerDownload
          onClose={() => table.actions.setDrawer(undefined)}
          onDownloadStart={async () => (await getApi().careDossierTableDownloadPost(table.req)).data}
        />
      )}
      {table.drawer?.name === "DrawerCreate" && (
        <CareDossierDrawerCreate
          onClose={(success: boolean) => {
            table.actions.setDrawer(undefined);
            if (success) table.actions.load();
          }}
        />
      )}
      {table.drawer?.name === "DrawerSave" && (
        <CareDossierDrawerSave
          record_id={table.drawer.record_id}
          person_id={table.drawer.person_id}
          onClose={(success: boolean) => {
            table.actions.setDrawer(undefined);
            if (success) table.actions.load();
          }}
        />
      )}
      {table.drawer?.name === "DrawerFilter" && (
        <CareDossierDrawerTableFilter
          req={table.req}
          onClose={(req: CareDossierTableRowsRequest) => {
            table.actions.setDrawer(undefined);

            if (req) {
              table.actions.setRequest(req);
            }
          }}
        />
      )}
      {table.drawer?.name === "DrawerView" && (
        <CareDossierDrawerView onClose={() => table.actions.setDrawer(undefined)} record_id={table.drawer.record_id} />
      )}
    </>
  );
};

export default CareDossierTableDrawers;
