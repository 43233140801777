import { PropTypes } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format, parse } from "date-fns";
import React from "react";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";

type Props = {
  form: RxjsForm<any>;

  name: string;
  label: string;

  format?: string;
  margin?: PropTypes.Margin;
  readOnly?: boolean;
};

const DateFieldExtComp: React.FC<Props> = (props) => {
  const { form, name, label, readOnly } = props;
  const { value, error, dirty } = extractRxjsField(form, name);

  const [valid, setValid] = React.useState(true);

  const extFormat = props.format || "dd/MM/yyyy";

  const extValue = React.useMemo(() => {
    if (value) {
      return parse(value.split("T")[0], "yyyy-MM-dd", new Date());
    } else {
      return null;
    }
  }, [value]);

  const onChange = React.useCallback((date: Date | null) => {
    const valid = date instanceof Date && !isNaN(date.getTime());

    if (valid) {
      setValid(true);
      form.actions.setValue(name, format(date, "yyyy-MM-dd"));
    } else if (date === null) {
      setValid(true);
      form.actions.setValue(name, "");
    } else {
      setValid(false);
    }
  }, []);

  return (
    <KeyboardDatePicker
      // disableToolbar
      format={extFormat}
      margin={props.margin}
      label={label}
      value={extValue}
      onChange={onChange}
      readOnly={readOnly}
      inputVariant="outlined"
      variant="dialog"
      error={dirty && !!error}
      helperText={dirty ? error : undefined}
      onBlur={() => {
        if (!valid) {
          form.actions.setValue(name, "");
        } else {
          form.actions.setDirty(name);
        }
      }}
      fullWidth
    />
  );
};

const DateFieldExt = React.memo(DateFieldExtComp, equalityRxjsFieldCompare);

export default DateFieldExt;
