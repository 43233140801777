import * as React from "react";
import { TsCompanyDto } from "../../api";
import {
  Avatar,
  Card,
  CardContent,
  colors,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import { DmzAuthenticatePageActions, useDmzAuthenticateStyles } from "./DmzAuthenticate";
import useGenericStyles from "../../core/useGenericStyles";
import DmzAuthenticateStepper from "./DmzAuthenticateStepper";

const DmzAuthenticateScreenSessionPick: React.FC<{
  email: string;
  company_set: TsCompanyDto[];
  actions: DmzAuthenticatePageActions;
}> = ({ email, company_set, actions }) => {
  const theme = useTheme();
  const classes = useDmzAuthenticateStyles();
  const gc = useGenericStyles();

  return (
    <Card className={classes.Form}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Aanmelden
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Selecteer de organisatie waar u bij wilt inloggen.
        </Typography>

        <DmzAuthenticateStepper step={1} steps={["E-mail", "Organisatie", "Wachtwoord"]} />

        <Typography variant="body1" paragraph>
          We hebben meerdere organisaties gevonden waar uw account <strong className={gc.Text_NoWrap}>{email}</strong>{" "}
          toegang tot heeft. Kies hieronder de organisatie uit waar u wilt inloggen.
        </Typography>

        <List>
          {company_set.map((company) => {
            const backgroundColor = colors[company.avatar_color][company.avatar_hue];
            const color = theme.palette.getContrastText(backgroundColor);

            return (
              <ListItem
                button
                key={company.slug}
                onClick={() => {
                  actions.onCompanySelect(company.id);
                }}
              >
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor, color }}>{company.avatar_title}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={company.name} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs />
          <Grid item>
            <Link
              href="#session-identify"
              onClick={(e) => ~e.preventDefault() && actions.setScreen("session-identify")}
              variant="body2"
            >
              Ander e-mailadres.
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DmzAuthenticateScreenSessionPick;
