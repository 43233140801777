import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React from "react";

type Props = {
  icon?: React.ReactNode;

  items: (PropItem | null | undefined | false)[];
};

type PropItem = {
  icon?: React.ReactNode;
  label: React.ReactNode;
  onClick: () => void;
};

const IconButtonDropExt: React.FC<Props> = (props) => {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);

  const onButtonClick = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onClickAway = React.useCallback(() => {
    setOpen(false);
  }, [open]);

  const onItemClick = React.useCallback(
    (callback: () => void) => {
      setOpen(false);
      callback();
    },
    [open],
  );

  return (
    <>
      <Tooltip title="Instellingen.">
        <IconButton onClick={onButtonClick} ref={ref}>
          {props.icon || <MoreVert />}
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={ref.current} transition placement="bottom-end" style={{ zIndex: 10000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList>
                  {props.items
                    .filter((x) => !!x)
                    .map((item: PropItem, rx) => (
                      <MenuItem key={rx} onClick={() => onItemClick(item.onClick)}>
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        <Typography variant="inherit">{item.label}</Typography>
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default IconButtonDropExt;
