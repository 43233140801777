import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Edit,
  ExpandMore,
  Flag,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Link,
  LocalOffer,
  PlayCircleOutline,
  TrackChanges,
} from "@material-ui/icons";
import { parse } from "date-fns";
import React, { useMemo, useState } from "react";
import _ from "underscore";
import {
  CareMatrixDto,
  CareMatrixMarkMomentEnum,
  CareMatrixMarkValuesScoreDto,
  CareMatrixRowDto,
  MatrixCoreDtoExt,
  MatrixRowDto,
} from "../../../api";
import FormatDate from "../../../core/FormatDate";
import getTranslation from "../../../core/getTranslation";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import QuillContent from "../../../core/QuillContent";
import TagCoreChip from "../../../core/TagCoreChip";
import { getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useTranslations from "../../../core/useTranslations";
import CareMatrixService from "../../CareMatrix/CareMatrixService";
import CareMatrixExtRadarChart from "../../CareMatrix/Ext/CareMatrixExtRadarChart";
import CrmNoteDrawerSave from "../../CrmNote/Drawers/CrmNoteDrawerSave";
import { CareDossierViewActions } from "../CareDossierView";

type Props = {
  matrix_core_id: number;
  care_matrix: CareMatrixDto;
  actions: CareDossierViewActions;
  person_id: number;
  dossier_id: number;
};

type CareMatrixRowDisplay = {
  core_matrix_row: MatrixRowDto;
  care_matrix_row?: CareMatrixRowDto;

  is_active: boolean;

  mark_zero_value?: CareMatrixMarkValuesScoreDto;
  mark_target_value?: CareMatrixMarkValuesScoreDto;
  mark_latest_value?: CareMatrixMarkValuesScoreDto;
};

const CareDossierViewExtCareMatrix: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const app = useAppStore();
  const gc = useGenericStyles();
  const { matrix_core_id, care_matrix, actions, person_id, dossier_id } = props;
  const [expanded, setExpanded] = React.useState(false);

  const matrix_core = useMemo(
    () => app.data.matrix_core_set.find((x) => x.id === matrix_core_id),
    [props.matrix_core_id],
  );

  const drawerProps = {
    matrix_core_id: matrix_core.id,
    person_id: person_id,
    dossier_id: dossier_id,
  };

  const rows: CareMatrixRowDisplay[] = matrix_core.row_set
    .map((core_matrix_row) => {
      const row_id = core_matrix_row.id;
      const care_matrix_row = care_matrix?.row_set.find((x) => x.matrix_row_id === row_id);
      const mark_zero_value = CareMatrixService.getValueFromMark(care_matrix?.mark_zero, row_id);
      const mark_target_value = CareMatrixService.getValueFromMark(care_matrix?.mark_target, row_id);
      const mark_latest_value = CareMatrixService.getValueFromMark(care_matrix?.mark_latest, row_id);

      const is_active = [mark_zero_value, mark_target_value, mark_latest_value].some((x) => !!x && x.score);

      return {
        core_matrix_row,
        care_matrix_row,
        is_active,
        mark_zero_value,
        mark_target_value,
        mark_latest_value,
      };
    })
    .filter((row) => !matrix_core.has_hide_empty_rows || row.is_active);

  return (
    <Accordion key={matrix_core.id} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
          {matrix_core.name}
        </Typography>
        <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
          {!!care_matrix ? (
            <>
              {matrix_core.has_zero_mark && care_matrix.score_zero !== null && (
                <Tooltip title={care_matrix.mark_zero?.description}>
                  <Chip
                    label={
                      <>
                        0m: {care_matrix.score_zero}
                        {", "}
                        <FormatDate value={care_matrix.mark_zero?.created_at} />
                      </>
                    }
                    className={gc.Chip_Inline}
                    variant="outlined"
                    style={{ color: "inherit" }}
                  />
                </Tooltip>
              )}
              {matrix_core.has_continuation_mark && care_matrix.score_latest !== null && (
                <Tooltip title={care_matrix.mark_latest?.description}>
                  <Chip
                    label={
                      <>
                        nu: {care_matrix.score_latest}
                        {", "}
                        <FormatDate value={care_matrix.mark_latest?.created_at} />
                      </>
                    }
                    className={gc.Chip_Inline}
                    variant="outlined"
                    style={{ color: "inherit" }}
                  />
                </Tooltip>
              )}
              {matrix_core.has_target_mark && care_matrix.score_target !== null && (
                <Tooltip title={care_matrix.mark_target?.description}>
                  <Chip
                    label={
                      <>
                        doel: {care_matrix.score_target}
                        {", "}
                        <Tooltip title="Doel aangemaakt op">
                          <FormatDate value={care_matrix.mark_target?.created_at} />
                        </Tooltip>
                      </>
                    }
                    className={gc.Chip_Inline}
                    variant="outlined"
                    style={{ color: "inherit" }}
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <>leeg</>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Factor</TableCell>
              {matrix_core.has_responsibles && <TableCell>{_t("Ketenpartner(s)")}</TableCell>}
              {matrix_core.has_tags && <TableCell>Tags</TableCell>}
              {matrix_core.has_zero_mark && (
                <TableCell className={gc.TableCell_Shrink} align="right">
                  0m
                </TableCell>
              )}
              {matrix_core.has_continuation_mark && (
                <TableCell className={gc.TableCell_Shrink} align="right">
                  nu
                </TableCell>
              )}
              {matrix_core.has_target_mark && (
                <TableCell className={gc.TableCell_Shrink} align="right">
                  doel
                </TableCell>
              )}

              {(matrix_core.has_responsibles || matrix_core.has_tags) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rx) => (
              <CareDossierViewExtCareMatrixRow key={rx} matrix_core={matrix_core} row={row} actions={actions} />
            ))}

            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>Er is nog geen data geregistreerd</TableCell>
                {matrix_core.has_zero_mark && <TableCell />}
                {matrix_core.has_continuation_mark && <TableCell />}
                {matrix_core.has_target_mark && <TableCell />}
              </TableRow>
            )}

            {rows.length > 0 && (
              <TableRow className={gc.TableRow_Highlight}>
                <TableCell colSpan={2 + +matrix_core.has_responsibles + +matrix_core.has_tags}>
                  {/* Recidive score */}
                </TableCell>
                {matrix_core.has_zero_mark && (
                  <TableCell className={gc.TableCell_Shrink} align="right">
                    {care_matrix?.score_zero}
                  </TableCell>
                )}
                {matrix_core.has_continuation_mark && (
                  <TableCell className={gc.TableCell_Shrink} align="right">
                    {care_matrix?.score_latest}
                  </TableCell>
                )}
                {matrix_core.has_target_mark && (
                  <TableCell className={gc.TableCell_Shrink} align="right">
                    {care_matrix?.score_target}
                  </TableCell>
                )}
                {(matrix_core.has_responsibles || matrix_core.has_tags) && <TableCell />}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </AccordionDetails>
      <Divider />
      <AccordionDetails>
        <ButtonGroup variant="contained" color="primary">
          {matrix_core.has_zero_mark && (
            <Button
              startIcon={<PlayCircleOutline />}
              onClick={() => {
                actions.setDrawer({
                  name: "CareMatrixMark",
                  moment: CareMatrixMarkMomentEnum.ZERO,
                  ...drawerProps,
                });
              }}
            >
              Nul Meting
            </Button>
          )}
          {matrix_core.has_target_mark && (
            <Button
              startIcon={<Flag />}
              onClick={() => {
                actions.setDrawer({
                  name: "CareMatrixMark",
                  moment: CareMatrixMarkMomentEnum.TARGET,
                  ...drawerProps,
                });
              }}
            >
              Doel Meting
            </Button>
          )}
          {matrix_core.has_continuation_mark && (
            <Button
              startIcon={<TrackChanges />}
              onClick={() => {
                actions.setDrawer({
                  name: "CareMatrixMark",
                  moment: CareMatrixMarkMomentEnum.CONTINUATION,
                  ...drawerProps,
                });
              }}
            >
              Actuele Meting
            </Button>
          )}
        </ButtonGroup>
      </AccordionDetails>
      <Divider />
      {expanded && (
        <AccordionDetails>
          <CareMatrixExtRadarChart
            matrix_core_id={matrix_core.id}
            mark_set={care_matrix?.mark_set || []}
            zero={care_matrix?.mark_zero}
            target={care_matrix?.mark_target}
            current={care_matrix?.mark_latest}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

type CareDossierViewExtCareMatrixRowProps = {
  matrix_core: MatrixCoreDtoExt;
  row: CareMatrixRowDisplay;
  actions: CareDossierViewActions;
};

const CareDossierViewExtCareMatrixRow: React.FC<CareDossierViewExtCareMatrixRowProps> = ({
  matrix_core,
  row,
  actions,
}) => {
  const _t = useTranslations();
  const gc = useGenericStyles();
  const [open, setOpen] = useState(false);

  const { core_matrix_row, care_matrix_row } = row;

  return (
    <>
      <TableRow key={core_matrix_row.id}>
        <TableCell className={gc.TableCell_Shrink}>
          {care_matrix_row && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{core_matrix_row.name}</TableCell>
        {matrix_core.has_responsibles && (
          <TableCell>
            <Chip
              color={row.care_matrix_row.crm_company_location ? "primary" : "default"}
              label={
                row.care_matrix_row.crm_company_location
                  ? `${row.care_matrix_row.crm_company?.name} - ${row.care_matrix_row.crm_company_location.name}`
                  : _t("Geen ketenpartner gekoppeld")
              }
            />
          </TableCell>
        )}
        {matrix_core.has_tags && (
          <TableCell>
            {care_matrix_row?.tag_set
              .filter((x) => {
                const today = new Date(new Date().toDateString());
                const dateEnd = parse(x.date_end, "yyyy-MM-dd", new Date());
                return !x.date_end || dateEnd >= today;
              })
              .map((care_matrix_row_tag) => {
                return (
                  <TagCoreChip
                    tag_id={care_matrix_row_tag.tag_id}
                    date_start={care_matrix_row_tag.date_start}
                    date_end={care_matrix_row_tag.date_end}
                    onClick={() =>
                      actions.setDrawer({
                        name: "CareMatrixRowTag",
                        care_matrix_row_tag_id: care_matrix_row_tag.id,
                        care_matrix_row_id: care_matrix_row.id,
                      })
                    }
                  />
                );
              })}
          </TableCell>
        )}
        {matrix_core.has_zero_mark && (
          <TableCell className={gc.TableCell_Shrink} align="right">
            {(row.mark_zero_value && row.mark_zero_value.score) || "-"}
          </TableCell>
        )}
        {matrix_core.has_continuation_mark && (
          <TableCell className={gc.TableCell_Shrink} align="right">
            {(row.mark_latest_value && row.mark_latest_value.score) || "-"}
          </TableCell>
        )}
        {matrix_core.has_target_mark && (
          <TableCell className={gc.TableCell_Shrink} align="right">
            {(row.mark_target_value && row.mark_target_value.score) || "-"}
          </TableCell>
        )}
        {(matrix_core.has_responsibles || matrix_core.has_tags) && (
          <TableCell className={gc.TableCell_Shrink} align="right">
            <IconButtonDropExt
              items={[
                {
                  icon: <Link />,
                  label: _t("Ketenpartner koppeling"),
                  onClick: () =>
                    actions.setDrawer({ name: "CareMatrixRowResponsible", care_matrix_row_id: care_matrix_row.id }),
                },
                {
                  icon: <LocalOffer />,
                  label: "Tag toevoegen",
                  onClick: () =>
                    actions.setDrawer({ name: "CareMatrixRowTag", care_matrix_row_id: care_matrix_row.id }),
                },
              ]}
            />
          </TableCell>
        )}
      </TableRow>
      {care_matrix_row && (
        <TableRow>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0 }}
            colSpan={
              2 +
              +matrix_core.has_responsibles +
              +matrix_core.has_tags +
              +matrix_core.has_zero_mark +
              +matrix_core.has_continuation_mark +
              +matrix_core.has_target_mark +
              +(matrix_core.has_responsibles || matrix_core.has_tags)
            }
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box marginTop={3} marginBottom={3}>
                <CareDossierViewExtCareMatrixRowCompanies row={row} actions={actions} />
                <CareDossierViewExtCareMatrixRowTarget row={row} actions={actions} />
                <CareDossierViewExtCareMatrixRowNotes row={row} actions={actions} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

type CareDossierViewExtCareMatrixRowCompaniesProps = {
  row: CareMatrixRowDisplay;
  actions: CareDossierViewActions;
};

const CareDossierViewExtCareMatrixRowCompanies: React.FC<CareDossierViewExtCareMatrixRowCompaniesProps> = ({
  row,
  actions,
}) => {
  const gc = useGenericStyles();
  const { care_matrix_row } = row;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
          Ketenpartner(s)
        </Typography>
        <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
          {care_matrix_row.crm_company?.name} - {care_matrix_row.crm_company_location?.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ketenpartner</TableCell>
              <TableCell>Vanaf</TableCell>
              <TableCell>Tot</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {care_matrix_row.company_set.map((care_matrix_row_company) => {
              return (
                <TableRow>
                  <TableCell>
                    {care_matrix_row_company.crm_company?.name} - {care_matrix_row_company.crm_company_location?.name}
                  </TableCell>
                  <TableCell>
                    <FormatDate value={care_matrix_row_company.started_at} fmt="PP" />
                  </TableCell>
                  <TableCell>
                    <FormatDate value={care_matrix_row_company.closed_at} fmt="PP" />
                  </TableCell>
                  <TableCell className={gc.TableCell_Shrink}>
                    <IconButtonDropExt
                      items={[
                        {
                          icon: <Edit />,
                          label: "Aanpassen",
                          onClick: () => {
                            actions.setDrawer({
                              name: "CareMatrixRowResponsible",
                              id: care_matrix_row_company.id,
                              care_matrix_row_id: care_matrix_row.id,
                            });
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
      <AccordionActions>
        <Button
          onClick={() =>
            actions.setDrawer({ name: "CareMatrixRowResponsible", care_matrix_row_id: care_matrix_row.id })
          }
          variant="text"
          startIcon={<Edit />}
        >
          Ketenparter koppelen
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

type CareDossierViewExtCareMatrixRowTargetProps = {
  row: CareMatrixRowDisplay;
  actions: CareDossierViewActions;
};

const CareDossierViewExtCareMatrixRowTarget: React.FC<CareDossierViewExtCareMatrixRowTargetProps> = ({
  row,
  actions,
}) => {
  const gc = useGenericStyles();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
          Doelstelling
        </Typography>
        <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
          {row.care_matrix_row.target ? "ingevoerd" : "leeg"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {row.care_matrix_row.target ? (
          <QuillContent content={row.care_matrix_row.target} />
        ) : (
          <em>Er is nog geen doelstelling opgegeven</em>
        )}
      </AccordionDetails>
      <AccordionActions>
        <Button
          onClick={() => actions.setDrawer({ name: "CareMatrixRowTarget", care_matrix_row_id: row.care_matrix_row.id })}
          variant="text"
          startIcon={<Edit />}
        >
          Doelstelling aanpassen
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

type CareDossierViewExtCareMatrixRowNotesProps = {
  row: CareMatrixRowDisplay;
  actions: CareDossierViewActions;
};

const CareDossierViewExtCareMatrixRowNotes: React.FC<CareDossierViewExtCareMatrixRowNotesProps> = ({
  row,
  actions,
}) => {
  const gc = useGenericStyles();

  const [filter, setFilter] = useState<"all" | "open" | "closed">("all");
  const [dialog, setDialog] = useState<undefined | { name: "note"; crm_note_id?: number }>(undefined);

  const { record, load, booted, loading } = useRxjsFetch(async () => {
    return await getApi().crmNoteManyPost({
      connected_matrix_row_id: row.care_matrix_row.id,
      closed_state: [],
      is_closed: -1,
    });
  }, [row.care_matrix_row.id]);

  const counts = useMemo(() => {
    const partial: { open?: number; closed?: number } =
      booted && record?.data?.records ? _.countBy(record.data.records, (x) => (!x.closed_at ? "open" : "closed")) : {};

    return {
      open: partial.open || 0,
      closed: partial.closed || 0,
      all: (partial.open || 0) + (partial.closed || 0),
    };
  }, [booted && record?.data?.records]);

  const record_set = useMemo(
    () => record?.data?.records.filter((x) => filter === "all" || !x.closed_at === (filter === "open")),
    [record?.data?.records, filter],
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
          Afspraken
        </Typography>
        <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
          {counts.open > 0 ? `${counts.open} lopende afspraken` : "geen lopende afspraken"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Chip
            label={`Alles (${counts.all})`}
            className={gc.Chip_Inline}
            onClick={() => setFilter("all")}
            variant={filter === "all" ? "default" : "outlined"}
          />
          <Chip
            label={`Openstaand (${counts.open})`}
            className={gc.Chip_Inline}
            onClick={() => setFilter("open")}
            variant={filter === "open" ? "default" : "outlined"}
          />
          <Chip
            label={`Afgerond (${counts.closed})`}
            className={gc.Chip_Inline}
            onClick={() => setFilter("closed")}
            variant={filter === "closed" ? "default" : "outlined"}
          />
        </Box>
      </AccordionDetails>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Informatie</TableCell>
              <TableCell>Afspraak</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {booted &&
              record_set?.map((target, rx) => (
                <TableRow key={rx}>
                  <TableCell className={gc.TableCell_Shrink} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ borderBottom: 0 }}>Auteur</TableCell>
                          <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                            {target.created_by.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 0 }}>Start</TableCell>
                          <TableCell style={{ borderBottom: 0 }}>
                            <FormatDate fmt="PP" value={target.started_at} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 0 }}>Eind</TableCell>
                          <TableCell style={{ borderBottom: 0 }}>
                            <FormatDate fmt="PP" value={target.closed_at} emptyLabel="heden" />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">{target.subject}</Typography>
                    <QuillContent content={target.document} />
                  </TableCell>
                  <TableCell className={gc.TableCell_Shrink}>
                    {getTranslation(`crm_note_closed_state.${target.closed_state}`)}
                  </TableCell>
                  <TableCell className={gc.TableCell_Shrink}>
                    <IconButtonDropExt
                      items={[
                        {
                          icon: <Edit />,
                          label: "Aanpassen",
                          onClick: () => {
                            setDialog({ name: "note", crm_note_id: target.id });
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}

            {!record_set?.length && (
              <TableRow>
                <TableCell colSpan={4}>
                  <em>Er zijn geen afspraken</em>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </AccordionDetails>
      <AccordionDetails>
        <Button variant="contained" color="primary" onClick={() => setDialog({ name: "note" })}>
          Nieuwe afspraak
        </Button>
      </AccordionDetails>

      {dialog?.name === "note" && (
        <CrmNoteDrawerSave
          id={dialog.crm_note_id}
          connected_matrix_row_id={row.care_matrix_row.id}
          onClose={async (success?: boolean) => {
            setDialog(undefined);
            if (success) {
              await load();
            }
          }}
        />
      )}
    </Accordion>
  );
};

export default CareDossierViewExtCareMatrix;
