import { Chip, ChipProps, colors, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import useAppStore from "./useAppStore";
import useGenericStyles from "./useGenericStyles";

type Props = {
  crm_role_id: number;
} & Omit<ChipProps, "label" | "style" | "className">;

const CrmRoleChip: React.FC<Props> = (props) => {
  const { crm_role_id, ...extra } = props;
  const gc = useGenericStyles();
  const theme = useTheme();
  const app = useAppStore();
  const crm_role = useMemo(() => app.data.crm_role_set.find((x) => x.id === crm_role_id), [crm_role_id]);
  const style = useMemo(() => {
    const muiColor = crm_role.name_slug.includes("beheerder")
      ? colors.deepOrange["500"]
      : colors[crm_role.color_name][crm_role.color_hue];

    return {
      backgroundColor: muiColor,
      color: theme.palette.getContrastText(muiColor),
    };
  }, [crm_role]);

  return <Chip label={crm_role.name} style={style} className={gc.Chip_Inline} {...extra} />;
};

export default CrmRoleChip;
