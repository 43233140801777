import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Info } from "@material-ui/icons";
import clsx from "clsx";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { CareMatrixMarkMomentEnum, MatrixCoreDtoExt, MatrixRowDto } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import sleep from "../../../core/sleep";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../../core/useRxjsStore";
import TextFieldExt from "../../../form/TextFieldExt";

// region Types

type Props = {
  moment: CareMatrixMarkMomentEnum;
  matrix_core_id: number;
  record_id?: number;
  person_id: number;
  dossier_id: number;
  onClose: (success: boolean) => void | Promise<void>;
};

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },

  MatrixValue_Box: {
    "&:hover": {
      backgroundColor: colors.blue[100],
      color: theme.palette.getContrastText(colors.blue[100]),
    },
  },
  MatrixValue_Box_Active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),

    "&:hover": {
      backgroudColor: "inherit",
      color: "inherit",
    },
  },
}));

// endregion
// region Component

const CareMatrixDrawerMark: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { matrix_core, state, actions } = useComponent(props);
  const form = useRxjsForm<{ description: string }>({
    initial: {
      description: "",
    },
    submit: async (values) => {
      await actions.submit(values.description);
    },
  });

  return (
    <Drawer open anchor="right">
      <DialogContent>
        <form onSubmit={form.actions.submit} noValidate>
          <DialogTitle>{state.matrix_core.name}</DialogTitle>
          <DialogContent style={{ width: "90vw" }}>
            <Grid container spacing={2} className={classes.grid}>
              {matrix_core.row_set.map((matrix_row) => {
                const value = state.values.find((x) => x.row_id === matrix_row.id);

                return (
                  <Grid item md={12} key={matrix_row.id}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="h6">{matrix_row.name}</Typography>
                      </Grid>
                      {!!matrix_row.description && (
                        <Grid item>
                          <IconButton onClick={() => actions.setDialog(matrix_row.id)}>
                            <Info />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2} alignItems="stretch">
                      {matrix_core.column_set.map((matrix_column) => {
                        const matrix_value = matrix_core.value_set.find(
                          (x) => x.row_id === matrix_row.id && x.column_id === matrix_column.id,
                        );

                        return (
                          <Grid item sm key={matrix_column.id}>
                            <Box
                              boxShadow={1}
                              p={2}
                              style={{ height: "100%" }}
                              className={clsx(
                                value.column_id === matrix_column.id && classes.MatrixValue_Box_Active,
                                classes.MatrixValue_Box,
                              )}
                              onClick={() => {
                                actions.setScore(matrix_row.id, matrix_column.id);
                              }}
                            >
                              <Typography color="textSecondary" gutterBottom>
                                {matrix_column.name}
                              </Typography>
                              <Typography variant="body1">{matrix_value?.description}</Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <TextFieldExt form={form} label="Opmerking" name="description" />
          </DialogContent>
          <DialogActions>
            <Button type="button" disabled={state.busy} onClick={() => actions.onClose()}>
              Sluiten
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={state.busy}>
              Opslaan
            </Button>
          </DialogActions>

          <DialogBusy busy={state.busy} />

          <Dialog open={state.dialog !== undefined} onClose={() => actions.setDialog()}>
            <DialogTitle>{state.dialog?.name || "Geen titel"}</DialogTitle>
            <DialogContent>
              <ReactMarkdown source={state.dialog?.description || "Geen content"} />
            </DialogContent>
          </Dialog>
        </form>
      </DialogContent>
    </Drawer>
  );
};

// endregion
// region Store

type State = {
  busy: boolean;
  matrix_core: MatrixCoreDtoExt;
  dialog?: MatrixRowDto;

  values: {
    row_id: number;
    column_id: number;
    score: number;
  }[];
};

type Actions = ReturnType<typeof useActions>;

function useComponent(props: Props) {
  const app = useAppStore();
  const matrix_core = useMemo(() => app.data.matrix_core_set.find((x) => x.id === props.matrix_core_id), []);

  const { state, next, getState } = useRxjsStore<State>({
    busy: false,
    matrix_core,
    values: matrix_core.row_set.map((matrix_row) => ({
      row_id: matrix_row.id,
      column_id: matrix_core.column_set.find((x) => x.is_zero_default)?.id || -1,
      score: 0,
    })),
  });
  const actions: Actions = useActions(next, getState, props);

  useAsyncEffect(actions.loadPreviousMark, []);

  return { matrix_core, state, actions };
}

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>, props: Props) {
  const app = useAppStore();
  const isMounted = useIsMounted();

  const actions = useMemo(
    () => ({
      loadPreviousMark: async () => {
        next((d) => (d.busy = true));

        try {
          const res = (
            await getApi().careMatrixMarkLatestPost({
              matrix_core_id: props.matrix_core_id,
              crm_person_id: props.person_id,
              care_dossier_id: props.dossier_id,
              moment: props.moment,
            })
          ).data;

          next((d) => {
            res.values_json.values.forEach((v) => {
              const value = d.values.find((x) => x.row_id === v.row_id);
              value.column_id = v.column_id;
              if (v.column_id !== -1) {
                value.score = d.matrix_core.value_set.find(
                  (x) => x.row_id === v.row_id && x.column_id === v.column_id,
                ).score;
              }
            });

            d.busy = false;
          });
        } catch (e) {
          next((d) => (d.busy = false));
        }
      },

      setScore: (row_id: number, column_id: number) => {
        next((d) => {
          const row = d.values.find((x) => x.row_id === row_id);
          row.column_id = column_id;

          if (column_id !== -1) {
            row.score = d.matrix_core.value_set.find((x) => x.row_id === row_id && x.column_id === column_id).score;
          }
        });
      },
      setDialog: (row_id?: number) => {
        next((d) => (d.dialog = d.matrix_core.row_set.find((x) => row_id && x.id === row_id)));
      },

      submit: async (description: string) => {
        next((d) => (d.busy = true));

        await sleep(250);
        await callApi(app, async (api) => {
          return await api.careMatrixSaveMarkPost({
            id: props.record_id,
            matrix_core_id: props.matrix_core_id,
            person_id: props.person_id,
            dossier_id: props.dossier_id,
            moment: props.moment,
            values_json: {
              values: getState().values,
            },
            description,
          });
        });

        await actions.onClose(true);
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));
        await props.onClose(success);

        if (isMounted()) next((d) => (d.busy = false));
      },
    }),
    [],
  );

  return actions;
}

// endregion

export default CareMatrixDrawerMark;
