import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import { CareMatrixRowDto } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";

type Props = {
  care_matrix_row_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const CareMatrixDrawerRowTarget: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { state, form, actions } = useComponent(props);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Doelstelling</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={form.values.target}
                onChange={(v) => form.actions.setValue("target", v)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" disabled={state.busy} onClick={() => actions.onClose()}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={state.busy}>
            Opslaan
          </Button>
        </DialogActions>
      </form>
      <DialogBusy busy={state.busy} />
    </Drawer>
  );
};

type State = {
  busy: boolean;
  record?: CareMatrixRowDto;
};

type Values = {
  target: string;
};

function useComponent(props: Props) {
  const app = useAppStore();
  const isMounted = useIsMounted();
  const { state, getState, next } = useRxjsStore<State>({ busy: false, record: undefined });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));
        const record = await callApi(
          app,
          async (api) => (await api.careMatrixRowOnePost({ id: props.care_matrix_row_id })).data,
        );
        next((d) => {
          d.busy = false;
          d.record = record;
        });
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));
        await props.onClose(success);
        if (isMounted()) next((d) => (d.busy = false));
      },
      submit: async (values: Values) => {
        next((d) => (d.busy = true));

        const res = await callApi(
          app,
          async (api) =>
            (
              await api.careMatrixRowSaveTargetPost({
                id: props.care_matrix_row_id,
                ...values,
              })
            ).data,
        );

        if (res !== undefined) {
          await actions.onClose(true);
        } else {
          next((d) => (d.busy = false));
        }
      },
    }),
    [props.care_matrix_row_id],
  );

  const form = useRxjsForm<Values>({
    initial: {
      target: "",
    },
    submit: async (values) => {
      await actions.submit(values);
    },
  });

  useAsyncEffect(actions.load, [props.care_matrix_row_id]);
  useEffect(() => {
    form.actions.setValue("target", state.record?.target || "");
  }, [state.record?.target]);

  return { state, form, actions };
}

export default CareMatrixDrawerRowTarget;
