import { Dashboard, HelpOutline } from "@material-ui/icons";
import GroupIcon from "@material-ui/icons/Group";
import StorageIcon from "@material-ui/icons/Storage";
import React from "react";
import { useHasPlugin } from "../../core/HasPlugin";
import useAppStore from "../../core/useAppStore";
import useTranslations from "../../core/useTranslations";

export default function useSkeletonDrawerDefinition() {
  const app = useAppStore();
  const _t = useTranslations();
  const isBB = app.session?.company.slug === "bb";
  const hasRole1 = app.session?.person.role_id_set.includes(1);
  const hasRole2 = app.session?.person.role_id_set.includes(2);
  const hasRole3 = app.session?.person.role_id_set.includes(3);
  const hasDossier = useHasPlugin("dossier");
  const hasCapDash = useHasPlugin("capdash");

  return React.useMemo(() => {
    const crm_menu = {
      label: "TagScore",
      icon: <GroupIcon />,
      children: [
        {
          label: "Personen",
          icon: <StorageIcon />,
          path: "/crm/person",
        },
        hasDossier && {
          label: _t("Dossiers"),
          icon: <StorageIcon />,
          path: "/care/dossier",
        },
        {
          label: _t("Ketenpartners"),
          icon: <StorageIcon />,
          path: "/crm/company",
        },
        !isBB && {
          label: _t("Dashboard"),
          icon: <StorageIcon />,
          path: "/tagscore/dashboard",
        },
      ],
    };

    const mm_menu = {
      label: "Management",
      icon: <Dashboard />,
      children: [
        {
          label: "Matrix",
          icon: <StorageIcon />,
          path: "/matrix/core/table",
        },
      ],
    };

    const cd_menu = {
      label: "CapDash",
      icon: <Dashboard />,
      children: [
        (hasRole2 || hasRole3) && {
          label: "Dashboard",
          icon: <StorageIcon />,
          path: "/capdash/dashboard",
        },
        (hasRole2 || hasRole1) && {
          label: "Invoer",
          icon: <StorageIcon />,
          path: "/capdash/data",
        },
      ],
    };

    const gen_menu = {
      label: "Generiek",
      icon: <StorageIcon />,
      children: [
        hasRole2 && {
          label: "Inloghistorie",
          icon: <HelpOutline />,
          path: "/session-login/history",
        },
        hasRole2 && {
          label: "Inloggegevens",
          icon: <HelpOutline />,
          path: "/logs/sessionlogin",
        },
        {
          label: "Handleidingen",
          icon: <HelpOutline />,
          path: "/generic/manuals",
        },
      ],
    };

    const menu_set = [];

    if (isBB) {
      if (hasRole2) {
        menu_set.push(crm_menu);
      }

      if (cd_menu.children.length) {
        menu_set.push(cd_menu);
      }
    } else {
      menu_set.push(crm_menu);
      menu_set.push(mm_menu);
    }

    menu_set.push(gen_menu);

    return menu_set;
  }, [hasDossier, hasCapDash, isBB, hasRole1, hasRole2, hasRole3]);
}
