import { TableHeaderDto } from "../api";
import React from "react";
import _ from "underscore";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import produce from "immer";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ArchiveIcon from "@material-ui/icons/Archive";
import FilterListIcon from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core/styles";
import useGenericStyles from "./useGenericStyles";
import useTranslations from "./useTranslations";

type TableDrawerHeadersProps = {
  open?: boolean;
  header_set: TableHeaderDto[];
  used_set: string[];
  onClose: () => void;
  onSave: (header_set: string[]) => void;
};

const TableDrawerHeaders: React.FC<TableDrawerHeadersProps> = (props) => {
  const _t = useTranslations();
  const { open, header_set, used_set, onClose, onSave } = props;
  const gc = useGenericStyles();
  const classes = useTableDrawerHeadersStyles();

  const stateFromProps = React.useMemo(() => {
    return {
      unused: header_set.map((x) => x.name).filter((x) => used_set.indexOf(x) === -1),
      used: used_set,
    };
  }, [header_set, used_set]);
  const header_mapping = React.useMemo(() => _.object(header_set.map((x) => [x.name, x])), header_set);

  const [columns, setColumns] = React.useState<{ [name: string]: string[] }>(stateFromProps);

  React.useEffect(() => {
    setColumns(stateFromProps);
  }, [stateFromProps]);

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (!destination) return;

    return setColumns((state) => {
      return produce(state, (draft: { [name: string]: string[] }) => {
        const [removed] = draft[source.droppableId].splice(source.index, 1);
        draft[destination.droppableId].splice(destination.index, 0, removed);
      });
    });
  }

  return (
    <Drawer open={open} variant={"temporary"} anchor="right" onClose={onClose}>
      <div className={gc.DrawerContainer}>
        <DialogTitle>Kolommen selecteren</DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              {Object.keys(columns).map((column_name) => {
                const header_name_set = columns[column_name];

                return (
                  <Grid item xs key={column_name}>
                    <Droppable droppableId={column_name}>
                      {(provided, snapshot) => (
                        <>
                          <Typography variant="h5" component="h2">
                            {column_name === "unused" ? "Beschikbaar" : "Gebruikt"}
                          </Typography>

                          <List
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={clsx(classes.List, {
                              [classes.List_IsDraggingOver]: snapshot.isDraggingOver,
                            })}
                          >
                            {column_name === "unused" && <ListSubheader>Generieke velden</ListSubheader>}
                            {header_name_set
                              .map((x) => header_mapping[x])
                              .filter((x) => !!x)
                              .map((header: TableHeaderDto, hx) => (
                                <Draggable draggableId={header.name} index={hx} key={header.name}>
                                  {(provided) => (
                                    <ListItem
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      divider
                                    >
                                      <ListItemText primary={_t(header.title)} />
                                    </ListItem>
                                  )}
                                </Draggable>
                              ))}
                            {header_name_set.length === 0 && (
                              <ListItem>
                                <ListItemText>
                                  <Typography color="textSecondary" variant="body2">
                                    Deze lijst is leeg, sleep velden naar deze lijst toe om hem te vullen.
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            )}
                            {provided.placeholder}
                          </List>
                        </>
                      )}
                    </Droppable>
                  </Grid>
                );
              })}
            </Grid>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<ArchiveIcon />} onClick={onClose}>
            Annuleren & sluiten
          </Button>
          <Button
            startIcon={<FilterListIcon />}
            variant="contained"
            color="primary"
            onClick={() => onSave(columns.used)}
          >
            Kolommen opslaan
          </Button>
        </DialogActions>
      </div>
    </Drawer>
  );
};

const useTableDrawerHeadersStyles = makeStyles((theme) => ({
  List: {
    minHeight: 400,
  },
  List_IsDraggingOver: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default TableDrawerHeaders;
