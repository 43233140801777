import { Box, Typography } from "@material-ui/core";
import React from "react";
import { CrmDataScreenFieldDto } from "../../../api";

type Props = {
  field: CrmDataScreenFieldDto;
};

const CrmDataScreenExtFieldStaticText: React.FC<Props> = (props) => {
  const { field } = props;

  return (
    <Box boxShadow={1} p={2}>
      <Typography variant="h6">{field.name}</Typography>
      {field.description && <Typography variant="body2">{field.description}</Typography>}
    </Box>
  );
};

export default CrmDataScreenExtFieldStaticText;
