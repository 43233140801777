import { Chip, colors, Grid, useTheme } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import clsx from "clsx";
import React, { useMemo } from "react";
import _ from "underscore";
import { CrmCompanyDto, CrmCompanyLocationDto, TableHeaderDto } from "../api";
import { FormatCrmCompanyLocation } from "../pages/CrmPersonTable/Ext/CrmPersonExtDataTable";
import CrmRoleChip from "./CrmRoleChip";
import FormatDate from "./FormatDate";
import getTranslation from "./getTranslation";
import TagCoreChip from "./TagCoreChip";
import useAppStore from "./useAppStore";
import useGenericStyles from "./useGenericStyles";

type Props = {
  header: TableHeaderDto;
  value: any;
};

const FormatValue: React.FC<{ value: any; fmt: string }> = ({ value, fmt }) => {
  const gc = useGenericStyles();
  const app = useAppStore();
  const location_set = useMemo(
    () =>
      app.data.crm_company_set.reduce(
        (prev, curr) => prev.concat(curr.location_set.map((loc) => ({ ...loc, company: curr }))),
        [] as (CrmCompanyLocationDto & { company: CrmCompanyDto })[],
      ),
    [],
  );

  const [modifier, ...allOptions] = fmt.split(".");
  const options = allOptions.join(".");
  let label = "";

  if (value === undefined) return null;

  switch (modifier) {
    case "plain":
    case "":
      return value;
    case "date":
      return <FormatDate value={value} fmt="PP" />;
    case "datetime":
      return <FormatDate value={value} fmt="PP p" />;
    case "email":
      return <a href={`mailto:${value}`}>{value}</a>;
    case "tel":
      return <a href={`tel:${value}`}>{value}</a>;
    case "boolean":
      return value ? "Ja" : "Nee";
    case "array":
      if (!Array.isArray(value)) {
        return (value || "").toString();
      }

      return (
        <React.Fragment>
          {value.map((v: any, rx: number) => (
            <FormatValue key={rx} value={v} fmt={options} />
          ))}
        </React.Fragment>
      );
    case "array_as_chip":
      if (!Array.isArray(value)) return value;

      return (
        <Grid container spacing={1}>
          {value.map((v: any, rx: number) => {
            return (
              <Grid item key={rx}>
                <Chip label={<FormatValue key={rx} value={v} fmt={options} />} />
              </Grid>
            );
          })}
        </Grid>
      );
    case "identifier":
      return value;
    case "crm_person.mode":
    case "translation":
      return getTranslation(value);
    case "json":
      return <pre>{JSON.stringify(value, null, 1)}</pre>;
    case "tag_set":
      const tag_set: number[] = value || [];

      if (!_.isArray(tag_set)) return null;

      return (
        <Grid container spacing={1}>
          {tag_set.map((tag_id, rx) => {
            return (
              <Grid item key={rx}>
                <TagCoreChip tag_id={tag_id} />
              </Grid>
            );
          })}
        </Grid>
      );
    case "role_set":
      const role_set: number[] = value || [];

      return (
        <Grid container spacing={1}>
          {role_set.map((role_id, rx) => {
            return (
              <Grid item key={rx}>
                <CrmRoleChip crm_role_id={role_id} />
              </Grid>
            );
          })}
        </Grid>
      );
    case "rating":
      return (
        <Rating
          value={value ? (value / 100) * 5 : 0}
          precision={0.5}
          readOnly
          className={clsx({
            [gc.Password_Weak]: value < 50,
            [gc.Password_Ok]: value >= 50 && value < 80,
            [gc.Password_Strong]: value >= 80,
          })}
        />
      );
    case "lookup":
      switch (options) {
        case "company":
          label = app.data.crm_company_set.find((x) => x.id === value)?.name || "Onbekend";
          return <Chip label={label} className={gc.Chip_Inline} />;
        case "company_location":
          const location = location_set.find((x) => x.id === value);
          return <Chip label={<FormatCrmCompanyLocation location_id={location.id} />} className={gc.Chip_Inline} />;
        default:
          return <strong>Unknown lookup {options}</strong>;
      }
  }

  console.warn("Missing TableValue modifier", fmt);
  return <div>{value}</div>;
};

const TableCellValue: React.FC<Props> = ({ header, value }) => {
  if (!header.display_modifier) {
    return value || "";
  }

  return <FormatValue value={value} fmt={header.display_modifier} />;
};

export default TableCellValue;
export { FormatValue };
