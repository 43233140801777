import React from "react";
import _ from "underscore";
import { CareDossierTableRowsRequest } from "../../api";
import useAppStore from "../../core/useAppStore";
import useDebouncedValueCallback from "../../core/useDebouncedValueCallback";
import { Box, Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import TextFieldExt from "../../form/TextFieldExt";
import useRxjsForm from "../../core/useRxjsForm";
import useTableStyles from "../../core/useTableStyles";
import useCareDossierTableStore from "./useCareDossierTableStore";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import FilterListIcon from "@material-ui/icons/FilterList";
import GetAppIcon from "@material-ui/icons/GetApp";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import AutocompleteFieldExt from "../../form/AutocompleteFieldExt";

type Props = {
  initial: Values;
  onChange: (props: Partial<CareDossierTableRowsRequest>) => void;
};

type Values = {
  search: string;
  tag_id_set: number[];
  is_archived: number;
};

const CareDossierTableToolbar: React.FC<Props> = (props) => {
  const form = useRxjsForm<Values>({ initial: props.initial });
  const table = useCareDossierTableStore();
  const classes = useTableStyles();
  const app = useAppStore();
  const tag_category = React.useMemo(() => app.data.tag_category_set.find((v) => v.name_slug === "dossier-status"), []);
  const tag_core_set = React.useMemo(
    () => app.data.tag_core_set.filter((v) => v.category_id === tag_category.id),
    [tag_category.id],
  );

  const [, setFirst] = useDebouncedValueCallback((v) => props.onChange(v), form.values, 250);

  // Refresh the form if our props get updated.
  React.useEffect(() => {
    const keys: any = ["search", "is_archived"];
    const equal = _.isEqual(_.pick(form.values, keys), _.pick(props.initial, keys));

    if (!equal) {
      setFirst(true);
      form.actions.setValues(props.initial);
    }
  }, [props.initial]);

  const has_filters = React.useMemo(() => {
    const keys = ["search", "is_archived", "header_set", "offset", "limit"];
    const values = _.values(_.omit(table.req, keys));

    return values.some((x) => !_.isEmpty(x)) || table.req.is_archived !== 0;
  }, [table.req]);

  return (
    <React.Fragment>
      <Hidden smDown>
        <Box display="flex" flexDirection="row" ml={1}>
          <Tooltip title="Filters legen.">
            <IconButton onClick={() => table.actions.reset()}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid
          container
          component="form"
          noValidate
          onSubmit={form.actions.submit}
          className={classes.TableToolbarForm}
          spacing={2}
        >
          <Grid item xs={12} md>
            <TextFieldExt form={form} name="search" label="Zoeken ..." size="small" autoFocus />
          </Grid>
          <Grid item xs={12} md={4}>
            <AutocompleteFieldExt
              form={form}
              name="tag_id_set"
              label="Tags"
              items={tag_core_set.map((v) => ({ value: v.id, label: `#${v.id}. ${v.name}` }))}
              multiple
              autocompleteProps={{
                size: "small",
              }}
            />
          </Grid>
        </Grid>
      </Hidden>

      <Box display="flex" flexDirection="row">
        <Tooltip title="Toevoegen.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "DrawerCreate" })}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Resultaat exporteren.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "TableDownload" })}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Kolommen selecteren.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "TableHeaders" })}>
            <ViewWeekIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Lijst filteren.">
          <IconButton
            onClick={() => table.actions.setDrawer({ name: "DrawerFilter", req: table.req })}
            color={has_filters ? "secondary" : "default"}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
};

export default CareDossierTableToolbar;
