import React from "react";
import _ from "underscore";
import TableExt from "../../core/TableExt";
import LogsSessionloginTableToolbar from "./LogsSessionloginTableToolbar";
import useLogsSessionloginTableStore, { LogsSessionloginTableProvider } from "./useLogsSessionloginTableStore";

const LogsSessionloginTable: React.FC = () => {
  return (
    <LogsSessionloginTableProvider>
      <Render />
    </LogsSessionloginTableProvider>
  );
};

const Render: React.FC = () => {
  const table = useLogsSessionloginTableStore();

  const headers = React.useMemo(
    () => table.req.header_set.map((x) => table.header_set.find((h) => h.name === x)).filter((x) => !!x),
    [table.req.header_set],
  );

  const initial = React.useMemo(
    () => _.pick(table.req, ["search", "what_set", "role_id_set"]),
    [table.booted, table.req],
  );

  return (
    <>
      <TableExt
        loading={table.loading}
        title="Inloghistorie"
        header_set={headers}
        row_set={table.res.row_set}
        pagination={table.res.pagination}
        setPagination={(x) => table.actions.setPagination(x)}
        toolbar={
          <LogsSessionloginTableToolbar
            initial={initial}
            onChange={(props) => {
              table.actions.setRequest(props);
            }}
          />
        }
      />
    </>
  );
};

export default LogsSessionloginTable;
