import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { nl } from "date-fns/locale";
import * as React from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CoreLayout from "./core/CoreLayout";
import useApp, { AppStore } from "./core/useAppStore";
import CapDashDashboard from "./pages/CapDashDashboard/CapDashDashboard";
import CapDashData from "./pages/CapDashData/CapDashData";
import CareDossierTable from "./pages/CareDossierTable/CareDossierTable";
import CareDossierTriggersTable from "./pages/CareDossierTriggers/CareDossierTriggersTable";
import CareDossierView from "./pages/CareDossierView/CareDossierView";
import CrmCompanyTable from "./pages/CrmCompany/Table/CrmCompanyTable";
import CrmCompanyView from "./pages/CrmCompany/View/CrmCompanyView";
import CrmPersonTable from "./pages/CrmPersonTable/Table/CrmPersonTable";
import CrmPersonView from "./pages/CrmPersonView/CrmPersonView";
import DmzAuthenticate from "./pages/DmzAuthenticate/DmzAuthenticate";
import GenericManualsOverview from "./pages/GenericManuals/Overview/GenericManualsOverview";
import ManagementMatrixScoreTable from "./__tmp/ManagementMatrix/ManagementMatrixScoreTable";
import LogsSessionloginTable from "./pages/LogsSessionloginTable/LogsSessionloginTable";
import MatrixCoreTable from "./pages/MatrixCore/Table/MatrixCoreTable";
import MatrixCoreView from "./pages/MatrixCore/View/MatrixCoreView";
import ProfileCompanySwitch from "./pages/ProfileCompanySwitch/ProfileCompanySwitch";
import ProfileDashboard from "./pages/ProfileDashboard/ProfileDashboard";
import ProfileLogout from "./pages/ProfileLogout/ProfileLogout";
import ProfileTakeoverUndo from "./pages/ProfileTakeoverUndo/ProfileTakeoverUndo";
import ProfileTechnicalSupport from "./pages/ProfileTechnicalSupport/ProfileTechnicalSupport";
import TagScoreDashboard from "./pages/TagScore/Dashboard/TagScoreDashboard";
import SkeletonApp from "./skeleton/SkeletonApp";
import SkeletonLoading from "./skeleton/SkeletonLoading";

import "react-quill/dist/quill.snow.css";

import ManagementMatrixTable from "./__tmp/ManagementMatrix/ManagementMatrixTable";

import CriticalDataOwnOverviewTable from "./__tmp/CriticalData/CriticalDataOwnOverview/CriticalDataOwnOverviewTable";
import CriticalDataOverallOverviewTable from "./__tmp/CriticalData/CriticalDataOverallOverview/CriticalDataOverallOverviewTable";

function App() {
  return (
    <AppStore>
      <CoreLayout>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
            <ProtectedApp />
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </CoreLayout>
    </AppStore>
  );
}

function ProtectedApp() {
  const app = useApp();

  if (!app.is_booted) {
    return <SkeletonLoading />;
  }

  return (
    <SkeletonApp>
      {!app.is_authorized && (
        <Switch>
          <Route path="/" component={DmzAuthenticate} />
        </Switch>
      )}
      {app.is_authorized && (
        <Switch>
          <Redirect from="/" exact to="/profile/dashboard" />

          <Route path="/dmz/authenticate" component={DmzAuthenticate} />

          <Route path="/matrix/core/table" component={MatrixCoreTable} />
          <Route path="/matrix/core/view/:record_id" component={MatrixCoreView} />

          <Route path="/management/matrix/table" component={ManagementMatrixTable} />
          <Route path="/management/matrix/view/:record_id" component={ManagementMatrixScoreTable} />

          <Route path="/profile/dashboard" component={ProfileDashboard} />
          <Route path="/profile/company-switch" component={ProfileCompanySwitch} />
          <Route path="/profile/technical-support" component={ProfileTechnicalSupport} />
          <Route path="/profile/takeover-undo" component={ProfileTakeoverUndo} />
          <Route path="/profile/logout" component={ProfileLogout} />

          <Redirect from="/crm/person" exact to="/crm/person/table" />
          <Route path="/crm/person/table" component={CrmPersonTable} />
          <Route path="/crm/person/view/:record_id" component={CrmPersonView} />

          <Redirect from="/crm/company" exact to="/crm/company/table" />
          <Route path="/crm/company/table" component={CrmCompanyTable} />
          <Route path="/crm/company/view/:record_id" component={CrmCompanyView} />

          <Redirect from="/care/dossier" exact to="/care/dossier/table" />
          <Route path="/care/dossier/table" component={CareDossierTable} />
          <Route path="/care/dossier/view/:record_id" component={CareDossierView} />
          <Route path="/care/dossier-triggers/table" component={CareDossierTriggersTable} />
          <Route path="/critical/own-overview/table" component={CriticalDataOwnOverviewTable} />
          <Route path="/critical/overall-overview/table" component={CriticalDataOverallOverviewTable} />

          <Route path="/capdash/dashboard" component={CapDashDashboard} />
          <Route path="/capdash/data/:crm_company_location_id?/:day?" component={CapDashData} />

          <Route path="/tagscore/dashboard" component={TagScoreDashboard} />

          <Route path="/logs/sessionlogin" component={LogsSessionloginTable} />
          <Route path="/generic/manuals" component={GenericManualsOverview} />
        </Switch>
      )}
    </SkeletonApp>
  );
}

declare let module: object;

export default hot(module)(App);
