import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

type Props = {
  label?: string;
};

const SkeletonLoading: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { label } = props;

  return (
    <main className={classes.SkeletonLoading_Container}>
      <div className={classes.SkeletonLoading_Holder}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
          <Typography variant="h4" style={{ marginTop: 24 }}>
            {label || "de applicatie wordt geladen"}
          </Typography>
        </Box>
      </div>
    </main>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    SkeletonLoading_Container: {
      flexGrow: 1,
      height: "100vh",
    },
    SkeletonLoading_Holder: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
);

export default SkeletonLoading;
