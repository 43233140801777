import { Box, Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import React from "react";
import _ from "underscore";
import { CareDossierTriggerTableRowsRequest } from "../../api";
import useAppStore from "../../core/useAppStore";
import useDebouncedValueCallback from "../../core/useDebouncedValueCallback";
import useRxjsForm from "../../core/useRxjsForm";
import useTableStyles from "../../core/useTableStyles";
import AutocompleteFieldExt from "../../form/AutocompleteFieldExt";
import useCareDossierTriggersTableStore from "./useCareDossierTriggersTableStore";

type Props = {
  initial: Values;
  onChange: (props: Partial<CareDossierTriggerTableRowsRequest>) => void;
};

type Values = {
  crm_person_id_set: number[];
  process_person_id_set: number[];
  is_closed: number;
};

const CareDossierTriggersTableToolbar: React.FC<Props> = (props) => {
  const form = useRxjsForm<Values>({ initial: props.initial });
  const table = useCareDossierTriggersTableStore();
  const classes = useTableStyles();
  const app = useAppStore();

  const [, setFirst] = useDebouncedValueCallback((v) => props.onChange(v), form.values, 250);

  // Refresh the form if our props get updated.
  React.useEffect(() => {
    const keys: any = ["crm_person_id_set", "process_person_id_set", "is_closed"];
    const equal = _.isEqual(_.pick(form.values, keys), _.pick(props.initial, keys));

    if (!equal) {
      setFirst(true);
      form.actions.setValues(props.initial);
    }
  }, [props.initial]);

  return (
    <React.Fragment>
      <Hidden smDown>
        <Box display="flex" flexDirection="row" ml={1}>
          <Tooltip title="Filters legen.">
            <IconButton onClick={() => table.actions.reset()}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid
          container
          component="form"
          noValidate
          onSubmit={form.actions.submit}
          className={classes.TableToolbarForm}
          spacing={2}
        >
          <Grid item xs={12} md={5}>
            <AutocompleteFieldExt
              form={form}
              name="crm_person_id_set"
              label="Dossiers"
              items={app.data.crm_person_set.map((v) => ({ value: v.id, label: `#${v.id}. ${v.name}` }))}
              multiple
              autocompleteProps={{
                size: "small",
              }}
            />
          </Grid>
          {app.session.person.role_id_set.includes(4) && (
            <Grid item xs={12} md={4}>
              <AutocompleteFieldExt
                form={form}
                name="process_person_id_set"
                label="Procesregisseur(s)"
                multiple
                items={app.data.crm_person_set
                  .filter((x) => x.role_id_set.includes(2))
                  .map((x) => ({ value: x.id, label: x.name }))}
                autocompleteProps={{
                  size: "small",
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <AutocompleteFieldExt
              form={form}
              name="is_closed"
              label="Gesloten"
              autocompleteProps={{
                size: "small",
                disableClearable: true,
              }}
              items={[
                { value: -1, label: "Niet filteren." },
                { value: 0, label: "Niet gesloten." },
                { value: 1, label: "Alleen gesloten." },
              ]}
            />
          </Grid>
        </Grid>
      </Hidden>

      <Box display="flex" flexDirection="row">
        <Tooltip title="Resultaat exporteren.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "TableDownload" })}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Kolommen selecteren.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "TableHeaders" })}>
            <ViewWeekIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
};

export default CareDossierTriggersTableToolbar;
