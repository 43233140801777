import React from "react";
import useNavigate from "../../core/useNavigate";
import SkeletonPage from "../../skeleton/SkeletonPage";
import {
  Avatar,
  Card,
  CardContent,
  colors,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import useAppStore from "../../core/useAppStore";

const ProfileCompanySwitch: React.FC = () => {
  const app = useAppStore();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <SkeletonPage>
      <Card>
        <CardContent>
          <Typography variant="h5">Organisatie switch</Typography>
          <Typography variant="body2">Kies hier een organisatie uit waar je naar toe wilt switchen</Typography>

          <List>
            {app.data.ts_company_set.map((company) => {
              const backgroundColor = colors[company.avatar_color][company.avatar_hue];
              const color = theme.palette.getContrastText(backgroundColor);

              function onCompanyClick() {
                navigate(
                  `/dmz/authenticate?screen=session-login&email=${app.session.person.email}&company_id=${company.id}`,
                );
              }

              return (
                <ListItem
                  button
                  key={company.slug}
                  disabled={company.slug === app.session.company.slug}
                  onClick={onCompanyClick}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor, color }}>{company.avatar_title}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={company.name} />
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </SkeletonPage>
  );
};

function useComponent() {
  return {};
}

export default ProfileCompanySwitch;
