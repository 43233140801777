import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import React, { useCallback } from "react";
import { CareDossierOneResponse } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import sleep from "../../../core/sleep";
import { callApi, getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useApp from "../../../core/useAppStore";
import useIsMounted from "../../../core/useIsMounted";

type Props = {
  onClose: (success: boolean) => void;
  record_id: number;
};

const CareDossierDialogArchive: React.FC<Props> = (props) => {
  const app = useApp();
  const { onClose } = props;
  const [busy, setBusy] = React.useState(false);
  const isMounted = useIsMounted();

  const [{ res: record }] = useApiResult<CareDossierOneResponse>(
    async () => {
      await sleep(250);
      return (await getApi().careDossierOnePost({ id: props.record_id })).data;
    },
    [props.record_id],
    { run_on_startup: true },
  );

  const onArchiveClick = useCallback(async () => {
    if (busy) return;

    setBusy(true);

    await callApi(app, async (api) => {
      await api.careDossierArchivePost({ id: props.record_id });
    });

    await onClose(true);

    if (isMounted()) {
      setBusy(false);
    }
  }, [busy]);

  if (!record) return <DialogBusy busy={true} title="Laden" />;

  return (
    <Dialog open={true}>
      <DialogTitle>
        Profiel {record.person.name} {record.is_archived ? "heropenen" : "archiveren"}.
      </DialogTitle>
      {record.is_archived ? (
        <DialogContent>
          <Typography variant="body1" paragraph>
            Je staat op het punt om <strong>{record.person.name}</strong> te heropenen. Na het heropenen kunt u de
            normale werkzaamheden weer hervatten.
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1" paragraph>
            Je staat op het punt om <strong>{record.person.name}</strong> te archiveren. Bij het archiveren sluiten we
            alle lopende posten, dit kan niet ongedaan gemaakt worden.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={() => onClose(false)} color="primary" disabled={busy}>
          Sluiten
        </Button>
        <Button
          startIcon={<FlightTakeoffIcon />}
          variant="contained"
          onClick={onArchiveClick}
          color="primary"
          disabled={busy}
        >
          {record.is_archived ? "Heropenen." : "Archiveren."}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CareDossierDialogArchive;
