import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import GetAppIcon from "@material-ui/icons/GetApp";
import { GenericDownloadDto } from "../api";
import useAsyncEffect from "./useAsyncEffect";
import useApp from "./useAppStore";
import useApi, { getServerUrl } from "./useApi";
import sleep from "./sleep";
import { makeStyles } from "@material-ui/core/styles";

type GenericDrawer = {
  onClose: () => void;
};

type Props = GenericDrawer & {
  busy?: boolean;
  onDownloadStart: () => Promise<GenericDownloadDto>;
};

const TableDrawerDownload: React.FC<Props> = (props) => {
  const { onClose } = props;
  const classes = useStyles();
  const app = useApp();
  const api = useApi();

  const [step, setStep] = React.useState(0);
  const [sequence, setSequence] = React.useState(0);
  const [download, setDownload] = React.useState<GenericDownloadDto | null>(null);

  useAsyncEffect(async () => {
    if (step === 1) {
      try {
        setDownload(await props.onDownloadStart());
        setStep(2);
        setSequence(1);
      } catch (err) {
        app.actions.getApiError(err);
        setStep(0);
      }
    } else if (step >= 2 && sequence) {
      const res = (
        await api.genericDownloadOnePost({
          id: download.id,
          token: download.token,
        })
      ).data;

      setDownload(res);

      if (res.error) {
        setStep(0);
        setSequence(0);
      } else if (res.started_at && step === 2) {
        setStep(3);
      } else if (res.filename) {
        setStep(4);
        setSequence(0);
      } else {
        await sleep(1000);
        setSequence(sequence + 1);
      }
    }
  }, [step, sequence]);

  const steps = ["Starten", "Aanvraag", "Wachtrij", "Verwerken", "Klaar"];

  return (
    <Dialog open={true}>
      <DialogTitle>Download overzicht</DialogTitle>
      <DialogContent>
        <Stepper activeStep={step} alternativeLabel className={classes.stepper}>
          {steps.map((v, x) => (
            <Step completed={x <= step - 1} key={x}>
              <StepLabel>{v}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {step === 0 && (
          <>
            <Typography variant="body1" paragraph>
              Om de aanvraag naar de server te versturen kun je op de onderstaande button klikken. Het kan even duren
              voordat de download klaar is. Deze duur is afhankelijk van de drukte op de server en de grootte en
              complexiteit van de download.
            </Typography>
          </>
        )}
        {step === 1 && (
          <>
            <Typography variant="body1" paragraph>
              De aanvraag wordt verstuurd naar de server, een ogenblik geduld alsjeblieft.
            </Typography>
          </>
        )}
        {step === 2 && (
          <>
            <Typography variant="body1" paragraph>
              De server heeft de aanvraag ontvangen en heeft deze in de wachtrij geplaatst. De taak kan ieder moment
              worden opgepakt uit de wachtrij.
            </Typography>
          </>
        )}
        {step === 3 && (
          <>
            <Typography variant="body1" paragraph>
              De server is nu bezig met het maken van de download, zodra deze klaar is kun je hem hier direct
              downloaden.
            </Typography>
          </>
        )}
        {step === 4 && (
          <>
            <Typography variant="body1" paragraph>
              Het bestand staat klaar klaar op de server. Je kunt onderstaande knop gebruiken om het bestand te
              downloaden naar je device.
            </Typography>
          </>
        )}

        {step === 0 && download?.error && (
          <>
            <Typography variant="body2">
              Er ging wat mis bij het genereren van de download, je kunt onderstaande foutmelding rapporteren:
            </Typography>
            <pre>{download.error}</pre>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {(step === 0 || step === 3) && (
          <Button startIcon={<ArchiveIcon />} onClick={onClose} color="primary">
            Sluiten
          </Button>
        )}
        {step !== 4 && (
          <Button
            startIcon={<GetAppIcon />}
            onClick={() => setStep(1)}
            variant="contained"
            color="primary"
            disabled={step !== 0}
            autoFocus
          >
            Starten
          </Button>
        )}
        {step === 4 && (
          <Button
            startIcon={<GetAppIcon />}
            href={[
              getServerUrl(),
              "/app/company:",
              app.session.company.slug,
              "/download/stream/",
              download.filename.split("/").pop(),
              "?id=",
              download.id,
              "&token=",
              download.token,
            ].join("")}
            target="_blank"
            onClick={() => onClose()}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: "0 14px 14px 14px",

    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: 4,
      fontSize: 12,
      fontWeight: 300,
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      fontWeight: "500 !important",
    },
  },
}));

export default TableDrawerDownload;
