import { KeyboardReturn } from "@material-ui/icons";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import React, { useCallback, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import TagScoreWhiteSvg from "../../assets/tagscore-white.svg";
import CapDashWhiteSvg from "../../assets/capdash-white.svg";
import TagScoreSvg from "../../assets/tagscore.svg";
import CapDashSvg from "../../assets/capdash.svg";
import ZvhNhnSvg from "../../assets/zvh-nhn.svg";
import { useHasPlugin } from "../../core/HasPlugin";
import IconButtonDropExt from "../../core/IconButtonDropExt";
import { getApi } from "../../core/useApi";
import useNavigate from "../../core/useNavigate";
import SkeletonDrawerMenuList from "./SkeletonDrawerMenuList";
import useSkeletonDrawerDefinition from "./useSkeletonDrawerDefinition";
import { Box, Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import useSkeletonDrawerStyles from "./useSkeletonDrawerStyles";
import useApp from "../../core/useAppStore";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { Link } from "react-router-dom";

function SkeletonDrawerTriggerNotification() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);

  const loadAmount = useCallback(async () => {
    try {
      setAmount((await getApi().careDossierTriggerAmountPost()).data.amount);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const i = setInterval(loadAmount, 60000);
    loadAmount();

    return () => clearInterval(i);
  }, []);

  return (
    <Grid item xs={4} style={{ display: "flex" }}>
      <Box position="relative">
        <IconButton onClick={() => navigate("/care/dossier-triggers/table")}>
          <NotificationsNoneIcon />
        </IconButton>
        {amount > 0 && (
          <Box
            position="absolute"
            borderRadius="50%"
            top={0}
            right={0}
            width={18}
            height={16}
            bgcolor={theme.palette.error.dark}
            textAlign="center"
          >
            <div
              style={{
                fontSize: 15 >= 10 ? 10 : 12,
                color: "#fff",
              }}
            >
              {amount}
            </div>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export default function SkeletonDrawer() {
  const app = useApp();
  const hasCapdash = useHasPlugin("capdash");
  const menu = useSkeletonDrawerDefinition();
  const classes = useSkeletonDrawerStyles();
  const is_open = React.useMemo(() => app.is_authorized && app.drawer.is_open, [app]);
  const navigate = useNavigate();
  const logo = hasCapdash
    ? app.theme.is_dark
      ? CapDashWhiteSvg
      : CapDashSvg
    : app.theme.is_dark
    ? TagScoreWhiteSvg
    : TagScoreSvg;

  return (
    <Drawer
      className={classes.SkeletonDrawer_Container}
      variant="persistent"
      anchor="left"
      open={is_open}
      classes={{
        paper: classes.SkeletonDrawer_Paper,
      }}
    >
      <div className={classes.SkeletonDrawer_ToolbarClipper} />

      <Box p={2} boxShadow={1} display="flex" width="100%" alignItems="center">
        <img src={logo} alt="TagScore" style={{ maxWidth: "100%" }} />
      </Box>
      {app.is_authorized && (
        <Box display="flex" flexDirection="column" p={2} boxShadow={1}>
          <Box display="flex" width="100%" alignItems="center">
            <Box flexGrow={1}>
              <Grid container alignItems={"center"}>
                <Grid item xs={8}>
                  <Typography variant="body1">{app.session.person.name}</Typography>
                </Grid>
                <SkeletonDrawerTriggerNotification />
              </Grid>
            </Box>
            <IconButtonDropExt
              items={[
                { icon: <DashboardOutlinedIcon />, label: "Dashboard", onClick: () => navigate("/profile/dashboard") },
                {
                  icon: <ImportExportIcon />,
                  label: "Organisatie switch",
                  onClick: () => navigate("/profile/company-switch"),
                },
                {
                  icon: <LiveHelpIcon />,
                  label: "Technisch Support",
                  onClick: () => navigate("/profile/technical-support"),
                },
                app.session.origin_person && {
                  icon: <KeyboardReturn />,
                  label: `Terugkeren naar ${app.session.origin_person.name}`,
                  onClick: () => navigate("/profile/takeover-undo"),
                },
                { icon: <ExitToAppIcon />, label: "Afmelden", onClick: () => navigate("/profile/logout") },
              ]}
              icon={<MoreVertIcon />}
            />
          </Box>
        </Box>
      )}

      <List style={{ flexGrow: 1 }}>
        {menu.map((item, ix) => (
          <SkeletonDrawerMenuList item={item} key={ix} />
        ))}
      </List>
      {app.is_authorized && !hasCapdash && (
        <Box p={2} display="flex" width="100%" alignItems="center">
          <img src={ZvhNhnSvg} alt="ZVH - NHN" style={{ maxWidth: "100%" }} />
        </Box>
      )}
    </Drawer>
  );
}
