import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useMemo } from "react";
import _ from "underscore";
import { CareDossierDataOneResponse, CrmDataScreenFieldTypeEnum } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsForm from "../../../core/useRxjsForm";
import CrmDataScreenExtFieldFactory from "../Ext/CrmDataScreenExtFieldFactory";

// region Types

type Props = {
  screen_id: number;
  record_id?: number;

  endpoint: {
    which: "careDossierData";
    person_id: number;
    dossier_id: number;
  };

  onClose: (success: boolean) => void | Promise<void>;
};

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

// endregion
// region Component

const CrmDataScreenDrawerWrite: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const classes = useStyles();
  const { busy, screen, form, onClose } = useComponent(props);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>{screen.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid}>
            {screen.fields_json.field_set.map((field) => {
              return (
                <Grid item xs={12} key={field.identifier}>
                  <CrmDataScreenExtFieldFactory field={field} form={form} />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose(false)}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>
      </form>

      <DialogBusy busy={busy} />
    </Drawer>
  );
};

// endregion
// region Store

function useComponent(props: Props) {
  const app = useAppStore();
  const { screen_id, record_id } = props;
  const [closing, setClosing] = React.useState(false);
  const isMounted = useIsMounted();

  const screen = useMemo(() => {
    return app.data.crm_data_screen_set.find((x) => x.id === screen_id);
  }, [screen_id]);

  const initial = useMemo(() => {
    const data: { [name: string]: any } = {};
    for (const field of screen.fields_json.field_set.filter((x) => x.type !== CrmDataScreenFieldTypeEnum.STATICTEXT)) {
      let value: any = field.default_value;

      if (value === undefined) {
        if (field.type === CrmDataScreenFieldTypeEnum.CHOICEMULTIPLE) {
          value = [];
        } else {
          value = "";
        }
      }
      data[field.identifier] = value;
    }
    return data;
  }, [screen]);

  // Load the record from the server.
  const { record } = useRxjsFetch<CareDossierDataOneResponse>(async () => {
    const { which, ...endpointProps } = props.endpoint;
    return record_id ? (await getApi()[`${which}OnePost`]({ id: record_id, screen_id, ...endpointProps })).data : null;
  }, [record_id]);

  const form = useRxjsForm({
    initial: {
      id: undefined,
      ...initial,
    },
    submit: async (values) => {
      await callApi(app, async (api) => {
        const { which, ...endpointProps } = props.endpoint;
        await api[`${which}SavePost`]({
          id: record_id,
          screen_id,
          ...endpointProps,
          fields_json: values,
        });
      });

      await onClose(true);
    },
  });

  const onClose = React.useCallback(async (success: boolean) => {
    setClosing(true);
    await props.onClose(success);

    if (isMounted) setClosing(false);
  }, []);

  // As soon as the record is loaded, inject the information into the form.
  React.useEffect(() => {
    form.actions.setValues(record ? _.pick(record.fields_json, Object.keys(form.initial) as any) : form.initial);
  }, [record?.id]);

  const busy = React.useMemo(() => closing || form.submitting, [closing, form.submitting]);

  return { busy, screen, form, onClose };
}

// endregion

export default CrmDataScreenDrawerWrite;
