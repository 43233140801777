import React from "react";
import _ from "underscore";
import { CrmPersonTableRowsRequest } from "../../../api";
import useAppStore from "../../../core/useAppStore";
import useDebouncedValueCallback from "../../../core/useDebouncedValueCallback";
import { Box, Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import TextFieldExt from "../../../form/TextFieldExt";
import useRxjsForm from "../../../core/useRxjsForm";
import useTableStyles from "../../../core/useTableStyles";
import useCrmPersonTableStore from "./useCrmPersonTableStore";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import FilterListIcon from "@material-ui/icons/FilterList";
import GetAppIcon from "@material-ui/icons/GetApp";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";

type Props = {
  initial: Values;
  onChange: (props: Partial<CrmPersonTableRowsRequest>) => void;
};

type Values = {
  search: string;
  is_archived: number;
};

const CrmPersonTableToolbar: React.FC<Props> = (props) => {
  const form = useRxjsForm<Values>({ initial: props.initial });
  const table = useCrmPersonTableStore();
  const classes = useTableStyles();
  const app = useAppStore();

  const [, setFirst] = useDebouncedValueCallback((v) => props.onChange(v), form.values, 250);

  // Refresh the form if our props get updated.
  React.useEffect(() => {
    const keys: any = ["search", "is_archived"];
    const equal = _.isEqual(_.pick(form.values, keys), _.pick(props.initial, keys));

    if (!equal) {
      setFirst(true);
      form.actions.setValues(props.initial);
    }
  }, [props.initial]);

  const has_filters = React.useMemo(() => {
    const keys = ["search", "is_archived", "header_set", "offset", "limit"];
    const values = _.values(_.omit(table.req, keys));

    return values.some((x) => !_.isEmpty(x)) || table.req.is_archived !== 0;
  }, [table.req]);

  return (
    <React.Fragment>
      <Hidden smDown>
        <Box display="flex" flexDirection="row" ml={1}>
          <Tooltip title="Filters legen.">
            <IconButton onClick={() => table.actions.reset()}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid
          container
          component="form"
          noValidate
          onSubmit={form.actions.submit}
          className={classes.TableToolbarForm}
          spacing={2}
        >
          <Grid item xs={12} md>
            <TextFieldExt form={form} name="search" label="Zoeken ..." size="small" autoFocus />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteFieldExt
              form={form}
              name="is_archived"
              label="Archief"
              autocompleteProps={{
                size: "small",
                disableClearable: true,
              }}
              items={[
                { value: -1, label: "Niet filteren." },
                { value: 0, label: "Niet gearchiveerd." },
                { value: 1, label: "Wel gearchiveerd." },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutocompleteFieldExt
              form={form}
              name="tag_id_set"
              label="Persoontype"
              multiple
              autocompleteProps={{
                size: "small",
                disableClearable: true,
              }}
              items={app.data.tag_core_set
                .filter((x) => x.category_id === 1)
                .map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
            />
          </Grid>
        </Grid>
      </Hidden>

      <Box display="flex" flexDirection="row">
        <Tooltip title="Toevoegen.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "DrawerSave" })}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Resultaat exporteren.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "TableDownload" })}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Kolommen selecteren.">
          <IconButton onClick={() => table.actions.setDrawer({ name: "TableHeaders" })}>
            <ViewWeekIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Lijst filteren.">
          <IconButton
            onClick={() => table.actions.setDrawer({ name: "DrawerFilter", req: table.req })}
            color={has_filters ? "secondary" : "default"}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
};

export default CrmPersonTableToolbar;
