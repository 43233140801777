import React from "react";

export type SkeletonDrawerMenuItemBase = {
  label: string;
  icon?: React.ReactElement;
};

export type SkeletonDrawerMenuItemButton = SkeletonDrawerMenuItemBase & {
  path: string;
  count?: number;
};
export type SkeletonDrawerMenuItemWithChildren = SkeletonDrawerMenuItemBase & {
  children: SkeletonDrawerMenuItem[];
};

export type SkeletonDrawerMenuItem = SkeletonDrawerMenuItemButton | SkeletonDrawerMenuItemWithChildren;

export function isSkeletonDrawerMenuItemButton(item: SkeletonDrawerMenuItem): item is SkeletonDrawerMenuItemButton {
  return (item as SkeletonDrawerMenuItemButton).path !== undefined;
}
export function isSkeletonDrawerMenuItemWithChildren(
  item: SkeletonDrawerMenuItem,
): item is SkeletonDrawerMenuItemWithChildren {
  return (item as SkeletonDrawerMenuItemWithChildren).children !== undefined;
}
