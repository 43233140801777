import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Delete } from "@material-ui/icons";
import ArchiveIcon from "@material-ui/icons/Archive";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useCallback, useState } from "react";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";

type Props = {
  crm_note_id: number;
  onClose: (success?: boolean) => void;
};

const CrmNoteDialogArchive: React.FC<Props> = (props) => {
  const app = useAppStore();
  const [busy, setBusy] = useState(false);

  const onArchive = useCallback(async () => {
    if (busy) return;
    setBusy(true);

    await callApi(app, async (api) => api.crmNoteArchivePost({ id: props.crm_note_id }));

    setBusy(false);
    props.onClose(true);
  }, [busy]);

  return (
    <Dialog open={true}>
      <DialogTitle>Archiveren</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Weet je zeker dat je deze notitie wilt archiveren?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button startIcon={<ArchiveIcon />} onClick={() => !busy && props.onClose()} color="primary">
          Sluiten
        </Button>
        <Button
          startIcon={<Delete />}
          onClick={() => onArchive()}
          variant="contained"
          color="primary"
          disabled={busy}
          autoFocus
        >
          Archiveren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrmNoteDialogArchive;
