import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import _ from "underscore";
import { CareDossierTableRowsRequest } from "../../api";
import useGenericStyles from "../../core/useGenericStyles";
import useRxjsForm from "../../core/useRxjsForm";
import AutocompleteFieldExt from "../../form/AutocompleteFieldExt";
import TextFieldExt from "../../form/TextFieldExt";

type Props = {
  onClose: (req?: Values) => void;
  req: CareDossierTableRowsRequest;
};

type Values = Omit<CareDossierTableRowsRequest, "header_set">;

const CareDossierDrawerTableFilter: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const classes = useStyles();

  const { req, onClose } = props;
  const form = useRxjsForm<Values>({
    initial: _.omit(req, ["header_set"]),
    submit: async (values) => {
      onClose(values);
    },
  });

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Resultaat filteren</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Dingen</Typography>
              <Typography variant="body2" color="textSecondary">
                Dingen, met woorden enzo.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <TextFieldExt form={form} name="search" label="Zoeken op naam / e-mailadres ..." />
            </Grid>
            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="is_archived"
                label="Archief"
                items={[
                  { value: -1, label: "Niet filteren." },
                  { value: 0, label: "Niet gearchiveerd." },
                  { value: 1, label: "Wel gearchiveerd." },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Filteren
          </Button>
        </DialogActions>
      </form>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

export default CareDossierDrawerTableFilter;
