import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  colors,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Refresh, SaveAltOutlined } from "@material-ui/icons";
import { Sunburst } from "@nivo/sunburst";
import React, { useMemo, useState } from "react";
import _ from "underscore";
import { TsChartDataDto } from "../../../api";
import ChartResponsive from "../../../core/ChartResponsive";
import sleep from "../../../core/sleep";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useAppStore from "../../../core/useAppStore";
import useCrmCompanyLocationSet from "../../../core/useCrmCompanyLocationSet";
import useTranslations from "../../../core/useTranslations";
import CareDossierDrawerListing from "../../CareDossier/Drawers/CareDossierDrawerListing";
import CrmCompanyLocationShortCode from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";

type Props = {
  dataDto: TsChartDataDto;
};

const colorList: string[] = _.flatten(
  _.times(20, () => [
    colors.red[500],
    colors.pink[500],
    colors.purple[500],
    colors.deepPurple[500],
    colors.indigo[500],
    colors.blue[500],
    colors.lightBlue[500],
    colors.cyan[500],
    colors.teal[500],
    colors.green[500],
    colors.lightGreen[500],
    colors.lime[500],
    colors.yellow[500],
    colors.amber[500],
    colors.orange[500],
    colors.deepOrange[500],
    colors.brown[500],
    colors.grey[500],
    colors.blueGrey[500],
  ]),
);

const TagScoreDashboardChartMatrixes: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const app = useAppStore();
  const crmCompanyLocationSet = useCrmCompanyLocationSet();
  const [iteration, setIteration] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const [data] = useApiResult(async () => {
    await sleep(250);

    return (await getApi().tsChartMatrixesPost(props.dataDto)).data;
  }, [props.dataDto, iteration]);
  const [activeDossierIdSet, setActiveDossierIdSet] = useState<null | number[]>(null);

  const tree = useMemo(() => {
    if (!data.res) return undefined;

    return data.res.matrixes_set.map((matrix_data) => {
      const core_matrix = app.data.matrix_core_set.find((x) => x.id === matrix_data.matrix_core_id);

      return {
        name: core_matrix.name,
        scoreRows: matrix_data.row_set.map((matrix_data_row, rx) => {
          const core_matrix_row = core_matrix.row_set.find((x) => x.id === matrix_data_row.matrix_row_id);

          return {
            name: core_matrix_row.name,
            color: colorList[rx],
            dossier_id_set: matrix_data_row.dossier_id_set,
            dossier_count: matrix_data_row.dossier_count,
            critical_count: matrix_data_row.critical_count,
          };
        }),
      };
    });
  }, [data.res]);

  const cardSize = useMemo(() => (12 / ((tree?.length || 0) + 1)) as any, [tree]);

  return (
    <Card>
      <CardHeader
        title="Ondersteuningsgebieden & betrokken ketenpartners"
        action={
          <>
            {!!data.res && (
              <Tooltip title="Download als Excel.">
                <span>
                  <IconButton disabled={data.busy} onClick={() => setDownloading(true)}>
                    <SaveAltOutlined />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Herlaad deze statistiek.">
              <span>
                <IconButton onClick={() => setIteration((x) => x + 1)} disabled={data.busy}>
                  <Refresh />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
      />

      <Grid container spacing={3}>
        {tree?.map((item, ix) => {
          const total_dossier_count = item.scoreRows.reduce((v, i) => v + i.dossier_count, 0);
          const total_critical_count = item.scoreRows.reduce((v, i) => v + i.critical_count, 0);

          return (
            <Grid item xs={12} md={6} xl={cardSize} key={ix}>
              <Table style={{ width: "100%", maxWidth: "100%", tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" width="60px" size="small" style={{ paddingRight: 0 }}>
                      &nbsp;
                    </TableCell>
                    <TableCell variant="head">{item.name}</TableCell>
                    <TableCell variant="head" width="80px" size="small" style={{ paddingLeft: 0, textAlign: "right" }}>
                      Cliënten
                    </TableCell>
                    <TableCell variant="head" width="70px" size="small" style={{ paddingLeft: 0, textAlign: "right" }}>
                      Ernstig
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.scoreRows.map((row, rx) => {
                    const nameSet = row.name.split(" ");
                    const prefix = nameSet.shift();
                    const name = nameSet.join(" ");

                    return (
                      <TableRow key={rx} hover onClick={() => setActiveDossierIdSet(row.dossier_id_set)}>
                        <TableCell size="small" style={{ paddingRight: 0, textAlign: "right" }}>
                          <Chip style={{ backgroundColor: row.color }} label={prefix} size="small" />
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {name}
                        </TableCell>
                        <TableCell size="small" style={{ paddingLeft: 0, textAlign: "right" }}>
                          {row.dossier_count}
                        </TableCell>
                        <TableCell size="small" style={{ paddingLeft: 0, textAlign: "right" }}>
                          {row.critical_count}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell variant="footer" colSpan={2}>
                      totalen
                    </TableCell>
                    <TableCell variant="footer" style={{ paddingLeft: 0, textAlign: "right" }}>
                      {total_dossier_count}
                    </TableCell>
                    <TableCell variant="footer" style={{ paddingLeft: 0, textAlign: "right" }}>
                      {total_critical_count}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
              <PieChart
                data={{
                  id: "total",
                  name: "Totaal",
                  children: item.scoreRows.map((row, rx) => {
                    const nameSet = row.name.split(" ");
                    const prefix = nameSet.shift();

                    return {
                      id: rx.toString(),
                      name: row.name,
                      prefix,
                      value: row.dossier_count,
                      children: row.critical_count > 0 && [
                        {
                          id: `${rx.toString()}-critical`,
                          name: "Ernstig",
                          value: row.critical_count,
                        },
                      ],
                    };
                  }),
                }}
              />
            </Grid>
          );
        })}

        <Grid item xs={12} md={6} xl={cardSize}>
          <Table style={{ width: "100%", maxWidth: "100%", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Aangesloten Ketenpartners</TableCell>
                <TableCell variant="head" width="80px">
                  Cliënten
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.res?.crm_company_set.map((row) => {
                const crmCompanyLocation = crmCompanyLocationSet.find((x) => x.id === row.crm_company_location_id);

                return (
                  <TableRow hover onClick={() => setActiveDossierIdSet(row.dossier_id_set)}>
                    <TableCell>
                      {crmCompanyLocation.company.name !== crmCompanyLocation.name && crmCompanyLocation.name ? (
                        <>
                          {crmCompanyLocation.company.name} - <em>{crmCompanyLocation.name}</em>
                        </>
                      ) : (
                        crmCompanyLocation.company.name
                      )}
                    </TableCell>
                    <TableCell style={{ paddingLeft: 0, textAlign: "right" }}>{row.dossier_count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell variant="footer">Totaal</TableCell>
                <TableCell variant="footer" style={{ paddingLeft: 0, textAlign: "right" }}>
                  {data.res?.crm_company_set.reduce((v, i) => v + i.dossier_count, 0)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>

      {activeDossierIdSet !== null && (
        <CareDossierDrawerListing dossierIdSet={activeDossierIdSet} onClose={() => setActiveDossierIdSet(null)} />
      )}

      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () => (await getApi().tsChartMatrixesDownloadPost(props.dataDto)).data}
        />
      )}
    </Card>
  );
};

const PieChart: React.FC<{ data: any }> = ({ data }) => {
  return (
    <ChartResponsive height={400}>
      {({ width, height }) => (
        <Sunburst
          width={width}
          height={height}
          data={data}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          id="id"
          value="value"
          cornerRadius={2}
          colors={colorList}
          colorBy="id"
          childColor={{ from: "color", modifiers: [["brighter", 0.1]] }}
          enableArcLabels={true}
          arcLabelsSkipAngle={8}
          arcLabel={(d) => {
            return `${d.data.prefix} ${d.value}`;
          }}
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 1.4]] }}
          isInteractive={true}
          tooltip={(d) => {
            console.info("Tooltip!", d);
            return (
              <div color="green">
                {d.data.name}: {d.value}
              </div>
            );
          }}
        />
      )}
    </ChartResponsive>
  );
};

export default TagScoreDashboardChartMatrixes;
