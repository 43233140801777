import React from "react";
import sleep from "../../../core/sleep";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useApp from "../../../core/useAppStore";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { CrmPersonDto } from "../../../api";

type Props = {
  onClose: () => void;
  record_id: number;
};

const CrmPersonDialogSignoutEverywhere: React.FC<Props> = (props) => {
  const app = useApp();
  const { onClose } = props;
  const [busy, setBusy] = React.useState(false);

  const [{ res: record }] = useApiResult<CrmPersonDto>(
    async () => {
      return (await getApi().crmPersonOnePost({ id: props.record_id })).data;
    },
    [props.record_id],
    { run_on_startup: true },
  );

  const onSignoutEverywhereClick = React.useCallback(async () => {
    if (busy || !record) return;

    setBusy(true);

    try {
      await sleep(250);
      // await getApi().crmPersonSignoutEverywherePost({ request_for_id: record.id });

      app.actions.addMessage(`Het acccount van ${record.name} is overal afgemeld.`, "info", 3);

      onClose();
    } catch (err) {
      app.actions.getApiError(err);
      console.error(err);
      setBusy(false);
    }
  }, [busy, record]);

  return (
    <Dialog open={true}>
      <DialogTitle>Overal afmelden</DialogTitle>
      {record && (
        <DialogContent>
          <Typography variant="body1" paragraph>
            Je staat op het punt om alle sessies van <strong>{record.name}</strong> af te melden. Dit betekent dat alle
            openstaande sessies, gebruikt of ongebruikt, niet meer geldig zullen zijn. Al het onopgeslagen werk zal
            verloren gaan.
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={onClose} color="primary" disabled={busy}>
          Sluiten
        </Button>
        <Button
          startIcon={<ExitToAppIcon />}
          variant="contained"
          onClick={onSignoutEverywhereClick}
          color="primary"
          disabled={busy}
        >
          Overal afmelden.
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrmPersonDialogSignoutEverywhere;
