import React from "react";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import TableDrawerHeaders from "../../../core/TableDrawerHeaders";
import { getApi } from "../../../core/useApi";
import CrmCompanyArchiveDialog from "../Drawers/CrmCompanyArchiveDialog";
import CrmCompanySaveDrawer from "../Drawers/CrmCompanySaveDrawer";
import useCrmCompanyTable from "./useCrmCompanyTable";

const CrmCompanyTableDrawers: React.FC = () => {
  const table = useCrmCompanyTable();
  const { drawer, actions } = table;

  return (
    <>
      {drawer?.name === "TableHeaders" && (
        <TableDrawerHeaders
          open={true}
          header_set={table.header_set}
          used_set={table.req.header_set || ["id", "name"]}
          onClose={() => actions.setDrawer(undefined)}
          onSave={(header_set) => {
            actions.setRequest({ header_set });
            actions.setDrawer(undefined);
          }}
        />
      )}
      {table.drawer?.name === "TableDownload" && (
        <TableDrawerDownload
          onClose={() => table.actions.setDrawer(undefined)}
          onDownloadStart={async () => (await getApi().crmCompanyTableDownloadPost(table.req)).data}
        />
      )}
      {drawer?.name === "Save" && (
        <CrmCompanySaveDrawer
          record_id={drawer.record_id}
          onClose={async (success) => {
            if (success) await actions.load();
            actions.setDrawer();
          }}
        />
      )}
      {drawer?.name === "Archive" && (
        <CrmCompanyArchiveDialog
          record_id={drawer.record_id}
          onClose={async (success) => {
            if (success) await actions.load();
            actions.setDrawer();
          }}
        />
      )}
    </>
  );
};

export default CrmCompanyTableDrawers;
