import makeStyles from "@material-ui/core/styles/makeStyles";

export const Style_SkeletonDrawer_Width = 260;

const useSkeletonDrawerStyles = makeStyles((theme) => ({
  SkeletonDrawer_Container: {
    width: Style_SkeletonDrawer_Width,
    flexShrink: 0,
  },
  SkeletonDrawer_Paper: {
    width: Style_SkeletonDrawer_Width,
  },

  SkeletonDrawer_ToolbarClipper: theme.mixins.toolbar,

  SkeletonDrawer_IconExpander: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    lineHeight: 1,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  SkeletonDrawer_IconExpanderOn_225: {
    transform: "rotate(225deg)",
  },
  SkeletonDrawer_IconExpanderOn_180: {
    transform: "rotate(180deg)",
  },
  SkeletonDrawer_Avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  ListItem_Open: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  ListItem_Selected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.contrastText,
    },
  },

  ListItem_Depth_0: {
    paddingLeft: theme.spacing(2),
  },
  ListItem_Depth_1: {
    paddingLeft: theme.spacing(4),
  },
  ListItem_Depth_2: {
    paddingLeft: theme.spacing(6),
  },
  ListItem_Depth_3: {
    paddingLeft: theme.spacing(8),
  },
}));

export default useSkeletonDrawerStyles;
