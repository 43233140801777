import { Grid, IconButton, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";

const FormatHidden: React.FC<{ value: React.ReactNode }> = ({ value }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        {visible ? (
          <Typography variant="body1">{value}</Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            ••••••••
          </Typography>
        )}
      </Grid>
      <Grid item>
        <IconButton onClick={() => setVisible(!visible)}>{visible ? <VisibilityOff /> : <Visibility />}</IconButton>
      </Grid>
    </Grid>
  );
};

export default FormatHidden;
