import { Chip } from "@material-ui/core";
import { addQuarters, format, parseISO, setDayOfYear, subMonths, subQuarters } from "date-fns";
import React from "react";
import capdash_data from "../../core/capdash_data";
import useGenericStyles from "../../core/useGenericStyles";
import { CapDashDashboardStateFiltersData } from "./CapDashDashboard";

type Props = {
  filters_data: CapDashDashboardStateFiltersData;
};

const CapDashDashboardFiltersSentence: React.FC<Props> = (props) => {
  const gc = useGenericStyles();

  const previousMonth = subMonths(new Date(), 1);
  const previousQuarter = subQuarters(new Date(), 1);
  const currentQuarter = addQuarters(previousQuarter, 1);
  const previousYear = subMonths(new Date(), 12);
  const currentYear = setDayOfYear(new Date(), 1);

  const period_set = [
    { value: "Now", label: "Nu" },
    { value: "Yesterday", label: "Gisteren" },
    { value: "YesterdayTillNow", label: "Gisteren en vandaag" },
    { value: "Previous7Days", label: "Afgelopen 7 dagen" },
    { value: "Previous4Weeks", label: "Afgelopen 4 weken" },
    { value: "PreviousMonth", label: `Vorige maand (${format(previousMonth, "MMMM")})` },
    { value: "PreviousQuarter", label: `Afgelopen kwartaal (${format(previousQuarter, "yyyy - Q")})` },
    { value: "ThisQuarter", label: `Dit kwartaal (${format(currentQuarter, "yyyy - Q")})` },
    { value: "Previous12Months", label: `Afgelopen 12 maanden (vanaf ${format(previousYear, "PP")})` },
    { value: "ThisYear", label: `Dit jaar (vanaf ${format(currentYear, "PP")})` },
    { value: "Manual", label: "Zelf invoeren" },
  ];

  let period_method = period_set.find((x) => x.value === props.filters_data.period_method).label;
  if (props.filters_data.period_method === "Manual") {
    const period_start = props.filters_data.period_start
      ? parseISO(props.filters_data.period_start as any)
      : new Date();
    const period_end = props.filters_data.period_end ? parseISO(props.filters_data.period_end as any) : new Date();
    period_method += ` (${format(period_start, "PP")} `;
    period_method += `t/m ${format(period_end, "PP")})`;
  }

  const address_province_set = props.filters_data.address_province_set || [];
  const special_filter_set = t_special_filter_set(props.filters_data);

  const extract = (from: string[], set: string[]) => {
    return from.filter((x) => set.includes(x));
  };

  const all_sets = [
    ...address_province_set,
    ...extract(capdash_data.cd_life_course_region, props.filters_data.cd_life_course_region_set),
    ...extract(capdash_data.cd_care_type, props.filters_data.cd_care_type_set),
    ...extract(capdash_data.cd_security_level, props.filters_data.cd_security_level_set),
    ...extract(capdash_data.cd_legal_regime, props.filters_data.cd_legal_regime_set),
    ...extract(capdash_data.cd_primary_specialism, props.filters_data.cd_primary_specialism_set),
    ...extract(capdash_data.cd_characteristic_specialism, props.filters_data.cd_characteristic_specialism_set),
    ...extract(capdash_data.cd_contraindication, props.filters_data.cd_contraindication_set),
    ...special_filter_set,
  ];

  return (
    <div>
      <Chip label={period_method} className={gc.Chip_Inline} />
      {all_sets.map((label, rx) => (
        <Chip label={label} className={gc.Chip_Inline} key={rx} />
      ))}
    </div>
  );
};

function t_special_filter_set(filters_data: CapDashDashboardStateFiltersData) {
  let res = [];

  if (filters_data.special_filter_set?.includes("ignore-0-valued")) {
    res.push("Negeer locaties met 0 waarden.");
  }
  if (filters_data.special_filter_set?.includes("ignore-0-first-valued")) {
    res.push("Negeer locaties waarbij eerste waarde 0 is.");
  }
  if (filters_data.special_filter_set?.includes("ignore-0-last-valued")) {
    res.push("Negeer locaties waarbij laatste waarde 0 is.");
  }

  return res;
}

export default CapDashDashboardFiltersSentence;
