import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useTheme,
} from "@material-ui/core";

const data = {
  id: 2,
  name: "Leeftijd eerste politie contact",
  score: [
    { name: " ispsum", number: "Score 1" },
    { name: " ispsum", number: "Score 2" },
    { name: " ispsum", number: "Score 3" },
    { name: " ispsum", number: "Score 4" },
    { name: " ispsum", number: "Score 5" },
  ],
};

type ManagementMatrixOverviewScoreDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};
const ManagementMatrixOverviewScoreDialog: React.FC<ManagementMatrixOverviewScoreDialogProps> = (props) => {
  const { isOpen, onClose } = props;
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle style={{ color: theme.palette.primary.main }}>{data.name}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {data.score.map((score, i) => {
            return (
              <Grid item xs={12} key={i}>
                <TextField fullWidth label={score.name} variant="outlined" helperText={score.number} disabled />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant={"text"} onClick={() => onClose()}>
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagementMatrixOverviewScoreDialog;
