import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { PlayArrow, Stop } from "@material-ui/icons";
import React, { useState } from "react";
import DialogBusy from "../../../core/DialogBusy";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useTranslations from "../../../core/useTranslations";

type Props = {
  record_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const CrmCompanyLocationArchiveDialog: React.FC<Props> = (props) => {
  const app = useAppStore();
  const _t = useTranslations();
  const [busy, setBusy] = useState(false);
  const { record } = useRxjsFetch(
    async () => (await getApi().crmCompanyLocationOnePost({ id: props.record_id })).data,
    [props.record_id],
  );

  const onClose = async (success?: boolean) => {
    setBusy(true);
    try {
      await props.onClose(success);
    } catch (e) {
      setBusy(false);
    }
  };

  const submit = async () => {
    setBusy(true);
    await callApi(app, async (api) =>
      api.crmCompanyLocationArchivePost({ id: record.id, is_archived: !record.is_archived }),
    );
    await onClose(true);
  };

  return (
    <Dialog open={true}>
      {record && (
        <>
          <DialogTitle>
            {record.is_archived ? "Heractiveren" : "Archiveren"} {record.name}
          </DialogTitle>
          <DialogContent>
            {record.is_archived ? (
              <DialogContentText>
                Weet u zeker dat u de {_t("locatie")} {record.name} wilt herarctiveren?
              </DialogContentText>
            ) : (
              <DialogContentText>
                Weet u zeker dat u de {_t("locatie")} {record.name} wilt archiveren?
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => onClose()} disabled={busy}>
              Annuleren
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={busy}
              onClick={submit}
              startIcon={record.is_archived ? <PlayArrow /> : <Stop />}
            >
              {record.is_archived ? "Heractiveren" : "Archiveren"}
            </Button>
          </DialogActions>
        </>
      )}

      <DialogBusy busy={busy} />
    </Dialog>
  );
};

export default CrmCompanyLocationArchiveDialog;
