import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import produce from "immer";
import React from "react";
import _ from "underscore";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";

type Props = {
  form: RxjsForm<any>;

  name: string;
  label: React.ReactNode;
  helperText?: React.ReactNode;

  items: (PropItem | false | undefined | null)[];
};

type PropItem = {
  label: React.ReactNode;
  value: any;
};

const CheckboxFieldExtComp: React.FC<Props> = (props) => {
  const { name, label, items, helperText, form } = props;
  const { value, error, dirty } = extractRxjsField(form, name);

  const classes = useStyles();

  const onCheckboxChange = React.useCallback(
    (item: PropItem, checked: boolean) => {
      const v = produce(value || [], (draft) => {
        if (checked) {
          draft.push(item.value);
        } else {
          const index = draft.indexOf(item.value);
          if (index > -1) {
            draft.splice(index, 1);
          }
        }
      });

      form.actions.setValue(name, v);
    },
    [value],
  );

  return (
    <FormControl error={!!error} margin="normal" className={classes.FormControl}>
      <FormLabel>{label}</FormLabel>
      <FormGroup className={classes.FormGroup}>
        {items
          .filter((x) => !!x)
          .map((item: PropItem) => {
            const checked = _.contains(value, item.value);
            return (
              <FormControlLabel
                key={item.value}
                control={<Checkbox />}
                label={item.label}
                checked={checked}
                value={item.value}
                onChange={(e, c) => onCheckboxChange(item, c)}
              />
            );
          })}
      </FormGroup>
      {((dirty && error) || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  FormControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  FormGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

const CheckboxFieldExt = React.memo(CheckboxFieldExtComp, equalityRxjsFieldCompare);

export default CheckboxFieldExt;
