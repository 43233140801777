import { useMemo } from "react";
import useAppStore from "./useAppStore";

export default function useTranslations() {
  const app = useAppStore();
  const slug = useMemo(() => app.session?.company.slug, [app.session?.token]);

  const translations = useMemo(() => {
    if (maps[slug] !== undefined) {
      return maps[slug];
    }
    return {};
  }, [slug, maps]);

  return function get(value: string): string {
    return translations[value] || value;
  };
}

const maps: { [name: string]: { [name: string]: string } } = {
  bb: {
    "Betrokken ketenpartner": "Betrokken zorgaanbieder",
    "Betrokken ketenpartner(s)": "Betrokken zorgaanbieder(s)",
    "Betrokken ketenpartners": "Betrokken zorgaanbieders",
    "Geen ketenpartner gekoppeld": "Geen zorgaanbieder gekoppeld",
    "Gekoppelde ketenpartner": "Gekoppelde zorgaanbieder",
    "Gekoppelde ketenpartner(s)": "Gekoppelde zorgaanbieder(s)",
    "Ketenpartner koppeling": "Zorgaanbieder koppeling",
    "Ketenpartner organisatie(s)": "Zorgaanbieder organisatie(s)",
    "Ketenpartner(s)": "Zorgaanbieder(s)",
    Dossiers: "Dossier",
    Ketenpartner: "Zorgaanbieder",
    Ketenpartners: "Zorgaanbieders",
    Locatie: "Afdeling",
    Locaties: "Afdelingen",
    ketenpartner: "zorgaanbieder",
    locatie: "afdeling",
    locaties: "afdelingen",

    "waiting_failed_lookup.content": "Inhoudelijk",
    "waiting_failed_lookup.financial": "Financieel",
    "waiting_failed_lookup.system": "Systemisch",
  },
};
