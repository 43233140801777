import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";
import { CrmPersonDto, TableRowsResponse } from "../../api";
import { callApi } from "../../core/useApi";
import useAppStore from "../../core/useAppStore";
import useAsyncEffect from "../../core/useAsyncEffect";
import useDebounce from "../../core/useDebounce";
import useGenericStyles from "../../core/useGenericStyles";
import useRxjsForm, { extractRxjsField, RxjsForm } from "../../core/useRxjsForm";
import TextFieldExt from "../../form/TextFieldExt";
import CrmPersonDrawerSave from "../../pages/CrmPersonTable/Drawer/CrmPersonDrawerSave";

// region Component

type Props = {
  form: RxjsForm<any>;

  name: string;
  label: string;
};

const LookupCrmPersonField: React.FC<Props> = (props) => {
  const app = useAppStore();
  const { form, name, label } = props;
  const { value, error, dirty } = extractRxjsField(form, name);
  const [person, setPerson] = React.useState<CrmPersonDto | undefined>(undefined);
  const [busy, setBusy] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const onDialogClose = React.useCallback((record_id?: number) => {
    if (record_id !== undefined) {
      form.actions.setValue(name, record_id);
    }

    setOpen(false);
  }, []);

  useAsyncEffect(
    async (interrupted) => {
      if (value === -1) {
        setPerson(undefined);
        return;
      }

      setBusy(true);

      const res = await callApi(app, async (api) => {
        return (await api.crmPersonOnePost({ id: value })).data;
      });

      if (interrupted) return;

      setBusy(false);
      setPerson(res);
    },
    [value],
  );

  return (
    <>
      <TextField
        fullWidth
        disabled
        variant="outlined"
        label={label}
        type="text"
        value={person?.name || "Geen persoon gekozen."}
        onClick={() => setOpen(true)}
        error={dirty && !!error}
        helperText={dirty && error}
        InputProps={{
          startAdornment: busy && (
            <InputAdornment position="start">
              <CircularProgress size={18} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={busy}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LookupCrmPersonDialog open={open} onClose={onDialogClose} />
    </>
  );
};

// endregion
// region Dialog

type DialogProps = {
  open: boolean;
  onClose: (record_id?: number) => void;
};

const LookupCrmPersonDialog: React.FC<DialogProps> = (props) => {
  const app = useAppStore();
  const { open, onClose } = props;
  const form = useRxjsForm<{ search: string }>({ initial: { search: "" } });
  const [busy, setBusy] = React.useState(false);
  const [result, setResult] = React.useState<TableRowsResponse>(undefined);
  const [crmPersonDrawerSaveOpen, setCrmPersonDrawerSaveOpen] = React.useState(false);
  const gc = useGenericStyles();

  const [search] = useDebounce(form.values.search, 250);

  useAsyncEffect(
    async (interrupted) => {
      setBusy(true);
      const res = await callApi(app, async (api) => {
        return (
          await api.crmPersonTableRowsPost({
            header_set: ["id", "name", "tag_set"],
            offset: 0,
            limit: 10,
            is_archived: 0,
            search,
            tag_id_set: [],
            role_id_set: [],
            company_id_set: [],
            involved_company_id_set: [],
          })
        ).data;
      });
      setBusy(false);

      if (res === undefined || interrupted) return;

      setResult(res);
    },
    [search],
  );

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <form onSubmit={form.actions.submit} noValidate>
        <DialogTitle>Persoon opzoeken</DialogTitle>
        <DialogContent>
          <DialogContentText>Zoeken naar een persoon</DialogContentText>

          <TextFieldExt form={form} name="search" label="Zoeken..." />
        </DialogContent>
        <List>
          {result?.row_set.map((row) => (
            <ListItem key={row.record_id} button disabled={busy} onClick={() => onClose(row.record_id)}>
              <ListItemText primary={row.values[1]} />
            </ListItem>
          ))}
          <ListItem button disabled={busy} onClick={() => setCrmPersonDrawerSaveOpen(true)}>
            <ListItemText primary="Niet gevonden, aanmaken." />
          </ListItem>
        </List>

        <DialogActions>
          <Button onClick={() => onClose()}>Sluiten</Button>
        </DialogActions>
      </form>

      {crmPersonDrawerSaveOpen && (
        <CrmPersonDrawerSave
          onClose={(success, record_id?: number) => {
            setCrmPersonDrawerSaveOpen(false);

            if (success) {
              onClose(record_id);
            }
          }}
        />
      )}
    </Dialog>
  );
};

// endregion

export default LookupCrmPersonField;
