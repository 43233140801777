import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import {
  CareDossierOneResponse,
  CareMatrixRowCompanyDto,
  CareMatrixRowDto,
  CrmCompanyLocationDto,
  CrmCompanyManyResponse,
} from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";
import DateFieldExt from "../../../form/DateFieldExt";

type Props = {
  id?: number;
  care_dossier_id: number;
  care_matrix_row_id: number;
  started_at?: string;
  closed_at?: string;
  onClose: (success?: boolean) => Promise<void>;
};

type CrmCompanyLocationDtoExt = CrmCompanyLocationDto & { score: number; crm_company_sm: { id: number; name: string } };

const CareMatrixDrawerRowResponsible: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { app, state, form, actions } = useComponent(props);

  const matrix_core = useMemo(
    () => app.data.matrix_core_set.find((x) => x.id === state.care_matrix_row?.matrix_core_id),
    [state.care_matrix_row?.id],
  );
  const matrix_row = useMemo(
    () => matrix_core?.row_set.find((x) => x.id === state.care_matrix_row?.matrix_row_id),
    [state.care_matrix_row?.id],
  );

  const crm_company_location_set: CrmCompanyLocationDtoExt[] = useMemo(() => {
    let result: CrmCompanyLocationDtoExt[] = [];

    if (!state.crm_company_set?.records) return result;

    for (const crm_company of state.crm_company_set.records) {
      const crm_company_matrix = crm_company.crm_company_matrix_set.find((x) => x.matrix_core_id === matrix_core.id);
      const crm_company_matrix_value = crm_company_matrix?.crm_company_matrix_value_set.find(
        (x) => x.matrix_row_id === matrix_row.id,
      );
      const crm_company_matrix_value_resolved = matrix_core.value_set.find(
        (x) => x.id === crm_company_matrix_value?.matrix_value_id,
      );

      const crm_company_matrix_allowed_score = crm_company_matrix_value_resolved?.score || -1;

      let crm_company_score = 0;

      if (crm_company_matrix_allowed_score >= state.care_matrix_row.score_current) {
        crm_company_score += 2;
      } else if (crm_company_matrix_allowed_score > -1) {
        crm_company_score += 1;
      }

      if (crm_company.employable_regions.indexOf(state.care_dossier.person.address_region)) {
        crm_company_score += 1;
      }

      for (const crm_company_location of crm_company.location_set) {
        let score = crm_company_score;
        if (crm_company_location.capacity_available > 0) {
          score += 1;
        }

        result.push({ ...crm_company_location, crm_company_sm: { id: crm_company.id, name: crm_company.name }, score });
      }
    }

    return _.sortBy(result, (x) => -x.score);
  }, [state.crm_company_set?.records]);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>
          Doelstelling bij {state.care_dossier?.person.name} op {matrix_row?.name}
        </DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <DateFieldExt form={form} label="Start-datum" name="started_at" />
            </Grid>
            <Grid item md={6}>
              <DateFieldExt form={form} label="Eind-datum" name="closed_at" />
            </Grid>
          </Grid>
          <div style={{ overflow: "auto", flex: "1 1 auto" }}>
            <List>
              {crm_company_location_set.map((crm_company_location) => (
                <ListItem
                  key={crm_company_location.id}
                  selected={crm_company_location.id === form.values.crm_company_location_id}
                  button
                  onClick={() => form.actions.setValue("crm_company_location_id", crm_company_location.id)}
                >
                  <ListItemText
                    primary={`${crm_company_location.crm_company_sm.name} - ${crm_company_location.name}`}
                    secondary={<Rating size="small" value={crm_company_location.score} max={4} readOnly />}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="button" disabled={state.busy} onClick={() => actions.onClose()}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={state.busy}>
            Opslaan
          </Button>
        </DialogActions>
      </form>
      <DialogBusy busy={state.busy} />
    </Drawer>
  );
};

type State = {
  busy: boolean;
  care_dossier?: CareDossierOneResponse;
  care_matrix_row?: CareMatrixRowDto;
  care_matrix_row_company?: CareMatrixRowCompanyDto;
  crm_company_set?: CrmCompanyManyResponse;
};

type Values = {
  crm_company_location_id: number;
  started_at: string;
  closed_at: string;
};

function useComponent(props: Props) {
  const app = useAppStore();
  const isMounted = useIsMounted();
  const { state, getState, next } = useRxjsStore<State>({ busy: false });
  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));

        const care_dossier = await callApi(
          app,
          async (api) => (await api.careDossierOnePost({ id: props.care_dossier_id })).data,
        );
        const care_matrix_row = await callApi(
          app,
          async (api) => (await api.careMatrixRowOnePost({ id: props.care_matrix_row_id })).data,
        );

        const crm_company_set = await callApi(app, async (api) => (await api.crmCompanyManyPost({})).data);

        next((d) => {
          d.busy = false;
          d.care_dossier = care_dossier;
          d.care_matrix_row = care_matrix_row;
          d.care_matrix_row_company = care_matrix_row.company_set.find((x) => {
            console.info(x.id, props.id);
            return x.id === props.id;
          });
          d.crm_company_set = crm_company_set;
        });
      },

      onClose: async (success?: boolean) => {
        await props.onClose(success);
      },
      submit: async (values: Values) => {
        next((d) => (d.busy = true));

        const curr = getState();
        const res = await callApi(app, async (api) =>
          api.careMatrixRowSaveResponsiblePost({
            id: curr.care_matrix_row_company?.id,
            care_matrix_row_id: curr.care_matrix_row.id,
            crm_company_location_id: values.crm_company_location_id,
            started_at: values.started_at === "" ? null : values.started_at,
            closed_at: values.closed_at === "" ? null : values.closed_at,
          }),
        );

        if (res) {
          await actions.onClose(true);
        }

        if (isMounted()) {
          next((d) => (d.busy = false));
        }
      },
    }),
    [],
  );
  const form = useRxjsForm<Values>({
    initial: { crm_company_location_id: null, started_at: "", closed_at: "" },
    submit: async (values) => actions.submit(values),
  });

  useAsyncEffect(actions.load, [props.care_matrix_row_id]);
  useEffect(() => {
    console.info(state.care_matrix_row_company, state.care_matrix_row, {
      crm_company_location_id:
        state.care_matrix_row_company?.crm_company_location_id ||
        state.care_matrix_row?.crm_company_location_id ||
        null,
      started_at: state.care_matrix_row_company?.started_at || "",
      closed_at: state.care_matrix_row_company?.closed_at || "",
    });
    form.actions.setValues({
      crm_company_location_id:
        state.care_matrix_row_company?.crm_company_location_id ||
        state.care_matrix_row?.crm_company_location_id ||
        null,
      started_at: state.care_matrix_row_company?.started_at || "",
      closed_at: state.care_matrix_row_company?.closed_at || "",
    });
  }, [state.care_matrix_row?.id]);

  return { app, state, form, actions };
}

export default CareMatrixDrawerRowResponsible;
