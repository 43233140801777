import { Chip } from "@material-ui/core";
import React from "react";
import { CrmCompanyDto, CrmCompanyLocationDto } from "../../../api";
import DataTableExt from "../../../core/DataTableExt";
import { FormatValue } from "../../../core/TableCellValue";
import useGenericStyles from "../../../core/useGenericStyles";
import useTranslations from "../../../core/useTranslations";

type Props = {
  company: CrmCompanyDto;
  location?: CrmCompanyLocationDto;
};

const CrmCompanyDataTableExt: React.FC<Props> = ({ company, location }) => {
  const _t = useTranslations();
  const gc = useGenericStyles();

  return (
    <DataTableExt
      data={[
        ["Gearchiveerd", company.is_archived && <strong>Deze {_t("ketenpartner")} is gearchiveerd</strong>],
        ["Naam", company.name],
        ["Contactpersoon", company.contact_name],
        ["Functie", company.contact_role],
        ["E-mailadres", company.contact_email && <FormatValue value={company.contact_email} fmt="email" />],
        ["Telefoonnummer", company.contact_phone && <FormatValue value={company.contact_phone} fmt="tel" />],
        ["Regio's", company.employable_regions.join(", ")],
        ["Openingstijden", company.opening_hours],
        ["Provincie", company.address_province],
        [
          "Adres",
          company.address_zipcode && (
            <>
              {company.address_street} {company.address_housenumber}
              {company.address_affix && ` ${company.address_affix}`},<br />
              {company.address_zipcode} {company.address_city},<br />
              {company.address_country}
            </>
          ),
        ],
        [
          "Contra-indicatie(s)",
          location?.cd_contraindication_set.map((v) => <Chip label={v} className={gc.Chip_Inline} />),
        ],
      ]}
    />
  );
};

export default CrmCompanyDataTableExt;
