import React from "react";
import _ from "underscore";
import TableExt from "../../../core/TableExt";
import useTranslations from "../../../core/useTranslations";
import { MatrixCoreTableRowButtonsStart } from "./MatrixCoreTableButtons";
import MatrixCoreTableDrawers from "./MatrixCoreTableDrawers";
import MatrixCoreTableToolbar from "./MatrixCoreTableToolbar";
import useMatrixCoreTable, { MatrixCoreTableProvider } from "./useMatrixCoreTable";

type Props = {};

const MatrixCoreTable: React.FC<Props> = (props) => {
  return (
    <MatrixCoreTableProvider>
      <Render />
    </MatrixCoreTableProvider>
  );
};

const Render: React.FC = () => {
  const table = useMatrixCoreTable();
  const _t = useTranslations();

  const headers = React.useMemo(
    () => table.req.header_set.map((x) => table.header_set.find((h) => h.name === x)).filter((x) => !!x),
    [table.req.header_set],
  );

  const initial = React.useMemo(() => _.pick(table.req, ["search"]), [table.booted, table.req]);

  return (
    <>
      <TableExt
        loading={table.loading}
        title="Matrix management"
        header_set={headers}
        row_set={table.res.row_set}
        pagination={table.res.pagination}
        setPagination={(x) => table.actions.setPagination(x)}
        toolbar={
          <MatrixCoreTableToolbar
            initial={initial}
            discardAdd
            discardColumns
            discardDownload
            onChange={(props) => {
              table.actions.setRequest(props);
            }}
          />
        }
        RowButtonsStart={MatrixCoreTableRowButtonsStart}
      />
      <MatrixCoreTableDrawers />
    </>
  );
};

export default MatrixCoreTable;
