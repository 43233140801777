import {
  Card,
  CardContent,
  CardHeader,
  colors,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Refresh, SaveAltOutlined, ToggleOff, ToggleOn } from "@material-ui/icons";
import React, { useState } from "react";
import { CdDataChartCurrentCapacityRow, CdDataChartTypeEnum } from "../../../api";
import FormatDate from "../../../core/FormatDate";
import FormatDateDistance from "../../../core/FormatDateDistance";
import sleep from "../../../core/sleep";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useCrmCompanyLocation from "../../../core/useCrmCompanyLocation";
import useGenericStyles from "../../../core/useGenericStyles";
import useTranslations from "../../../core/useTranslations";
import CrmCompanyInfoDrawer from "../../CrmCompany/Ext/CrmCompanyInfoDrawer";
import CrmCompanyLocationShortCode from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";
import { CapDashDashboardStateFiltersData } from "../CapDashDashboard";

type Props = {
  filters_data: CapDashDashboardStateFiltersData;
};

const CapDashDashboardChartCurrentCapacity: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const _t = useTranslations();
  const [filterCrmLocationIdSet, setFilterCrmLocationIdSet] = useState<number[] | undefined>(undefined);
  const [downloading, setDownloading] = useState(false);
  const [onlyAvailableRows, setOnlyAvailableRows] = useState(false);
  const [iteration, setIteration] = useState(0);

  const [data] = useApiResult(async () => {
    await sleep(250);

    const filter_crm_location_id_set = (
      await getApi().cdDataChartLocationsPost({ chart: CdDataChartTypeEnum.CareAccess, ...props.filters_data })
    ).data.location_id_set;

    setFilterCrmLocationIdSet(filter_crm_location_id_set);

    return (
      await getApi().cdDataChartCurrentCapacityPost({
        day_after: props.filters_data.period_start,
        day_before: props.filters_data.period_end,
        crm_company_location_id_set: filter_crm_location_id_set,
      })
    ).data;
  }, [props.filters_data, iteration]);

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Actuele Bedcapaciteit"
        action={
          <>
            {!!data.res && (
              <>
                <Tooltip
                  title={
                    onlyAvailableRows
                      ? "Toon alle afdelingen ongeacht de beschikbare capaciteit."
                      : "Alleen afdelingen met beschikbare capaciteit."
                  }
                >
                  <IconButton onClick={() => setOnlyAvailableRows((x) => !x)} disabled={data.busy}>
                    {onlyAvailableRows ? <ToggleOff /> : <ToggleOn />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download als Excel.">
                  <IconButton onClick={() => setDownloading(true)} disabled={data.busy}>
                    <SaveAltOutlined />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="Herlaad deze statistiek.">
              <IconButton onClick={() => setIteration((x) => x + 1)} disabled={data.busy}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </>
        }
      />

      {data.busy && (
        <>
          <LinearProgress style={{ width: "100%", height: 3 }} variant="indeterminate" />
          <CardContent>
            <Typography variant="body2">
              We zijn de statistiek aan het laden, een ogenblik geduld alstublieft.
            </Typography>
          </CardContent>
        </>
      )}

      {!!data.res && (
        <div style={{ overflow: "hidden", position: "relative", width: "100%", height: 600 }}>
          <TableContainer style={{ position: "absolute", height: "100%", overflowX: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="300px" style={{ left: 0, position: "sticky", zIndex: 3 }} variant="head">
                    {_t("Locatie")}
                  </TableCell>
                  <TableCell className={gc.TableCell_Right}>Actualisatie</TableCell>
                  <TableCell className={gc.TableCell_Right}>Totale capaciteit</TableCell>
                  <TableCell className={gc.TableCell_Right}>Externe capaciteit</TableCell>
                  <TableCell className={gc.TableCell_Right}>Bezette capaciteit</TableCell>
                  <TableCell className={gc.TableCell_Right}>Beschikbare capaciteit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.res.row_set
                  .filter((x) => (onlyAvailableRows ? x.available > 0 : true))
                  .map((row: CdDataChartCurrentCapacityRow) => (
                    <CapDashDashboardChartCurrentCapacityTableRow row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () =>
            (
              await getApi().cdDataChartCurrentCapacityDownloadPost({
                day_after: props.filters_data.period_start,
                day_before: props.filters_data.period_end,
                crm_company_location_id_set: filterCrmLocationIdSet,
              })
            ).data
          }
        />
      )}
    </Card>
  );
};

const CapDashDashboardChartCurrentCapacityTableRow: React.FC<{ row: CdDataChartCurrentCapacityRow }> = ({ row }) => {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const location = useCrmCompanyLocation(row.crm_company_location_id);
  const gc = useGenericStyles();

  return (
    <TableRow>
      <TableCell style={{ left: 0, position: "sticky", backgroundColor: "#fff" }}>
        <div style={{ width: 400, cursor: "help" }} onClick={() => setInfoOpen(true)}>
          <strong>{location.company.name}</strong>
          <br />
          <CrmCompanyLocationShortCode location={location} />
        </div>

        <CrmCompanyInfoDrawer
          open={isInfoOpen}
          onClose={() => setInfoOpen(false)}
          company={location.company}
          location={location}
        />
      </TableCell>
      <TableCell className={gc.TableCell_Right}>
        <FormatDate value={row.day} fmt="PP" />
        <br />
        <small>
          <FormatDateDistance value={row.day} />
        </small>
      </TableCell>
      <TableCell className={gc.TableCell_Right}>{row.capacity}</TableCell>
      <TableCell className={gc.TableCell_Right}>{row.waiting}</TableCell>
      <TableCell className={gc.TableCell_Right}>{row.occupied}</TableCell>
      <TableCell
        className={gc.TableCell_Right}
        style={{
          // backgroundColor: colors[has_day ? "green" : "red"][100],
          color: colors[row.available > 0 ? "green" : "red"][500],
        }}
      >
        {row.available}
      </TableCell>
    </TableRow>
  );
};

export default CapDashDashboardChartCurrentCapacity;
