import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { nl } from "date-fns/locale";
import React from "react";
import FormatDate from "../../core/FormatDate";
import FormatDateDistance from "../../core/FormatDateDistance";
import useAppStore from "../../core/useAppStore";
import SkeletonPage from "../../skeleton/SkeletonPage";

const ProfileTechnicalSupport: React.FC = () => {
  const app = useAppStore();
  const [sessionLoading, setSessionLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);

  const onSessionRefreshClick = React.useCallback(async () => {
    if (sessionLoading) return;
    setSessionLoading(true);
    await app.actions.checkAuthentication();
    setSessionLoading(false);
  }, [sessionLoading]);

  const onDataRefreshClick = React.useCallback(async () => {
    if (dataLoading) return;

    setDataLoading(true);
    await app.actions.loadData();
    setDataLoading(false);
  }, [dataLoading]);
  return (
    <SkeletonPage>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5">Sessie-gegevens</Typography>
            </CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Token</TableCell>
                  <TableCell>
                    {app.session.token.substring(0, 12)}…{app.session.token.substring(app.session.token.length - 12)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Geldig tot</TableCell>
                  <TableCell>
                    <FormatDate value={app.session.expires_at} />
                    {", "}
                    <FormatDateDistance value={app.session.expires_at} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Uw e-mailadres</TableCell>
                  <TableCell>{app.session.person.email}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <CardActions>
              <Button variant="outlined" color="primary" onClick={onSessionRefreshClick} disabled={sessionLoading}>
                Herladen
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5">Data-gegevens</Typography>
            </CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Applicatie Versie</TableCell>
                  <TableCell>{app.data.version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Moment</TableCell>
                  <TableCell>
                    <FormatDate value={app.data.updated_at} />
                    {", "}
                    <FormatDateDistance value={app.data.updated_at} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Organisaties</TableCell>
                  <TableCell>{app.data.ts_company_set.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tag Categorieën</TableCell>
                  <TableCell>{app.data.tag_category_set.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tags</TableCell>
                  <TableCell>{app.data.tag_core_set.length}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <CardActions>
              <Button variant="outlined" color="primary" onClick={onDataRefreshClick} disabled={dataLoading}>
                Herladen
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </SkeletonPage>
  );
};

function useComponent() {
  return {};
}

export default ProfileTechnicalSupport;
