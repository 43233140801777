import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { CrmCompanyDto, MatrixCoreDtoExt } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import SwitchFieldExt from "../../../form/SwitchFieldExt";

type Props = {
  crm_company_id: number;
  matrix_core_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const CrmCompanySaveMatrixDrawer: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { state, form, actions } = useComponent(props);
  return (
    <Drawer open anchor="right">
      {state.booted && (
        <form className={gc.DrawerContainer} onSubmit={form.actions.submit} noValidate>
          <DialogTitle>
            {state.crm_company.name} - {state.matrix_core.name}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SwitchFieldExt
                  form={form}
                  name="is_scored"
                  onLabel="Er is een score-inrichting."
                  offLabel="Er zijn geen scores op te geven."
                />
              </Grid>

              <Grid item xs={12}>
                <Box marginTop={1} marginBottom={3}>
                  <Divider />
                </Box>
              </Grid>

              {form.values.is_scored &&
                state.matrix_core.row_set.map((matrix_row) => {
                  const matrix_value_set = state.matrix_core.value_set.filter((x) => x.row_id === matrix_row.id);

                  return (
                    <Grid item container spacing={2}>
                      <Grid item xs>
                        {matrix_row.name}
                      </Grid>
                      <Grid item md={7}>
                        <AutocompleteFieldExt
                          form={form}
                          name={`value_id_for_${matrix_row.id}`}
                          label="Score"
                          items={matrix_value_set
                            .slice()
                            .reverse()
                            .map((x) => ({
                              value: x.id,
                              label: `Score: ${x.score} - ${x.description}`,
                            }))}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => actions.onClose()}>
              Annuleren
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={state.busy}>
              Opslaan
            </Button>
          </DialogActions>
        </form>
      )}

      <DialogBusy busy={state.busy} />
    </Drawer>
  );
};

type State = {
  booted: boolean;
  busy: boolean;

  crm_company?: CrmCompanyDto;
  matrix_core?: MatrixCoreDtoExt;
};

function useComponent(props: Props) {
  const app = useAppStore();
  const isMounted = useIsMounted();
  const { state, getState, next } = useRxjsStore<State>({
    booted: false,
    busy: true,
  });
  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));

        const crm_company = await callApi(
          app,
          async (api) => (await api.crmCompanyOnePost({ id: props.crm_company_id })).data,
        );
        const matrix_core = app.data.matrix_core_set.find((x) => x.id === props.matrix_core_id);

        next((d) => {
          d.crm_company = crm_company;
          d.matrix_core = matrix_core;
          d.booted = true;
          d.busy = false;
        });
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));

        await props.onClose(success);

        if (isMounted()) {
          next((d) => (d.busy = false));
        }
      },

      submit: async (is_scored: boolean, value_id_set: number[]) => {
        next((d) => (d.busy = true));

        const res = await callApi(app, async (api) =>
          api.crmCompanySaveMatrixPost({
            crm_company_id: props.crm_company_id,
            matrix_core_id: props.matrix_core_id,
            is_scored,
            value_id_set,
          }),
        );

        if (res !== undefined) {
          await actions.onClose(true);
        } else {
          next((d) => (d.busy = false));
        }
      },
    }),
    [props.crm_company_id, props.matrix_core_id],
  );
  const form = useRxjsForm({
    initial: {
      is_scored: false,
    },
    submit: async (values: any) => {
      const is_scored = values.is_scored;
      const value_id_set = Object.values(_.omit(values, "is_scored")) as number[];

      await actions.submit(is_scored, value_id_set);
    },
  });

  useAsyncEffect(actions.load, [props.crm_company_id, props.matrix_core_id]);
  useEffect(() => {
    if (state.crm_company && state.matrix_core) {
      const crm_company_matrix = state.crm_company.crm_company_matrix_set.find(
        (x) => x.matrix_core_id === state.matrix_core.id,
      );
      const is_scored = crm_company_matrix?.is_scored;
      let initial: { [name: string]: any } = { is_scored };

      if (is_scored) {
        Object.assign(
          initial,
          _.object(
            crm_company_matrix.crm_company_matrix_value_set.map((x) => [
              `value_id_for_${x.matrix_row_id}`,
              x.matrix_value_id,
            ]),
          ),
        );
      }

      form.actions.setValues(initial);
    }
  }, [state.crm_company, state.matrix_core]);

  return { state, form, actions };
}

export default CrmCompanySaveMatrixDrawer;
