import {
  Card,
  CardHeader,
  colors,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CardContent,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Refresh, SaveAltOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { CdDataChartTypeEnum } from "../../../api";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import { CapDashDashboardStateFiltersData } from "../CapDashDashboard";

type Props = {
  filters_data: CapDashDashboardStateFiltersData;
};

const SessionLoginHistory: React.FC<Props> = (props) => {
  const [iteration, setIteration] = useState(0);

  const [data] = useApiResult(async () => {
    return (
      await getApi().genericLogSessionLoginCheckupPost({
        day_after: props.filters_data.period_start,
        day_before: props.filters_data.period_end,
        crm_company_location_id_set: [],
      })
    ).data;
  }, [props.filters_data, iteration]);

  const [downloading, setDownloading] = useState(false);

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Inloghistorie"
        action={
          <>
            {!!data.res && (
              <Tooltip title="Download als Excel.">
                <IconButton onClick={() => setDownloading(true)} disabled={data.busy}>
                  <SaveAltOutlined />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Herlaad deze statistiek.">
              <IconButton onClick={() => setIteration((x) => x + 1)} disabled={data.busy}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </>
        }
      />

      {data.busy && (
        <>
          <LinearProgress style={{ width: "100%", height: 3 }} variant="indeterminate" />
          <CardContent>
            <Typography variant="body2">
              We zijn de statistiek aan het laden, een ogenblik geduld alstublieft.
            </Typography>
          </CardContent>
        </>
      )}
      {!!data.res && (
        <div style={{ overflow: "hidden", position: "relative", width: "100%", height: 600 }}>
          <TableContainer style={{ position: "absolute", height: "100%", overflowX: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="300px" style={{ left: 0, position: "sticky", zIndex: 3 }} variant="head">
                    Persoon
                  </TableCell>
                  {data.res.weeks.map((week) => (
                    <TableCell align="center">{week}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.res.rows.map((row) => (
                  <TableRow>
                    <TableCell>{row.crm_person_name}</TableCell>
                    {row.week_set.map((week) => (
                      <TableCell
                        align="center"
                        style={{
                          color: colors[week.count ? "green" : "red"][500],
                        }}
                      >
                        {week.count}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () =>
            (
              await getApi().genericLogSessionLoginCheckupDownloadPost({
                day_after: props.filters_data.period_start,
                day_before: props.filters_data.period_end,
                crm_company_location_id_set: [],
              })
            ).data
          }
        />
      )}
    </Card>
  );
};

export default SessionLoginHistory;
