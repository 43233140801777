import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  Drawer,
  Typography,
} from "@material-ui/core";
import React from "react";
import { CrmCompanyDto, CrmCompanyLocationDto } from "../../../api";
import useAppStore from "../../../core/useAppStore";
import useNavigate from "../../../core/useNavigate";
import CrmPersonPartialExtDataTable from "../../CrmPersonTable/Ext/CrmPersonPartialExtDataTable";
import CrmCompanyDataTableExt from "./CrmCompanyDataTableExt";

type Props = {
  open: boolean;
  onClose: () => void;
  company?: CrmCompanyDto;
  location?: CrmCompanyLocationDto;
};

const CrmCompanyInfoDrawer: React.FC<Props> = ({ open, onClose, company, location }) => {
  const app = useAppStore();
  const navigate = useNavigate();

  return (
    <Drawer open={open} anchor="right" variant="temporary">
      {company && (
        <>
          <DialogContent>
            <Accordion defaultExpanded={true}>
              <AccordionSummary>
                <Typography variant="h6">{company.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CrmCompanyDataTableExt company={company} location={location} />
              </AccordionDetails>
            </Accordion>

            {location &&
              app.data.crm_person_set
                .filter((x) => !x.is_archived)
                .filter((x) => x.location_id_set.includes(location.id))
                .map((crm_person, rx) => (
                  <Accordion defaultExpanded={rx === 0}>
                    <AccordionSummary>
                      <Typography variant="h6">{crm_person.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CrmPersonPartialExtDataTable person={crm_person} />
                    </AccordionDetails>
                  </Accordion>
                ))}
          </DialogContent>
          <DialogActions>
            {location && app.session.person.role_id_set.includes(2) && (
              <Button
                onClick={() => {
                  onClose();
                  navigate(`/capdash/data/${location.id}`);
                }}
              >
                Invoer
              </Button>
            )}
            <Button onClick={() => onClose()}>Sluiten</Button>
          </DialogActions>
        </>
      )}
    </Drawer>
  );
};

export default CrmCompanyInfoDrawer;
