import useRxjsStore, { RxjsStore } from "./useRxjsStore";
import useAsyncEffect from "./useAsyncEffect";
import { DependencyList } from "react";
import useApp from "./useAppStore";

type RxjsFetchState<Record> = {
  booted: boolean;
  loading: boolean;
  record?: Record;
};

type RxjsFetch<Record> = RxjsFetchState<Record> & {
  load: () => Promise<void>;
};

function useRxjsFetch<Record = any>(load: () => Promise<Record | undefined>, deps: DependencyList): RxjsFetch<Record> {
  const app = useApp();
  const store = useRxjsStore<RxjsFetchState<Record>>({
    booted: false,
    loading: false,
    record: undefined,
  });

  const { next, getState } = store;

  const actions = {
    load: async () => {
      next((d) => (d.loading = true));

      try {
        const record = await load();
        next((d) => (d.record = record));
      } catch (err) {
        app.actions.getApiError(err);
      }

      next((d) => {
        d.booted = true;
        d.loading = false;
      });
    },
  };

  useAsyncEffect(async () => {
    await actions.load();
  }, deps);

  return { ...store.state, load: actions.load };
}

export default useRxjsFetch;
