import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Delete, PlayArrow, Stop } from "@material-ui/icons";
import React, { useState } from "react";
import DialogBusy from "../../../core/DialogBusy";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useTranslations from "../../../core/useTranslations";

type Props = {
  record_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const MatrixColumnDeleteDialog: React.FC<Props> = (props) => {
  const app = useAppStore();
  const _t = useTranslations();
  const [busy, setBusy] = useState(false);
  const { record } = useRxjsFetch(
    async () => (await getApi().matrixColumnOnePost({ id: props.record_id })).data,
    [props.record_id],
  );

  const onClose = async (success?: boolean) => {
    setBusy(true);
    try {
      await props.onClose(success);
    } catch (e) {
      setBusy(false);
    }
  };

  const submit = async () => {
    setBusy(true);
    await callApi(app, async (api) => api.matrixColumnDeletePost({ id: record.id }));
    await onClose(true);
  };

  return (
    <Dialog open={true}>
      {record && (
        <>
          <DialogTitle>{record.name} verwijderen</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Weet u zeker dat u {record.name} wilt verwijderen? Het verwijderen <strong>kan niet</strong> ongedaan
              gemaakt worden en is permanent. Het verwijderen zal ook consequenties hebben op de ingevoerde data, deze
              zal ook (deels) muteren. Verwijder alleen wanneer u echt zeker bent van de uitwerking. We raden aan dit
              eerst te testen in staging omgeving.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => onClose()} disabled={busy}>
              Annuleren
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={busy}
              onClick={submit}
              startIcon={<Delete />}
            >
              Verwijderen
            </Button>
          </DialogActions>
        </>
      )}

      <DialogBusy busy={busy} />
    </Dialog>
  );
};

export default MatrixColumnDeleteDialog;
