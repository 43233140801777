import { Typography, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import { Note, NoteOutlined } from "@material-ui/icons";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness5Icon from "@material-ui/icons/Brightness5";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import createStyles from "@material-ui/styles/createStyles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TagScoreWhiteSvg from "../assets/tagscore-white.svg";
import TagScoreSvg from "../assets/tagscore.svg";
import useApp from "../core/useAppStore";
import { useEventListener } from "../pages/CareDossierView/CareDossierView";

export default function SkeletonAppBar() {
  const app = useApp();
  const history = useHistory();
  const classes = useSkeletonAppBarStyles();
  const is_handheld = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const toggleSkeletonDrawer = React.useCallback(() => {
    app.actions.toggleDrawer();
  }, [app]);

  useEffect(() => {
    return history.listen(() => {
      console.info("Resetting title");
      app.actions.updateTitle();
      app.actions.removeRightToggler();
      app.actions.toggleDrawer(false);
    });
  });

  return (
    <AppBar position="fixed" className={classes.AppBar_Container}>
      <Toolbar className={classes.AppBar_Toolbar}>
        {app.is_authorized && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.AppBar_MenuButton}
            onClick={toggleSkeletonDrawer}
          >
            {app.drawer.is_open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}

        {is_handheld && (
          <div className={classes.AppBar_Title}>
            <img src={app.theme.is_dark ? TagScoreWhiteSvg : TagScoreSvg} alt="TagScore" height={46} />
          </div>
        )}

        {!is_handheld && app.title && (
          <Typography variant="h6" className={classes.AppBar_Title}>
            {app.title}
          </Typography>
        )}
        <div className={classes.ButtonIcon_Container}>
          {app.is_authorized !== undefined ? (
            <>
              <SkeletonAppBarTogglerRight />
              <SkeletonAppBarColorScheme />
            </>
          ) : (
            <>
              <SkeletonAppBarColorScheme />
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export const useSkeletonAppBarStyles = makeStyles((theme) =>
  createStyles({
    AppBar_Container: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    AppBar_Toolbar: {
      justifyContent: "space-between",
      alignItems: "center",
    },
    AppBar_MenuButton: {
      marginRight: theme.spacing(2),
    },
    AppBar_Title: {
      lineHeight: 0,

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    AppBar_TitlePlus: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(2),
    },

    Search_Container: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    Search_Icon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    Search_InputRoot: {
      color: "inherit",
    },
    Search_Input: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },

    ButtonIcon_Container: {
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
  }),
);

function SkeletonAppBarColorScheme() {
  const app = useApp();
  const classes = useSkeletonAppBarStyles();

  const onClick = React.useCallback(() => {
    app.actions.setThemeIsDark();
  }, [app]);

  return (
    <Tooltip title={app.theme.is_dark ? "Switch to light theme." : "Switch to dark theme."} arrow>
      <IconButton onClick={onClick} color="inherit">
        {app.theme.is_dark ? <Brightness4Icon /> : <Brightness5Icon />}
      </IconButton>
    </Tooltip>
  );
}

function SkeletonAppBarTogglerRight() {
  const app = useApp();
  const [draft, setDraft] = useState(false);

  const onClick = React.useCallback(() => {
    window.dispatchEvent(new CustomEvent("app-bar.toggle-right"));
  }, []);

  useEventListener("notes.draft", (evt: CustomEvent<boolean>) => {
    setDraft(evt.detail);
  });

  if (app.togglerMode === undefined) return null;

  return (
    <Tooltip
      title={
        draft
          ? "Er staan nog 1 of meerdere notities open, klik op deze knop om verder te gaan met bewerken. Navigeert u naar een andere pagina, dan verdwijnen de aanpassingen."
          : "Open de notities."
      }
      arrow
    >
      <IconButton onClick={onClick} color={draft ? "secondary" : "inherit"}>
        {draft ? <Note /> : <NoteOutlined />}
      </IconButton>
    </Tooltip>
  );
}
