import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import _ from "underscore";
import { CrmNoteClosedStateEnum, CrmNoteModeEnum, CrmNoteSaveRequest } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsForm from "../../../core/useRxjsForm";
import useTranslations from "../../../core/useTranslations";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import DateFieldExt from "../../../form/DateFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";
import UploadFieldExt from "../../../form/UploadFieldExt";

type Props = {
  id?: number;
  mode?: CrmNoteModeEnum;
  connected_crm_person_id?: number;
  connected_care_dossier_id?: number;
  connected_matrix_row_id?: number;
  involved_company_id_set?: number[];
  involved_person_id_set?: number[];

  hidden?: boolean;

  onClose: (success?: boolean) => Promise<void>;
};

type Values = Omit<
  CrmNoteSaveRequest,
  "id" | "connected_crm_person_id" | "connected_care_dossier_id" | "connected_matrix_row_id"
>;

const CareDossierViewExtNotesWriter: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const app = useAppStore();
  const gc = useGenericStyles();
  const { onClose } = props;
  const [shouldClose, setShouldClose] = useState(true);

  const { record } = useRxjsFetch(async () => {
    if (props.id) return (await getApi().crmNoteOnePost({ id: props.id })).data;
  }, [props.id]);

  const form = useRxjsForm<Values>({
    initial: {
      mode: props.mode || CrmNoteModeEnum.DOSSIER,
      started_at: undefined,
      ending_at: undefined,
      closed_at: undefined,
      closed_state: undefined,
      crm_company_id_set: [],
      crm_person_id_set: [],
      subject: "",
      document: "",
      resource_id_set: [],
      tag_set: [],
    },
    submit: async (values) => {
      const res = await callApi(
        app,
        async (api) =>
          await api.crmNoteSavePost({
            id: props.id,
            connected_crm_person_id: props.connected_crm_person_id,
            connected_care_dossier_id: props.connected_care_dossier_id,
            connected_matrix_row_id: props.connected_matrix_row_id,
            ...values,
            started_at: values.started_at || undefined,
            ending_at: values.ending_at || undefined,
            closed_at: values.closed_at || undefined,
          }),
      );

      if (res && shouldClose) {
        await props.onClose(true);
      }
    },
  });

  const matrix_row_src = useRxjsFetch(async () => {
    return (
      _.contains([CrmNoteModeEnum.MATRIXROW, CrmNoteModeEnum.TARGET], form.values.mode) &&
      (
        await getApi().crmNoteAutocompleteMatrixRowPost({
          care_dossier_id: props.connected_care_dossier_id,
        })
      ).data
    );
  }, [form.values.mode]);

  useEffect(() => {
    if (record) {
      const pick: any = _.pick(record, Object.keys(form.initial) as any);
      pick.connected_matrix_row_id = record.connected_matrix_row_id;
      pick.resource_id_set = record.resource_set.map((x) => x.id);
      form.actions.setValues(pick);
    } else {
      form.actions.setValues(form.initial);
    }
  }, [record?.id]);

  return (
    <form
      onSubmit={form.actions.submit}
      noValidate
      style={{ display: props.hidden ? "none" : "flex", flex: "1 1 auto", flexDirection: "column", overflow: "auto" }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <AutocompleteFieldExt
              form={form}
              label="Type"
              name="mode"
              items={[
                { label: "Notitie", value: CrmNoteModeEnum.DOSSIER },
                { label: "Afspraak", value: CrmNoteModeEnum.MATRIXROW },
                { label: "Doel", value: CrmNoteModeEnum.TARGET },
              ]}
            />
          </Grid>
          {_.contains([CrmNoteModeEnum.MATRIXROW, CrmNoteModeEnum.TARGET], form.values.mode) && (
            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                label="Risicofactor"
                name="connected_matrix_row_id"
                items={matrix_row_src.record?.row_set?.map((row) => ({
                  value: row.id,
                  label: row.matrix_row_name,
                }))}
              />
            </Grid>
          )}
          <Grid item md={6}>
            <DateFieldExt form={form} label="Overlegdatum" name="started_at" />
          </Grid>
          {_.contains([CrmNoteModeEnum.MATRIXROW, CrmNoteModeEnum.TARGET], form.values.mode) && (
            <>
              <Grid item md={6}>
                <DateFieldExt form={form} label="Beoogde einddatum" name="ending_at" />
              </Grid>
              <Grid item md={6}>
                <DateFieldExt form={form} label="Werkelijke einddatum" name="closed_at" />
              </Grid>
              {form.values.closed_at && (
                <Grid item md={6}>
                  <AutocompleteFieldExt
                    form={form}
                    label="Resultaat"
                    name="closed_state"
                    items={[
                      { value: CrmNoteClosedStateEnum.ISACHIEVED, label: "Behaald" },
                      { value: CrmNoteClosedStateEnum.NOTACHIEVED, label: "Niet behaald" },
                      { value: CrmNoteClosedStateEnum.NOLONGERAPPLICABLE, label: "Niet langer van toepassing" },
                    ]}
                  />
                </Grid>
              )}
            </>
          )}

          {_.contains([CrmNoteModeEnum.MATRIXROW, CrmNoteModeEnum.TARGET], form.values.mode) &&
            props.involved_company_id_set?.length > 0 && (
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="crm_company_id_set"
                  label={_t("Ketenpartner(s)")}
                  multiple
                  items={props.involved_company_id_set
                    ?.map((id) => app.data.crm_company_set.find((x) => x.id === id))
                    .map((company) => ({
                      label: company.name,
                      value: company.id,
                    }))}
                />
              </Grid>
            )}

          {_.contains([CrmNoteModeEnum.MATRIXROW, CrmNoteModeEnum.TARGET], form.values.mode) &&
            form.values.crm_company_id_set.length > 0 && (
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="crm_person_id_set"
                  label="Actiehouder(s)"
                  multiple
                  items={props.involved_person_id_set
                    ?.map((id) => app.data.crm_person_set.find((x) => x.id === id))
                    .map((person) => ({
                      label: person.name,
                      value: person.id,
                    }))}
                />
              </Grid>
            )}

          <Grid item md={12}>
            <TextFieldExt form={form} label="Onderwerp" name="subject" />

            <FormControl error={false} margin="normal" fullWidth>
              <FormLabel style={{ marginBottom: 4 }}>Inhoud</FormLabel>

              <FormGroup>
                <ReactQuill
                  theme="snow"
                  value={form.values.document}
                  onChange={(v) => form.actions.setValue("document", v)}
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item md={12}>
            <UploadFieldExt label="Bestand(en)" form={form} name="resource_id_set" multiple />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={() => onClose()} disabled={form.submitting}>
          Annuleren
        </Button>
        <Button
          type="submit"
          onClick={() => setShouldClose(false)}
          variant="contained"
          color="primary"
          disabled={form.submitting}
        >
          Opslaan
        </Button>
        <Button
          type="submit"
          onClick={() => setShouldClose(true)}
          variant="contained"
          color="primary"
          disabled={form.submitting}
        >
          Opslaan en sluiten
        </Button>
      </DialogActions>

      <DialogBusy busy={form.submitting} />
    </form>
  );
};

export default CareDossierViewExtNotesWriter;
