import { Typography } from "@material-ui/core";
import React from "react";
import useGenericStyles from "./useGenericStyles";

type Props = {
  content?: string;
};

const QuillContent: React.FC<Props> = ({ content }) => {
  const gc = useGenericStyles();
  return <Typography className={gc.Quill_ContentHolder} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default QuillContent;
