import dot from "dot-object";
import { FormEvent, useEffect } from "react";
import _ from "underscore";
import useApp from "./useAppStore";
import useDebounce from "./useDebounce";
import useIsMounted from "./useIsMounted";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "./useRxjsStore";

type RxjsFormState<Values extends { [name: string]: any }> = {
  submitting: boolean;
  values: Values;
  errors: { [name: string]: string };
  hasErrors: boolean;
  dirty: { [name: string]: boolean };
};

type RxjsFormActions = {
  submit: (e?: FormEvent<any>) => Promise<void>;
  validate: (dirty?: boolean) => boolean;
  setSubmitting: (value: boolean) => void;
  setValue: (name: string, value: any, dirty?: boolean) => void;
  setValues: (values: { [name: string]: any }, dirty?: boolean) => void;
  setDirty: (...names: string[]) => void;
  setErrors: (errors: { [name: string]: any }, dirty?: boolean) => boolean;
  hasErrors: () => void;
  canSubmit: () => void;
};

type RxjsForm<Values extends { [name: string]: any }> = RxjsFormState<Values> & {
  actions: RxjsFormActions;
  initial: Values;
};

type RxjsFormCreateActionsParams<Values extends { [name: string]: any }> = {
  next: RxjsNextCallback<any>;
  getState: RxjsGetStateCallback<any>;
  state_key?: string;
  submit?: (Values) => Promise<void>;
  validate?: (Values) => { [name: string]: string };
  default_errors?: {
    [name: string]: [string, string] | ((headers: any | undefined) => [string, string]);
  };
};

type RxjsFormParams<Values> = {
  initial: Values;
  submit?: (values: Values) => Promise<void>;
  validate?: (values: Values) => { [name: string]: string };
  default_errors?: {
    [name: string]: [string, string] | ((headers: any | undefined) => [string, string]);
  };
};

function useRxjsForm<Values extends { [name: string]: any }>(params: RxjsFormParams<Values>): RxjsForm<Values> {
  const { initial, submit, validate, default_errors } = params;

  const { state, next, getState } = useRxjsStore<RxjsFormState<Values>>(createFormState(initial));

  const actions = {
    ...createFormActions({ next, getState, validate, submit, default_errors }),
  };

  const [debounced_form_values] = useDebounce(state.values, 500);

  useEffect(() => {
    actions.validate();
  }, [debounced_form_values]);

  return { ...state, actions, initial };
}

function createFormState<Values extends { [name: string]: any }>(values: Values): RxjsFormState<Values> {
  return {
    submitting: false,
    values,
    errors: {},
    hasErrors: false,
    dirty: {},
  };
}

function createFormActions<Values extends { [name: string]: any } = any>(params: RxjsFormCreateActionsParams<Values>) {
  const app = useApp();
  const { next, getState, state_key, submit, validate, default_errors } = params;
  const isMounted = useIsMounted();

  const actions: RxjsFormActions = {
    submit: async (e?: FormEvent<HTMLFormElement>) => {
      if (!submit) {
        return;
      }

      e && e.preventDefault();
      e && e.stopPropagation();

      if (getState().submitting || !actions.validate(true)) {
        return;
      }

      actions.setSubmitting(true);
      try {
        await submit(getState().values);
      } catch (err) {
        const [status, detail, headers] = app.actions.getApiError(err);

        if (status === 400 && default_errors && default_errors[detail]) {
          const handler = default_errors[detail];
          const [field, error] = _.isFunction(handler) ? handler(headers) : handler;

          actions.setErrors({ [field]: error }, true);
        }
      }

      if (isMounted()) actions.setSubmitting(false);
    },
    validate: (dirty?: boolean) => {
      if (!validate) {
        return true;
      }

      const s = state_key ? dot.pick(state_key, getState()) : getState();
      const errors = validate(s.values);

      return actions.setErrors(errors, dirty);
    },
    setSubmitting: (value) => {
      next((d) => {
        const s = state_key ? dot.pick(state_key, d) : d;

        s.submitting = value;
      });
    },
    setValue: (name, value, dirty?: boolean) => {
      next((d) => {
        const s = state_key ? dot.pick(state_key, d) : d;

        dot.set(`values.${name}`, value, s);

        if (dirty !== undefined) {
          s.dirty[name] = dirty;
        }
      });
    },
    setValues: (values: { [name: string]: any }, dirty?: boolean) => {
      next((d) => {
        const s = state_key ? dot.pick(state_key, d) : d;

        Object.entries(values).forEach(([name, value]) => {
          dot.set(`values.${name}`, value, s);

          if (dirty !== undefined) {
            s.dirty[name] = dirty;
          }
        });
      });
    },
    setDirty: (...names: string[]) => {
      next((d) => {
        const s = state_key ? dot.pick(state_key, d) : d;

        for (const name of names) {
          s.dirty[name] = true;
        }
      });
    },
    setErrors: (errors: { [name: string]: any }, dirty?: boolean) => {
      next((d) => {
        const s = state_key ? dot.pick(state_key, d) : d;
        s.errors = {};
        if (dirty === true) {
          s.dirty = {};
        }

        for (const [name, error] of Object.entries(errors)) {
          s.errors[name] = error;

          if (dirty !== undefined) {
            s.dirty[name] = dirty;
          }
        }

        s.hasErrors = !_.isEmpty(errors);
      });

      return _.isEmpty(errors);
    },
    hasErrors: () => {
      const s = state_key ? dot.pick(state_key, getState()) : getState();
      return _.isEmpty(s.errors);
    },
    canSubmit: () => {
      const s = state_key ? dot.pick(state_key, getState()) : getState();

      return _.isEmpty(s.errors) && !s.submitting;
    },
  };

  return actions;
}

function extractRxjsField<T = any>(form: RxjsForm<T>, name: string): { value: any; error?: string; dirty?: boolean } {
  const value = dot.pick(name, form.values);
  const error = form.errors[name];
  const dirty = form.dirty[name];

  return { value, error, dirty };
}

function equalityRxjsFieldCompare(
  prev: { form: RxjsForm<any>; name: string },
  next: { form: RxjsForm<any>; name: string },
) {
  const pObj = extractRxjsField(prev.form, prev.name);
  const nObj = extractRxjsField(next.form, next.name);

  return pObj.value === nObj.value && pObj.error === nObj.error && pObj.dirty === nObj.dirty;
}

export default useRxjsForm;
export type {
  RxjsForm,
  RxjsFormActions,
  RxjsNextCallback,
  RxjsGetStateCallback,
  RxjsFormState,
  RxjsFormCreateActionsParams,
};
export { createFormState, createFormActions, extractRxjsField, equalityRxjsFieldCompare };
