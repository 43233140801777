import { Button } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import React from "react";
import { GenericResourcePublicDto } from "../api";
import { getServerUrl } from "./useApi";
import useAppStore from "./useAppStore";

const GenericResourceLink: React.FC<{ resource: GenericResourcePublicDto }> = ({ resource }) => {
  const app = useAppStore();
  const company_slug = app.session.company.slug;
  const { id, private_token, original_filename } = resource;

  const base = `/app/company:${company_slug}/resource/stream/${id}/${private_token}/${original_filename}`;
  const query = new URLSearchParams({ session_token: app.session.token });

  return (
    <Button
      startIcon={<CloudDownload />}
      variant="text"
      href={[getServerUrl(), base, "?", query.toString()].join("")}
      target="_blank"
    >
      <small>{resource.id}.</small> {resource.original_filename}
    </Button>
  );
};

export default GenericResourceLink;
