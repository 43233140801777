import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid, List, ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatISO } from "date-fns";
import React, { useMemo } from "react";
import _ from "underscore";
import {
  CareDossierSaveRequest,
  CrmPersonDto,
  TableRowsResponse,
  TagCategoryAppliesToEnum,
  TagCategoryDto,
} from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import FormatDate from "../../../core/FormatDate";
import TagCoreChip from "../../../core/TagCoreChip";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useNavigate from "../../../core/useNavigate";
import useRxjsForm from "../../../core/useRxjsForm";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import LookupCrmPersonField from "../../../form/lookups/LookupCrmPersonField";

// region Types

type Props = {
  person_id?: number;
  onClose: (success: boolean) => void | Promise<void>;
};

type Values = CareDossierSaveRequest;

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

// endregion
// region Component

const CareDossierDrawerCreate: React.FC<Props> = (props) => {
  const classes = useStyles();
  const gc = useGenericStyles();
  const { app, busy, crm_person, care_dossier_set, form, onClose } = useComponent(props);
  const navigate = useNavigate();

  const tag_category_id_set = useMemo(
    () =>
      app.data.tag_category_set
        .filter((x) => x.applies_to_set.indexOf(TagCategoryAppliesToEnum.CAREDOSSIER) > -1)
        .map((x) => x.id),
    [],
  );
  const tag_category_map: { [name: number]: TagCategoryDto } = useMemo(
    () =>
      _.object(
        app.data.tag_category_set.filter((x) => _.contains(tag_category_id_set, x.id)).map((x) => [x.id, x]),
      ) as any,
    [],
  );
  const tag_set = useMemo(
    () => app.data.tag_core_set.filter((x) => _.contains(tag_category_id_set, x.category_id)),
    [],
  );

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Dossier toevoegen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Persoon</Typography>
              <Typography variant="body2" color="textSecondary">
                Voordat je verder kunt moet je de persoon selecteren waar je dit dossier bij wilt aanmaken. Bestaat de
                persoon niet? Maak deze dan eerst aan voordat je verder gaat.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <LookupCrmPersonField form={form} name="person_id" label="Persoon" />
            </Grid>

            {crm_person != null && care_dossier_set != null && (
              <Grid item md={12}>
                {care_dossier_set.row_set.length === 0 && (
                  <Typography variant="body2" color="textSecondary">
                    Er zijn nog geen dossiers bij {crm_person.name}
                  </Typography>
                )}
                {care_dossier_set.row_set.length > 0 && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Er zijn actieve dossiers gevonden bij {crm_person.name}. Bedoelt u een van deze dossiers? U kunt
                      hier direct het dossier openen. Continueer met het formulier om een extra dossier te openen bij{" "}
                      {crm_person.name}.
                    </Typography>
                    <List>
                      {care_dossier_set.row_set.map((row) => (
                        <ListItem button onClick={() => navigate(`/care/dossier/view/${row.record_id}`)}>
                          <ListItemText
                            primary={`Dossier #${row.record_id}`}
                            secondary={
                              <>
                                Aangemaakt op <FormatDate value={row.values[2]} />
                                {row.values[3] && " - is gearchiveerd"}
                              </>
                            }
                          />
                          {row.values[1].map((tag_id) => (
                            <TagCoreChip tag_id={tag_id} />
                          ))}
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </Grid>
            )}

            <Grid item md={12}>
              <Typography variant="subtitle1">Opzet</Typography>
              <Typography variant="body2" color="textSecondary">
                Geef met de tags aan om wat voor type dossier het gaat. De tags zorgen voor extra functionaliteiten bij
                het invoeren van gegevens, schermen, en/of scorematrixen.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="tag_id_set"
                label="Tags"
                items={tag_set.map((x) => ({
                  value: x.id,
                  label: x.name,
                  extra: { tag: x, category: tag_category_map[x.category_id] },
                }))}
                autocompleteProps={{
                  groupBy: (x) => x.extra.category.name,
                }}
                multiple
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose(false)}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>
      </form>

      <DialogBusy busy={busy} />
    </Drawer>
  );
};

// endregion
// region Store

function useComponent(props: Props) {
  const app = useAppStore();
  const [closing, setClosing] = React.useState(false);
  const [crm_person, setCrmPerson] = React.useState<CrmPersonDto | null>(null);
  const [care_dossier_set, setCareDossierSet] = React.useState<TableRowsResponse | null>(null);

  const form = useRxjsForm<Values>({
    initial: {
      id: undefined,
      person_id: props.person_id || -1,
      created_at: formatISO(Date.now()),
      tag_id_set: [],
    },
    submit: async (values) => {
      let data = _.clone(values);

      await getApi().careDossierSavePost(data);
      await onClose(true);
    },
  });

  const onClose = React.useCallback(async (success: boolean) => {
    setClosing(true);
    await props.onClose(success);
    setClosing(false);
  }, []);

  const busy = React.useMemo(() => closing || form.submitting, [closing, form.submitting]);

  useAsyncEffect(async () => {
    if (!form.values.person_id) return;

    const crm_person_res = await callApi(
      app,
      async (api) => (await api.crmPersonOnePost({ id: form.values.person_id })).data,
    );
    const care_dossier_set_res = await callApi(
      app,
      async (api) =>
        (
          await api.careDossierTableRowsPost({
            header_set: ["id", "tag_set", "created_at", "is_archived"],
            is_archived: -1,
            offset: 0,
            limit: 10,
            search: "",
            tag_id_set: [],
            person_id_set: [form.values.person_id],
          })
        ).data,
    );

    setCrmPerson(crm_person_res);
    setCareDossierSet(care_dossier_set_res);
  }, [form.values.person_id]);

  return { app, busy, crm_person, care_dossier_set, form, onClose };
}

// endregion

export default CareDossierDrawerCreate;
