/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface ApiWebDataResponse
 */
export interface ApiWebDataResponse {
  /**
   *
   * @type {string}
   * @memberof ApiWebDataResponse
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebDataResponse
   */
  updated_at: string;
  /**
   *
   * @type {Array<TsCompanyDto>}
   * @memberof ApiWebDataResponse
   */
  ts_company_set: Array<TsCompanyDto>;
  /**
   *
   * @type {Array<TagCoreDto>}
   * @memberof ApiWebDataResponse
   */
  tag_core_set: Array<TagCoreDto>;
  /**
   *
   * @type {Array<TagCategoryDto>}
   * @memberof ApiWebDataResponse
   */
  tag_category_set: Array<TagCategoryDto>;
  /**
   *
   * @type {Array<CrmRoleDto>}
   * @memberof ApiWebDataResponse
   */
  crm_role_set: Array<CrmRoleDto>;
  /**
   *
   * @type {Array<CrmPersonDtoPartial>}
   * @memberof ApiWebDataResponse
   */
  crm_person_set: Array<CrmPersonDtoPartial>;
  /**
   *
   * @type {Array<CrmCompanyDto>}
   * @memberof ApiWebDataResponse
   */
  crm_company_set: Array<CrmCompanyDto>;
  /**
   *
   * @type {Array<CrmDataScreenDto>}
   * @memberof ApiWebDataResponse
   */
  crm_data_screen_set: Array<CrmDataScreenDto>;
  /**
   *
   * @type {Array<MatrixCoreDtoExt>}
   * @memberof ApiWebDataResponse
   */
  matrix_core_set: Array<MatrixCoreDtoExt>;
}
/**
 *
 * @export
 * @interface ApiWebResolveZipcodeRequest
 */
export interface ApiWebResolveZipcodeRequest {
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeRequest
   */
  zipcode: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeRequest
   */
  housenumber: string;
}
/**
 *
 * @export
 * @interface ApiWebResolveZipcodeResponse
 */
export interface ApiWebResolveZipcodeResponse {
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  zipcode: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  housenumber: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  municipality: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  province: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  latitude?: string;
  /**
   *
   * @type {string}
   * @memberof ApiWebResolveZipcodeResponse
   */
  longitude?: string;
}
/**
 *
 * @export
 * @interface ApiWebVersionRequest
 */
export interface ApiWebVersionRequest {
  /**
   *
   * @type {string}
   * @memberof ApiWebVersionRequest
   */
  version: string;
}
/**
 *
 * @export
 * @interface ApiWebVersionResponse
 */
export interface ApiWebVersionResponse {
  /**
   *
   * @type {string}
   * @memberof ApiWebVersionResponse
   */
  version: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiWebVersionResponse
   */
  should_update: boolean;
}
/**
 *
 * @export
 * @interface BodyGenericResourceSaveRouteGenericResourceSavePost
 */
export interface BodyGenericResourceSaveRouteGenericResourceSavePost {
  /**
   *
   * @type {any}
   * @memberof BodyGenericResourceSaveRouteGenericResourceSavePost
   */
  upload: any;
}
/**
 *
 * @export
 * @interface CareDossierArchiveRequest
 */
export interface CareDossierArchiveRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierArchiveRequest
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierArchiveRequest
   */
  is_archived?: boolean;
}
/**
 *
 * @export
 * @interface CareDossierDataDto
 */
export interface CareDossierDataDto {
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  screen_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  prev_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  next_id?: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierDataDto
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataDto
   */
  created_by_id: number;
  /**
   *
   * @type {object}
   * @memberof CareDossierDataDto
   */
  fields_json: object;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDataDto
   */
  is_history: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDataDto
   */
  is_current: boolean;
}
/**
 *
 * @export
 * @interface CareDossierDataHistoryRequest
 */
export interface CareDossierDataHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierDataHistoryRequest
   */
  screen_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataHistoryRequest
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataHistoryRequest
   */
  dossier_id: number;
}
/**
 *
 * @export
 * @interface CareDossierDataHistoryResponse
 */
export interface CareDossierDataHistoryResponse {
  /**
   *
   * @type {Array<CareDossierDataDto>}
   * @memberof CareDossierDataHistoryResponse
   */
  data_set: Array<CareDossierDataDto>;
}
/**
 *
 * @export
 * @interface CareDossierDataMarkRequest
 */
export interface CareDossierDataMarkRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierDataMarkRequest
   */
  record_id: number;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDataMarkRequest
   */
  is_current?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDataMarkRequest
   */
  is_history?: boolean;
}
/**
 *
 * @export
 * @interface CareDossierDataOneRequest
 */
export interface CareDossierDataOneRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneRequest
   */
  screen_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneRequest
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneRequest
   */
  dossier_id: number;
}
/**
 *
 * @export
 * @interface CareDossierDataOneResponse
 */
export interface CareDossierDataOneResponse {
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  screen_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  prev_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  next_id?: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierDataOneResponse
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataOneResponse
   */
  created_by_id: number;
  /**
   *
   * @type {object}
   * @memberof CareDossierDataOneResponse
   */
  fields_json: object;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDataOneResponse
   */
  is_history: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDataOneResponse
   */
  is_current: boolean;
}
/**
 *
 * @export
 * @interface CareDossierDataSaveRequest
 */
export interface CareDossierDataSaveRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierDataSaveRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataSaveRequest
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataSaveRequest
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierDataSaveRequest
   */
  screen_id: number;
  /**
   *
   * @type {object}
   * @memberof CareDossierDataSaveRequest
   */
  fields_json: object;
}
/**
 *
 * @export
 * @interface CareDossierDto
 */
export interface CareDossierDto {
  /**
   *
   * @type {number}
   * @memberof CareDossierDto
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierDto
   */
  is_archived: boolean;
  /**
   *
   * @type {string}
   * @memberof CareDossierDto
   */
  created_at?: string;
  /**
   *
   * @type {CrmPersonDto}
   * @memberof CareDossierDto
   */
  person: CrmPersonDto;
  /**
   *
   * @type {Array<CareDossierTagDto>}
   * @memberof CareDossierDto
   */
  tag_set: Array<CareDossierTagDto>;
}
/**
 *
 * @export
 * @interface CareDossierManyRequest
 */
export interface CareDossierManyRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierManyRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierManyRequest
   */
  limit?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierManyRequest
   */
  id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CareDossierManyResponse
 */
export interface CareDossierManyResponse {
  /**
   *
   * @type {GenericPagination}
   * @memberof CareDossierManyResponse
   */
  pagination: GenericPagination;
  /**
   *
   * @type {Array<CareDossierDto>}
   * @memberof CareDossierManyResponse
   */
  records: Array<CareDossierDto>;
}
/**
 *
 * @export
 * @interface CareDossierOneRequest
 */
export interface CareDossierOneRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareDossierOneResponse
 */
export interface CareDossierOneResponse {
  /**
   *
   * @type {number}
   * @memberof CareDossierOneResponse
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CareDossierOneResponse
   */
  is_archived: boolean;
  /**
   *
   * @type {string}
   * @memberof CareDossierOneResponse
   */
  created_at?: string;
  /**
   *
   * @type {CrmPersonDto}
   * @memberof CareDossierOneResponse
   */
  person: CrmPersonDto;
  /**
   *
   * @type {Array<CareDossierTagDto>}
   * @memberof CareDossierOneResponse
   */
  tag_set: Array<CareDossierTagDto>;
  /**
   *
   * @type {Array<CareDossierDataDto>}
   * @memberof CareDossierOneResponse
   */
  current_dossier_data_set: Array<CareDossierDataDto>;
  /**
   *
   * @type {Array<CareDossierDataDto>}
   * @memberof CareDossierOneResponse
   */
  history_dossier_data_set: Array<CareDossierDataDto>;
  /**
   *
   * @type {Array<CareDossierDataDto>}
   * @memberof CareDossierOneResponse
   */
  latest_dossier_data_set: Array<CareDossierDataDto>;
  /**
   *
   * @type {Array<CareMatrixDto>}
   * @memberof CareDossierOneResponse
   */
  care_matrix_set: Array<CareMatrixDto>;
}
/**
 *
 * @export
 * @interface CareDossierSaveRequest
 */
export interface CareDossierSaveRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierSaveRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierSaveRequest
   */
  person_id: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierSaveRequest
   */
  created_at: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierSaveRequest
   */
  tag_id_set: Array<number>;
}
/**
 * A simple detail-response, the detail is always one of the following options:  - `OK`: the request was processed. - `NOT_FOUND`: the requested request was not found.  - `ACCOUNT_NO_PASSWORD`: the account you have reached has no password setup. - `ACCOUNT_PASSWORD_INVALID`: the password you tried to verify does not match.  Something, things go bad, these are the details that tell you we could not handle it:  - `NOK`: dead machine-state reached, this should not occur. - `NI`: not-implemented, we haven\'t had the time to implement this feature / option yet.
 * @export
 * @interface CareDossierSaveResponse
 */
export interface CareDossierSaveResponse {
  /**
   *
   * @type {DetailEnum}
   * @memberof CareDossierSaveResponse
   */
  detail: DetailEnum;
  /**
   *
   * @type {number}
   * @memberof CareDossierSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareDossierTableRowsRequest
 */
export interface CareDossierTableRowsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof CareDossierTableRowsRequest
   */
  header_set: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CareDossierTableRowsRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTableRowsRequest
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierTableRowsRequest
   */
  search: string;
  /**
   *
   * @type {number}
   * @memberof CareDossierTableRowsRequest
   */
  is_archived: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierTableRowsRequest
   */
  tag_id_set: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierTableRowsRequest
   */
  person_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CareDossierTagDto
 */
export interface CareDossierTagDto {
  /**
   *
   * @type {number}
   * @memberof CareDossierTagDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTagDto
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTagDto
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTagDto
   */
  tag_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTagDto
   */
  category_id: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierTagDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CareDossierTagDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierTagDto
   */
  owner_id_set: Array<number>;
  /**
   *
   * @type {Array<CrmPersonDtoSmall>}
   * @memberof CareDossierTagDto
   */
  crm_person_owner_set?: Array<CrmPersonDtoSmall>;
}
/**
 *
 * @export
 * @interface CareDossierTagHistoryRequest
 */
export interface CareDossierTagHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierTagHistoryRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierTagHistoryRequest
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CareDossierTagHistoryRequest
   */
  date_end?: string;
}
/**
 *
 * @export
 * @interface CareDossierTagHistoryResponse
 */
export interface CareDossierTagHistoryResponse {
  /**
   *
   * @type {Array<CareDossierTagDto>}
   * @memberof CareDossierTagHistoryResponse
   */
  tag_set: Array<CareDossierTagDto>;
}
/**
 *
 * @export
 * @interface CareDossierTagOneRequest
 */
export interface CareDossierTagOneRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierTagOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareDossierTagSaveDto
 */
export interface CareDossierTagSaveDto {
  /**
   *
   * @type {number}
   * @memberof CareDossierTagSaveDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTagSaveDto
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTagSaveDto
   */
  tag_id: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierTagSaveDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CareDossierTagSaveDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierTagSaveDto
   */
  owner_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CareDossierTagSaveResponse
 */
export interface CareDossierTagSaveResponse {
  /**
   *
   * @type {number}
   * @memberof CareDossierTagSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareDossierTriggerAmountResponse
 */
export interface CareDossierTriggerAmountResponse {
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerAmountResponse
   */
  amount: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CareDossierTriggerColumnNameEnum {
  ENDOFDETENTION = "END_OF_DETENTION",
  PROBATIONSUPERVISIONPRESENT = "PROBATION_SUPERVISION_PRESENT",
  TBSMEASUREPRESENT = "TBS_MEASURE_PRESENT",
  ISDMEASUREPRESENT = "ISD_MEASURE_PRESENT",
  WLZEXPIRESAT = "WLZ_EXPIRES_AT",
  WMOINDICATIONEXPIRESAT = "WMO_INDICATION_EXPIRES_AT",
  BWINDICATIONEXPIRESAT = "BW_INDICATION_EXPIRES_AT",
  MATRIXFROMSTART = "MATRIX_FROM_START",
  MATRIXFROMCONTINUATION = "MATRIX_FROM_CONTINUATION",
}

/**
 *
 * @export
 * @interface CareDossierTriggerDto
 */
export interface CareDossierTriggerDto {
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CareDossierTriggerDto
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof CareDossierTriggerDto
   */
  closed_at?: string;
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerDto
   */
  crm_person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerDto
   */
  care_dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerDto
   */
  closed_by_id?: number;
  /**
   *
   * @type {CareDossierTriggerColumnNameEnum}
   * @memberof CareDossierTriggerDto
   */
  column_name: CareDossierTriggerColumnNameEnum;
  /**
   *
   * @type {string}
   * @memberof CareDossierTriggerDto
   */
  column_definition?: string;
  /**
   *
   * @type {string}
   * @memberof CareDossierTriggerDto
   */
  column_ends_at?: string;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof CareDossierTriggerDto
   */
  crm_person: CrmPersonDtoSmall;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof CareDossierTriggerDto
   */
  closed_by?: CrmPersonDtoSmall;
}
/**
 *
 * @export
 * @interface CareDossierTriggerOneRequest
 */
export interface CareDossierTriggerOneRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareDossierTriggerTableRowsRequest
 */
export interface CareDossierTriggerTableRowsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof CareDossierTriggerTableRowsRequest
   */
  header_set: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerTableRowsRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerTableRowsRequest
   */
  limit: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierTriggerTableRowsRequest
   */
  crm_person_id_set: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CareDossierTriggerTableRowsRequest
   */
  process_person_id_set: Array<number>;
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerTableRowsRequest
   */
  is_closed: number;
}
/**
 *
 * @export
 * @interface CareDossierTriggerToggleRequest
 */
export interface CareDossierTriggerToggleRequest {
  /**
   *
   * @type {number}
   * @memberof CareDossierTriggerToggleRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareMatrixDto
 */
export interface CareMatrixDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  matrix_core_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  mark_zero_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  mark_target_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  mark_latest_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  score_zero?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  score_target?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixDto
   */
  score_latest?: number;
  /**
   *
   * @type {CareMatrixMarkDto}
   * @memberof CareMatrixDto
   */
  mark_zero?: CareMatrixMarkDto;
  /**
   *
   * @type {CareMatrixMarkDto}
   * @memberof CareMatrixDto
   */
  mark_target?: CareMatrixMarkDto;
  /**
   *
   * @type {CareMatrixMarkDto}
   * @memberof CareMatrixDto
   */
  mark_latest?: CareMatrixMarkDto;
  /**
   *
   * @type {Array<CareMatrixMarkDto>}
   * @memberof CareMatrixDto
   */
  mark_set?: Array<CareMatrixMarkDto>;
  /**
   *
   * @type {string}
   * @memberof CareMatrixDto
   */
  created_at: string;
  /**
   *
   * @type {Array<CareMatrixRowDto>}
   * @memberof CareMatrixDto
   */
  row_set: Array<CareMatrixRowDto>;
}
/**
 *
 * @export
 * @interface CareMatrixMarkDto
 */
export interface CareMatrixMarkDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixMarkDto
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkDto
   */
  created_by_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkDto
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkDto
   */
  next_id?: number;
  /**
   *
   * @type {CareMatrixMarkMomentEnum}
   * @memberof CareMatrixMarkDto
   */
  moment: CareMatrixMarkMomentEnum;
  /**
   *
   * @type {CareMatrixMarkValuesDto}
   * @memberof CareMatrixMarkDto
   */
  values_json: CareMatrixMarkValuesDto;
  /**
   *
   * @type {string}
   * @memberof CareMatrixMarkDto
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof CareMatrixMarkDto
   */
  is_archived: boolean;
}
/**
 *
 * @export
 * @interface CareMatrixMarkLatestRequest
 */
export interface CareMatrixMarkLatestRequest {
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkLatestRequest
   */
  matrix_core_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkLatestRequest
   */
  crm_person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkLatestRequest
   */
  care_dossier_id: number;
  /**
   *
   * @type {CareMatrixMarkMomentEnum}
   * @memberof CareMatrixMarkLatestRequest
   */
  moment: CareMatrixMarkMomentEnum;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CareMatrixMarkMomentEnum {
  ZERO = "ZERO",
  TARGET = "TARGET",
  CONTINUATION = "CONTINUATION",
}

/**
 *
 * @export
 * @interface CareMatrixMarkValuesDto
 */
export interface CareMatrixMarkValuesDto {
  /**
   *
   * @type {Array<CareMatrixMarkValuesScoreDto>}
   * @memberof CareMatrixMarkValuesDto
   */
  values: Array<CareMatrixMarkValuesScoreDto>;
}
/**
 *
 * @export
 * @interface CareMatrixMarkValuesScoreDto
 */
export interface CareMatrixMarkValuesScoreDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkValuesScoreDto
   */
  row_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkValuesScoreDto
   */
  column_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixMarkValuesScoreDto
   */
  score: number;
}
/**
 *
 * @export
 * @interface CareMatrixOneRequest
 */
export interface CareMatrixOneRequest {
  /**
   *
   * @type {number}
   * @memberof CareMatrixOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareMatrixRowCompanyDto
 */
export interface CareMatrixRowCompanyDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowCompanyDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowCompanyDto
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowCompanyDto
   */
  started_at?: string;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowCompanyDto
   */
  closed_at?: string;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowCompanyDto
   */
  care_matrix_row_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowCompanyDto
   */
  crm_company_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowCompanyDto
   */
  crm_company_location_id?: number;
  /**
   *
   * @type {CrmCompanyDtoSmall}
   * @memberof CareMatrixRowCompanyDto
   */
  crm_company?: CrmCompanyDtoSmall;
  /**
   *
   * @type {CrmCompanyLocationDtoSmall}
   * @memberof CareMatrixRowCompanyDto
   */
  crm_company_location?: CrmCompanyLocationDtoSmall;
}
/**
 *
 * @export
 * @interface CareMatrixRowDto
 */
export interface CareMatrixRowDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  care_matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  crm_company_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  crm_company_location_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  matrix_core_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  matrix_row_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  value_zero_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  value_target_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  value_current_id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  score_zero?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  score_target?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowDto
   */
  score_current?: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowDto
   */
  target: string;
  /**
   *
   * @type {Array<CareMatrixRowTagDto>}
   * @memberof CareMatrixRowDto
   */
  tag_set: Array<CareMatrixRowTagDto>;
  /**
   *
   * @type {CrmCompanyDtoSmall}
   * @memberof CareMatrixRowDto
   */
  crm_company?: CrmCompanyDtoSmall;
  /**
   *
   * @type {CrmCompanyLocationDtoSmall}
   * @memberof CareMatrixRowDto
   */
  crm_company_location?: CrmCompanyLocationDtoSmall;
  /**
   *
   * @type {Array<CareMatrixRowCompanyDto>}
   * @memberof CareMatrixRowDto
   */
  company_set: Array<CareMatrixRowCompanyDto>;
}
/**
 *
 * @export
 * @interface CareMatrixRowOneRequest
 */
export interface CareMatrixRowOneRequest {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CareMatrixRowSaveResponsibleRequest
 */
export interface CareMatrixRowSaveResponsibleRequest {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowSaveResponsibleRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowSaveResponsibleRequest
   */
  care_matrix_row_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowSaveResponsibleRequest
   */
  crm_company_location_id: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowSaveResponsibleRequest
   */
  started_at?: string;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowSaveResponsibleRequest
   */
  closed_at?: string;
}
/**
 *
 * @export
 * @interface CareMatrixRowSaveTargetRequest
 */
export interface CareMatrixRowSaveTargetRequest {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowSaveTargetRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowSaveTargetRequest
   */
  target: string;
}
/**
 *
 * @export
 * @interface CareMatrixRowTagDto
 */
export interface CareMatrixRowTagDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  care_matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  care_matrix_row_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  tag_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagDto
   */
  category_id: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowTagDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowTagDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CareMatrixRowTagDto
   */
  owner_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CareMatrixRowTagSaveDto
 */
export interface CareMatrixRowTagSaveDto {
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagSaveDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagSaveDto
   */
  care_matrix_row_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixRowTagSaveDto
   */
  tag_id: number;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowTagSaveDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CareMatrixRowTagSaveDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CareMatrixRowTagSaveDto
   */
  owner_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CareMatrixSaveMarkRequest
 */
export interface CareMatrixSaveMarkRequest {
  /**
   *
   * @type {number}
   * @memberof CareMatrixSaveMarkRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixSaveMarkRequest
   */
  matrix_core_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixSaveMarkRequest
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CareMatrixSaveMarkRequest
   */
  dossier_id: number;
  /**
   *
   * @type {CareMatrixMarkMomentEnum}
   * @memberof CareMatrixSaveMarkRequest
   */
  moment: CareMatrixMarkMomentEnum;
  /**
   *
   * @type {CareMatrixMarkValuesDto}
   * @memberof CareMatrixSaveMarkRequest
   */
  values_json: CareMatrixMarkValuesDto;
  /**
   *
   * @type {string}
   * @memberof CareMatrixSaveMarkRequest
   */
  description: string;
}
/**
 *
 * @export
 * @interface CdDataByDayRequest
 */
export interface CdDataByDayRequest {
  /**
   *
   * @type {number}
   * @memberof CdDataByDayRequest
   */
  location_id: number;
  /**
   *
   * @type {string}
   * @memberof CdDataByDayRequest
   */
  day: string;
}
/**
 *
 * @export
 * @interface CdDataChartCareAccessRequest
 */
export interface CdDataChartCareAccessRequest {
  /**
   *
   * @type {string}
   * @memberof CdDataChartCareAccessRequest
   */
  day_after: string;
  /**
   *
   * @type {string}
   * @memberof CdDataChartCareAccessRequest
   */
  day_before: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataChartCareAccessRequest
   */
  crm_company_location_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CdDataChartCareAccessResponse
 */
export interface CdDataChartCareAccessResponse {
  /**
   *
   * @type {Array<RecordDto>}
   * @memberof CdDataChartCareAccessResponse
   */
  row_set: Array<RecordDto>;
}
/**
 *
 * @export
 * @interface CdDataChartCurrentCapacityRequest
 */
export interface CdDataChartCurrentCapacityRequest {
  /**
   *
   * @type {string}
   * @memberof CdDataChartCurrentCapacityRequest
   */
  day_after: string;
  /**
   *
   * @type {string}
   * @memberof CdDataChartCurrentCapacityRequest
   */
  day_before: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataChartCurrentCapacityRequest
   */
  crm_company_location_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CdDataChartCurrentCapacityResponse
 */
export interface CdDataChartCurrentCapacityResponse {
  /**
   *
   * @type {Array<CdDataChartCurrentCapacityRow>}
   * @memberof CdDataChartCurrentCapacityResponse
   */
  row_set: Array<CdDataChartCurrentCapacityRow>;
}
/**
 *
 * @export
 * @interface CdDataChartCurrentCapacityRow
 */
export interface CdDataChartCurrentCapacityRow {
  /**
   *
   * @type {string}
   * @memberof CdDataChartCurrentCapacityRow
   */
  day: string;
  /**
   *
   * @type {number}
   * @memberof CdDataChartCurrentCapacityRow
   */
  crm_company_location_id: number;
  /**
   *
   * @type {number}
   * @memberof CdDataChartCurrentCapacityRow
   */
  capacity?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataChartCurrentCapacityRow
   */
  waiting?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataChartCurrentCapacityRow
   */
  occupied?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataChartCurrentCapacityRow
   */
  available?: number;
}
/**
 *
 * @export
 * @interface CdDataChartLocationsResponse
 */
export interface CdDataChartLocationsResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataChartLocationsResponse
   */
  location_id_set: Array<number>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdDataChartPeriodEnum {
  Now = "Now",
  YesterdayTillNow = "YesterdayTillNow",
  Yesterday = "Yesterday",
  Previous7Days = "Previous7Days",
  Previous4Weeks = "Previous4Weeks",
  PreviousMonth = "PreviousMonth",
  PreviousQuarter = "PreviousQuarter",
  ThisQuarter = "ThisQuarter",
  Previous12Months = "Previous12Months",
  ThisYear = "ThisYear",
  Manual = "Manual",
}

/**
 *
 * @export
 * @interface CdDataChartRequest
 */
export interface CdDataChartRequest {
  /**
   *
   * @type {CdDataChartTypeEnum}
   * @memberof CdDataChartRequest
   */
  chart: CdDataChartTypeEnum;
  /**
   *
   * @type {CdDataChartPeriodEnum}
   * @memberof CdDataChartRequest
   */
  period_method: CdDataChartPeriodEnum;
  /**
   *
   * @type {string}
   * @memberof CdDataChartRequest
   */
  period_start?: string;
  /**
   *
   * @type {string}
   * @memberof CdDataChartRequest
   */
  period_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataChartRequest
   */
  crm_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  address_province_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_life_course_region_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_care_type_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_care_capacity_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_security_level_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_legal_regime_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_primary_specialism_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_characteristic_specialism_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  cd_contraindication_set?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof CdDataChartRequest
   */
  special_filter_set?: Array<string>;
}
/**
 *
 * @export
 * @interface CdDataChartResponse
 */
export interface CdDataChartResponse {
  /**
   *
   * @type {Array<ChartDataDto>}
   * @memberof CdDataChartResponse
   */
  data_set: Array<ChartDataDto>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CdDataChartTypeEnum {
  Available = "available",
  WaitingDuration = "waiting_duration",
  CurrentCapacity = "current_capacity",
  Capacity = "capacity",
  Occupied = "occupied",
  Intake = "intake",
  Outflow = "outflow",
  Reserved = "reserved",
  Waiting = "waiting",
  WaitingNew = "waiting_new",
  WaitingFailedContent = "waiting_failed_content",
  WaitingFailedFinancial = "waiting_failed_financial",
  WaitingFailedSystem = "waiting_failed_system",
  RegistryCheckup = "registry_checkup",
  SessionLoginCheckup = "session_login_checkup",
  CareAccess = "care_access",
}

/**
 *
 * @export
 * @interface CdDataCheckupLocationDto
 */
export interface CdDataCheckupLocationDto {
  /**
   *
   * @type {number}
   * @memberof CdDataCheckupLocationDto
   */
  crm_company_location_id: number;
  /**
   *
   * @type {Array<CdDataCheckupLocationItemDto>}
   * @memberof CdDataCheckupLocationDto
   */
  day_set: Array<CdDataCheckupLocationItemDto>;
  /**
   *
   * @type {number}
   * @memberof CdDataCheckupLocationDto
   */
  count: number;
}
/**
 *
 * @export
 * @interface CdDataCheckupLocationItemDto
 */
export interface CdDataCheckupLocationItemDto {
  /**
   *
   * @type {string}
   * @memberof CdDataCheckupLocationItemDto
   */
  key: string;
  /**
   *
   * @type {number}
   * @memberof CdDataCheckupLocationItemDto
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof CdDataCheckupLocationItemDto
   */
  week: number;
  /**
   *
   * @type {string}
   * @memberof CdDataCheckupLocationItemDto
   */
  day: string;
  /**
   *
   * @type {boolean}
   * @memberof CdDataCheckupLocationItemDto
   */
  auto_stamped: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdDataCheckupLocationItemDto
   */
  has_data: boolean;
}
/**
 *
 * @export
 * @interface CdDataCheckupRequest
 */
export interface CdDataCheckupRequest {
  /**
   *
   * @type {string}
   * @memberof CdDataCheckupRequest
   */
  day_after: string;
  /**
   *
   * @type {string}
   * @memberof CdDataCheckupRequest
   */
  day_before: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataCheckupRequest
   */
  crm_company_location_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CdDataCheckupResponse
 */
export interface CdDataCheckupResponse {
  /**
   *
   * @type {Array<CdDataCheckupLocationDto>}
   * @memberof CdDataCheckupResponse
   */
  location_set: Array<CdDataCheckupLocationDto>;
}
/**
 *
 * @export
 * @interface CdDataExistsRequest
 */
export interface CdDataExistsRequest {
  /**
   *
   * @type {string}
   * @memberof CdDataExistsRequest
   */
  day: string;
}
/**
 *
 * @export
 * @interface CdDataExistsResponse
 */
export interface CdDataExistsResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataExistsResponse
   */
  filled_location_id_set: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CdDataExistsResponse
   */
  within_location_id_set: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CdDataExistsResponse
   */
  day: string;
}
/**
 *
 * @export
 * @interface CdDataRegisterRequest
 */
export interface CdDataRegisterRequest {
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  has_data?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  auto_stamped?: boolean;
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterRequest
   */
  waiting_duration?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterRequest
   */
  waiting_new?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterRequest
   */
  waiting_to_intake?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  has_waiting_failed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  has_waiting_failed_content?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CdDataRegisterRequest
   */
  waiting_failed_content_setup?: { [key: string]: number };
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  has_waiting_failed_financial?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CdDataRegisterRequest
   */
  waiting_failed_financial_setup?: { [key: string]: number };
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  has_waiting_failed_system?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CdDataRegisterRequest
   */
  waiting_failed_system_setup?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterRequest
   */
  outflow?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterRequest
   */
  reserved?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegisterRequest
   */
  may_receive_opening?: boolean;
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterRequest
   */
  location_id: number;
  /**
   *
   * @type {string}
   * @memberof CdDataRegisterRequest
   */
  day: string;
}
/**
 *
 * @export
 * @interface CdDataRegisterResponse
 */
export interface CdDataRegisterResponse {
  /**
   *
   * @type {number}
   * @memberof CdDataRegisterResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CdDataRegistryDataDto
 */
export interface CdDataRegistryDataDto {
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  has_data?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  auto_stamped?: boolean;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDataDto
   */
  waiting_duration?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDataDto
   */
  waiting_new?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDataDto
   */
  waiting_to_intake?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  has_waiting_failed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  has_waiting_failed_content?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CdDataRegistryDataDto
   */
  waiting_failed_content_setup?: { [key: string]: number };
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  has_waiting_failed_financial?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CdDataRegistryDataDto
   */
  waiting_failed_financial_setup?: { [key: string]: number };
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  has_waiting_failed_system?: boolean;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CdDataRegistryDataDto
   */
  waiting_failed_system_setup?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDataDto
   */
  outflow?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDataDto
   */
  reserved?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDataDto
   */
  may_receive_opening?: boolean;
}
/**
 *
 * @export
 * @interface CdDataRegistryDto
 */
export interface CdDataRegistryDto {
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  person_id?: number;
  /**
   *
   * @type {string}
   * @memberof CdDataRegistryDto
   */
  created_on?: string;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  created_by_id?: number;
  /**
   *
   * @type {string}
   * @memberof CdDataRegistryDto
   */
  updated_on?: string;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  updated_by_id?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  location_id: number;
  /**
   *
   * @type {string}
   * @memberof CdDataRegistryDto
   */
  day: string;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDto
   */
  auto_stamped: boolean;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  waiting_duration?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  capacity?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  waiting?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  waiting_failed_content?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  waiting_failed_financial?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  waiting_failed_system?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  intake?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  outflow?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  reserved?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  occupied?: number;
  /**
   *
   * @type {number}
   * @memberof CdDataRegistryDto
   */
  available?: number;
  /**
   *
   * @type {boolean}
   * @memberof CdDataRegistryDto
   */
  may_receive_opening?: boolean;
  /**
   *
   * @type {CdDataRegistryDataDto}
   * @memberof CdDataRegistryDto
   */
  data: CdDataRegistryDataDto;
  /**
   *
   * @type {CrmPersonBaseDto}
   * @memberof CdDataRegistryDto
   */
  person?: CrmPersonBaseDto;
  /**
   *
   * @type {CrmPersonBaseDto}
   * @memberof CdDataRegistryDto
   */
  created_by?: CrmPersonBaseDto;
  /**
   *
   * @type {CrmPersonBaseDto}
   * @memberof CdDataRegistryDto
   */
  updated_by?: CrmPersonBaseDto;
}
/**
 *
 * @export
 * @interface ChartDataDto
 */
export interface ChartDataDto {
  /**
   *
   * @type {string}
   * @memberof ChartDataDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ChartDataDto
   */
  crm_company_id: number;
  /**
   *
   * @type {number}
   * @memberof ChartDataDto
   */
  crm_company_location_id: number;
  /**
   *
   * @type {Array<ChartDataRowDto>}
   * @memberof ChartDataDto
   */
  data: Array<ChartDataRowDto>;
}
/**
 *
 * @export
 * @interface ChartDataRowDto
 */
export interface ChartDataRowDto {
  /**
   *
   * @type {string}
   * @memberof ChartDataRowDto
   */
  x: string;
  /**
   *
   * @type {number}
   * @memberof ChartDataRowDto
   */
  y?: number;
  /**
   *
   * @type {string}
   * @memberof ChartDataRowDto
   */
  l?: string;
}
/**
 *
 * @export
 * @interface CrmCompanyArchiveRequest
 */
export interface CrmCompanyArchiveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyArchiveRequest
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmCompanyArchiveRequest
   */
  is_archived?: boolean;
}
/**
 *
 * @export
 * @interface CrmCompanyDto
 */
export interface CrmCompanyDto {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  name_slug: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  contact_name?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  contact_role?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  contact_email?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  contact_phone?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanyDto
   */
  employable_regions: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_province?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_zipcode?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_housenumber?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_affix?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_street?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_city?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  address_country?: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDto
   */
  opening_hours?: string;
  /**
   *
   * @type {Array<CrmCompanyLocationDto>}
   * @memberof CrmCompanyDto
   */
  location_set: Array<CrmCompanyLocationDto>;
  /**
   *
   * @type {boolean}
   * @memberof CrmCompanyDto
   */
  is_archived: boolean;
  /**
   *
   * @type {Array<CrmCompanyMatrixDto>}
   * @memberof CrmCompanyDto
   */
  crm_company_matrix_set: Array<CrmCompanyMatrixDto>;
}
/**
 *
 * @export
 * @interface CrmCompanyDtoSmall
 */
export interface CrmCompanyDtoSmall {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyDtoSmall
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyDtoSmall
   */
  name: string;
}
/**
 *
 * @export
 * @interface CrmCompanyLocationArchiveRequest
 */
export interface CrmCompanyLocationArchiveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationArchiveRequest
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmCompanyLocationArchiveRequest
   */
  is_archived?: boolean;
}
/**
 *
 * @export
 * @interface CrmCompanyLocationDto
 */
export interface CrmCompanyLocationDto {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDto
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDto
   */
  capacity_total: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDto
   */
  capacity_external: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDto
   */
  capacity_taken: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDto
   */
  capacity_available: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_life_course_region: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_care_type: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_care_capacity: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_security_level: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanyLocationDto
   */
  cd_legal_regime_set: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_primary_specialism: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_characteristic_specialism: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDto
   */
  cd_characteristic_specialism_explanation: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanyLocationDto
   */
  cd_contraindication_set: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof CrmCompanyLocationDto
   */
  is_archived: boolean;
}
/**
 *
 * @export
 * @interface CrmCompanyLocationDtoSmall
 */
export interface CrmCompanyLocationDtoSmall {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDtoSmall
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationDtoSmall
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationDtoSmall
   */
  name: string;
}
/**
 *
 * @export
 * @interface CrmCompanyLocationOneRequest
 */
export interface CrmCompanyLocationOneRequest {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmCompanyLocationSaveDto
 */
export interface CrmCompanyLocationSaveDto {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationSaveDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationSaveDto
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationSaveDto
   */
  capacity_total: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationSaveDto
   */
  capacity_external: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_life_course_region: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_care_type: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_care_capacity: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_security_level: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_legal_regime_set: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_primary_specialism: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_characteristic_specialism: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_characteristic_specialism_explanation: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanyLocationSaveDto
   */
  cd_contraindication_set: Array<string>;
}
/**
 *
 * @export
 * @interface CrmCompanyLocationSaveResponse
 */
export interface CrmCompanyLocationSaveResponse {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyLocationSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmCompanyManyRequest
 */
export interface CrmCompanyManyRequest {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyManyRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyManyRequest
   */
  limit?: number;
}
/**
 *
 * @export
 * @interface CrmCompanyManyResponse
 */
export interface CrmCompanyManyResponse {
  /**
   *
   * @type {GenericPagination}
   * @memberof CrmCompanyManyResponse
   */
  pagination: GenericPagination;
  /**
   *
   * @type {Array<CrmCompanyDto>}
   * @memberof CrmCompanyManyResponse
   */
  records: Array<CrmCompanyDto>;
}
/**
 *
 * @export
 * @interface CrmCompanyMatrixDto
 */
export interface CrmCompanyMatrixDto {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixDto
   */
  crm_company_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixDto
   */
  matrix_core_id: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmCompanyMatrixDto
   */
  is_scored: boolean;
  /**
   *
   * @type {Array<CrmCompanyMatrixValueDto>}
   * @memberof CrmCompanyMatrixDto
   */
  crm_company_matrix_value_set: Array<CrmCompanyMatrixValueDto>;
}
/**
 *
 * @export
 * @interface CrmCompanyMatrixValueDto
 */
export interface CrmCompanyMatrixValueDto {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  crm_company_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  crm_company_matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  matrix_core_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  matrix_row_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  matrix_column_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyMatrixValueDto
   */
  matrix_value_id: number;
}
/**
 *
 * @export
 * @interface CrmCompanyOneRequest
 */
export interface CrmCompanyOneRequest {
  /**
   *
   * @type {number}
   * @memberof CrmCompanyOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmCompanySaveDto
 */
export interface CrmCompanySaveDto {
  /**
   *
   * @type {number}
   * @memberof CrmCompanySaveDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  contact_name: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  contact_role: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  contact_email: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  contact_phone: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanySaveDto
   */
  employable_regions: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_province: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_zipcode: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_housenumber: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_affix: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_street: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_city: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  address_country: string;
  /**
   *
   * @type {string}
   * @memberof CrmCompanySaveDto
   */
  opening_hours: string;
}
/**
 *
 * @export
 * @interface CrmCompanySaveMatrixRequest
 */
export interface CrmCompanySaveMatrixRequest {
  /**
   *
   * @type {number}
   * @memberof CrmCompanySaveMatrixRequest
   */
  crm_company_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanySaveMatrixRequest
   */
  matrix_core_id: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmCompanySaveMatrixRequest
   */
  is_scored: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmCompanySaveMatrixRequest
   */
  value_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CrmCompanySaveResponse
 */
export interface CrmCompanySaveResponse {
  /**
   *
   * @type {number}
   * @memberof CrmCompanySaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmCompanyTableRowsRequest
 */
export interface CrmCompanyTableRowsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof CrmCompanyTableRowsRequest
   */
  header_set: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyTableRowsRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof CrmCompanyTableRowsRequest
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof CrmCompanyTableRowsRequest
   */
  search: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CrmDataScreenBoundEnum {
  CRMPERSON = "CRM_PERSON",
  CAREDOSSIER = "CARE_DOSSIER",
}

/**
 *
 * @export
 * @interface CrmDataScreenDto
 */
export interface CrmDataScreenDto {
  /**
   *
   * @type {number}
   * @memberof CrmDataScreenDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenDto
   */
  name_slug: string;
  /**
   *
   * @type {CrmDataScreenBoundEnum}
   * @memberof CrmDataScreenDto
   */
  bound: CrmDataScreenBoundEnum;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmDataScreenDto
   */
  tag_id_set: Array<number>;
  /**
   *
   * @type {CrmDataScreenFieldsDto}
   * @memberof CrmDataScreenDto
   */
  fields_json: CrmDataScreenFieldsDto;
}
/**
 *
 * @export
 * @interface CrmDataScreenFieldDto
 */
export interface CrmDataScreenFieldDto {
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenFieldDto
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenFieldDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenFieldDto
   */
  description: string;
  /**
   *
   * @type {CrmDataScreenFieldTypeEnum}
   * @memberof CrmDataScreenFieldDto
   */
  type: CrmDataScreenFieldTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof CrmDataScreenFieldDto
   */
  is_required: boolean;
  /**
   *
   * @type {any}
   * @memberof CrmDataScreenFieldDto
   */
  default_value?: any | null;
  /**
   *
   * @type {Array<CrmDataScreenFieldOptionDto>}
   * @memberof CrmDataScreenFieldDto
   */
  option_set?: Array<CrmDataScreenFieldOptionDto>;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmDataScreenFieldDto
   */
  show_set?: Array<string>;
}
/**
 *
 * @export
 * @interface CrmDataScreenFieldOptionDto
 */
export interface CrmDataScreenFieldOptionDto {
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenFieldOptionDto
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof CrmDataScreenFieldOptionDto
   */
  label: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CrmDataScreenFieldTypeEnum {
  STATICTEXT = "STATIC_TEXT",
  TEXTSHORT = "TEXT_SHORT",
  TEXTLONG = "TEXT_LONG",
  CHOICESINGLE = "CHOICE_SINGLE",
  CHOICEMULTIPLE = "CHOICE_MULTIPLE",
  DATEPAST = "DATE_PAST",
  DATEALL = "DATE_ALL",
  UPLOAD = "UPLOAD",
  PERSONALNAME = "PERSONAL_NAME",
  PERSONALADDRESS = "PERSONAL_ADDRESS",
  PERSONALPHONE = "PERSONAL_PHONE",
  PERSONALEMAIL = "PERSONAL_EMAIL",
}

/**
 *
 * @export
 * @interface CrmDataScreenFieldsDto
 */
export interface CrmDataScreenFieldsDto {
  /**
   *
   * @type {Array<CrmDataScreenFieldDto>}
   * @memberof CrmDataScreenFieldsDto
   */
  field_set: Array<CrmDataScreenFieldDto>;
}
/**
 *
 * @export
 * @interface CrmNoteArchiveRequest
 */
export interface CrmNoteArchiveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmNoteArchiveRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompleteCompanyRequest
 */
export interface CrmNoteAutocompleteCompanyRequest {
  /**
   *
   * @type {number}
   * @memberof CrmNoteAutocompleteCompanyRequest
   */
  care_dossier_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteAutocompleteCompanyRequest
   */
  matrix_row_id?: number;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompleteCompanyResponse
 */
export interface CrmNoteAutocompleteCompanyResponse {
  /**
   *
   * @type {Array<CrmCompanyDtoSmall>}
   * @memberof CrmNoteAutocompleteCompanyResponse
   */
  company_set: Array<CrmCompanyDtoSmall>;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompleteMatrixRowRequest
 */
export interface CrmNoteAutocompleteMatrixRowRequest {
  /**
   *
   * @type {number}
   * @memberof CrmNoteAutocompleteMatrixRowRequest
   */
  care_dossier_id?: number;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompleteMatrixRowResponse
 */
export interface CrmNoteAutocompleteMatrixRowResponse {
  /**
   *
   * @type {Array<CrmNoteAutocompleteMatrixRowResponseRowDto>}
   * @memberof CrmNoteAutocompleteMatrixRowResponse
   */
  row_set: Array<CrmNoteAutocompleteMatrixRowResponseRowDto>;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompleteMatrixRowResponseRowDto
 */
export interface CrmNoteAutocompleteMatrixRowResponseRowDto {
  /**
   *
   * @type {number}
   * @memberof CrmNoteAutocompleteMatrixRowResponseRowDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteAutocompleteMatrixRowResponseRowDto
   */
  matrix_core_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteAutocompleteMatrixRowResponseRowDto
   */
  matrix_row_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmNoteAutocompleteMatrixRowResponseRowDto
   */
  matrix_core_name: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteAutocompleteMatrixRowResponseRowDto
   */
  matrix_row_name: string;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompletePersonRequest
 */
export interface CrmNoteAutocompletePersonRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteAutocompletePersonRequest
   */
  crm_company_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CrmNoteAutocompletePersonResponse
 */
export interface CrmNoteAutocompletePersonResponse {
  /**
   *
   * @type {Array<CrmPersonDtoSmall>}
   * @memberof CrmNoteAutocompletePersonResponse
   */
  person_set: Array<CrmPersonDtoSmall>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CrmNoteClosedStateEnum {
  ISACHIEVED = "IS_ACHIEVED",
  NOTACHIEVED = "NOT_ACHIEVED",
  NOLONGERAPPLICABLE = "NO_LONGER_APPLICABLE",
}

/**
 *
 * @export
 * @interface CrmNoteDto
 */
export interface CrmNoteDto {
  /**
   *
   * @type {number}
   * @memberof CrmNoteDto
   */
  id: number;
  /**
   *
   * @type {CrmNoteModeEnum}
   * @memberof CrmNoteDto
   */
  mode: CrmNoteModeEnum;
  /**
   *
   * @type {string}
   * @memberof CrmNoteDto
   */
  created_at: string;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof CrmNoteDto
   */
  created_by: CrmPersonDtoSmall;
  /**
   *
   * @type {string}
   * @memberof CrmNoteDto
   */
  started_at?: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteDto
   */
  ending_at?: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteDto
   */
  closed_at?: string;
  /**
   *
   * @type {CrmNoteClosedStateEnum}
   * @memberof CrmNoteDto
   */
  closed_state?: CrmNoteClosedStateEnum;
  /**
   *
   * @type {number}
   * @memberof CrmNoteDto
   */
  connected_crm_person_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteDto
   */
  connected_care_dossier_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteDto
   */
  connected_matrix_row_id?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteDto
   */
  crm_company_id_set: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteDto
   */
  crm_person_id_set: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CrmNoteDto
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteDto
   */
  document: string;
  /**
   *
   * @type {Array<CrmNoteTagDto>}
   * @memberof CrmNoteDto
   */
  tag_set: Array<CrmNoteTagDto>;
  /**
   *
   * @type {Array<GenericResourceDto>}
   * @memberof CrmNoteDto
   */
  resource_set: Array<GenericResourceDto>;
  /**
   *
   * @type {number}
   * @memberof CrmNoteDto
   */
  matrix_core_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteDto
   */
  matrix_row_id?: number;
}
/**
 *
 * @export
 * @interface CrmNoteManyRequest
 */
export interface CrmNoteManyRequest {
  /**
   *
   * @type {number}
   * @memberof CrmNoteManyRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteManyRequest
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteManyRequest
   */
  connected_crm_person_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteManyRequest
   */
  connected_care_dossier_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteManyRequest
   */
  connected_matrix_row_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteManyRequest
   */
  is_closed: number;
  /**
   *
   * @type {Array<CrmNoteClosedStateEnum>}
   * @memberof CrmNoteManyRequest
   */
  closed_state: Array<CrmNoteClosedStateEnum>;
}
/**
 *
 * @export
 * @interface CrmNoteManyResponse
 */
export interface CrmNoteManyResponse {
  /**
   *
   * @type {GenericPagination}
   * @memberof CrmNoteManyResponse
   */
  pagination: GenericPagination;
  /**
   *
   * @type {Array<CrmNoteDto>}
   * @memberof CrmNoteManyResponse
   */
  records: Array<CrmNoteDto>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CrmNoteModeEnum {
  DOSSIER = "DOSSIER",
  MATRIXROW = "MATRIX_ROW",
  TARGET = "TARGET",
}

/**
 *
 * @export
 * @interface CrmNoteOneRequest
 */
export interface CrmNoteOneRequest {
  /**
   *
   * @type {number}
   * @memberof CrmNoteOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmNoteSaveRequest
 */
export interface CrmNoteSaveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmNoteSaveRequest
   */
  id?: number;
  /**
   *
   * @type {CrmNoteModeEnum}
   * @memberof CrmNoteSaveRequest
   */
  mode: CrmNoteModeEnum;
  /**
   *
   * @type {string}
   * @memberof CrmNoteSaveRequest
   */
  started_at?: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteSaveRequest
   */
  ending_at?: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteSaveRequest
   */
  closed_at?: string;
  /**
   *
   * @type {CrmNoteClosedStateEnum}
   * @memberof CrmNoteSaveRequest
   */
  closed_state?: CrmNoteClosedStateEnum;
  /**
   *
   * @type {number}
   * @memberof CrmNoteSaveRequest
   */
  connected_crm_person_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteSaveRequest
   */
  connected_care_dossier_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteSaveRequest
   */
  connected_matrix_row_id?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteSaveRequest
   */
  crm_company_id_set: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteSaveRequest
   */
  crm_person_id_set: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CrmNoteSaveRequest
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteSaveRequest
   */
  document: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteSaveRequest
   */
  resource_id_set: Array<number>;
  /**
   *
   * @type {Array<CrmNoteTagSaveDto>}
   * @memberof CrmNoteSaveRequest
   */
  tag_set: Array<CrmNoteTagSaveDto>;
}
/**
 *
 * @export
 * @interface CrmNoteTagDto
 */
export interface CrmNoteTagDto {
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagDto
   */
  note_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagDto
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagDto
   */
  dossier_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagDto
   */
  tag_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagDto
   */
  category_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmNoteTagDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteTagDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteTagDto
   */
  owner_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CrmNoteTagSaveDto
 */
export interface CrmNoteTagSaveDto {
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagSaveDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmNoteTagSaveDto
   */
  tag_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmNoteTagSaveDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CrmNoteTagSaveDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmNoteTagSaveDto
   */
  owner_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CrmPersonAddressMunicipalityPickResponse
 */
export interface CrmPersonAddressMunicipalityPickResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof CrmPersonAddressMunicipalityPickResponse
   */
  item_set: Array<string>;
}
/**
 *
 * @export
 * @interface CrmPersonArchiveRequest
 */
export interface CrmPersonArchiveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonArchiveRequest
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonArchiveRequest
   */
  is_archived?: boolean;
}
/**
 *
 * @export
 * @interface CrmPersonBaseDto
 */
export interface CrmPersonBaseDto {
  /**
   *
   * @type {number}
   * @memberof CrmPersonBaseDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonBaseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonBaseDto
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonBaseDto
   */
  is_archived: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonBaseDto
   */
  tag_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonBaseDto
   */
  role_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonBaseDto
   */
  location_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CrmPersonDto
 */
export interface CrmPersonDto {
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  is_archived: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  tag_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  role_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  location_id_set?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  has_password?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  notifying_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  involved_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  involved_person_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  process_person_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDto
   */
  case_manager_person_id_set?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  birth?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  started_on?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  has_no_fixed_address?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_zipcode?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_housenumber?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_affix?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_street?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_city?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_country?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_region?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_municipality?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  address_often_stays?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  remarks?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  csn?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  healthcare_present?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  healthcare?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  care_authorization_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  care_authorization_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  ifzo_indication_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  ifzo_indication_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  wmo_indication_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  wmo_indication_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  bw_indication_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  bw_indication_expires_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  curator_present?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  curator_name?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  probation_supervision_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  probation_supervision_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  isd_measure_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  isd_measure_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  tbs_measure_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  tbs_measure_expires_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  current_detention_present?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  current_detention_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  end_of_detention?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  end_of_detention_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  wlz_indicated?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  wlz_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  wlz_resource_id?: number;
  /**
   *
   * @type {GenericResourcePublicDto}
   * @memberof CrmPersonDto
   */
  wlz_resource?: GenericResourcePublicDto;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  nationality_has_secondary?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  nationality_primary?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  nationality_secondary?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  csn_resource_id?: number;
  /**
   *
   * @type {GenericResourcePublicDto}
   * @memberof CrmPersonDto
   */
  csn_resource?: GenericResourcePublicDto;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  has_residence_permit?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  residence_permit_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonDto
   */
  residence_permit_resource_id?: number;
  /**
   *
   * @type {GenericResourcePublicDto}
   * @memberof CrmPersonDto
   */
  residence_permit_resource?: GenericResourcePublicDto;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDto
   */
  has_deceased?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDto
   */
  deceased_at?: string;
}
/**
 *
 * @export
 * @interface CrmPersonDtoPartial
 */
export interface CrmPersonDtoPartial {
  /**
   *
   * @type {number}
   * @memberof CrmPersonDtoPartial
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDtoPartial
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDtoPartial
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonDtoPartial
   */
  is_archived: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  tag_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  role_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  location_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  notifying_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  involved_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  involved_person_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonDtoPartial
   */
  process_person_id_set?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDtoPartial
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDtoPartial
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDtoPartial
   */
  birth?: string;
}
/**
 *
 * @export
 * @interface CrmPersonDtoSmall
 */
export interface CrmPersonDtoSmall {
  /**
   *
   * @type {number}
   * @memberof CrmPersonDtoSmall
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonDtoSmall
   */
  name: string;
}
/**
 *
 * @export
 * @interface CrmPersonManyRequest
 */
export interface CrmPersonManyRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonManyRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonManyRequest
   */
  limit?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonManyRequest
   */
  tag_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CrmPersonManyResponse
 */
export interface CrmPersonManyResponse {
  /**
   *
   * @type {GenericPagination}
   * @memberof CrmPersonManyResponse
   */
  pagination: GenericPagination;
  /**
   *
   * @type {Array<CrmPersonDto>}
   * @memberof CrmPersonManyResponse
   */
  records: Array<CrmPersonDto>;
}
/**
 *
 * @export
 * @interface CrmPersonSaveRequest
 */
export interface CrmPersonSaveRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  id?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  tag_id_set: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  role_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  location_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  notifying_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  involved_company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  involved_person_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  process_person_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonSaveRequest
   */
  case_manager_person_id_set?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  birth?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  started_on?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSaveRequest
   */
  has_no_fixed_address?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_zipcode?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_housenumber?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_affix?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_street?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_city?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_country?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_municipality?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_region?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  address_often_stays?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  remarks?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  csn?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSaveRequest
   */
  healthcare_present?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  healthcare?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  care_authorization_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  care_authorization_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  ifzo_indication_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  ifzo_indication_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  wmo_indication_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  wmo_indication_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  bw_indication_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  bw_indication_expires_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSaveRequest
   */
  curator_present?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  curator_name?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  probation_supervision_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  probation_supervision_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  isd_measure_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  isd_measure_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  tbs_measure_present?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  tbs_measure_expires_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSaveRequest
   */
  current_detention_present?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  current_detention_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  end_of_detention?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  end_of_detention_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  wlz_indicated?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  wlz_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  wlz_resource_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSaveRequest
   */
  nationality_has_secondary?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  nationality_primary?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  nationality_secondary?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  csn_resource_id?: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  has_residence_permit?: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  residence_permit_expires_at?: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveRequest
   */
  residence_permit_resource_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSaveRequest
   */
  has_deceased?: boolean;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSaveRequest
   */
  deceased_at?: string;
}
/**
 * A simple detail-response, the detail is always one of the following options:  - `OK`: the request was processed. - `NOT_FOUND`: the requested request was not found.  - `ACCOUNT_NO_PASSWORD`: the account you have reached has no password setup. - `ACCOUNT_PASSWORD_INVALID`: the password you tried to verify does not match.  Something, things go bad, these are the details that tell you we could not handle it:  - `NOK`: dead machine-state reached, this should not occur. - `NI`: not-implemented, we haven\'t had the time to implement this feature / option yet.
 * @export
 * @interface CrmPersonSaveResponse
 */
export interface CrmPersonSaveResponse {
  /**
   *
   * @type {DetailEnum}
   * @memberof CrmPersonSaveResponse
   */
  detail: DetailEnum;
  /**
   *
   * @type {number}
   * @memberof CrmPersonSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface CrmPersonSettingsRequest
 */
export interface CrmPersonSettingsRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonSettingsRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonSettingsRequest
   */
  password?: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmPersonSettingsRequest
   */
  is_archived?: boolean;
}
/**
 *
 * @export
 * @interface CrmPersonTableRowsRequest
 */
export interface CrmPersonTableRowsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof CrmPersonTableRowsRequest
   */
  header_set: Array<string>;
  /**
   *
   * @type {number}
   * @memberof CrmPersonTableRowsRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonTableRowsRequest
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonTableRowsRequest
   */
  search: string;
  /**
   *
   * @type {number}
   * @memberof CrmPersonTableRowsRequest
   */
  is_archived: number;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonTableRowsRequest
   */
  tag_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonTableRowsRequest
   */
  role_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonTableRowsRequest
   */
  company_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonTableRowsRequest
   */
  involved_company_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface CrmPersonTagDto
 */
export interface CrmPersonTagDto {
  /**
   *
   * @type {number}
   * @memberof CrmPersonTagDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonTagDto
   */
  person_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonTagDto
   */
  tag_id: number;
  /**
   *
   * @type {number}
   * @memberof CrmPersonTagDto
   */
  category_id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonTagDto
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonTagDto
   */
  date_end?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CrmPersonTagDto
   */
  owner_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface CrmPersonTagHistoryRequest
 */
export interface CrmPersonTagHistoryRequest {
  /**
   *
   * @type {number}
   * @memberof CrmPersonTagHistoryRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmPersonTagHistoryRequest
   */
  date_start?: string;
  /**
   *
   * @type {string}
   * @memberof CrmPersonTagHistoryRequest
   */
  date_end?: string;
}
/**
 *
 * @export
 * @interface CrmPersonTagHistoryResponse
 */
export interface CrmPersonTagHistoryResponse {
  /**
   *
   * @type {Array<CrmPersonTagDto>}
   * @memberof CrmPersonTagHistoryResponse
   */
  tag_set: Array<CrmPersonTagDto>;
}
/**
 *
 * @export
 * @interface CrmRoleDto
 */
export interface CrmRoleDto {
  /**
   *
   * @type {number}
   * @memberof CrmRoleDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CrmRoleDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CrmRoleDto
   */
  name_slug: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CrmRoleDto
   */
  right_set: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CrmRoleDto
   */
  color_name: string;
  /**
   *
   * @type {string}
   * @memberof CrmRoleDto
   */
  color_hue: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DetailEnum {
  OK = "OK",
  NOK = "NOK",
  NI = "NI",
  NOTFOUND = "NOT_FOUND",
  EXPIRED = "EXPIRED",
  DUPLICATE = "DUPLICATE",
  ACCOUNTNOPASSWORD = "ACCOUNT_NO_PASSWORD",
  ACCOUNTPASSWORDINVALID = "ACCOUNT_PASSWORD_INVALID",
  PASSWORDPOLICY = "PASSWORD_POLICY",
  INVALIDVERSION = "INVALID_VERSION",
  FORMERROR = "FORM_ERROR",
  CAPDASHREGISTERNODATAAFTERNOTHING = "CAPDASH_REGISTER_NO_DATA_AFTER_NOTHING",
}

/**
 * A simple detail-response, the detail is always one of the following options:  - `OK`: the request was processed. - `NOT_FOUND`: the requested request was not found.  - `ACCOUNT_NO_PASSWORD`: the account you have reached has no password setup. - `ACCOUNT_PASSWORD_INVALID`: the password you tried to verify does not match.  Something, things go bad, these are the details that tell you we could not handle it:  - `NOK`: dead machine-state reached, this should not occur. - `NI`: not-implemented, we haven\'t had the time to implement this feature / option yet.
 * @export
 * @interface DetailResponse
 */
export interface DetailResponse {
  /**
   *
   * @type {DetailEnum}
   * @memberof DetailResponse
   */
  detail: DetailEnum;
}
/**
 *
 * @export
 * @interface GenericDownloadDto
 */
export interface GenericDownloadDto {
  /**
   *
   * @type {number}
   * @memberof GenericDownloadDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof GenericDownloadDto
   */
  request_by_id: number;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  requested_at: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  started_at?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  expires_at?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  filename?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  content_type?: string;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadDto
   */
  error?: string;
}
/**
 *
 * @export
 * @interface GenericDownloadOneRequest
 */
export interface GenericDownloadOneRequest {
  /**
   *
   * @type {number}
   * @memberof GenericDownloadOneRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GenericDownloadOneRequest
   */
  token: string;
}
/**
 *
 * @export
 * @interface GenericLogDto
 */
export interface GenericLogDto {
  /**
   *
   * @type {number}
   * @memberof GenericLogDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GenericLogDto
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof GenericLogDto
   */
  created_by_id?: number;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof GenericLogDto
   */
  created_by?: CrmPersonDtoSmall;
  /**
   *
   * @type {GenericLogWhatEnum}
   * @memberof GenericLogDto
   */
  what: GenericLogWhatEnum;
  /**
   *
   * @type {object}
   * @memberof GenericLogDto
   */
  object_json: object;
  /**
   *
   * @type {number}
   * @memberof GenericLogDto
   */
  crm_person_id?: number;
  /**
   *
   * @type {number}
   * @memberof GenericLogDto
   */
  care_dossier_id?: number;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof GenericLogDto
   */
  crm_person?: CrmPersonDtoSmall;
}
/**
 *
 * @export
 * @interface GenericLogManyRequest
 */
export interface GenericLogManyRequest {
  /**
   *
   * @type {number}
   * @memberof GenericLogManyRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof GenericLogManyRequest
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof GenericLogManyRequest
   */
  created_by_id?: number;
  /**
   *
   * @type {number}
   * @memberof GenericLogManyRequest
   */
  crm_person_id?: number;
  /**
   *
   * @type {number}
   * @memberof GenericLogManyRequest
   */
  care_dossier_id?: number;
}
/**
 *
 * @export
 * @interface GenericLogManyResponse
 */
export interface GenericLogManyResponse {
  /**
   *
   * @type {GenericPagination}
   * @memberof GenericLogManyResponse
   */
  pagination: GenericPagination;
  /**
   *
   * @type {Array<GenericLogDto>}
   * @memberof GenericLogManyResponse
   */
  records: Array<GenericLogDto>;
}
/**
 *
 * @export
 * @interface GenericLogSessionLoginCheckupDto
 */
export interface GenericLogSessionLoginCheckupDto {
  /**
   *
   * @type {number}
   * @memberof GenericLogSessionLoginCheckupDto
   */
  crm_person_id: number;
  /**
   *
   * @type {string}
   * @memberof GenericLogSessionLoginCheckupDto
   */
  crm_person_name: string;
  /**
   *
   * @type {Array<GenericLogSessionLoginCheckupWeekDto>}
   * @memberof GenericLogSessionLoginCheckupDto
   */
  week_set: Array<GenericLogSessionLoginCheckupWeekDto>;
}
/**
 *
 * @export
 * @interface GenericLogSessionLoginCheckupRequest
 */
export interface GenericLogSessionLoginCheckupRequest {
  /**
   *
   * @type {string}
   * @memberof GenericLogSessionLoginCheckupRequest
   */
  day_after: string;
  /**
   *
   * @type {string}
   * @memberof GenericLogSessionLoginCheckupRequest
   */
  day_before: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GenericLogSessionLoginCheckupRequest
   */
  crm_company_location_id_set?: Array<number>;
}
/**
 *
 * @export
 * @interface GenericLogSessionLoginCheckupResponse
 */
export interface GenericLogSessionLoginCheckupResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof GenericLogSessionLoginCheckupResponse
   */
  weeks: Array<string>;
  /**
   *
   * @type {Array<GenericLogSessionLoginCheckupDto>}
   * @memberof GenericLogSessionLoginCheckupResponse
   */
  rows: Array<GenericLogSessionLoginCheckupDto>;
}
/**
 *
 * @export
 * @interface GenericLogSessionLoginCheckupWeekDto
 */
export interface GenericLogSessionLoginCheckupWeekDto {
  /**
   *
   * @type {string}
   * @memberof GenericLogSessionLoginCheckupWeekDto
   */
  created_at_week: string;
  /**
   *
   * @type {number}
   * @memberof GenericLogSessionLoginCheckupWeekDto
   */
  count: number;
}
/**
 *
 * @export
 * @interface GenericLogTableRowsRequest
 */
export interface GenericLogTableRowsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GenericLogTableRowsRequest
   */
  header_set: Array<string>;
  /**
   *
   * @type {number}
   * @memberof GenericLogTableRowsRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof GenericLogTableRowsRequest
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof GenericLogTableRowsRequest
   */
  search: string;
  /**
   *
   * @type {Array<number>}
   * @memberof GenericLogTableRowsRequest
   */
  role_id_set?: Array<number>;
  /**
   *
   * @type {Array<GenericLogWhatEnum>}
   * @memberof GenericLogTableRowsRequest
   */
  what_set?: Array<GenericLogWhatEnum>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum GenericLogWhatEnum {
  GENERIC = "GENERIC",
  SESSIONLOGIN = "SESSION_LOGIN",
  SESSIONTAKEOVER = "SESSION_TAKEOVER",
  CRMPERSONSAVECREATE = "CRM_PERSON_SAVE_CREATE",
  CRMPERSONSAVEUPDATE = "CRM_PERSON_SAVE_UPDATE",
  CRMPERSONTAGOPEN = "CRM_PERSON_TAG_OPEN",
  CRMPERSONTAGMUTATE = "CRM_PERSON_TAG_MUTATE",
  CRMPERSONTAGCLOSE = "CRM_PERSON_TAG_CLOSE",
  CAREDOSSIERSAVECREATE = "CARE_DOSSIER_SAVE_CREATE",
  CAREDOSSIERSAVEUPDATE = "CARE_DOSSIER_SAVE_UPDATE",
  CAREDOSSIERTAGOPEN = "CARE_DOSSIER_TAG_OPEN",
  CAREDOSSIERTAGMUTATE = "CARE_DOSSIER_TAG_MUTATE",
  CAREDOSSIERTAGCLOSE = "CARE_DOSSIER_TAG_CLOSE",
}

/**
 *
 * @export
 * @interface GenericManualArchiveRequest
 */
export interface GenericManualArchiveRequest {
  /**
   *
   * @type {number}
   * @memberof GenericManualArchiveRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface GenericManualDto
 */
export interface GenericManualDto {
  /**
   *
   * @type {number}
   * @memberof GenericManualDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GenericManualDto
   */
  created_at: string;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof GenericManualDto
   */
  created_by: CrmPersonDtoSmall;
  /**
   *
   * @type {string}
   * @memberof GenericManualDto
   */
  updated_at: string;
  /**
   *
   * @type {CrmPersonDtoSmall}
   * @memberof GenericManualDto
   */
  updated_by: CrmPersonDtoSmall;
  /**
   *
   * @type {number}
   * @memberof GenericManualDto
   */
  version: number;
  /**
   *
   * @type {GenericResourceDto}
   * @memberof GenericManualDto
   */
  resource: GenericResourceDto;
}
/**
 *
 * @export
 * @interface GenericManualManyResponse
 */
export interface GenericManualManyResponse {
  /**
   *
   * @type {Array<GenericManualDto>}
   * @memberof GenericManualManyResponse
   */
  record_set: Array<GenericManualDto>;
}
/**
 *
 * @export
 * @interface GenericManualOneRequest
 */
export interface GenericManualOneRequest {
  /**
   *
   * @type {number}
   * @memberof GenericManualOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface GenericManualSaveRequest
 */
export interface GenericManualSaveRequest {
  /**
   *
   * @type {number}
   * @memberof GenericManualSaveRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof GenericManualSaveRequest
   */
  resource_id: number;
}
/**
 *
 * @export
 * @interface GenericPagination
 */
export interface GenericPagination {
  /**
   *
   * @type {number}
   * @memberof GenericPagination
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof GenericPagination
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof GenericPagination
   */
  count: number;
}
/**
 *
 * @export
 * @interface GenericResourceDto
 */
export interface GenericResourceDto {
  /**
   *
   * @type {number}
   * @memberof GenericResourceDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GenericResourceDto
   */
  created_at: string;
  /**
   *
   * @type {number}
   * @memberof GenericResourceDto
   */
  created_by_id: number;
  /**
   *
   * @type {string}
   * @memberof GenericResourceDto
   */
  original_filename: string;
  /**
   *
   * @type {string}
   * @memberof GenericResourceDto
   */
  content_type: string;
  /**
   *
   * @type {string}
   * @memberof GenericResourceDto
   */
  private_token: string;
}
/**
 *
 * @export
 * @interface GenericResourceOneRequest
 */
export interface GenericResourceOneRequest {
  /**
   *
   * @type {number}
   * @memberof GenericResourceOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface GenericResourcePublicDto
 */
export interface GenericResourcePublicDto {
  /**
   *
   * @type {number}
   * @memberof GenericResourcePublicDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GenericResourcePublicDto
   */
  original_filename: string;
  /**
   *
   * @type {string}
   * @memberof GenericResourcePublicDto
   */
  content_type: string;
  /**
   *
   * @type {string}
   * @memberof GenericResourcePublicDto
   */
  private_token: string;
}
/**
 *
 * @export
 * @interface GenericResourceSaveResponse
 */
export interface GenericResourceSaveResponse {
  /**
   *
   * @type {number}
   * @memberof GenericResourceSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface MatrixColumnDto
 */
export interface MatrixColumnDto {
  /**
   *
   * @type {number}
   * @memberof MatrixColumnDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixColumnDto
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixColumnDto
   */
  position: number;
  /**
   *
   * @type {string}
   * @memberof MatrixColumnDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof MatrixColumnDto
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof MatrixColumnDto
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof MatrixColumnDto
   */
  is_zero_default: boolean;
}
/**
 *
 * @export
 * @interface MatrixColumnOneRequest
 */
export interface MatrixColumnOneRequest {
  /**
   *
   * @type {number}
   * @memberof MatrixColumnOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface MatrixColumnSaveDto
 */
export interface MatrixColumnSaveDto {
  /**
   *
   * @type {number}
   * @memberof MatrixColumnSaveDto
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MatrixColumnSaveDto
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixColumnSaveDto
   */
  position: number;
  /**
   *
   * @type {string}
   * @memberof MatrixColumnSaveDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof MatrixColumnSaveDto
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof MatrixColumnSaveDto
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof MatrixColumnSaveDto
   */
  is_zero_default: boolean;
}
/**
 *
 * @export
 * @interface MatrixColumnSaveResponse
 */
export interface MatrixColumnSaveResponse {
  /**
   *
   * @type {number}
   * @memberof MatrixColumnSaveResponse
   */
  id: number;
}
/**
 *
 * @export
 * @interface MatrixCoreDtoExt
 */
export interface MatrixCoreDtoExt {
  /**
   *
   * @type {number}
   * @memberof MatrixCoreDtoExt
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof MatrixCoreDtoExt
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MatrixCoreDtoExt
   */
  name_slug: string;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  is_active: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_hide_empty_rows: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_zero_mark: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_target_mark: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_continuation_mark: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_notes: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_tags: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MatrixCoreDtoExt
   */
  has_responsibles: boolean;
  /**
   *
   * @type {Array<MatrixRowDto>}
   * @memberof MatrixCoreDtoExt
   */
  row_set: Array<MatrixRowDto>;
  /**
   *
   * @type {Array<MatrixColumnDto>}
   * @memberof MatrixCoreDtoExt
   */
  column_set: Array<MatrixColumnDto>;
  /**
   *
   * @type {Array<MatrixValueDto>}
   * @memberof MatrixCoreDtoExt
   */
  value_set: Array<MatrixValueDto>;
}
/**
 *
 * @export
 * @interface MatrixCoreOneRequest
 */
export interface MatrixCoreOneRequest {
  /**
   *
   * @type {number}
   * @memberof MatrixCoreOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface MatrixCoreTableRowsRequest
 */
export interface MatrixCoreTableRowsRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof MatrixCoreTableRowsRequest
   */
  header_set: Array<string>;
  /**
   *
   * @type {number}
   * @memberof MatrixCoreTableRowsRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof MatrixCoreTableRowsRequest
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof MatrixCoreTableRowsRequest
   */
  search: string;
}
/**
 *
 * @export
 * @interface MatrixRowDto
 */
export interface MatrixRowDto {
  /**
   *
   * @type {number}
   * @memberof MatrixRowDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixRowDto
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixRowDto
   */
  position: number;
  /**
   *
   * @type {string}
   * @memberof MatrixRowDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MatrixRowDto
   */
  description: string;
}
/**
 *
 * @export
 * @interface MatrixRowOneRequest
 */
export interface MatrixRowOneRequest {
  /**
   *
   * @type {number}
   * @memberof MatrixRowOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface MatrixRowSaveRequest
 */
export interface MatrixRowSaveRequest {
  /**
   *
   * @type {number}
   * @memberof MatrixRowSaveRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MatrixRowSaveRequest
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixRowSaveRequest
   */
  position: number;
  /**
   *
   * @type {string}
   * @memberof MatrixRowSaveRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MatrixRowSaveRequest
   */
  description: string;
}
/**
 *
 * @export
 * @interface MatrixValueDto
 */
export interface MatrixValueDto {
  /**
   *
   * @type {number}
   * @memberof MatrixValueDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueDto
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueDto
   */
  row_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueDto
   */
  column_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueDto
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof MatrixValueDto
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof MatrixValueDto
   */
  has_own_score: boolean;
}
/**
 *
 * @export
 * @interface MatrixValueOneRequest
 */
export interface MatrixValueOneRequest {
  /**
   *
   * @type {number}
   * @memberof MatrixValueOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface MatrixValueSaveRequest
 */
export interface MatrixValueSaveRequest {
  /**
   *
   * @type {number}
   * @memberof MatrixValueSaveRequest
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueSaveRequest
   */
  matrix_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueSaveRequest
   */
  row_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueSaveRequest
   */
  column_id: number;
  /**
   *
   * @type {number}
   * @memberof MatrixValueSaveRequest
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof MatrixValueSaveRequest
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof MatrixValueSaveRequest
   */
  has_own_score: boolean;
}
/**
 *
 * @export
 * @interface RecordDto
 */
export interface RecordDto {
  /**
   *
   * @type {number}
   * @memberof RecordDto
   */
  crm_company_location_id: number;
  /**
   *
   * @type {number}
   * @memberof RecordDto
   */
  sum_waiting_new?: number;
  /**
   *
   * @type {number}
   * @memberof RecordDto
   */
  sum_waiting_failed?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof RecordDto
   */
  waiting_failed_content?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof RecordDto
   */
  waiting_failed_financial?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof RecordDto
   */
  waiting_failed_system?: { [key: string]: number };
}
/**
 *
 * @export
 * @interface TableHeaderDto
 */
export interface TableHeaderDto {
  /**
   *
   * @type {string}
   * @memberof TableHeaderDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TableHeaderDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof TableHeaderDto
   */
  display_modifier?: string;
  /**
   *
   * @type {number}
   * @memberof TableHeaderDto
   */
  display_width?: number;
  /**
   *
   * @type {string}
   * @memberof TableHeaderDto
   */
  opt_align?: string;
  /**
   *
   * @type {boolean}
   * @memberof TableHeaderDto
   */
  opt_grow?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TableHeaderDto
   */
  opt_shrink?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TableHeaderDto
   */
  opt_default?: boolean;
}
/**
 *
 * @export
 * @interface TableHeadersResponse
 */
export interface TableHeadersResponse {
  /**
   *
   * @type {Array<TableHeaderDto>}
   * @memberof TableHeadersResponse
   */
  header_set: Array<TableHeaderDto>;
}
/**
 *
 * @export
 * @interface TableRowDto
 */
export interface TableRowDto {
  /**
   *
   * @type {number}
   * @memberof TableRowDto
   */
  record_id?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof TableRowDto
   */
  values: Array<any>;
}
/**
 *
 * @export
 * @interface TableRowsResponse
 */
export interface TableRowsResponse {
  /**
   *
   * @type {Array<TableRowDto>}
   * @memberof TableRowsResponse
   */
  row_set: Array<TableRowDto>;
  /**
   *
   * @type {Array<string>}
   * @memberof TableRowsResponse
   */
  header_set: Array<string>;
  /**
   *
   * @type {Array<TableHeaderDto>}
   * @memberof TableRowsResponse
   */
  headers: Array<TableHeaderDto>;
  /**
   *
   * @type {GenericPagination}
   * @memberof TableRowsResponse
   */
  pagination: GenericPagination;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TagCategoryAppliesToEnum {
  APIRIGHTS = "API_RIGHTS",
  CRMPERSON = "CRM_PERSON",
  CAREDOSSIER = "CARE_DOSSIER",
  CAREMATRIX = "CARE_MATRIX",
}

/**
 *
 * @export
 * @interface TagCategoryDto
 */
export interface TagCategoryDto {
  /**
   *
   * @type {number}
   * @memberof TagCategoryDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TagCategoryDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TagCategoryDto
   */
  name_slug: string;
  /**
   *
   * @type {boolean}
   * @memberof TagCategoryDto
   */
  is_automated: boolean;
  /**
   *
   * @type {Array<TagCategoryAppliesToEnum>}
   * @memberof TagCategoryDto
   */
  applies_to_set: Array<TagCategoryAppliesToEnum>;
}
/**
 *
 * @export
 * @interface TagCoreDto
 */
export interface TagCoreDto {
  /**
   *
   * @type {number}
   * @memberof TagCoreDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof TagCoreDto
   */
  category_id: number;
  /**
   *
   * @type {string}
   * @memberof TagCoreDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TagCoreDto
   */
  name_slug: string;
  /**
   *
   * @type {boolean}
   * @memberof TagCoreDto
   */
  has_start: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TagCoreDto
   */
  has_end: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TagCoreDto
   */
  has_owner_set: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TagCoreDto
   */
  may_authenticate: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof TagCoreDto
   */
  accessible_is_set: Array<number>;
  /**
   *
   * @type {string}
   * @memberof TagCoreDto
   */
  color_name: string;
  /**
   *
   * @type {string}
   * @memberof TagCoreDto
   */
  color_hue: string;
}
/**
 *
 * @export
 * @interface TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
 */
export interface TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest {
  /**
   *
   * @type {number}
   * @memberof TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
 */
export interface TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest {
  /**
   *
   * @type {number}
   * @memberof TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
   */
  id: number;
}
/**
 *
 * @export
 * @interface TsChartCrmCompanyDto
 */
export interface TsChartCrmCompanyDto {
  /**
   *
   * @type {number}
   * @memberof TsChartCrmCompanyDto
   */
  crm_company_location_id: number;
  /**
   *
   * @type {number}
   * @memberof TsChartCrmCompanyDto
   */
  dossier_count?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartCrmCompanyDto
   */
  dossier_id_set?: Array<number>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TsChartDataChartEnum {
  Totals = "Totals",
  Flows = "Flows",
  Matrixes = "Matrixes",
}

/**
 *
 * @export
 * @interface TsChartDataDto
 */
export interface TsChartDataDto {
  /**
   *
   * @type {TsChartDataChartEnum}
   * @memberof TsChartDataDto
   */
  chart?: TsChartDataChartEnum;
  /**
   *
   * @type {TsChartDataPeriodMethodEnum}
   * @memberof TsChartDataDto
   */
  period_method: TsChartDataPeriodMethodEnum;
  /**
   *
   * @type {string}
   * @memberof TsChartDataDto
   */
  period_start?: string;
  /**
   *
   * @type {string}
   * @memberof TsChartDataDto
   */
  period_end?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TsChartDataDto
   */
  region_set: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TsChartDataDto
   */
  address_municipality_set: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartDataDto
   */
  dossier_tag_set: Array<number>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TsChartDataPeriodMethodEnum {
  PreviousQuarter = "PreviousQuarter",
  ThisQuarter = "ThisQuarter",
  Previous12Months = "Previous12Months",
  PreviousYear = "PreviousYear",
  ThisYear = "ThisYear",
  Manual = "Manual",
}

/**
 *
 * @export
 * @interface TsChartFlowItemDto
 */
export interface TsChartFlowItemDto {
  /**
   *
   * @type {number}
   * @memberof TsChartFlowItemDto
   */
  core_tag_id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartFlowItemDto
   */
  dossier_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartFlowItemDto
   */
  dossier_id_started_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartFlowItemDto
   */
  dossier_id_ended_set?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof TsChartFlowItemDto
   */
  dossier_count?: number;
  /**
   *
   * @type {number}
   * @memberof TsChartFlowItemDto
   */
  started_count?: number;
  /**
   *
   * @type {number}
   * @memberof TsChartFlowItemDto
   */
  ended_count?: number;
}
/**
 *
 * @export
 * @interface TsChartFlowResponse
 */
export interface TsChartFlowResponse {
  /**
   *
   * @type {Array<TsChartFlowItemDto>}
   * @memberof TsChartFlowResponse
   */
  item_set?: Array<TsChartFlowItemDto>;
}
/**
 *
 * @export
 * @interface TsChartMatrixDto
 */
export interface TsChartMatrixDto {
  /**
   *
   * @type {number}
   * @memberof TsChartMatrixDto
   */
  matrix_core_id: number;
  /**
   *
   * @type {Array<TsChartMatrixRowDto>}
   * @memberof TsChartMatrixDto
   */
  row_set?: Array<TsChartMatrixRowDto>;
}
/**
 *
 * @export
 * @interface TsChartMatrixRowDto
 */
export interface TsChartMatrixRowDto {
  /**
   *
   * @type {number}
   * @memberof TsChartMatrixRowDto
   */
  matrix_row_id: number;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartMatrixRowDto
   */
  dossier_id_set?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartMatrixRowDto
   */
  critical_id_set?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof TsChartMatrixRowDto
   */
  dossier_count?: number;
  /**
   *
   * @type {number}
   * @memberof TsChartMatrixRowDto
   */
  critical_count?: number;
}
/**
 *
 * @export
 * @interface TsChartMatrixesResponse
 */
export interface TsChartMatrixesResponse {
  /**
   *
   * @type {Array<TsChartMatrixDto>}
   * @memberof TsChartMatrixesResponse
   */
  matrixes_set?: Array<TsChartMatrixDto>;
  /**
   *
   * @type {Array<TsChartCrmCompanyDto>}
   * @memberof TsChartMatrixesResponse
   */
  crm_company_set?: Array<TsChartCrmCompanyDto>;
}
/**
 *
 * @export
 * @interface TsChartTotalsResponse
 */
export interface TsChartTotalsResponse {
  /**
   *
   * @type {Array<number>}
   * @memberof TsChartTotalsResponse
   */
  dossier_id_set: Array<number>;
}
/**
 *
 * @export
 * @interface TsCompanyDto
 */
export interface TsCompanyDto {
  /**
   *
   * @type {number}
   * @memberof TsCompanyDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TsCompanyDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TsCompanyDto
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof TsCompanyDto
   */
  avatar_title: string;
  /**
   *
   * @type {string}
   * @memberof TsCompanyDto
   */
  avatar_color: string;
  /**
   *
   * @type {string}
   * @memberof TsCompanyDto
   */
  avatar_hue: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TsCompanyDto
   */
  plugins: Array<string>;
}
/**
 *
 * @export
 * @interface TsCompanySetByEmailRequest
 */
export interface TsCompanySetByEmailRequest {
  /**
   *
   * @type {string}
   * @memberof TsCompanySetByEmailRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface TsCompanySetByEmailResponse
 */
export interface TsCompanySetByEmailResponse {
  /**
   *
   * @type {Array<TsCompanyDto>}
   * @memberof TsCompanySetByEmailResponse
   */
  company_set: Array<TsCompanyDto>;
}
/**
 *
 * @export
 * @interface TsSessionIdentifyRequest
 */
export interface TsSessionIdentifyRequest {
  /**
   *
   * @type {string}
   * @memberof TsSessionIdentifyRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface TsSessionIdentifyResponse
 */
export interface TsSessionIdentifyResponse {
  /**
   *
   * @type {Array<TsCompanyDto>}
   * @memberof TsSessionIdentifyResponse
   */
  company_set: Array<TsCompanyDto>;
}
/**
 *
 * @export
 * @interface TsSessionLoginRequest
 */
export interface TsSessionLoginRequest {
  /**
   *
   * @type {number}
   * @memberof TsSessionLoginRequest
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof TsSessionLoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TsSessionLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface TsSessionLoginResponse
 */
export interface TsSessionLoginResponse {
  /**
   *
   * @type {TsCompanyDto}
   * @memberof TsSessionLoginResponse
   */
  company: TsCompanyDto;
  /**
   *
   * @type {CrmPersonBaseDto}
   * @memberof TsSessionLoginResponse
   */
  person: CrmPersonBaseDto;
  /**
   *
   * @type {CrmPersonBaseDto}
   * @memberof TsSessionLoginResponse
   */
  origin_person?: CrmPersonBaseDto;
  /**
   *
   * @type {string}
   * @memberof TsSessionLoginResponse
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof TsSessionLoginResponse
   */
  expires_at: string;
}
/**
 *
 * @export
 * @interface TsSessionResetPasswordRequest
 */
export interface TsSessionResetPasswordRequest {
  /**
   *
   * @type {number}
   * @memberof TsSessionResetPasswordRequest
   */
  sotp_id: number;
  /**
   *
   * @type {string}
   * @memberof TsSessionResetPasswordRequest
   */
  sotp_token: string;
  /**
   *
   * @type {string}
   * @memberof TsSessionResetPasswordRequest
   */
  sotp_password: string;
  /**
   *
   * @type {number}
   * @memberof TsSessionResetPasswordRequest
   */
  company_id: number;
  /**
   *
   * @type {string}
   * @memberof TsSessionResetPasswordRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof TsSessionResetPasswordRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface TsSessionSotpGenerateRequest
 */
export interface TsSessionSotpGenerateRequest {
  /**
   *
   * @type {string}
   * @memberof TsSessionSotpGenerateRequest
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof TsSessionSotpGenerateRequest
   */
  company_id: number;
  /**
   *
   * @type {TsSessionSotpReasonEnum}
   * @memberof TsSessionSotpGenerateRequest
   */
  reason: TsSessionSotpReasonEnum;
}
/**
 *
 * @export
 * @interface TsSessionSotpGenerateResponse
 */
export interface TsSessionSotpGenerateResponse {
  /**
   *
   * @type {number}
   * @memberof TsSessionSotpGenerateResponse
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TsSessionSotpGenerateResponse
   */
  token: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum TsSessionSotpReasonEnum {
  OFPASSWORDFORGOT = "OF_PASSWORD_FORGOT",
}

/**
 *
 * @export
 * @interface TsSessionSotpValidateRequest
 */
export interface TsSessionSotpValidateRequest {
  /**
   *
   * @type {number}
   * @memberof TsSessionSotpValidateRequest
   */
  sotp_id: number;
  /**
   *
   * @type {string}
   * @memberof TsSessionSotpValidateRequest
   */
  sotp_token: string;
  /**
   *
   * @type {string}
   * @memberof TsSessionSotpValidateRequest
   */
  sotp_password: string;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationError
   */
  loc: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Api Openapi Docs Route
     * @param {string} [sessionToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDocsGet: async (sessionToken?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api-openapi/docs`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      if (sessionToken !== undefined) {
        localVarQueryParameter["session_token"] = sessionToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Api Web Data Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiWebDataPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/web/data`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Api Web Resolve Zipcode Route
     * @param {ApiWebResolveZipcodeRequest} apiWebResolveZipcodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiWebResolveZipcodePost: async (
      apiWebResolveZipcodeRequest: ApiWebResolveZipcodeRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiWebResolveZipcodeRequest' is not null or undefined
      if (apiWebResolveZipcodeRequest === null || apiWebResolveZipcodeRequest === undefined) {
        throw new RequiredError(
          "apiWebResolveZipcodeRequest",
          "Required parameter apiWebResolveZipcodeRequest was null or undefined when calling apiWebResolveZipcodePost.",
        );
      }
      const localVarPath = `/api/web/resolve_zipcode`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof apiWebResolveZipcodeRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(apiWebResolveZipcodeRequest !== undefined ? apiWebResolveZipcodeRequest : {})
        : apiWebResolveZipcodeRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Api Web Version Route
     * @param {ApiWebVersionRequest} apiWebVersionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiWebVersionPost: async (apiWebVersionRequest: ApiWebVersionRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'apiWebVersionRequest' is not null or undefined
      if (apiWebVersionRequest === null || apiWebVersionRequest === undefined) {
        throw new RequiredError(
          "apiWebVersionRequest",
          "Required parameter apiWebVersionRequest was null or undefined when calling apiWebVersionPost.",
        );
      }
      const localVarPath = `/api/web/version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof apiWebVersionRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(apiWebVersionRequest !== undefined ? apiWebVersionRequest : {})
        : apiWebVersionRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Download Stream Route
     * @param {string} companySlug
     * @param {string} filename
     * @param {number} id
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appCompanycompanySlugDownloadStreamFilenameGet: async (
      companySlug: string,
      filename: string,
      id: number,
      token: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companySlug' is not null or undefined
      if (companySlug === null || companySlug === undefined) {
        throw new RequiredError(
          "companySlug",
          "Required parameter companySlug was null or undefined when calling appCompanycompanySlugDownloadStreamFilenameGet.",
        );
      }
      // verify required parameter 'filename' is not null or undefined
      if (filename === null || filename === undefined) {
        throw new RequiredError(
          "filename",
          "Required parameter filename was null or undefined when calling appCompanycompanySlugDownloadStreamFilenameGet.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling appCompanycompanySlugDownloadStreamFilenameGet.",
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling appCompanycompanySlugDownloadStreamFilenameGet.",
        );
      }
      const localVarPath = `/app/company:{company_slug}/download/stream/{filename}`
        .replace(`{${"company_slug"}}`, encodeURIComponent(String(companySlug)))
        .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      if (token !== undefined) {
        localVarQueryParameter["token"] = token;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Resource Stream Route
     * @param {string} companySlug
     * @param {number} id
     * @param {string} privateToken
     * @param {string} filename
     * @param {boolean} [download]
     * @param {string} [publicToken]
     * @param {string} [sessionToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet: async (
      companySlug: string,
      id: number,
      privateToken: string,
      filename: string,
      download?: boolean,
      publicToken?: string,
      sessionToken?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companySlug' is not null or undefined
      if (companySlug === null || companySlug === undefined) {
        throw new RequiredError(
          "companySlug",
          "Required parameter companySlug was null or undefined when calling appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet.",
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet.",
        );
      }
      // verify required parameter 'privateToken' is not null or undefined
      if (privateToken === null || privateToken === undefined) {
        throw new RequiredError(
          "privateToken",
          "Required parameter privateToken was null or undefined when calling appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet.",
        );
      }
      // verify required parameter 'filename' is not null or undefined
      if (filename === null || filename === undefined) {
        throw new RequiredError(
          "filename",
          "Required parameter filename was null or undefined when calling appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet.",
        );
      }
      const localVarPath = `/app/company:{company_slug}/resource/stream/{id}/{private_token}/{filename}`
        .replace(`{${"company_slug"}}`, encodeURIComponent(String(companySlug)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"private_token"}}`, encodeURIComponent(String(privateToken)))
        .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (download !== undefined) {
        localVarQueryParameter["download"] = download;
      }

      if (publicToken !== undefined) {
        localVarQueryParameter["public_token"] = publicToken;
      }

      if (sessionToken !== undefined) {
        localVarQueryParameter["session_token"] = sessionToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Archive Route
     * @param {CareDossierArchiveRequest} careDossierArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierArchivePost: async (
      careDossierArchiveRequest: CareDossierArchiveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierArchiveRequest' is not null or undefined
      if (careDossierArchiveRequest === null || careDossierArchiveRequest === undefined) {
        throw new RequiredError(
          "careDossierArchiveRequest",
          "Required parameter careDossierArchiveRequest was null or undefined when calling careDossierArchivePost.",
        );
      }
      const localVarPath = `/care/dossier/archive`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierArchiveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierArchiveRequest !== undefined ? careDossierArchiveRequest : {})
        : careDossierArchiveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Data History Route
     * @param {CareDossierDataHistoryRequest} careDossierDataHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataHistoryPost: async (
      careDossierDataHistoryRequest: CareDossierDataHistoryRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierDataHistoryRequest' is not null or undefined
      if (careDossierDataHistoryRequest === null || careDossierDataHistoryRequest === undefined) {
        throw new RequiredError(
          "careDossierDataHistoryRequest",
          "Required parameter careDossierDataHistoryRequest was null or undefined when calling careDossierDataHistoryPost.",
        );
      }
      const localVarPath = `/care/dossier/data/history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierDataHistoryRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierDataHistoryRequest !== undefined ? careDossierDataHistoryRequest : {})
        : careDossierDataHistoryRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Data Mark Route
     * @param {CareDossierDataMarkRequest} careDossierDataMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataMarkPost: async (
      careDossierDataMarkRequest: CareDossierDataMarkRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierDataMarkRequest' is not null or undefined
      if (careDossierDataMarkRequest === null || careDossierDataMarkRequest === undefined) {
        throw new RequiredError(
          "careDossierDataMarkRequest",
          "Required parameter careDossierDataMarkRequest was null or undefined when calling careDossierDataMarkPost.",
        );
      }
      const localVarPath = `/care/dossier/data/mark`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierDataMarkRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierDataMarkRequest !== undefined ? careDossierDataMarkRequest : {})
        : careDossierDataMarkRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Data One Route
     * @param {CareDossierDataOneRequest} careDossierDataOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataOnePost: async (
      careDossierDataOneRequest: CareDossierDataOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierDataOneRequest' is not null or undefined
      if (careDossierDataOneRequest === null || careDossierDataOneRequest === undefined) {
        throw new RequiredError(
          "careDossierDataOneRequest",
          "Required parameter careDossierDataOneRequest was null or undefined when calling careDossierDataOnePost.",
        );
      }
      const localVarPath = `/care/dossier/data/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierDataOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierDataOneRequest !== undefined ? careDossierDataOneRequest : {})
        : careDossierDataOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Data Save Route
     * @param {CareDossierDataSaveRequest} careDossierDataSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataSavePost: async (
      careDossierDataSaveRequest: CareDossierDataSaveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierDataSaveRequest' is not null or undefined
      if (careDossierDataSaveRequest === null || careDossierDataSaveRequest === undefined) {
        throw new RequiredError(
          "careDossierDataSaveRequest",
          "Required parameter careDossierDataSaveRequest was null or undefined when calling careDossierDataSavePost.",
        );
      }
      const localVarPath = `/care/dossier/data/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierDataSaveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierDataSaveRequest !== undefined ? careDossierDataSaveRequest : {})
        : careDossierDataSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Many Route
     * @param {CareDossierManyRequest} careDossierManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierManyPost: async (
      careDossierManyRequest: CareDossierManyRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierManyRequest' is not null or undefined
      if (careDossierManyRequest === null || careDossierManyRequest === undefined) {
        throw new RequiredError(
          "careDossierManyRequest",
          "Required parameter careDossierManyRequest was null or undefined when calling careDossierManyPost.",
        );
      }
      const localVarPath = `/care/dossier/many`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierManyRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierManyRequest !== undefined ? careDossierManyRequest : {})
        : careDossierManyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier One Route
     * @param {CareDossierOneRequest} careDossierOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierOnePost: async (
      careDossierOneRequest: CareDossierOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierOneRequest' is not null or undefined
      if (careDossierOneRequest === null || careDossierOneRequest === undefined) {
        throw new RequiredError(
          "careDossierOneRequest",
          "Required parameter careDossierOneRequest was null or undefined when calling careDossierOnePost.",
        );
      }
      const localVarPath = `/care/dossier/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierOneRequest !== undefined ? careDossierOneRequest : {})
        : careDossierOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Save Route
     * @param {CareDossierSaveRequest} careDossierSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierSavePost: async (
      careDossierSaveRequest: CareDossierSaveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierSaveRequest' is not null or undefined
      if (careDossierSaveRequest === null || careDossierSaveRequest === undefined) {
        throw new RequiredError(
          "careDossierSaveRequest",
          "Required parameter careDossierSaveRequest was null or undefined when calling careDossierSavePost.",
        );
      }
      const localVarPath = `/care/dossier/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierSaveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierSaveRequest !== undefined ? careDossierSaveRequest : {})
        : careDossierSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Table Download Route
     * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTableDownloadPost: async (
      careDossierTableRowsRequest: CareDossierTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTableRowsRequest' is not null or undefined
      if (careDossierTableRowsRequest === null || careDossierTableRowsRequest === undefined) {
        throw new RequiredError(
          "careDossierTableRowsRequest",
          "Required parameter careDossierTableRowsRequest was null or undefined when calling careDossierTableDownloadPost.",
        );
      }
      const localVarPath = `/care/dossier/table_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTableRowsRequest !== undefined ? careDossierTableRowsRequest : {})
        : careDossierTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTableHeadersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/care/dossier/table_headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Table Rows Route
     * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTableRowsPost: async (
      careDossierTableRowsRequest: CareDossierTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTableRowsRequest' is not null or undefined
      if (careDossierTableRowsRequest === null || careDossierTableRowsRequest === undefined) {
        throw new RequiredError(
          "careDossierTableRowsRequest",
          "Required parameter careDossierTableRowsRequest was null or undefined when calling careDossierTableRowsPost.",
        );
      }
      const localVarPath = `/care/dossier/table_rows`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTableRowsRequest !== undefined ? careDossierTableRowsRequest : {})
        : careDossierTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Tag History Route
     * @param {CareDossierTagHistoryRequest} careDossierTagHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTagHistoryPost: async (
      careDossierTagHistoryRequest: CareDossierTagHistoryRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTagHistoryRequest' is not null or undefined
      if (careDossierTagHistoryRequest === null || careDossierTagHistoryRequest === undefined) {
        throw new RequiredError(
          "careDossierTagHistoryRequest",
          "Required parameter careDossierTagHistoryRequest was null or undefined when calling careDossierTagHistoryPost.",
        );
      }
      const localVarPath = `/care/dossier/tag_history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTagHistoryRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTagHistoryRequest !== undefined ? careDossierTagHistoryRequest : {})
        : careDossierTagHistoryRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Tag One Route
     * @param {CareDossierTagOneRequest} careDossierTagOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTagOnePost: async (
      careDossierTagOneRequest: CareDossierTagOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTagOneRequest' is not null or undefined
      if (careDossierTagOneRequest === null || careDossierTagOneRequest === undefined) {
        throw new RequiredError(
          "careDossierTagOneRequest",
          "Required parameter careDossierTagOneRequest was null or undefined when calling careDossierTagOnePost.",
        );
      }
      const localVarPath = `/care/dossier/tag_one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTagOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTagOneRequest !== undefined ? careDossierTagOneRequest : {})
        : careDossierTagOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Tag Save Route
     * @param {CareDossierTagSaveDto} careDossierTagSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTagSavePost: async (
      careDossierTagSaveDto: CareDossierTagSaveDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTagSaveDto' is not null or undefined
      if (careDossierTagSaveDto === null || careDossierTagSaveDto === undefined) {
        throw new RequiredError(
          "careDossierTagSaveDto",
          "Required parameter careDossierTagSaveDto was null or undefined when calling careDossierTagSavePost.",
        );
      }
      const localVarPath = `/care/dossier/tag_save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTagSaveDto !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTagSaveDto !== undefined ? careDossierTagSaveDto : {})
        : careDossierTagSaveDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Amount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerAmountPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/care/dossier/trigger/amount`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Trigger One Route
     * @param {CareDossierTriggerOneRequest} careDossierTriggerOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerOnePost: async (
      careDossierTriggerOneRequest: CareDossierTriggerOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTriggerOneRequest' is not null or undefined
      if (careDossierTriggerOneRequest === null || careDossierTriggerOneRequest === undefined) {
        throw new RequiredError(
          "careDossierTriggerOneRequest",
          "Required parameter careDossierTriggerOneRequest was null or undefined when calling careDossierTriggerOnePost.",
        );
      }
      const localVarPath = `/care/dossier/trigger/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTriggerOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTriggerOneRequest !== undefined ? careDossierTriggerOneRequest : {})
        : careDossierTriggerOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Table Download Route
     * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTableDownloadPost: async (
      careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTriggerTableRowsRequest' is not null or undefined
      if (careDossierTriggerTableRowsRequest === null || careDossierTriggerTableRowsRequest === undefined) {
        throw new RequiredError(
          "careDossierTriggerTableRowsRequest",
          "Required parameter careDossierTriggerTableRowsRequest was null or undefined when calling careDossierTriggerTableDownloadPost.",
        );
      }
      const localVarPath = `/care/dossier/trigger/table_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTriggerTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTriggerTableRowsRequest !== undefined ? careDossierTriggerTableRowsRequest : {})
        : careDossierTriggerTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTableHeadersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/care/dossier/trigger/table_headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Table Rows Route
     * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTableRowsPost: async (
      careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTriggerTableRowsRequest' is not null or undefined
      if (careDossierTriggerTableRowsRequest === null || careDossierTriggerTableRowsRequest === undefined) {
        throw new RequiredError(
          "careDossierTriggerTableRowsRequest",
          "Required parameter careDossierTriggerTableRowsRequest was null or undefined when calling careDossierTriggerTableRowsPost.",
        );
      }
      const localVarPath = `/care/dossier/trigger/table_rows`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTriggerTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTriggerTableRowsRequest !== undefined ? careDossierTriggerTableRowsRequest : {})
        : careDossierTriggerTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Toggle Route
     * @param {CareDossierTriggerToggleRequest} careDossierTriggerToggleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTogglePost: async (
      careDossierTriggerToggleRequest: CareDossierTriggerToggleRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careDossierTriggerToggleRequest' is not null or undefined
      if (careDossierTriggerToggleRequest === null || careDossierTriggerToggleRequest === undefined) {
        throw new RequiredError(
          "careDossierTriggerToggleRequest",
          "Required parameter careDossierTriggerToggleRequest was null or undefined when calling careDossierTriggerTogglePost.",
        );
      }
      const localVarPath = `/care/dossier/trigger/toggle`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careDossierTriggerToggleRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careDossierTriggerToggleRequest !== undefined ? careDossierTriggerToggleRequest : {})
        : careDossierTriggerToggleRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix Mark Latest Route
     * @param {CareMatrixMarkLatestRequest} careMatrixMarkLatestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixMarkLatestPost: async (
      careMatrixMarkLatestRequest: CareMatrixMarkLatestRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixMarkLatestRequest' is not null or undefined
      if (careMatrixMarkLatestRequest === null || careMatrixMarkLatestRequest === undefined) {
        throw new RequiredError(
          "careMatrixMarkLatestRequest",
          "Required parameter careMatrixMarkLatestRequest was null or undefined when calling careMatrixMarkLatestPost.",
        );
      }
      const localVarPath = `/care/matrix/mark_latest`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixMarkLatestRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixMarkLatestRequest !== undefined ? careMatrixMarkLatestRequest : {})
        : careMatrixMarkLatestRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix One Route
     * @param {CareMatrixOneRequest} careMatrixOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixOnePost: async (careMatrixOneRequest: CareMatrixOneRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixOneRequest' is not null or undefined
      if (careMatrixOneRequest === null || careMatrixOneRequest === undefined) {
        throw new RequiredError(
          "careMatrixOneRequest",
          "Required parameter careMatrixOneRequest was null or undefined when calling careMatrixOnePost.",
        );
      }
      const localVarPath = `/care/matrix/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixOneRequest !== undefined ? careMatrixOneRequest : {})
        : careMatrixOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix Row One Route
     * @param {CareMatrixRowOneRequest} careMatrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowOnePost: async (
      careMatrixRowOneRequest: CareMatrixRowOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixRowOneRequest' is not null or undefined
      if (careMatrixRowOneRequest === null || careMatrixRowOneRequest === undefined) {
        throw new RequiredError(
          "careMatrixRowOneRequest",
          "Required parameter careMatrixRowOneRequest was null or undefined when calling careMatrixRowOnePost.",
        );
      }
      const localVarPath = `/care/matrix/row/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixRowOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixRowOneRequest !== undefined ? careMatrixRowOneRequest : {})
        : careMatrixRowOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix Row Save Responsible Route
     * @param {CareMatrixRowSaveResponsibleRequest} careMatrixRowSaveResponsibleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowSaveResponsiblePost: async (
      careMatrixRowSaveResponsibleRequest: CareMatrixRowSaveResponsibleRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixRowSaveResponsibleRequest' is not null or undefined
      if (careMatrixRowSaveResponsibleRequest === null || careMatrixRowSaveResponsibleRequest === undefined) {
        throw new RequiredError(
          "careMatrixRowSaveResponsibleRequest",
          "Required parameter careMatrixRowSaveResponsibleRequest was null or undefined when calling careMatrixRowSaveResponsiblePost.",
        );
      }
      const localVarPath = `/care/matrix/row/save_responsible`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixRowSaveResponsibleRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixRowSaveResponsibleRequest !== undefined ? careMatrixRowSaveResponsibleRequest : {})
        : careMatrixRowSaveResponsibleRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix Row Save Target Route
     * @param {CareMatrixRowSaveTargetRequest} careMatrixRowSaveTargetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowSaveTargetPost: async (
      careMatrixRowSaveTargetRequest: CareMatrixRowSaveTargetRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixRowSaveTargetRequest' is not null or undefined
      if (careMatrixRowSaveTargetRequest === null || careMatrixRowSaveTargetRequest === undefined) {
        throw new RequiredError(
          "careMatrixRowSaveTargetRequest",
          "Required parameter careMatrixRowSaveTargetRequest was null or undefined when calling careMatrixRowSaveTargetPost.",
        );
      }
      const localVarPath = `/care/matrix/row/save_target`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixRowSaveTargetRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixRowSaveTargetRequest !== undefined ? careMatrixRowSaveTargetRequest : {})
        : careMatrixRowSaveTargetRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix Row Tag Save Route
     * @param {CareMatrixRowTagSaveDto} careMatrixRowTagSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowTagSavePost: async (
      careMatrixRowTagSaveDto: CareMatrixRowTagSaveDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixRowTagSaveDto' is not null or undefined
      if (careMatrixRowTagSaveDto === null || careMatrixRowTagSaveDto === undefined) {
        throw new RequiredError(
          "careMatrixRowTagSaveDto",
          "Required parameter careMatrixRowTagSaveDto was null or undefined when calling careMatrixRowTagSavePost.",
        );
      }
      const localVarPath = `/care/matrix/row/tag_save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixRowTagSaveDto !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixRowTagSaveDto !== undefined ? careMatrixRowTagSaveDto : {})
        : careMatrixRowTagSaveDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Care Matrix Save Mark Route
     * @param {CareMatrixSaveMarkRequest} careMatrixSaveMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixSaveMarkPost: async (
      careMatrixSaveMarkRequest: CareMatrixSaveMarkRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'careMatrixSaveMarkRequest' is not null or undefined
      if (careMatrixSaveMarkRequest === null || careMatrixSaveMarkRequest === undefined) {
        throw new RequiredError(
          "careMatrixSaveMarkRequest",
          "Required parameter careMatrixSaveMarkRequest was null or undefined when calling careMatrixSaveMarkPost.",
        );
      }
      const localVarPath = `/care/matrix/save_mark`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof careMatrixSaveMarkRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(careMatrixSaveMarkRequest !== undefined ? careMatrixSaveMarkRequest : {})
        : careMatrixSaveMarkRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data By Day Route
     * @param {CdDataByDayRequest} cdDataByDayRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataByDayPost: async (cdDataByDayRequest: CdDataByDayRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'cdDataByDayRequest' is not null or undefined
      if (cdDataByDayRequest === null || cdDataByDayRequest === undefined) {
        throw new RequiredError(
          "cdDataByDayRequest",
          "Required parameter cdDataByDayRequest was null or undefined when calling cdDataByDayPost.",
        );
      }
      const localVarPath = `/cd/data/by_day`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataByDayRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataByDayRequest !== undefined ? cdDataByDayRequest : {})
        : cdDataByDayRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Chart Care Access Download Route
     * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCareAccessDownloadPost: async (
      cdDataChartCareAccessRequest: CdDataChartCareAccessRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartCareAccessRequest' is not null or undefined
      if (cdDataChartCareAccessRequest === null || cdDataChartCareAccessRequest === undefined) {
        throw new RequiredError(
          "cdDataChartCareAccessRequest",
          "Required parameter cdDataChartCareAccessRequest was null or undefined when calling cdDataChartCareAccessDownloadPost.",
        );
      }
      const localVarPath = `/cd/data/chart_care_access_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartCareAccessRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartCareAccessRequest !== undefined ? cdDataChartCareAccessRequest : {})
        : cdDataChartCareAccessRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Chart Care Access Route
     * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCareAccessPost: async (
      cdDataChartCareAccessRequest: CdDataChartCareAccessRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartCareAccessRequest' is not null or undefined
      if (cdDataChartCareAccessRequest === null || cdDataChartCareAccessRequest === undefined) {
        throw new RequiredError(
          "cdDataChartCareAccessRequest",
          "Required parameter cdDataChartCareAccessRequest was null or undefined when calling cdDataChartCareAccessPost.",
        );
      }
      const localVarPath = `/cd/data/chart_care_access`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartCareAccessRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartCareAccessRequest !== undefined ? cdDataChartCareAccessRequest : {})
        : cdDataChartCareAccessRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Chart Current Capacity Download Route
     * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCurrentCapacityDownloadPost: async (
      cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartCurrentCapacityRequest' is not null or undefined
      if (cdDataChartCurrentCapacityRequest === null || cdDataChartCurrentCapacityRequest === undefined) {
        throw new RequiredError(
          "cdDataChartCurrentCapacityRequest",
          "Required parameter cdDataChartCurrentCapacityRequest was null or undefined when calling cdDataChartCurrentCapacityDownloadPost.",
        );
      }
      const localVarPath = `/cd/data/chart_current_capacity_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartCurrentCapacityRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartCurrentCapacityRequest !== undefined ? cdDataChartCurrentCapacityRequest : {})
        : cdDataChartCurrentCapacityRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Chart Current Capacity Route
     * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCurrentCapacityPost: async (
      cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartCurrentCapacityRequest' is not null or undefined
      if (cdDataChartCurrentCapacityRequest === null || cdDataChartCurrentCapacityRequest === undefined) {
        throw new RequiredError(
          "cdDataChartCurrentCapacityRequest",
          "Required parameter cdDataChartCurrentCapacityRequest was null or undefined when calling cdDataChartCurrentCapacityPost.",
        );
      }
      const localVarPath = `/cd/data/chart_current_capacity`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartCurrentCapacityRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartCurrentCapacityRequest !== undefined ? cdDataChartCurrentCapacityRequest : {})
        : cdDataChartCurrentCapacityRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Chart Locations Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartLocationsPost: async (
      cdDataChartRequest: CdDataChartRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartRequest' is not null or undefined
      if (cdDataChartRequest === null || cdDataChartRequest === undefined) {
        throw new RequiredError(
          "cdDataChartRequest",
          "Required parameter cdDataChartRequest was null or undefined when calling cdDataChartLocationsPost.",
        );
      }
      const localVarPath = `/cd/data/chart_locations`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartRequest !== undefined ? cdDataChartRequest : {})
        : cdDataChartRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Chart Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartPost: async (cdDataChartRequest: CdDataChartRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartRequest' is not null or undefined
      if (cdDataChartRequest === null || cdDataChartRequest === undefined) {
        throw new RequiredError(
          "cdDataChartRequest",
          "Required parameter cdDataChartRequest was null or undefined when calling cdDataChartPost.",
        );
      }
      const localVarPath = `/cd/data/chart`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartRequest !== undefined ? cdDataChartRequest : {})
        : cdDataChartRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Checkup Route
     * @param {CdDataCheckupRequest} cdDataCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataCheckupPost: async (cdDataCheckupRequest: CdDataCheckupRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'cdDataCheckupRequest' is not null or undefined
      if (cdDataCheckupRequest === null || cdDataCheckupRequest === undefined) {
        throw new RequiredError(
          "cdDataCheckupRequest",
          "Required parameter cdDataCheckupRequest was null or undefined when calling cdDataCheckupPost.",
        );
      }
      const localVarPath = `/cd/data/checkup`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataCheckupRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataCheckupRequest !== undefined ? cdDataCheckupRequest : {})
        : cdDataCheckupRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Download Checkup Route
     * @param {CdDataCheckupRequest} cdDataCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataDownloadCheckupPost: async (
      cdDataCheckupRequest: CdDataCheckupRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataCheckupRequest' is not null or undefined
      if (cdDataCheckupRequest === null || cdDataCheckupRequest === undefined) {
        throw new RequiredError(
          "cdDataCheckupRequest",
          "Required parameter cdDataCheckupRequest was null or undefined when calling cdDataDownloadCheckupPost.",
        );
      }
      const localVarPath = `/cd/data/download_checkup`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataCheckupRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataCheckupRequest !== undefined ? cdDataCheckupRequest : {})
        : cdDataCheckupRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Download Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataDownloadPost: async (cdDataChartRequest: CdDataChartRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'cdDataChartRequest' is not null or undefined
      if (cdDataChartRequest === null || cdDataChartRequest === undefined) {
        throw new RequiredError(
          "cdDataChartRequest",
          "Required parameter cdDataChartRequest was null or undefined when calling cdDataDownloadPost.",
        );
      }
      const localVarPath = `/cd/data/download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataChartRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataChartRequest !== undefined ? cdDataChartRequest : {})
        : cdDataChartRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Exists Route
     * @param {CdDataExistsRequest} cdDataExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataExistsPost: async (cdDataExistsRequest: CdDataExistsRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'cdDataExistsRequest' is not null or undefined
      if (cdDataExistsRequest === null || cdDataExistsRequest === undefined) {
        throw new RequiredError(
          "cdDataExistsRequest",
          "Required parameter cdDataExistsRequest was null or undefined when calling cdDataExistsPost.",
        );
      }
      const localVarPath = `/cd/data/exists`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataExistsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataExistsRequest !== undefined ? cdDataExistsRequest : {})
        : cdDataExistsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cd Data Register Route
     * @param {CdDataRegisterRequest} cdDataRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataRegisterPost: async (
      cdDataRegisterRequest: CdDataRegisterRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cdDataRegisterRequest' is not null or undefined
      if (cdDataRegisterRequest === null || cdDataRegisterRequest === undefined) {
        throw new RequiredError(
          "cdDataRegisterRequest",
          "Required parameter cdDataRegisterRequest was null or undefined when calling cdDataRegisterPost.",
        );
      }
      const localVarPath = `/cd/data/register`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof cdDataRegisterRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cdDataRegisterRequest !== undefined ? cdDataRegisterRequest : {})
        : cdDataRegisterRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Archive Route
     * @param {CrmCompanyArchiveRequest} crmCompanyArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyArchivePost: async (
      crmCompanyArchiveRequest: CrmCompanyArchiveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyArchiveRequest' is not null or undefined
      if (crmCompanyArchiveRequest === null || crmCompanyArchiveRequest === undefined) {
        throw new RequiredError(
          "crmCompanyArchiveRequest",
          "Required parameter crmCompanyArchiveRequest was null or undefined when calling crmCompanyArchivePost.",
        );
      }
      const localVarPath = `/crm/company/archive`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyArchiveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyArchiveRequest !== undefined ? crmCompanyArchiveRequest : {})
        : crmCompanyArchiveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Location Archive Route
     * @param {CrmCompanyLocationArchiveRequest} crmCompanyLocationArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyLocationArchivePost: async (
      crmCompanyLocationArchiveRequest: CrmCompanyLocationArchiveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyLocationArchiveRequest' is not null or undefined
      if (crmCompanyLocationArchiveRequest === null || crmCompanyLocationArchiveRequest === undefined) {
        throw new RequiredError(
          "crmCompanyLocationArchiveRequest",
          "Required parameter crmCompanyLocationArchiveRequest was null or undefined when calling crmCompanyLocationArchivePost.",
        );
      }
      const localVarPath = `/crm/company/location/archive`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyLocationArchiveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyLocationArchiveRequest !== undefined ? crmCompanyLocationArchiveRequest : {})
        : crmCompanyLocationArchiveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Location One Route
     * @param {CrmCompanyLocationOneRequest} crmCompanyLocationOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyLocationOnePost: async (
      crmCompanyLocationOneRequest: CrmCompanyLocationOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyLocationOneRequest' is not null or undefined
      if (crmCompanyLocationOneRequest === null || crmCompanyLocationOneRequest === undefined) {
        throw new RequiredError(
          "crmCompanyLocationOneRequest",
          "Required parameter crmCompanyLocationOneRequest was null or undefined when calling crmCompanyLocationOnePost.",
        );
      }
      const localVarPath = `/crm/company/location/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyLocationOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyLocationOneRequest !== undefined ? crmCompanyLocationOneRequest : {})
        : crmCompanyLocationOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Location Save Route
     * @param {CrmCompanyLocationSaveDto} crmCompanyLocationSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyLocationSavePost: async (
      crmCompanyLocationSaveDto: CrmCompanyLocationSaveDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyLocationSaveDto' is not null or undefined
      if (crmCompanyLocationSaveDto === null || crmCompanyLocationSaveDto === undefined) {
        throw new RequiredError(
          "crmCompanyLocationSaveDto",
          "Required parameter crmCompanyLocationSaveDto was null or undefined when calling crmCompanyLocationSavePost.",
        );
      }
      const localVarPath = `/crm/company/location/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyLocationSaveDto !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyLocationSaveDto !== undefined ? crmCompanyLocationSaveDto : {})
        : crmCompanyLocationSaveDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Many Route
     * @param {CrmCompanyManyRequest} crmCompanyManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyManyPost: async (
      crmCompanyManyRequest: CrmCompanyManyRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyManyRequest' is not null or undefined
      if (crmCompanyManyRequest === null || crmCompanyManyRequest === undefined) {
        throw new RequiredError(
          "crmCompanyManyRequest",
          "Required parameter crmCompanyManyRequest was null or undefined when calling crmCompanyManyPost.",
        );
      }
      const localVarPath = `/crm/company/many`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyManyRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyManyRequest !== undefined ? crmCompanyManyRequest : {})
        : crmCompanyManyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company One Route
     * @param {CrmCompanyOneRequest} crmCompanyOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyOnePost: async (crmCompanyOneRequest: CrmCompanyOneRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyOneRequest' is not null or undefined
      if (crmCompanyOneRequest === null || crmCompanyOneRequest === undefined) {
        throw new RequiredError(
          "crmCompanyOneRequest",
          "Required parameter crmCompanyOneRequest was null or undefined when calling crmCompanyOnePost.",
        );
      }
      const localVarPath = `/crm/company/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyOneRequest !== undefined ? crmCompanyOneRequest : {})
        : crmCompanyOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Save Matrix Route
     * @param {CrmCompanySaveMatrixRequest} crmCompanySaveMatrixRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanySaveMatrixPost: async (
      crmCompanySaveMatrixRequest: CrmCompanySaveMatrixRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanySaveMatrixRequest' is not null or undefined
      if (crmCompanySaveMatrixRequest === null || crmCompanySaveMatrixRequest === undefined) {
        throw new RequiredError(
          "crmCompanySaveMatrixRequest",
          "Required parameter crmCompanySaveMatrixRequest was null or undefined when calling crmCompanySaveMatrixPost.",
        );
      }
      const localVarPath = `/crm/company/save_matrix`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanySaveMatrixRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanySaveMatrixRequest !== undefined ? crmCompanySaveMatrixRequest : {})
        : crmCompanySaveMatrixRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Save Route
     * @param {CrmCompanySaveDto} crmCompanySaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanySavePost: async (crmCompanySaveDto: CrmCompanySaveDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanySaveDto' is not null or undefined
      if (crmCompanySaveDto === null || crmCompanySaveDto === undefined) {
        throw new RequiredError(
          "crmCompanySaveDto",
          "Required parameter crmCompanySaveDto was null or undefined when calling crmCompanySavePost.",
        );
      }
      const localVarPath = `/crm/company/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanySaveDto !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanySaveDto !== undefined ? crmCompanySaveDto : {})
        : crmCompanySaveDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Table Download Route
     * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyTableDownloadPost: async (
      crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyTableRowsRequest' is not null or undefined
      if (crmCompanyTableRowsRequest === null || crmCompanyTableRowsRequest === undefined) {
        throw new RequiredError(
          "crmCompanyTableRowsRequest",
          "Required parameter crmCompanyTableRowsRequest was null or undefined when calling crmCompanyTableDownloadPost.",
        );
      }
      const localVarPath = `/crm/company/table_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyTableRowsRequest !== undefined ? crmCompanyTableRowsRequest : {})
        : crmCompanyTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyTableHeadersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/crm/company/table_headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Company Table Rows Route
     * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyTableRowsPost: async (
      crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmCompanyTableRowsRequest' is not null or undefined
      if (crmCompanyTableRowsRequest === null || crmCompanyTableRowsRequest === undefined) {
        throw new RequiredError(
          "crmCompanyTableRowsRequest",
          "Required parameter crmCompanyTableRowsRequest was null or undefined when calling crmCompanyTableRowsPost.",
        );
      }
      const localVarPath = `/crm/company/table_rows`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmCompanyTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmCompanyTableRowsRequest !== undefined ? crmCompanyTableRowsRequest : {})
        : crmCompanyTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note Archive Route
     * @param {CrmNoteArchiveRequest} crmNoteArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteArchivePost: async (
      crmNoteArchiveRequest: CrmNoteArchiveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteArchiveRequest' is not null or undefined
      if (crmNoteArchiveRequest === null || crmNoteArchiveRequest === undefined) {
        throw new RequiredError(
          "crmNoteArchiveRequest",
          "Required parameter crmNoteArchiveRequest was null or undefined when calling crmNoteArchivePost.",
        );
      }
      const localVarPath = `/crm/note/archive`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteArchiveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteArchiveRequest !== undefined ? crmNoteArchiveRequest : {})
        : crmNoteArchiveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note Autocomplete Company Route
     * @param {CrmNoteAutocompleteCompanyRequest} crmNoteAutocompleteCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteAutocompleteCompanyPost: async (
      crmNoteAutocompleteCompanyRequest: CrmNoteAutocompleteCompanyRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteAutocompleteCompanyRequest' is not null or undefined
      if (crmNoteAutocompleteCompanyRequest === null || crmNoteAutocompleteCompanyRequest === undefined) {
        throw new RequiredError(
          "crmNoteAutocompleteCompanyRequest",
          "Required parameter crmNoteAutocompleteCompanyRequest was null or undefined when calling crmNoteAutocompleteCompanyPost.",
        );
      }
      const localVarPath = `/crm/note/autocomplete_company`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteAutocompleteCompanyRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteAutocompleteCompanyRequest !== undefined ? crmNoteAutocompleteCompanyRequest : {})
        : crmNoteAutocompleteCompanyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note Autocomplete Matrix Row Route
     * @param {CrmNoteAutocompleteMatrixRowRequest} crmNoteAutocompleteMatrixRowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteAutocompleteMatrixRowPost: async (
      crmNoteAutocompleteMatrixRowRequest: CrmNoteAutocompleteMatrixRowRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteAutocompleteMatrixRowRequest' is not null or undefined
      if (crmNoteAutocompleteMatrixRowRequest === null || crmNoteAutocompleteMatrixRowRequest === undefined) {
        throw new RequiredError(
          "crmNoteAutocompleteMatrixRowRequest",
          "Required parameter crmNoteAutocompleteMatrixRowRequest was null or undefined when calling crmNoteAutocompleteMatrixRowPost.",
        );
      }
      const localVarPath = `/crm/note/autocomplete_matrix_row`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteAutocompleteMatrixRowRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteAutocompleteMatrixRowRequest !== undefined ? crmNoteAutocompleteMatrixRowRequest : {})
        : crmNoteAutocompleteMatrixRowRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note Autocomplete Person Route
     * @param {CrmNoteAutocompletePersonRequest} crmNoteAutocompletePersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteAutocompletePersonPost: async (
      crmNoteAutocompletePersonRequest: CrmNoteAutocompletePersonRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteAutocompletePersonRequest' is not null or undefined
      if (crmNoteAutocompletePersonRequest === null || crmNoteAutocompletePersonRequest === undefined) {
        throw new RequiredError(
          "crmNoteAutocompletePersonRequest",
          "Required parameter crmNoteAutocompletePersonRequest was null or undefined when calling crmNoteAutocompletePersonPost.",
        );
      }
      const localVarPath = `/crm/note/autocomplete_person`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteAutocompletePersonRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteAutocompletePersonRequest !== undefined ? crmNoteAutocompletePersonRequest : {})
        : crmNoteAutocompletePersonRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note Many Route
     * @param {CrmNoteManyRequest} crmNoteManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteManyPost: async (crmNoteManyRequest: CrmNoteManyRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteManyRequest' is not null or undefined
      if (crmNoteManyRequest === null || crmNoteManyRequest === undefined) {
        throw new RequiredError(
          "crmNoteManyRequest",
          "Required parameter crmNoteManyRequest was null or undefined when calling crmNoteManyPost.",
        );
      }
      const localVarPath = `/crm/note/many`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteManyRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteManyRequest !== undefined ? crmNoteManyRequest : {})
        : crmNoteManyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note One Route
     * @param {CrmNoteOneRequest} crmNoteOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteOnePost: async (crmNoteOneRequest: CrmNoteOneRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteOneRequest' is not null or undefined
      if (crmNoteOneRequest === null || crmNoteOneRequest === undefined) {
        throw new RequiredError(
          "crmNoteOneRequest",
          "Required parameter crmNoteOneRequest was null or undefined when calling crmNoteOnePost.",
        );
      }
      const localVarPath = `/crm/note/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteOneRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteOneRequest !== undefined ? crmNoteOneRequest : {})
        : crmNoteOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Note Save Route
     * @param {CrmNoteSaveRequest} crmNoteSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteSavePost: async (crmNoteSaveRequest: CrmNoteSaveRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmNoteSaveRequest' is not null or undefined
      if (crmNoteSaveRequest === null || crmNoteSaveRequest === undefined) {
        throw new RequiredError(
          "crmNoteSaveRequest",
          "Required parameter crmNoteSaveRequest was null or undefined when calling crmNoteSavePost.",
        );
      }
      const localVarPath = `/crm/note/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmNoteSaveRequest !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmNoteSaveRequest !== undefined ? crmNoteSaveRequest : {})
        : crmNoteSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Address Municipality Pick Route
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonAddressMunicipalityPickPost: async (body: object, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling crmPersonAddressMunicipalityPickPost.",
        );
      }
      const localVarPath = `/crm/person/address_municipality_pick`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof body !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Archive Route
     * @param {CrmPersonArchiveRequest} crmPersonArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonArchivePost: async (
      crmPersonArchiveRequest: CrmPersonArchiveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonArchiveRequest' is not null or undefined
      if (crmPersonArchiveRequest === null || crmPersonArchiveRequest === undefined) {
        throw new RequiredError(
          "crmPersonArchiveRequest",
          "Required parameter crmPersonArchiveRequest was null or undefined when calling crmPersonArchivePost.",
        );
      }
      const localVarPath = `/crm/person/archive`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonArchiveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonArchiveRequest !== undefined ? crmPersonArchiveRequest : {})
        : crmPersonArchiveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Many Route
     * @param {CrmPersonManyRequest} crmPersonManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonManyPost: async (crmPersonManyRequest: CrmPersonManyRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonManyRequest' is not null or undefined
      if (crmPersonManyRequest === null || crmPersonManyRequest === undefined) {
        throw new RequiredError(
          "crmPersonManyRequest",
          "Required parameter crmPersonManyRequest was null or undefined when calling crmPersonManyPost.",
        );
      }
      const localVarPath = `/crm/person/many`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonManyRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonManyRequest !== undefined ? crmPersonManyRequest : {})
        : crmPersonManyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person One Route
     * @param {TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonOnePost: async (
      tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest' is not null or undefined
      if (
        tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest === null ||
        tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest === undefined
      ) {
        throw new RequiredError(
          "tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest",
          "Required parameter tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest was null or undefined when calling crmPersonOnePost.",
        );
      }
      const localVarPath = `/crm/person/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest !== undefined
              ? tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
              : {},
          )
        : tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Save Route
     * @param {CrmPersonSaveRequest} crmPersonSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSavePost: async (crmPersonSaveRequest: CrmPersonSaveRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonSaveRequest' is not null or undefined
      if (crmPersonSaveRequest === null || crmPersonSaveRequest === undefined) {
        throw new RequiredError(
          "crmPersonSaveRequest",
          "Required parameter crmPersonSaveRequest was null or undefined when calling crmPersonSavePost.",
        );
      }
      const localVarPath = `/crm/person/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonSaveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonSaveRequest !== undefined ? crmPersonSaveRequest : {})
        : crmPersonSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Settings Route
     * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSettingsPost: async (
      crmPersonSettingsRequest: CrmPersonSettingsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonSettingsRequest' is not null or undefined
      if (crmPersonSettingsRequest === null || crmPersonSettingsRequest === undefined) {
        throw new RequiredError(
          "crmPersonSettingsRequest",
          "Required parameter crmPersonSettingsRequest was null or undefined when calling crmPersonSettingsPost.",
        );
      }
      const localVarPath = `/crm/person/settings`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonSettingsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonSettingsRequest !== undefined ? crmPersonSettingsRequest : {})
        : crmPersonSettingsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Table Download Route
     * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTableDownloadPost: async (
      crmPersonTableRowsRequest: CrmPersonTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonTableRowsRequest' is not null or undefined
      if (crmPersonTableRowsRequest === null || crmPersonTableRowsRequest === undefined) {
        throw new RequiredError(
          "crmPersonTableRowsRequest",
          "Required parameter crmPersonTableRowsRequest was null or undefined when calling crmPersonTableDownloadPost.",
        );
      }
      const localVarPath = `/crm/person/table_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonTableRowsRequest !== undefined ? crmPersonTableRowsRequest : {})
        : crmPersonTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTableHeadersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/crm/person/table_headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Table Rows Route
     * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTableRowsPost: async (
      crmPersonTableRowsRequest: CrmPersonTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonTableRowsRequest' is not null or undefined
      if (crmPersonTableRowsRequest === null || crmPersonTableRowsRequest === undefined) {
        throw new RequiredError(
          "crmPersonTableRowsRequest",
          "Required parameter crmPersonTableRowsRequest was null or undefined when calling crmPersonTableRowsPost.",
        );
      }
      const localVarPath = `/crm/person/table_rows`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonTableRowsRequest !== undefined ? crmPersonTableRowsRequest : {})
        : crmPersonTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Tag History Route
     * @param {CrmPersonTagHistoryRequest} crmPersonTagHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTagHistoryPost: async (
      crmPersonTagHistoryRequest: CrmPersonTagHistoryRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'crmPersonTagHistoryRequest' is not null or undefined
      if (crmPersonTagHistoryRequest === null || crmPersonTagHistoryRequest === undefined) {
        throw new RequiredError(
          "crmPersonTagHistoryRequest",
          "Required parameter crmPersonTagHistoryRequest was null or undefined when calling crmPersonTagHistoryPost.",
        );
      }
      const localVarPath = `/crm/person/tag_history`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof crmPersonTagHistoryRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(crmPersonTagHistoryRequest !== undefined ? crmPersonTagHistoryRequest : {})
        : crmPersonTagHistoryRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Takeover Route
     * @param {TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTakeoverPost: async (
      tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest' is not null or undefined
      if (
        tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest === null ||
        tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest === undefined
      ) {
        throw new RequiredError(
          "tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest",
          "Required parameter tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest was null or undefined when calling crmPersonTakeoverPost.",
        );
      }
      const localVarPath = `/crm/person/takeover`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(
            tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest !== undefined
              ? tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
              : {},
          )
        : tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Crm Person Takeover Undo Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTakeoverUndoPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/crm/person/takeover_undo`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Download One Route
     * @param {GenericDownloadOneRequest} genericDownloadOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericDownloadOnePost: async (
      genericDownloadOneRequest: GenericDownloadOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericDownloadOneRequest' is not null or undefined
      if (genericDownloadOneRequest === null || genericDownloadOneRequest === undefined) {
        throw new RequiredError(
          "genericDownloadOneRequest",
          "Required parameter genericDownloadOneRequest was null or undefined when calling genericDownloadOnePost.",
        );
      }
      const localVarPath = `/generic/download/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericDownloadOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericDownloadOneRequest !== undefined ? genericDownloadOneRequest : {})
        : genericDownloadOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Log Many Route
     * @param {GenericLogManyRequest} genericLogManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogManyPost: async (
      genericLogManyRequest: GenericLogManyRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericLogManyRequest' is not null or undefined
      if (genericLogManyRequest === null || genericLogManyRequest === undefined) {
        throw new RequiredError(
          "genericLogManyRequest",
          "Required parameter genericLogManyRequest was null or undefined when calling genericLogManyPost.",
        );
      }
      const localVarPath = `/generic/log/many`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericLogManyRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericLogManyRequest !== undefined ? genericLogManyRequest : {})
        : genericLogManyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Log Session Login Checkup Download Route
     * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogSessionLoginCheckupDownloadPost: async (
      genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericLogSessionLoginCheckupRequest' is not null or undefined
      if (genericLogSessionLoginCheckupRequest === null || genericLogSessionLoginCheckupRequest === undefined) {
        throw new RequiredError(
          "genericLogSessionLoginCheckupRequest",
          "Required parameter genericLogSessionLoginCheckupRequest was null or undefined when calling genericLogSessionLoginCheckupDownloadPost.",
        );
      }
      const localVarPath = `/generic/log/session_login_checkup_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericLogSessionLoginCheckupRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericLogSessionLoginCheckupRequest !== undefined ? genericLogSessionLoginCheckupRequest : {})
        : genericLogSessionLoginCheckupRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Log Session Login Checkup Route
     * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogSessionLoginCheckupPost: async (
      genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericLogSessionLoginCheckupRequest' is not null or undefined
      if (genericLogSessionLoginCheckupRequest === null || genericLogSessionLoginCheckupRequest === undefined) {
        throw new RequiredError(
          "genericLogSessionLoginCheckupRequest",
          "Required parameter genericLogSessionLoginCheckupRequest was null or undefined when calling genericLogSessionLoginCheckupPost.",
        );
      }
      const localVarPath = `/generic/log/session_login_checkup`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericLogSessionLoginCheckupRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericLogSessionLoginCheckupRequest !== undefined ? genericLogSessionLoginCheckupRequest : {})
        : genericLogSessionLoginCheckupRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Log Table Download Route
     * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogTableDownloadPost: async (
      genericLogTableRowsRequest: GenericLogTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericLogTableRowsRequest' is not null or undefined
      if (genericLogTableRowsRequest === null || genericLogTableRowsRequest === undefined) {
        throw new RequiredError(
          "genericLogTableRowsRequest",
          "Required parameter genericLogTableRowsRequest was null or undefined when calling genericLogTableDownloadPost.",
        );
      }
      const localVarPath = `/generic/log/table_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericLogTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericLogTableRowsRequest !== undefined ? genericLogTableRowsRequest : {})
        : genericLogTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Log Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogTableHeadersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/generic/log/table_headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Log Table Rows Route
     * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogTableRowsPost: async (
      genericLogTableRowsRequest: GenericLogTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericLogTableRowsRequest' is not null or undefined
      if (genericLogTableRowsRequest === null || genericLogTableRowsRequest === undefined) {
        throw new RequiredError(
          "genericLogTableRowsRequest",
          "Required parameter genericLogTableRowsRequest was null or undefined when calling genericLogTableRowsPost.",
        );
      }
      const localVarPath = `/generic/log/table_rows`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericLogTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericLogTableRowsRequest !== undefined ? genericLogTableRowsRequest : {})
        : genericLogTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Manual Archive Route
     * @param {GenericManualArchiveRequest} genericManualArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualArchivePost: async (
      genericManualArchiveRequest: GenericManualArchiveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericManualArchiveRequest' is not null or undefined
      if (genericManualArchiveRequest === null || genericManualArchiveRequest === undefined) {
        throw new RequiredError(
          "genericManualArchiveRequest",
          "Required parameter genericManualArchiveRequest was null or undefined when calling genericManualArchivePost.",
        );
      }
      const localVarPath = `/generic/manual/archive`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericManualArchiveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericManualArchiveRequest !== undefined ? genericManualArchiveRequest : {})
        : genericManualArchiveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Manual Many Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualManyPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/generic/manual/many`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Manual One Route
     * @param {GenericManualOneRequest} genericManualOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualOnePost: async (
      genericManualOneRequest: GenericManualOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericManualOneRequest' is not null or undefined
      if (genericManualOneRequest === null || genericManualOneRequest === undefined) {
        throw new RequiredError(
          "genericManualOneRequest",
          "Required parameter genericManualOneRequest was null or undefined when calling genericManualOnePost.",
        );
      }
      const localVarPath = `/generic/manual/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericManualOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericManualOneRequest !== undefined ? genericManualOneRequest : {})
        : genericManualOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Manual Save Route
     * @param {GenericManualSaveRequest} genericManualSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualSavePost: async (
      genericManualSaveRequest: GenericManualSaveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericManualSaveRequest' is not null or undefined
      if (genericManualSaveRequest === null || genericManualSaveRequest === undefined) {
        throw new RequiredError(
          "genericManualSaveRequest",
          "Required parameter genericManualSaveRequest was null or undefined when calling genericManualSavePost.",
        );
      }
      const localVarPath = `/generic/manual/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericManualSaveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericManualSaveRequest !== undefined ? genericManualSaveRequest : {})
        : genericManualSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Resource One Route
     * @param {GenericResourceOneRequest} genericResourceOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericResourceOnePost: async (
      genericResourceOneRequest: GenericResourceOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'genericResourceOneRequest' is not null or undefined
      if (genericResourceOneRequest === null || genericResourceOneRequest === undefined) {
        throw new RequiredError(
          "genericResourceOneRequest",
          "Required parameter genericResourceOneRequest was null or undefined when calling genericResourceOnePost.",
        );
      }
      const localVarPath = `/generic/resource/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof genericResourceOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(genericResourceOneRequest !== undefined ? genericResourceOneRequest : {})
        : genericResourceOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generic Resource Save Route
     * @param {any} upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericResourceSavePost: async (upload: any, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'upload' is not null or undefined
      if (upload === null || upload === undefined) {
        throw new RequiredError(
          "upload",
          "Required parameter upload was null or undefined when calling genericResourceSavePost.",
        );
      }
      const localVarPath = `/generic/resource/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      if (upload !== undefined) {
        localVarFormParams.append("upload", upload as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Column Delete Route
     * @param {MatrixColumnOneRequest} matrixColumnOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixColumnDeletePost: async (
      matrixColumnOneRequest: MatrixColumnOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matrixColumnOneRequest' is not null or undefined
      if (matrixColumnOneRequest === null || matrixColumnOneRequest === undefined) {
        throw new RequiredError(
          "matrixColumnOneRequest",
          "Required parameter matrixColumnOneRequest was null or undefined when calling matrixColumnDeletePost.",
        );
      }
      const localVarPath = `/matrix/column/delete`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixColumnOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixColumnOneRequest !== undefined ? matrixColumnOneRequest : {})
        : matrixColumnOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Column One Route
     * @param {MatrixColumnOneRequest} matrixColumnOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixColumnOnePost: async (
      matrixColumnOneRequest: MatrixColumnOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matrixColumnOneRequest' is not null or undefined
      if (matrixColumnOneRequest === null || matrixColumnOneRequest === undefined) {
        throw new RequiredError(
          "matrixColumnOneRequest",
          "Required parameter matrixColumnOneRequest was null or undefined when calling matrixColumnOnePost.",
        );
      }
      const localVarPath = `/matrix/column/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixColumnOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixColumnOneRequest !== undefined ? matrixColumnOneRequest : {})
        : matrixColumnOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Column Save Route
     * @param {MatrixColumnSaveDto} matrixColumnSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixColumnSavePost: async (matrixColumnSaveDto: MatrixColumnSaveDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'matrixColumnSaveDto' is not null or undefined
      if (matrixColumnSaveDto === null || matrixColumnSaveDto === undefined) {
        throw new RequiredError(
          "matrixColumnSaveDto",
          "Required parameter matrixColumnSaveDto was null or undefined when calling matrixColumnSavePost.",
        );
      }
      const localVarPath = `/matrix/column/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixColumnSaveDto !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixColumnSaveDto !== undefined ? matrixColumnSaveDto : {})
        : matrixColumnSaveDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Core One Route
     * @param {MatrixCoreOneRequest} matrixCoreOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreOnePost: async (matrixCoreOneRequest: MatrixCoreOneRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'matrixCoreOneRequest' is not null or undefined
      if (matrixCoreOneRequest === null || matrixCoreOneRequest === undefined) {
        throw new RequiredError(
          "matrixCoreOneRequest",
          "Required parameter matrixCoreOneRequest was null or undefined when calling matrixCoreOnePost.",
        );
      }
      const localVarPath = `/matrix/core/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixCoreOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixCoreOneRequest !== undefined ? matrixCoreOneRequest : {})
        : matrixCoreOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Core Table Download Route
     * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreTableDownloadPost: async (
      matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matrixCoreTableRowsRequest' is not null or undefined
      if (matrixCoreTableRowsRequest === null || matrixCoreTableRowsRequest === undefined) {
        throw new RequiredError(
          "matrixCoreTableRowsRequest",
          "Required parameter matrixCoreTableRowsRequest was null or undefined when calling matrixCoreTableDownloadPost.",
        );
      }
      const localVarPath = `/matrix/core/table_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixCoreTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixCoreTableRowsRequest !== undefined ? matrixCoreTableRowsRequest : {})
        : matrixCoreTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Core Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreTableHeadersPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/matrix/core/table_headers`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Core Table Rows Route
     * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreTableRowsPost: async (
      matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matrixCoreTableRowsRequest' is not null or undefined
      if (matrixCoreTableRowsRequest === null || matrixCoreTableRowsRequest === undefined) {
        throw new RequiredError(
          "matrixCoreTableRowsRequest",
          "Required parameter matrixCoreTableRowsRequest was null or undefined when calling matrixCoreTableRowsPost.",
        );
      }
      const localVarPath = `/matrix/core/table_rows`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixCoreTableRowsRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixCoreTableRowsRequest !== undefined ? matrixCoreTableRowsRequest : {})
        : matrixCoreTableRowsRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Row Delete Route
     * @param {MatrixRowOneRequest} matrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixRowDeletePost: async (matrixRowOneRequest: MatrixRowOneRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'matrixRowOneRequest' is not null or undefined
      if (matrixRowOneRequest === null || matrixRowOneRequest === undefined) {
        throw new RequiredError(
          "matrixRowOneRequest",
          "Required parameter matrixRowOneRequest was null or undefined when calling matrixRowDeletePost.",
        );
      }
      const localVarPath = `/matrix/row/delete`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixRowOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixRowOneRequest !== undefined ? matrixRowOneRequest : {})
        : matrixRowOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Row One Route
     * @param {MatrixRowOneRequest} matrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixRowOnePost: async (matrixRowOneRequest: MatrixRowOneRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'matrixRowOneRequest' is not null or undefined
      if (matrixRowOneRequest === null || matrixRowOneRequest === undefined) {
        throw new RequiredError(
          "matrixRowOneRequest",
          "Required parameter matrixRowOneRequest was null or undefined when calling matrixRowOnePost.",
        );
      }
      const localVarPath = `/matrix/row/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixRowOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixRowOneRequest !== undefined ? matrixRowOneRequest : {})
        : matrixRowOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Row Save Route
     * @param {MatrixRowSaveRequest} matrixRowSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixRowSavePost: async (matrixRowSaveRequest: MatrixRowSaveRequest, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'matrixRowSaveRequest' is not null or undefined
      if (matrixRowSaveRequest === null || matrixRowSaveRequest === undefined) {
        throw new RequiredError(
          "matrixRowSaveRequest",
          "Required parameter matrixRowSaveRequest was null or undefined when calling matrixRowSavePost.",
        );
      }
      const localVarPath = `/matrix/row/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixRowSaveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixRowSaveRequest !== undefined ? matrixRowSaveRequest : {})
        : matrixRowSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Value One Route
     * @param {MatrixValueOneRequest} matrixValueOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixValueOnePost: async (
      matrixValueOneRequest: MatrixValueOneRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matrixValueOneRequest' is not null or undefined
      if (matrixValueOneRequest === null || matrixValueOneRequest === undefined) {
        throw new RequiredError(
          "matrixValueOneRequest",
          "Required parameter matrixValueOneRequest was null or undefined when calling matrixValueOnePost.",
        );
      }
      const localVarPath = `/matrix/value/one`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixValueOneRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixValueOneRequest !== undefined ? matrixValueOneRequest : {})
        : matrixValueOneRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Matrix Value Save Route
     * @param {MatrixValueSaveRequest} matrixValueSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixValueSavePost: async (
      matrixValueSaveRequest: MatrixValueSaveRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'matrixValueSaveRequest' is not null or undefined
      if (matrixValueSaveRequest === null || matrixValueSaveRequest === undefined) {
        throw new RequiredError(
          "matrixValueSaveRequest",
          "Required parameter matrixValueSaveRequest was null or undefined when calling matrixValueSavePost.",
        );
      }
      const localVarPath = `/matrix/value/save`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof matrixValueSaveRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(matrixValueSaveRequest !== undefined ? matrixValueSaveRequest : {})
        : matrixValueSaveRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Chart Flow Download Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartFlowDownloadPost: async (tsChartDataDto: TsChartDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'tsChartDataDto' is not null or undefined
      if (tsChartDataDto === null || tsChartDataDto === undefined) {
        throw new RequiredError(
          "tsChartDataDto",
          "Required parameter tsChartDataDto was null or undefined when calling tsChartFlowDownloadPost.",
        );
      }
      const localVarPath = `/ts/chart/flow_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsChartDataDto !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsChartDataDto !== undefined ? tsChartDataDto : {})
        : tsChartDataDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Chart Flow Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartFlowPost: async (tsChartDataDto: TsChartDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'tsChartDataDto' is not null or undefined
      if (tsChartDataDto === null || tsChartDataDto === undefined) {
        throw new RequiredError(
          "tsChartDataDto",
          "Required parameter tsChartDataDto was null or undefined when calling tsChartFlowPost.",
        );
      }
      const localVarPath = `/ts/chart/flow`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsChartDataDto !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsChartDataDto !== undefined ? tsChartDataDto : {})
        : tsChartDataDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Chart Matrixes Download Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartMatrixesDownloadPost: async (tsChartDataDto: TsChartDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'tsChartDataDto' is not null or undefined
      if (tsChartDataDto === null || tsChartDataDto === undefined) {
        throw new RequiredError(
          "tsChartDataDto",
          "Required parameter tsChartDataDto was null or undefined when calling tsChartMatrixesDownloadPost.",
        );
      }
      const localVarPath = `/ts/chart/matrixes_download`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsChartDataDto !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsChartDataDto !== undefined ? tsChartDataDto : {})
        : tsChartDataDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Chart Matrixes Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartMatrixesPost: async (tsChartDataDto: TsChartDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'tsChartDataDto' is not null or undefined
      if (tsChartDataDto === null || tsChartDataDto === undefined) {
        throw new RequiredError(
          "tsChartDataDto",
          "Required parameter tsChartDataDto was null or undefined when calling tsChartMatrixesPost.",
        );
      }
      const localVarPath = `/ts/chart/matrixes`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsChartDataDto !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsChartDataDto !== undefined ? tsChartDataDto : {})
        : tsChartDataDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Chart Totals Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartTotalsPost: async (tsChartDataDto: TsChartDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'tsChartDataDto' is not null or undefined
      if (tsChartDataDto === null || tsChartDataDto === undefined) {
        throw new RequiredError(
          "tsChartDataDto",
          "Required parameter tsChartDataDto was null or undefined when calling tsChartTotalsPost.",
        );
      }
      const localVarPath = `/ts/chart/totals`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsChartDataDto !== "string" || localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsChartDataDto !== undefined ? tsChartDataDto : {})
        : tsChartDataDto || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Company Set By Email Route
     * @param {TsCompanySetByEmailRequest} tsCompanySetByEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsCompanySetByEmailPost: async (
      tsCompanySetByEmailRequest: TsCompanySetByEmailRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tsCompanySetByEmailRequest' is not null or undefined
      if (tsCompanySetByEmailRequest === null || tsCompanySetByEmailRequest === undefined) {
        throw new RequiredError(
          "tsCompanySetByEmailRequest",
          "Required parameter tsCompanySetByEmailRequest was null or undefined when calling tsCompanySetByEmailPost.",
        );
      }
      const localVarPath = `/ts/company/set_by_email`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsCompanySetByEmailRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsCompanySetByEmailRequest !== undefined ? tsCompanySetByEmailRequest : {})
        : tsCompanySetByEmailRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Check Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionCheckPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/ts/session/check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Identify Route
     * @param {TsSessionIdentifyRequest} tsSessionIdentifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionIdentifyPost: async (
      tsSessionIdentifyRequest: TsSessionIdentifyRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tsSessionIdentifyRequest' is not null or undefined
      if (tsSessionIdentifyRequest === null || tsSessionIdentifyRequest === undefined) {
        throw new RequiredError(
          "tsSessionIdentifyRequest",
          "Required parameter tsSessionIdentifyRequest was null or undefined when calling tsSessionIdentifyPost.",
        );
      }
      const localVarPath = `/ts/session/identify`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsSessionIdentifyRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsSessionIdentifyRequest !== undefined ? tsSessionIdentifyRequest : {})
        : tsSessionIdentifyRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Login Route
     * @param {TsSessionLoginRequest} tsSessionLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionLoginPost: async (
      tsSessionLoginRequest: TsSessionLoginRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tsSessionLoginRequest' is not null or undefined
      if (tsSessionLoginRequest === null || tsSessionLoginRequest === undefined) {
        throw new RequiredError(
          "tsSessionLoginRequest",
          "Required parameter tsSessionLoginRequest was null or undefined when calling tsSessionLoginPost.",
        );
      }
      const localVarPath = `/ts/session/login`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsSessionLoginRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsSessionLoginRequest !== undefined ? tsSessionLoginRequest : {})
        : tsSessionLoginRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Logout Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionLogoutPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/ts/session/logout`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Reset Password Route
     * @param {TsSessionResetPasswordRequest} tsSessionResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionResetPasswordPost: async (
      tsSessionResetPasswordRequest: TsSessionResetPasswordRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tsSessionResetPasswordRequest' is not null or undefined
      if (tsSessionResetPasswordRequest === null || tsSessionResetPasswordRequest === undefined) {
        throw new RequiredError(
          "tsSessionResetPasswordRequest",
          "Required parameter tsSessionResetPasswordRequest was null or undefined when calling tsSessionResetPasswordPost.",
        );
      }
      const localVarPath = `/ts/session/reset_password`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsSessionResetPasswordRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsSessionResetPasswordRequest !== undefined ? tsSessionResetPasswordRequest : {})
        : tsSessionResetPasswordRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Sotp Generate Route
     * @param {TsSessionSotpGenerateRequest} tsSessionSotpGenerateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionSotpGeneratePost: async (
      tsSessionSotpGenerateRequest: TsSessionSotpGenerateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tsSessionSotpGenerateRequest' is not null or undefined
      if (tsSessionSotpGenerateRequest === null || tsSessionSotpGenerateRequest === undefined) {
        throw new RequiredError(
          "tsSessionSotpGenerateRequest",
          "Required parameter tsSessionSotpGenerateRequest was null or undefined when calling tsSessionSotpGeneratePost.",
        );
      }
      const localVarPath = `/ts/session/sotp_generate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsSessionSotpGenerateRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsSessionSotpGenerateRequest !== undefined ? tsSessionSotpGenerateRequest : {})
        : tsSessionSotpGenerateRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ts Session Sotp Validate Route
     * @param {TsSessionSotpValidateRequest} tsSessionSotpValidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionSotpValidatePost: async (
      tsSessionSotpValidateRequest: TsSessionSotpValidateRequest,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tsSessionSotpValidateRequest' is not null or undefined
      if (tsSessionSotpValidateRequest === null || tsSessionSotpValidateRequest === undefined) {
        throw new RequiredError(
          "tsSessionSotpValidateRequest",
          "Required parameter tsSessionSotpValidateRequest was null or undefined when calling tsSessionSotpValidatePost.",
        );
      }
      const localVarPath = `/ts/session/sotp_validate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication APIKeyHeader required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === "function"
            ? await configuration.apiKey("X-SESSION-TOKEN")
            : await configuration.apiKey;
        localVarHeaderParameter["X-SESSION-TOKEN"] = localVarApiKeyValue;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization =
        typeof tsSessionSotpValidateRequest !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tsSessionSotpValidateRequest !== undefined ? tsSessionSotpValidateRequest : {})
        : tsSessionSotpValidateRequest || "";

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Api Openapi Docs Route
     * @param {string} [sessionToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiDocsGet(
      sessionToken?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiOpenapiDocsGet(
        sessionToken,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Api Web Data Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiWebDataPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiWebDataResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiWebDataPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Api Web Resolve Zipcode Route
     * @param {ApiWebResolveZipcodeRequest} apiWebResolveZipcodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiWebResolveZipcodePost(
      apiWebResolveZipcodeRequest: ApiWebResolveZipcodeRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiWebResolveZipcodeResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiWebResolveZipcodePost(
        apiWebResolveZipcodeRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Api Web Version Route
     * @param {ApiWebVersionRequest} apiWebVersionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiWebVersionPost(
      apiWebVersionRequest: ApiWebVersionRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiWebVersionResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).apiWebVersionPost(
        apiWebVersionRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Download Stream Route
     * @param {string} companySlug
     * @param {string} filename
     * @param {number} id
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appCompanycompanySlugDownloadStreamFilenameGet(
      companySlug: string,
      filename: string,
      id: number,
      token: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration,
      ).appCompanycompanySlugDownloadStreamFilenameGet(companySlug, filename, id, token, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Resource Stream Route
     * @param {string} companySlug
     * @param {number} id
     * @param {string} privateToken
     * @param {string} filename
     * @param {boolean} [download]
     * @param {string} [publicToken]
     * @param {string} [sessionToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet(
      companySlug: string,
      id: number,
      privateToken: string,
      filename: string,
      download?: boolean,
      publicToken?: string,
      sessionToken?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration,
      ).appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet(
        companySlug,
        id,
        privateToken,
        filename,
        download,
        publicToken,
        sessionToken,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Archive Route
     * @param {CareDossierArchiveRequest} careDossierArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierArchivePost(
      careDossierArchiveRequest: CareDossierArchiveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierArchivePost(
        careDossierArchiveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Data History Route
     * @param {CareDossierDataHistoryRequest} careDossierDataHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierDataHistoryPost(
      careDossierDataHistoryRequest: CareDossierDataHistoryRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierDataHistoryResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierDataHistoryPost(
        careDossierDataHistoryRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Data Mark Route
     * @param {CareDossierDataMarkRequest} careDossierDataMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierDataMarkPost(
      careDossierDataMarkRequest: CareDossierDataMarkRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierDataDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierDataMarkPost(
        careDossierDataMarkRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Data One Route
     * @param {CareDossierDataOneRequest} careDossierDataOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierDataOnePost(
      careDossierDataOneRequest: CareDossierDataOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierDataOneResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierDataOnePost(
        careDossierDataOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Data Save Route
     * @param {CareDossierDataSaveRequest} careDossierDataSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierDataSavePost(
      careDossierDataSaveRequest: CareDossierDataSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierDataSavePost(
        careDossierDataSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Many Route
     * @param {CareDossierManyRequest} careDossierManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierManyPost(
      careDossierManyRequest: CareDossierManyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierManyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierManyPost(
        careDossierManyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier One Route
     * @param {CareDossierOneRequest} careDossierOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierOnePost(
      careDossierOneRequest: CareDossierOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierOneResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierOnePost(
        careDossierOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Save Route
     * @param {CareDossierSaveRequest} careDossierSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierSavePost(
      careDossierSaveRequest: CareDossierSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierSaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierSavePost(
        careDossierSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Table Download Route
     * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTableDownloadPost(
      careDossierTableRowsRequest: CareDossierTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTableDownloadPost(
        careDossierTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTableHeadersPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableHeadersResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTableHeadersPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Table Rows Route
     * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTableRowsPost(
      careDossierTableRowsRequest: CareDossierTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTableRowsPost(
        careDossierTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Tag History Route
     * @param {CareDossierTagHistoryRequest} careDossierTagHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTagHistoryPost(
      careDossierTagHistoryRequest: CareDossierTagHistoryRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierTagHistoryResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTagHistoryPost(
        careDossierTagHistoryRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Tag One Route
     * @param {CareDossierTagOneRequest} careDossierTagOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTagOnePost(
      careDossierTagOneRequest: CareDossierTagOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierTagDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTagOnePost(
        careDossierTagOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Tag Save Route
     * @param {CareDossierTagSaveDto} careDossierTagSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTagSavePost(
      careDossierTagSaveDto: CareDossierTagSaveDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierTagSaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTagSavePost(
        careDossierTagSaveDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Amount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTriggerAmountPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierTriggerAmountResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTriggerAmountPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Trigger One Route
     * @param {CareDossierTriggerOneRequest} careDossierTriggerOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTriggerOnePost(
      careDossierTriggerOneRequest: CareDossierTriggerOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierTriggerDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTriggerOnePost(
        careDossierTriggerOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Table Download Route
     * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTriggerTableDownloadPost(
      careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTriggerTableDownloadPost(
        careDossierTriggerTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTriggerTableHeadersPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableHeadersResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTriggerTableHeadersPost(
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Table Rows Route
     * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTriggerTableRowsPost(
      careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTriggerTableRowsPost(
        careDossierTriggerTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Dossier Trigger Toggle Route
     * @param {CareDossierTriggerToggleRequest} careDossierTriggerToggleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careDossierTriggerTogglePost(
      careDossierTriggerToggleRequest: CareDossierTriggerToggleRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareDossierTriggerDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careDossierTriggerTogglePost(
        careDossierTriggerToggleRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix Mark Latest Route
     * @param {CareMatrixMarkLatestRequest} careMatrixMarkLatestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixMarkLatestPost(
      careMatrixMarkLatestRequest: CareMatrixMarkLatestRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareMatrixMarkDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixMarkLatestPost(
        careMatrixMarkLatestRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix One Route
     * @param {CareMatrixOneRequest} careMatrixOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixOnePost(
      careMatrixOneRequest: CareMatrixOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareMatrixDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixOnePost(
        careMatrixOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix Row One Route
     * @param {CareMatrixRowOneRequest} careMatrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixRowOnePost(
      careMatrixRowOneRequest: CareMatrixRowOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CareMatrixRowDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixRowOnePost(
        careMatrixRowOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix Row Save Responsible Route
     * @param {CareMatrixRowSaveResponsibleRequest} careMatrixRowSaveResponsibleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixRowSaveResponsiblePost(
      careMatrixRowSaveResponsibleRequest: CareMatrixRowSaveResponsibleRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixRowSaveResponsiblePost(
        careMatrixRowSaveResponsibleRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix Row Save Target Route
     * @param {CareMatrixRowSaveTargetRequest} careMatrixRowSaveTargetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixRowSaveTargetPost(
      careMatrixRowSaveTargetRequest: CareMatrixRowSaveTargetRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixRowSaveTargetPost(
        careMatrixRowSaveTargetRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix Row Tag Save Route
     * @param {CareMatrixRowTagSaveDto} careMatrixRowTagSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixRowTagSavePost(
      careMatrixRowTagSaveDto: CareMatrixRowTagSaveDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixRowTagSavePost(
        careMatrixRowTagSaveDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Care Matrix Save Mark Route
     * @param {CareMatrixSaveMarkRequest} careMatrixSaveMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async careMatrixSaveMarkPost(
      careMatrixSaveMarkRequest: CareMatrixSaveMarkRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).careMatrixSaveMarkPost(
        careMatrixSaveMarkRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data By Day Route
     * @param {CdDataByDayRequest} cdDataByDayRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataByDayPost(
      cdDataByDayRequest: CdDataByDayRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataRegistryDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataByDayPost(
        cdDataByDayRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Chart Care Access Download Route
     * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataChartCareAccessDownloadPost(
      cdDataChartCareAccessRequest: CdDataChartCareAccessRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataChartCareAccessDownloadPost(
        cdDataChartCareAccessRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Chart Care Access Route
     * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataChartCareAccessPost(
      cdDataChartCareAccessRequest: CdDataChartCareAccessRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataChartCareAccessResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataChartCareAccessPost(
        cdDataChartCareAccessRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Chart Current Capacity Download Route
     * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataChartCurrentCapacityDownloadPost(
      cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataChartCurrentCapacityDownloadPost(
        cdDataChartCurrentCapacityRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Chart Current Capacity Route
     * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataChartCurrentCapacityPost(
      cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataChartCurrentCapacityResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataChartCurrentCapacityPost(
        cdDataChartCurrentCapacityRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Chart Locations Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataChartLocationsPost(
      cdDataChartRequest: CdDataChartRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataChartLocationsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataChartLocationsPost(
        cdDataChartRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Chart Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataChartPost(
      cdDataChartRequest: CdDataChartRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataChartResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataChartPost(
        cdDataChartRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Checkup Route
     * @param {CdDataCheckupRequest} cdDataCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataCheckupPost(
      cdDataCheckupRequest: CdDataCheckupRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataCheckupResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataCheckupPost(
        cdDataCheckupRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Download Checkup Route
     * @param {CdDataCheckupRequest} cdDataCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataDownloadCheckupPost(
      cdDataCheckupRequest: CdDataCheckupRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataDownloadCheckupPost(
        cdDataCheckupRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Download Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataDownloadPost(
      cdDataChartRequest: CdDataChartRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataDownloadPost(
        cdDataChartRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Exists Route
     * @param {CdDataExistsRequest} cdDataExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataExistsPost(
      cdDataExistsRequest: CdDataExistsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataExistsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataExistsPost(
        cdDataExistsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Cd Data Register Route
     * @param {CdDataRegisterRequest} cdDataRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cdDataRegisterPost(
      cdDataRegisterRequest: CdDataRegisterRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CdDataRegisterResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cdDataRegisterPost(
        cdDataRegisterRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Archive Route
     * @param {CrmCompanyArchiveRequest} crmCompanyArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyArchivePost(
      crmCompanyArchiveRequest: CrmCompanyArchiveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyArchivePost(
        crmCompanyArchiveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Location Archive Route
     * @param {CrmCompanyLocationArchiveRequest} crmCompanyLocationArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyLocationArchivePost(
      crmCompanyLocationArchiveRequest: CrmCompanyLocationArchiveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyLocationArchivePost(
        crmCompanyLocationArchiveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Location One Route
     * @param {CrmCompanyLocationOneRequest} crmCompanyLocationOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyLocationOnePost(
      crmCompanyLocationOneRequest: CrmCompanyLocationOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmCompanyLocationDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyLocationOnePost(
        crmCompanyLocationOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Location Save Route
     * @param {CrmCompanyLocationSaveDto} crmCompanyLocationSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyLocationSavePost(
      crmCompanyLocationSaveDto: CrmCompanyLocationSaveDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmCompanyLocationSaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyLocationSavePost(
        crmCompanyLocationSaveDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Many Route
     * @param {CrmCompanyManyRequest} crmCompanyManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyManyPost(
      crmCompanyManyRequest: CrmCompanyManyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmCompanyManyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyManyPost(
        crmCompanyManyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company One Route
     * @param {CrmCompanyOneRequest} crmCompanyOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyOnePost(
      crmCompanyOneRequest: CrmCompanyOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmCompanyDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyOnePost(
        crmCompanyOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Save Matrix Route
     * @param {CrmCompanySaveMatrixRequest} crmCompanySaveMatrixRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanySaveMatrixPost(
      crmCompanySaveMatrixRequest: CrmCompanySaveMatrixRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanySaveMatrixPost(
        crmCompanySaveMatrixRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Save Route
     * @param {CrmCompanySaveDto} crmCompanySaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanySavePost(
      crmCompanySaveDto: CrmCompanySaveDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmCompanySaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanySavePost(
        crmCompanySaveDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Table Download Route
     * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyTableDownloadPost(
      crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyTableDownloadPost(
        crmCompanyTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyTableHeadersPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableHeadersResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyTableHeadersPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Company Table Rows Route
     * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmCompanyTableRowsPost(
      crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmCompanyTableRowsPost(
        crmCompanyTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note Archive Route
     * @param {CrmNoteArchiveRequest} crmNoteArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteArchivePost(
      crmNoteArchiveRequest: CrmNoteArchiveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteArchivePost(
        crmNoteArchiveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note Autocomplete Company Route
     * @param {CrmNoteAutocompleteCompanyRequest} crmNoteAutocompleteCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteAutocompleteCompanyPost(
      crmNoteAutocompleteCompanyRequest: CrmNoteAutocompleteCompanyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmNoteAutocompleteCompanyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteAutocompleteCompanyPost(
        crmNoteAutocompleteCompanyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note Autocomplete Matrix Row Route
     * @param {CrmNoteAutocompleteMatrixRowRequest} crmNoteAutocompleteMatrixRowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteAutocompleteMatrixRowPost(
      crmNoteAutocompleteMatrixRowRequest: CrmNoteAutocompleteMatrixRowRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmNoteAutocompleteMatrixRowResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteAutocompleteMatrixRowPost(
        crmNoteAutocompleteMatrixRowRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note Autocomplete Person Route
     * @param {CrmNoteAutocompletePersonRequest} crmNoteAutocompletePersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteAutocompletePersonPost(
      crmNoteAutocompletePersonRequest: CrmNoteAutocompletePersonRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmNoteAutocompletePersonResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteAutocompletePersonPost(
        crmNoteAutocompletePersonRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note Many Route
     * @param {CrmNoteManyRequest} crmNoteManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteManyPost(
      crmNoteManyRequest: CrmNoteManyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmNoteManyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteManyPost(
        crmNoteManyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note One Route
     * @param {CrmNoteOneRequest} crmNoteOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteOnePost(
      crmNoteOneRequest: CrmNoteOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmNoteDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteOnePost(
        crmNoteOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Note Save Route
     * @param {CrmNoteSaveRequest} crmNoteSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmNoteSavePost(
      crmNoteSaveRequest: CrmNoteSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmNoteDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmNoteSavePost(
        crmNoteSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Address Municipality Pick Route
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonAddressMunicipalityPickPost(
      body: object,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmPersonAddressMunicipalityPickResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonAddressMunicipalityPickPost(
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Archive Route
     * @param {CrmPersonArchiveRequest} crmPersonArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonArchivePost(
      crmPersonArchiveRequest: CrmPersonArchiveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonArchivePost(
        crmPersonArchiveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Many Route
     * @param {CrmPersonManyRequest} crmPersonManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonManyPost(
      crmPersonManyRequest: CrmPersonManyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmPersonManyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonManyPost(
        crmPersonManyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person One Route
     * @param {TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonOnePost(
      tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmPersonDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonOnePost(
        tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Save Route
     * @param {CrmPersonSaveRequest} crmPersonSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonSavePost(
      crmPersonSaveRequest: CrmPersonSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmPersonSaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonSavePost(
        crmPersonSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Settings Route
     * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonSettingsPost(
      crmPersonSettingsRequest: CrmPersonSettingsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonSettingsPost(
        crmPersonSettingsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Table Download Route
     * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonTableDownloadPost(
      crmPersonTableRowsRequest: CrmPersonTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonTableDownloadPost(
        crmPersonTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonTableHeadersPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableHeadersResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonTableHeadersPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Table Rows Route
     * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonTableRowsPost(
      crmPersonTableRowsRequest: CrmPersonTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonTableRowsPost(
        crmPersonTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Tag History Route
     * @param {CrmPersonTagHistoryRequest} crmPersonTagHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonTagHistoryPost(
      crmPersonTagHistoryRequest: CrmPersonTagHistoryRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmPersonTagHistoryResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonTagHistoryPost(
        crmPersonTagHistoryRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Takeover Route
     * @param {TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonTakeoverPost(
      tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionLoginResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonTakeoverPost(
        tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Crm Person Takeover Undo Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async crmPersonTakeoverUndoPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionLoginResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).crmPersonTakeoverUndoPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Download One Route
     * @param {GenericDownloadOneRequest} genericDownloadOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericDownloadOnePost(
      genericDownloadOneRequest: GenericDownloadOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericDownloadOnePost(
        genericDownloadOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Log Many Route
     * @param {GenericLogManyRequest} genericLogManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericLogManyPost(
      genericLogManyRequest: GenericLogManyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericLogManyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericLogManyPost(
        genericLogManyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Log Session Login Checkup Download Route
     * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericLogSessionLoginCheckupDownloadPost(
      genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration,
      ).genericLogSessionLoginCheckupDownloadPost(genericLogSessionLoginCheckupRequest, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Log Session Login Checkup Route
     * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericLogSessionLoginCheckupPost(
      genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericLogSessionLoginCheckupResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericLogSessionLoginCheckupPost(
        genericLogSessionLoginCheckupRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Log Table Download Route
     * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericLogTableDownloadPost(
      genericLogTableRowsRequest: GenericLogTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericLogTableDownloadPost(
        genericLogTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Log Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericLogTableHeadersPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableHeadersResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericLogTableHeadersPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Log Table Rows Route
     * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericLogTableRowsPost(
      genericLogTableRowsRequest: GenericLogTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericLogTableRowsPost(
        genericLogTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Manual Archive Route
     * @param {GenericManualArchiveRequest} genericManualArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericManualArchivePost(
      genericManualArchiveRequest: GenericManualArchiveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericManualArchivePost(
        genericManualArchiveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Manual Many Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericManualManyPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericManualManyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericManualManyPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Manual One Route
     * @param {GenericManualOneRequest} genericManualOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericManualOnePost(
      genericManualOneRequest: GenericManualOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericManualDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericManualOnePost(
        genericManualOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Manual Save Route
     * @param {GenericManualSaveRequest} genericManualSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericManualSavePost(
      genericManualSaveRequest: GenericManualSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericManualDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericManualSavePost(
        genericManualSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Resource One Route
     * @param {GenericResourceOneRequest} genericResourceOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericResourceOnePost(
      genericResourceOneRequest: GenericResourceOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResourceDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericResourceOnePost(
        genericResourceOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Generic Resource Save Route
     * @param {any} upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async genericResourceSavePost(
      upload: any,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResourceSaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).genericResourceSavePost(
        upload,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Column Delete Route
     * @param {MatrixColumnOneRequest} matrixColumnOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixColumnDeletePost(
      matrixColumnOneRequest: MatrixColumnOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixColumnDeletePost(
        matrixColumnOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Column One Route
     * @param {MatrixColumnOneRequest} matrixColumnOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixColumnOnePost(
      matrixColumnOneRequest: MatrixColumnOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixColumnDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixColumnOnePost(
        matrixColumnOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Column Save Route
     * @param {MatrixColumnSaveDto} matrixColumnSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixColumnSavePost(
      matrixColumnSaveDto: MatrixColumnSaveDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixColumnSaveResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixColumnSavePost(
        matrixColumnSaveDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Core One Route
     * @param {MatrixCoreOneRequest} matrixCoreOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixCoreOnePost(
      matrixCoreOneRequest: MatrixCoreOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixCoreDtoExt>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixCoreOnePost(
        matrixCoreOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Core Table Download Route
     * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixCoreTableDownloadPost(
      matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixCoreTableDownloadPost(
        matrixCoreTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Core Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixCoreTableHeadersPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableHeadersResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixCoreTableHeadersPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Core Table Rows Route
     * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixCoreTableRowsPost(
      matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableRowsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixCoreTableRowsPost(
        matrixCoreTableRowsRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Row Delete Route
     * @param {MatrixRowOneRequest} matrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixRowDeletePost(
      matrixRowOneRequest: MatrixRowOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixRowDeletePost(
        matrixRowOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Row One Route
     * @param {MatrixRowOneRequest} matrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixRowOnePost(
      matrixRowOneRequest: MatrixRowOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixRowDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixRowOnePost(
        matrixRowOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Row Save Route
     * @param {MatrixRowSaveRequest} matrixRowSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixRowSavePost(
      matrixRowSaveRequest: MatrixRowSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixRowDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixRowSavePost(
        matrixRowSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Value One Route
     * @param {MatrixValueOneRequest} matrixValueOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixValueOnePost(
      matrixValueOneRequest: MatrixValueOneRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixValueOnePost(
        matrixValueOneRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Matrix Value Save Route
     * @param {MatrixValueSaveRequest} matrixValueSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matrixValueSavePost(
      matrixValueSaveRequest: MatrixValueSaveRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixValueDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).matrixValueSavePost(
        matrixValueSaveRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Chart Flow Download Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsChartFlowDownloadPost(
      tsChartDataDto: TsChartDataDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsChartFlowDownloadPost(
        tsChartDataDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Chart Flow Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsChartFlowPost(
      tsChartDataDto: TsChartDataDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsChartFlowResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsChartFlowPost(
        tsChartDataDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Chart Matrixes Download Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsChartMatrixesDownloadPost(
      tsChartDataDto: TsChartDataDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericDownloadDto>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsChartMatrixesDownloadPost(
        tsChartDataDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Chart Matrixes Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsChartMatrixesPost(
      tsChartDataDto: TsChartDataDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsChartMatrixesResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsChartMatrixesPost(
        tsChartDataDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Chart Totals Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsChartTotalsPost(
      tsChartDataDto: TsChartDataDto,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsChartTotalsResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsChartTotalsPost(
        tsChartDataDto,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Company Set By Email Route
     * @param {TsCompanySetByEmailRequest} tsCompanySetByEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsCompanySetByEmailPost(
      tsCompanySetByEmailRequest: TsCompanySetByEmailRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsCompanySetByEmailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsCompanySetByEmailPost(
        tsCompanySetByEmailRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Check Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionCheckPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionLoginResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionCheckPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Identify Route
     * @param {TsSessionIdentifyRequest} tsSessionIdentifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionIdentifyPost(
      tsSessionIdentifyRequest: TsSessionIdentifyRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionIdentifyResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionIdentifyPost(
        tsSessionIdentifyRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Login Route
     * @param {TsSessionLoginRequest} tsSessionLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionLoginPost(
      tsSessionLoginRequest: TsSessionLoginRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionLoginResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionLoginPost(
        tsSessionLoginRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Logout Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionLogoutPost(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionLogoutPost(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Reset Password Route
     * @param {TsSessionResetPasswordRequest} tsSessionResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionResetPasswordPost(
      tsSessionResetPasswordRequest: TsSessionResetPasswordRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionLoginResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionResetPasswordPost(
        tsSessionResetPasswordRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Sotp Generate Route
     * @param {TsSessionSotpGenerateRequest} tsSessionSotpGenerateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionSotpGeneratePost(
      tsSessionSotpGenerateRequest: TsSessionSotpGenerateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TsSessionSotpGenerateResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionSotpGeneratePost(
        tsSessionSotpGenerateRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Ts Session Sotp Validate Route
     * @param {TsSessionSotpValidateRequest} tsSessionSotpValidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tsSessionSotpValidatePost(
      tsSessionSotpValidateRequest: TsSessionSotpValidateRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailResponse>> {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).tsSessionSotpValidatePost(
        tsSessionSotpValidateRequest,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Api Openapi Docs Route
     * @param {string} [sessionToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDocsGet(sessionToken?: string, options?: any): AxiosPromise<any> {
      return DefaultApiFp(configuration)
        .apiOpenapiDocsGet(sessionToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Api Web Data Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiWebDataPost(options?: any): AxiosPromise<ApiWebDataResponse> {
      return DefaultApiFp(configuration)
        .apiWebDataPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Api Web Resolve Zipcode Route
     * @param {ApiWebResolveZipcodeRequest} apiWebResolveZipcodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiWebResolveZipcodePost(
      apiWebResolveZipcodeRequest: ApiWebResolveZipcodeRequest,
      options?: any,
    ): AxiosPromise<ApiWebResolveZipcodeResponse> {
      return DefaultApiFp(configuration)
        .apiWebResolveZipcodePost(apiWebResolveZipcodeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Api Web Version Route
     * @param {ApiWebVersionRequest} apiWebVersionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiWebVersionPost(apiWebVersionRequest: ApiWebVersionRequest, options?: any): AxiosPromise<ApiWebVersionResponse> {
      return DefaultApiFp(configuration)
        .apiWebVersionPost(apiWebVersionRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Download Stream Route
     * @param {string} companySlug
     * @param {string} filename
     * @param {number} id
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appCompanycompanySlugDownloadStreamFilenameGet(
      companySlug: string,
      filename: string,
      id: number,
      token: string,
      options?: any,
    ): AxiosPromise<any> {
      return DefaultApiFp(configuration)
        .appCompanycompanySlugDownloadStreamFilenameGet(companySlug, filename, id, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Resource Stream Route
     * @param {string} companySlug
     * @param {number} id
     * @param {string} privateToken
     * @param {string} filename
     * @param {boolean} [download]
     * @param {string} [publicToken]
     * @param {string} [sessionToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet(
      companySlug: string,
      id: number,
      privateToken: string,
      filename: string,
      download?: boolean,
      publicToken?: string,
      sessionToken?: string,
      options?: any,
    ): AxiosPromise<any> {
      return DefaultApiFp(configuration)
        .appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet(
          companySlug,
          id,
          privateToken,
          filename,
          download,
          publicToken,
          sessionToken,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Archive Route
     * @param {CareDossierArchiveRequest} careDossierArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierArchivePost(careDossierArchiveRequest: CareDossierArchiveRequest, options?: any): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .careDossierArchivePost(careDossierArchiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Data History Route
     * @param {CareDossierDataHistoryRequest} careDossierDataHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataHistoryPost(
      careDossierDataHistoryRequest: CareDossierDataHistoryRequest,
      options?: any,
    ): AxiosPromise<CareDossierDataHistoryResponse> {
      return DefaultApiFp(configuration)
        .careDossierDataHistoryPost(careDossierDataHistoryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Data Mark Route
     * @param {CareDossierDataMarkRequest} careDossierDataMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataMarkPost(
      careDossierDataMarkRequest: CareDossierDataMarkRequest,
      options?: any,
    ): AxiosPromise<CareDossierDataDto> {
      return DefaultApiFp(configuration)
        .careDossierDataMarkPost(careDossierDataMarkRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Data One Route
     * @param {CareDossierDataOneRequest} careDossierDataOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataOnePost(
      careDossierDataOneRequest: CareDossierDataOneRequest,
      options?: any,
    ): AxiosPromise<CareDossierDataOneResponse> {
      return DefaultApiFp(configuration)
        .careDossierDataOnePost(careDossierDataOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Data Save Route
     * @param {CareDossierDataSaveRequest} careDossierDataSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierDataSavePost(
      careDossierDataSaveRequest: CareDossierDataSaveRequest,
      options?: any,
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .careDossierDataSavePost(careDossierDataSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Many Route
     * @param {CareDossierManyRequest} careDossierManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierManyPost(
      careDossierManyRequest: CareDossierManyRequest,
      options?: any,
    ): AxiosPromise<CareDossierManyResponse> {
      return DefaultApiFp(configuration)
        .careDossierManyPost(careDossierManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier One Route
     * @param {CareDossierOneRequest} careDossierOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierOnePost(
      careDossierOneRequest: CareDossierOneRequest,
      options?: any,
    ): AxiosPromise<CareDossierOneResponse> {
      return DefaultApiFp(configuration)
        .careDossierOnePost(careDossierOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Save Route
     * @param {CareDossierSaveRequest} careDossierSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierSavePost(
      careDossierSaveRequest: CareDossierSaveRequest,
      options?: any,
    ): AxiosPromise<CareDossierSaveResponse> {
      return DefaultApiFp(configuration)
        .careDossierSavePost(careDossierSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Table Download Route
     * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTableDownloadPost(
      careDossierTableRowsRequest: CareDossierTableRowsRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .careDossierTableDownloadPost(careDossierTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTableHeadersPost(options?: any): AxiosPromise<TableHeadersResponse> {
      return DefaultApiFp(configuration)
        .careDossierTableHeadersPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Table Rows Route
     * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTableRowsPost(
      careDossierTableRowsRequest: CareDossierTableRowsRequest,
      options?: any,
    ): AxiosPromise<TableRowsResponse> {
      return DefaultApiFp(configuration)
        .careDossierTableRowsPost(careDossierTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Tag History Route
     * @param {CareDossierTagHistoryRequest} careDossierTagHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTagHistoryPost(
      careDossierTagHistoryRequest: CareDossierTagHistoryRequest,
      options?: any,
    ): AxiosPromise<CareDossierTagHistoryResponse> {
      return DefaultApiFp(configuration)
        .careDossierTagHistoryPost(careDossierTagHistoryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Tag One Route
     * @param {CareDossierTagOneRequest} careDossierTagOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTagOnePost(
      careDossierTagOneRequest: CareDossierTagOneRequest,
      options?: any,
    ): AxiosPromise<CareDossierTagDto> {
      return DefaultApiFp(configuration)
        .careDossierTagOnePost(careDossierTagOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Tag Save Route
     * @param {CareDossierTagSaveDto} careDossierTagSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTagSavePost(
      careDossierTagSaveDto: CareDossierTagSaveDto,
      options?: any,
    ): AxiosPromise<CareDossierTagSaveResponse> {
      return DefaultApiFp(configuration)
        .careDossierTagSavePost(careDossierTagSaveDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Trigger Amount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerAmountPost(options?: any): AxiosPromise<CareDossierTriggerAmountResponse> {
      return DefaultApiFp(configuration)
        .careDossierTriggerAmountPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Trigger One Route
     * @param {CareDossierTriggerOneRequest} careDossierTriggerOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerOnePost(
      careDossierTriggerOneRequest: CareDossierTriggerOneRequest,
      options?: any,
    ): AxiosPromise<CareDossierTriggerDto> {
      return DefaultApiFp(configuration)
        .careDossierTriggerOnePost(careDossierTriggerOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Trigger Table Download Route
     * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTableDownloadPost(
      careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .careDossierTriggerTableDownloadPost(careDossierTriggerTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Trigger Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTableHeadersPost(options?: any): AxiosPromise<TableHeadersResponse> {
      return DefaultApiFp(configuration)
        .careDossierTriggerTableHeadersPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Trigger Table Rows Route
     * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTableRowsPost(
      careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
      options?: any,
    ): AxiosPromise<TableRowsResponse> {
      return DefaultApiFp(configuration)
        .careDossierTriggerTableRowsPost(careDossierTriggerTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Dossier Trigger Toggle Route
     * @param {CareDossierTriggerToggleRequest} careDossierTriggerToggleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careDossierTriggerTogglePost(
      careDossierTriggerToggleRequest: CareDossierTriggerToggleRequest,
      options?: any,
    ): AxiosPromise<CareDossierTriggerDto> {
      return DefaultApiFp(configuration)
        .careDossierTriggerTogglePost(careDossierTriggerToggleRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix Mark Latest Route
     * @param {CareMatrixMarkLatestRequest} careMatrixMarkLatestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixMarkLatestPost(
      careMatrixMarkLatestRequest: CareMatrixMarkLatestRequest,
      options?: any,
    ): AxiosPromise<CareMatrixMarkDto> {
      return DefaultApiFp(configuration)
        .careMatrixMarkLatestPost(careMatrixMarkLatestRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix One Route
     * @param {CareMatrixOneRequest} careMatrixOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixOnePost(careMatrixOneRequest: CareMatrixOneRequest, options?: any): AxiosPromise<CareMatrixDto> {
      return DefaultApiFp(configuration)
        .careMatrixOnePost(careMatrixOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix Row One Route
     * @param {CareMatrixRowOneRequest} careMatrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowOnePost(
      careMatrixRowOneRequest: CareMatrixRowOneRequest,
      options?: any,
    ): AxiosPromise<CareMatrixRowDto> {
      return DefaultApiFp(configuration)
        .careMatrixRowOnePost(careMatrixRowOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix Row Save Responsible Route
     * @param {CareMatrixRowSaveResponsibleRequest} careMatrixRowSaveResponsibleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowSaveResponsiblePost(
      careMatrixRowSaveResponsibleRequest: CareMatrixRowSaveResponsibleRequest,
      options?: any,
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .careMatrixRowSaveResponsiblePost(careMatrixRowSaveResponsibleRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix Row Save Target Route
     * @param {CareMatrixRowSaveTargetRequest} careMatrixRowSaveTargetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowSaveTargetPost(
      careMatrixRowSaveTargetRequest: CareMatrixRowSaveTargetRequest,
      options?: any,
    ): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .careMatrixRowSaveTargetPost(careMatrixRowSaveTargetRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix Row Tag Save Route
     * @param {CareMatrixRowTagSaveDto} careMatrixRowTagSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixRowTagSavePost(
      careMatrixRowTagSaveDto: CareMatrixRowTagSaveDto,
      options?: any,
    ): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .careMatrixRowTagSavePost(careMatrixRowTagSaveDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Care Matrix Save Mark Route
     * @param {CareMatrixSaveMarkRequest} careMatrixSaveMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    careMatrixSaveMarkPost(careMatrixSaveMarkRequest: CareMatrixSaveMarkRequest, options?: any): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .careMatrixSaveMarkPost(careMatrixSaveMarkRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data By Day Route
     * @param {CdDataByDayRequest} cdDataByDayRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataByDayPost(cdDataByDayRequest: CdDataByDayRequest, options?: any): AxiosPromise<CdDataRegistryDto> {
      return DefaultApiFp(configuration)
        .cdDataByDayPost(cdDataByDayRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Chart Care Access Download Route
     * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCareAccessDownloadPost(
      cdDataChartCareAccessRequest: CdDataChartCareAccessRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .cdDataChartCareAccessDownloadPost(cdDataChartCareAccessRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Chart Care Access Route
     * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCareAccessPost(
      cdDataChartCareAccessRequest: CdDataChartCareAccessRequest,
      options?: any,
    ): AxiosPromise<CdDataChartCareAccessResponse> {
      return DefaultApiFp(configuration)
        .cdDataChartCareAccessPost(cdDataChartCareAccessRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Chart Current Capacity Download Route
     * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCurrentCapacityDownloadPost(
      cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .cdDataChartCurrentCapacityDownloadPost(cdDataChartCurrentCapacityRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Chart Current Capacity Route
     * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartCurrentCapacityPost(
      cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
      options?: any,
    ): AxiosPromise<CdDataChartCurrentCapacityResponse> {
      return DefaultApiFp(configuration)
        .cdDataChartCurrentCapacityPost(cdDataChartCurrentCapacityRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Chart Locations Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartLocationsPost(
      cdDataChartRequest: CdDataChartRequest,
      options?: any,
    ): AxiosPromise<CdDataChartLocationsResponse> {
      return DefaultApiFp(configuration)
        .cdDataChartLocationsPost(cdDataChartRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Chart Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataChartPost(cdDataChartRequest: CdDataChartRequest, options?: any): AxiosPromise<CdDataChartResponse> {
      return DefaultApiFp(configuration)
        .cdDataChartPost(cdDataChartRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Checkup Route
     * @param {CdDataCheckupRequest} cdDataCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataCheckupPost(cdDataCheckupRequest: CdDataCheckupRequest, options?: any): AxiosPromise<CdDataCheckupResponse> {
      return DefaultApiFp(configuration)
        .cdDataCheckupPost(cdDataCheckupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Download Checkup Route
     * @param {CdDataCheckupRequest} cdDataCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataDownloadCheckupPost(
      cdDataCheckupRequest: CdDataCheckupRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .cdDataDownloadCheckupPost(cdDataCheckupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Download Route
     * @param {CdDataChartRequest} cdDataChartRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataDownloadPost(cdDataChartRequest: CdDataChartRequest, options?: any): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .cdDataDownloadPost(cdDataChartRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Exists Route
     * @param {CdDataExistsRequest} cdDataExistsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataExistsPost(cdDataExistsRequest: CdDataExistsRequest, options?: any): AxiosPromise<CdDataExistsResponse> {
      return DefaultApiFp(configuration)
        .cdDataExistsPost(cdDataExistsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cd Data Register Route
     * @param {CdDataRegisterRequest} cdDataRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cdDataRegisterPost(
      cdDataRegisterRequest: CdDataRegisterRequest,
      options?: any,
    ): AxiosPromise<CdDataRegisterResponse> {
      return DefaultApiFp(configuration)
        .cdDataRegisterPost(cdDataRegisterRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Archive Route
     * @param {CrmCompanyArchiveRequest} crmCompanyArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyArchivePost(crmCompanyArchiveRequest: CrmCompanyArchiveRequest, options?: any): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .crmCompanyArchivePost(crmCompanyArchiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Location Archive Route
     * @param {CrmCompanyLocationArchiveRequest} crmCompanyLocationArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyLocationArchivePost(
      crmCompanyLocationArchiveRequest: CrmCompanyLocationArchiveRequest,
      options?: any,
    ): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .crmCompanyLocationArchivePost(crmCompanyLocationArchiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Location One Route
     * @param {CrmCompanyLocationOneRequest} crmCompanyLocationOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyLocationOnePost(
      crmCompanyLocationOneRequest: CrmCompanyLocationOneRequest,
      options?: any,
    ): AxiosPromise<CrmCompanyLocationDto> {
      return DefaultApiFp(configuration)
        .crmCompanyLocationOnePost(crmCompanyLocationOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Location Save Route
     * @param {CrmCompanyLocationSaveDto} crmCompanyLocationSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyLocationSavePost(
      crmCompanyLocationSaveDto: CrmCompanyLocationSaveDto,
      options?: any,
    ): AxiosPromise<CrmCompanyLocationSaveResponse> {
      return DefaultApiFp(configuration)
        .crmCompanyLocationSavePost(crmCompanyLocationSaveDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Many Route
     * @param {CrmCompanyManyRequest} crmCompanyManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyManyPost(
      crmCompanyManyRequest: CrmCompanyManyRequest,
      options?: any,
    ): AxiosPromise<CrmCompanyManyResponse> {
      return DefaultApiFp(configuration)
        .crmCompanyManyPost(crmCompanyManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company One Route
     * @param {CrmCompanyOneRequest} crmCompanyOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyOnePost(crmCompanyOneRequest: CrmCompanyOneRequest, options?: any): AxiosPromise<CrmCompanyDto> {
      return DefaultApiFp(configuration)
        .crmCompanyOnePost(crmCompanyOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Save Matrix Route
     * @param {CrmCompanySaveMatrixRequest} crmCompanySaveMatrixRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanySaveMatrixPost(
      crmCompanySaveMatrixRequest: CrmCompanySaveMatrixRequest,
      options?: any,
    ): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .crmCompanySaveMatrixPost(crmCompanySaveMatrixRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Save Route
     * @param {CrmCompanySaveDto} crmCompanySaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanySavePost(crmCompanySaveDto: CrmCompanySaveDto, options?: any): AxiosPromise<CrmCompanySaveResponse> {
      return DefaultApiFp(configuration)
        .crmCompanySavePost(crmCompanySaveDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Table Download Route
     * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyTableDownloadPost(
      crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .crmCompanyTableDownloadPost(crmCompanyTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyTableHeadersPost(options?: any): AxiosPromise<TableHeadersResponse> {
      return DefaultApiFp(configuration)
        .crmCompanyTableHeadersPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Company Table Rows Route
     * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmCompanyTableRowsPost(
      crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest,
      options?: any,
    ): AxiosPromise<TableRowsResponse> {
      return DefaultApiFp(configuration)
        .crmCompanyTableRowsPost(crmCompanyTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note Archive Route
     * @param {CrmNoteArchiveRequest} crmNoteArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteArchivePost(crmNoteArchiveRequest: CrmNoteArchiveRequest, options?: any): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .crmNoteArchivePost(crmNoteArchiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note Autocomplete Company Route
     * @param {CrmNoteAutocompleteCompanyRequest} crmNoteAutocompleteCompanyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteAutocompleteCompanyPost(
      crmNoteAutocompleteCompanyRequest: CrmNoteAutocompleteCompanyRequest,
      options?: any,
    ): AxiosPromise<CrmNoteAutocompleteCompanyResponse> {
      return DefaultApiFp(configuration)
        .crmNoteAutocompleteCompanyPost(crmNoteAutocompleteCompanyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note Autocomplete Matrix Row Route
     * @param {CrmNoteAutocompleteMatrixRowRequest} crmNoteAutocompleteMatrixRowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteAutocompleteMatrixRowPost(
      crmNoteAutocompleteMatrixRowRequest: CrmNoteAutocompleteMatrixRowRequest,
      options?: any,
    ): AxiosPromise<CrmNoteAutocompleteMatrixRowResponse> {
      return DefaultApiFp(configuration)
        .crmNoteAutocompleteMatrixRowPost(crmNoteAutocompleteMatrixRowRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note Autocomplete Person Route
     * @param {CrmNoteAutocompletePersonRequest} crmNoteAutocompletePersonRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteAutocompletePersonPost(
      crmNoteAutocompletePersonRequest: CrmNoteAutocompletePersonRequest,
      options?: any,
    ): AxiosPromise<CrmNoteAutocompletePersonResponse> {
      return DefaultApiFp(configuration)
        .crmNoteAutocompletePersonPost(crmNoteAutocompletePersonRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note Many Route
     * @param {CrmNoteManyRequest} crmNoteManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteManyPost(crmNoteManyRequest: CrmNoteManyRequest, options?: any): AxiosPromise<CrmNoteManyResponse> {
      return DefaultApiFp(configuration)
        .crmNoteManyPost(crmNoteManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note One Route
     * @param {CrmNoteOneRequest} crmNoteOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteOnePost(crmNoteOneRequest: CrmNoteOneRequest, options?: any): AxiosPromise<CrmNoteDto> {
      return DefaultApiFp(configuration)
        .crmNoteOnePost(crmNoteOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Note Save Route
     * @param {CrmNoteSaveRequest} crmNoteSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmNoteSavePost(crmNoteSaveRequest: CrmNoteSaveRequest, options?: any): AxiosPromise<CrmNoteDto> {
      return DefaultApiFp(configuration)
        .crmNoteSavePost(crmNoteSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Address Municipality Pick Route
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonAddressMunicipalityPickPost(
      body: object,
      options?: any,
    ): AxiosPromise<CrmPersonAddressMunicipalityPickResponse> {
      return DefaultApiFp(configuration)
        .crmPersonAddressMunicipalityPickPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Archive Route
     * @param {CrmPersonArchiveRequest} crmPersonArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonArchivePost(crmPersonArchiveRequest: CrmPersonArchiveRequest, options?: any): AxiosPromise<object> {
      return DefaultApiFp(configuration)
        .crmPersonArchivePost(crmPersonArchiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Many Route
     * @param {CrmPersonManyRequest} crmPersonManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonManyPost(crmPersonManyRequest: CrmPersonManyRequest, options?: any): AxiosPromise<CrmPersonManyResponse> {
      return DefaultApiFp(configuration)
        .crmPersonManyPost(crmPersonManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person One Route
     * @param {TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonOnePost(
      tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest,
      options?: any,
    ): AxiosPromise<CrmPersonDto> {
      return DefaultApiFp(configuration)
        .crmPersonOnePost(tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Save Route
     * @param {CrmPersonSaveRequest} crmPersonSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSavePost(crmPersonSaveRequest: CrmPersonSaveRequest, options?: any): AxiosPromise<CrmPersonSaveResponse> {
      return DefaultApiFp(configuration)
        .crmPersonSavePost(crmPersonSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Settings Route
     * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonSettingsPost(
      crmPersonSettingsRequest: CrmPersonSettingsRequest,
      options?: any,
    ): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .crmPersonSettingsPost(crmPersonSettingsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Table Download Route
     * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTableDownloadPost(
      crmPersonTableRowsRequest: CrmPersonTableRowsRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .crmPersonTableDownloadPost(crmPersonTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTableHeadersPost(options?: any): AxiosPromise<TableHeadersResponse> {
      return DefaultApiFp(configuration)
        .crmPersonTableHeadersPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Table Rows Route
     * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTableRowsPost(
      crmPersonTableRowsRequest: CrmPersonTableRowsRequest,
      options?: any,
    ): AxiosPromise<TableRowsResponse> {
      return DefaultApiFp(configuration)
        .crmPersonTableRowsPost(crmPersonTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Tag History Route
     * @param {CrmPersonTagHistoryRequest} crmPersonTagHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTagHistoryPost(
      crmPersonTagHistoryRequest: CrmPersonTagHistoryRequest,
      options?: any,
    ): AxiosPromise<CrmPersonTagHistoryResponse> {
      return DefaultApiFp(configuration)
        .crmPersonTagHistoryPost(crmPersonTagHistoryRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Takeover Route
     * @param {TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTakeoverPost(
      tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest,
      options?: any,
    ): AxiosPromise<TsSessionLoginResponse> {
      return DefaultApiFp(configuration)
        .crmPersonTakeoverPost(tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Crm Person Takeover Undo Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    crmPersonTakeoverUndoPost(options?: any): AxiosPromise<TsSessionLoginResponse> {
      return DefaultApiFp(configuration)
        .crmPersonTakeoverUndoPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Download One Route
     * @param {GenericDownloadOneRequest} genericDownloadOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericDownloadOnePost(
      genericDownloadOneRequest: GenericDownloadOneRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .genericDownloadOnePost(genericDownloadOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Log Many Route
     * @param {GenericLogManyRequest} genericLogManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogManyPost(
      genericLogManyRequest: GenericLogManyRequest,
      options?: any,
    ): AxiosPromise<GenericLogManyResponse> {
      return DefaultApiFp(configuration)
        .genericLogManyPost(genericLogManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Log Session Login Checkup Download Route
     * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogSessionLoginCheckupDownloadPost(
      genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .genericLogSessionLoginCheckupDownloadPost(genericLogSessionLoginCheckupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Log Session Login Checkup Route
     * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogSessionLoginCheckupPost(
      genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
      options?: any,
    ): AxiosPromise<GenericLogSessionLoginCheckupResponse> {
      return DefaultApiFp(configuration)
        .genericLogSessionLoginCheckupPost(genericLogSessionLoginCheckupRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Log Table Download Route
     * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogTableDownloadPost(
      genericLogTableRowsRequest: GenericLogTableRowsRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .genericLogTableDownloadPost(genericLogTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Log Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogTableHeadersPost(options?: any): AxiosPromise<TableHeadersResponse> {
      return DefaultApiFp(configuration)
        .genericLogTableHeadersPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Log Table Rows Route
     * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericLogTableRowsPost(
      genericLogTableRowsRequest: GenericLogTableRowsRequest,
      options?: any,
    ): AxiosPromise<TableRowsResponse> {
      return DefaultApiFp(configuration)
        .genericLogTableRowsPost(genericLogTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Manual Archive Route
     * @param {GenericManualArchiveRequest} genericManualArchiveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualArchivePost(
      genericManualArchiveRequest: GenericManualArchiveRequest,
      options?: any,
    ): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .genericManualArchivePost(genericManualArchiveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Manual Many Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualManyPost(options?: any): AxiosPromise<GenericManualManyResponse> {
      return DefaultApiFp(configuration)
        .genericManualManyPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Manual One Route
     * @param {GenericManualOneRequest} genericManualOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualOnePost(
      genericManualOneRequest: GenericManualOneRequest,
      options?: any,
    ): AxiosPromise<GenericManualDto> {
      return DefaultApiFp(configuration)
        .genericManualOnePost(genericManualOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Manual Save Route
     * @param {GenericManualSaveRequest} genericManualSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericManualSavePost(
      genericManualSaveRequest: GenericManualSaveRequest,
      options?: any,
    ): AxiosPromise<GenericManualDto> {
      return DefaultApiFp(configuration)
        .genericManualSavePost(genericManualSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Resource One Route
     * @param {GenericResourceOneRequest} genericResourceOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericResourceOnePost(
      genericResourceOneRequest: GenericResourceOneRequest,
      options?: any,
    ): AxiosPromise<GenericResourceDto> {
      return DefaultApiFp(configuration)
        .genericResourceOnePost(genericResourceOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generic Resource Save Route
     * @param {any} upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    genericResourceSavePost(upload: any, options?: any): AxiosPromise<GenericResourceSaveResponse> {
      return DefaultApiFp(configuration)
        .genericResourceSavePost(upload, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Column Delete Route
     * @param {MatrixColumnOneRequest} matrixColumnOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixColumnDeletePost(matrixColumnOneRequest: MatrixColumnOneRequest, options?: any): AxiosPromise<boolean> {
      return DefaultApiFp(configuration)
        .matrixColumnDeletePost(matrixColumnOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Column One Route
     * @param {MatrixColumnOneRequest} matrixColumnOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixColumnOnePost(matrixColumnOneRequest: MatrixColumnOneRequest, options?: any): AxiosPromise<MatrixColumnDto> {
      return DefaultApiFp(configuration)
        .matrixColumnOnePost(matrixColumnOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Column Save Route
     * @param {MatrixColumnSaveDto} matrixColumnSaveDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixColumnSavePost(
      matrixColumnSaveDto: MatrixColumnSaveDto,
      options?: any,
    ): AxiosPromise<MatrixColumnSaveResponse> {
      return DefaultApiFp(configuration)
        .matrixColumnSavePost(matrixColumnSaveDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Core One Route
     * @param {MatrixCoreOneRequest} matrixCoreOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreOnePost(matrixCoreOneRequest: MatrixCoreOneRequest, options?: any): AxiosPromise<MatrixCoreDtoExt> {
      return DefaultApiFp(configuration)
        .matrixCoreOnePost(matrixCoreOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Core Table Download Route
     * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreTableDownloadPost(
      matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest,
      options?: any,
    ): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .matrixCoreTableDownloadPost(matrixCoreTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Core Table Headers Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreTableHeadersPost(options?: any): AxiosPromise<TableHeadersResponse> {
      return DefaultApiFp(configuration)
        .matrixCoreTableHeadersPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Core Table Rows Route
     * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixCoreTableRowsPost(
      matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest,
      options?: any,
    ): AxiosPromise<TableRowsResponse> {
      return DefaultApiFp(configuration)
        .matrixCoreTableRowsPost(matrixCoreTableRowsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Row Delete Route
     * @param {MatrixRowOneRequest} matrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixRowDeletePost(matrixRowOneRequest: MatrixRowOneRequest, options?: any): AxiosPromise<boolean> {
      return DefaultApiFp(configuration)
        .matrixRowDeletePost(matrixRowOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Row One Route
     * @param {MatrixRowOneRequest} matrixRowOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixRowOnePost(matrixRowOneRequest: MatrixRowOneRequest, options?: any): AxiosPromise<MatrixRowDto> {
      return DefaultApiFp(configuration)
        .matrixRowOnePost(matrixRowOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Row Save Route
     * @param {MatrixRowSaveRequest} matrixRowSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixRowSavePost(matrixRowSaveRequest: MatrixRowSaveRequest, options?: any): AxiosPromise<MatrixRowDto> {
      return DefaultApiFp(configuration)
        .matrixRowSavePost(matrixRowSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Value One Route
     * @param {MatrixValueOneRequest} matrixValueOneRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixValueOnePost(matrixValueOneRequest: MatrixValueOneRequest, options?: any): AxiosPromise<MatrixValueDto> {
      return DefaultApiFp(configuration)
        .matrixValueOnePost(matrixValueOneRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Matrix Value Save Route
     * @param {MatrixValueSaveRequest} matrixValueSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matrixValueSavePost(matrixValueSaveRequest: MatrixValueSaveRequest, options?: any): AxiosPromise<MatrixValueDto> {
      return DefaultApiFp(configuration)
        .matrixValueSavePost(matrixValueSaveRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Chart Flow Download Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartFlowDownloadPost(tsChartDataDto: TsChartDataDto, options?: any): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .tsChartFlowDownloadPost(tsChartDataDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Chart Flow Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartFlowPost(tsChartDataDto: TsChartDataDto, options?: any): AxiosPromise<TsChartFlowResponse> {
      return DefaultApiFp(configuration)
        .tsChartFlowPost(tsChartDataDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Chart Matrixes Download Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartMatrixesDownloadPost(tsChartDataDto: TsChartDataDto, options?: any): AxiosPromise<GenericDownloadDto> {
      return DefaultApiFp(configuration)
        .tsChartMatrixesDownloadPost(tsChartDataDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Chart Matrixes Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartMatrixesPost(tsChartDataDto: TsChartDataDto, options?: any): AxiosPromise<TsChartMatrixesResponse> {
      return DefaultApiFp(configuration)
        .tsChartMatrixesPost(tsChartDataDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Chart Totals Route
     * @param {TsChartDataDto} tsChartDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsChartTotalsPost(tsChartDataDto: TsChartDataDto, options?: any): AxiosPromise<TsChartTotalsResponse> {
      return DefaultApiFp(configuration)
        .tsChartTotalsPost(tsChartDataDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Company Set By Email Route
     * @param {TsCompanySetByEmailRequest} tsCompanySetByEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsCompanySetByEmailPost(
      tsCompanySetByEmailRequest: TsCompanySetByEmailRequest,
      options?: any,
    ): AxiosPromise<TsCompanySetByEmailResponse> {
      return DefaultApiFp(configuration)
        .tsCompanySetByEmailPost(tsCompanySetByEmailRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Check Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionCheckPost(options?: any): AxiosPromise<TsSessionLoginResponse> {
      return DefaultApiFp(configuration)
        .tsSessionCheckPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Identify Route
     * @param {TsSessionIdentifyRequest} tsSessionIdentifyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionIdentifyPost(
      tsSessionIdentifyRequest: TsSessionIdentifyRequest,
      options?: any,
    ): AxiosPromise<TsSessionIdentifyResponse> {
      return DefaultApiFp(configuration)
        .tsSessionIdentifyPost(tsSessionIdentifyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Login Route
     * @param {TsSessionLoginRequest} tsSessionLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionLoginPost(
      tsSessionLoginRequest: TsSessionLoginRequest,
      options?: any,
    ): AxiosPromise<TsSessionLoginResponse> {
      return DefaultApiFp(configuration)
        .tsSessionLoginPost(tsSessionLoginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Logout Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionLogoutPost(options?: any): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .tsSessionLogoutPost(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Reset Password Route
     * @param {TsSessionResetPasswordRequest} tsSessionResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionResetPasswordPost(
      tsSessionResetPasswordRequest: TsSessionResetPasswordRequest,
      options?: any,
    ): AxiosPromise<TsSessionLoginResponse> {
      return DefaultApiFp(configuration)
        .tsSessionResetPasswordPost(tsSessionResetPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Sotp Generate Route
     * @param {TsSessionSotpGenerateRequest} tsSessionSotpGenerateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionSotpGeneratePost(
      tsSessionSotpGenerateRequest: TsSessionSotpGenerateRequest,
      options?: any,
    ): AxiosPromise<TsSessionSotpGenerateResponse> {
      return DefaultApiFp(configuration)
        .tsSessionSotpGeneratePost(tsSessionSotpGenerateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Ts Session Sotp Validate Route
     * @param {TsSessionSotpValidateRequest} tsSessionSotpValidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tsSessionSotpValidatePost(
      tsSessionSotpValidateRequest: TsSessionSotpValidateRequest,
      options?: any,
    ): AxiosPromise<DetailResponse> {
      return DefaultApiFp(configuration)
        .tsSessionSotpValidatePost(tsSessionSotpValidateRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Api Openapi Docs Route
   * @param {string} [sessionToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiOpenapiDocsGet(sessionToken?: string, options?: any) {
    return DefaultApiFp(this.configuration)
      .apiOpenapiDocsGet(sessionToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Api Web Data Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiWebDataPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .apiWebDataPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Api Web Resolve Zipcode Route
   * @param {ApiWebResolveZipcodeRequest} apiWebResolveZipcodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiWebResolveZipcodePost(apiWebResolveZipcodeRequest: ApiWebResolveZipcodeRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .apiWebResolveZipcodePost(apiWebResolveZipcodeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Api Web Version Route
   * @param {ApiWebVersionRequest} apiWebVersionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public apiWebVersionPost(apiWebVersionRequest: ApiWebVersionRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .apiWebVersionPost(apiWebVersionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Download Stream Route
   * @param {string} companySlug
   * @param {string} filename
   * @param {number} id
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appCompanycompanySlugDownloadStreamFilenameGet(
    companySlug: string,
    filename: string,
    id: number,
    token: string,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .appCompanycompanySlugDownloadStreamFilenameGet(companySlug, filename, id, token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Resource Stream Route
   * @param {string} companySlug
   * @param {number} id
   * @param {string} privateToken
   * @param {string} filename
   * @param {boolean} [download]
   * @param {string} [publicToken]
   * @param {string} [sessionToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet(
    companySlug: string,
    id: number,
    privateToken: string,
    filename: string,
    download?: boolean,
    publicToken?: string,
    sessionToken?: string,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .appCompanycompanySlugResourceStreamIdPrivateTokenFilenameGet(
        companySlug,
        id,
        privateToken,
        filename,
        download,
        publicToken,
        sessionToken,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Archive Route
   * @param {CareDossierArchiveRequest} careDossierArchiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierArchivePost(careDossierArchiveRequest: CareDossierArchiveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierArchivePost(careDossierArchiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Data History Route
   * @param {CareDossierDataHistoryRequest} careDossierDataHistoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierDataHistoryPost(careDossierDataHistoryRequest: CareDossierDataHistoryRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierDataHistoryPost(careDossierDataHistoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Data Mark Route
   * @param {CareDossierDataMarkRequest} careDossierDataMarkRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierDataMarkPost(careDossierDataMarkRequest: CareDossierDataMarkRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierDataMarkPost(careDossierDataMarkRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Data One Route
   * @param {CareDossierDataOneRequest} careDossierDataOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierDataOnePost(careDossierDataOneRequest: CareDossierDataOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierDataOnePost(careDossierDataOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Data Save Route
   * @param {CareDossierDataSaveRequest} careDossierDataSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierDataSavePost(careDossierDataSaveRequest: CareDossierDataSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierDataSavePost(careDossierDataSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Many Route
   * @param {CareDossierManyRequest} careDossierManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierManyPost(careDossierManyRequest: CareDossierManyRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierManyPost(careDossierManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier One Route
   * @param {CareDossierOneRequest} careDossierOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierOnePost(careDossierOneRequest: CareDossierOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierOnePost(careDossierOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Save Route
   * @param {CareDossierSaveRequest} careDossierSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierSavePost(careDossierSaveRequest: CareDossierSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierSavePost(careDossierSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Table Download Route
   * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTableDownloadPost(careDossierTableRowsRequest: CareDossierTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTableDownloadPost(careDossierTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Table Headers Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTableHeadersPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTableHeadersPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Table Rows Route
   * @param {CareDossierTableRowsRequest} careDossierTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTableRowsPost(careDossierTableRowsRequest: CareDossierTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTableRowsPost(careDossierTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Tag History Route
   * @param {CareDossierTagHistoryRequest} careDossierTagHistoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTagHistoryPost(careDossierTagHistoryRequest: CareDossierTagHistoryRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTagHistoryPost(careDossierTagHistoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Tag One Route
   * @param {CareDossierTagOneRequest} careDossierTagOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTagOnePost(careDossierTagOneRequest: CareDossierTagOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTagOnePost(careDossierTagOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Tag Save Route
   * @param {CareDossierTagSaveDto} careDossierTagSaveDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTagSavePost(careDossierTagSaveDto: CareDossierTagSaveDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTagSavePost(careDossierTagSaveDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Trigger Amount
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTriggerAmountPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTriggerAmountPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Trigger One Route
   * @param {CareDossierTriggerOneRequest} careDossierTriggerOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTriggerOnePost(careDossierTriggerOneRequest: CareDossierTriggerOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTriggerOnePost(careDossierTriggerOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Trigger Table Download Route
   * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTriggerTableDownloadPost(
    careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .careDossierTriggerTableDownloadPost(careDossierTriggerTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Trigger Table Headers Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTriggerTableHeadersPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTriggerTableHeadersPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Trigger Table Rows Route
   * @param {CareDossierTriggerTableRowsRequest} careDossierTriggerTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTriggerTableRowsPost(
    careDossierTriggerTableRowsRequest: CareDossierTriggerTableRowsRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .careDossierTriggerTableRowsPost(careDossierTriggerTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Dossier Trigger Toggle Route
   * @param {CareDossierTriggerToggleRequest} careDossierTriggerToggleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careDossierTriggerTogglePost(careDossierTriggerToggleRequest: CareDossierTriggerToggleRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careDossierTriggerTogglePost(careDossierTriggerToggleRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix Mark Latest Route
   * @param {CareMatrixMarkLatestRequest} careMatrixMarkLatestRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixMarkLatestPost(careMatrixMarkLatestRequest: CareMatrixMarkLatestRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careMatrixMarkLatestPost(careMatrixMarkLatestRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix One Route
   * @param {CareMatrixOneRequest} careMatrixOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixOnePost(careMatrixOneRequest: CareMatrixOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careMatrixOnePost(careMatrixOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix Row One Route
   * @param {CareMatrixRowOneRequest} careMatrixRowOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixRowOnePost(careMatrixRowOneRequest: CareMatrixRowOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careMatrixRowOnePost(careMatrixRowOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix Row Save Responsible Route
   * @param {CareMatrixRowSaveResponsibleRequest} careMatrixRowSaveResponsibleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixRowSaveResponsiblePost(
    careMatrixRowSaveResponsibleRequest: CareMatrixRowSaveResponsibleRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .careMatrixRowSaveResponsiblePost(careMatrixRowSaveResponsibleRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix Row Save Target Route
   * @param {CareMatrixRowSaveTargetRequest} careMatrixRowSaveTargetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixRowSaveTargetPost(careMatrixRowSaveTargetRequest: CareMatrixRowSaveTargetRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careMatrixRowSaveTargetPost(careMatrixRowSaveTargetRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix Row Tag Save Route
   * @param {CareMatrixRowTagSaveDto} careMatrixRowTagSaveDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixRowTagSavePost(careMatrixRowTagSaveDto: CareMatrixRowTagSaveDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .careMatrixRowTagSavePost(careMatrixRowTagSaveDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Care Matrix Save Mark Route
   * @param {CareMatrixSaveMarkRequest} careMatrixSaveMarkRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public careMatrixSaveMarkPost(careMatrixSaveMarkRequest: CareMatrixSaveMarkRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .careMatrixSaveMarkPost(careMatrixSaveMarkRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data By Day Route
   * @param {CdDataByDayRequest} cdDataByDayRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataByDayPost(cdDataByDayRequest: CdDataByDayRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataByDayPost(cdDataByDayRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Chart Care Access Download Route
   * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataChartCareAccessDownloadPost(cdDataChartCareAccessRequest: CdDataChartCareAccessRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataChartCareAccessDownloadPost(cdDataChartCareAccessRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Chart Care Access Route
   * @param {CdDataChartCareAccessRequest} cdDataChartCareAccessRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataChartCareAccessPost(cdDataChartCareAccessRequest: CdDataChartCareAccessRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataChartCareAccessPost(cdDataChartCareAccessRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Chart Current Capacity Download Route
   * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataChartCurrentCapacityDownloadPost(
    cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .cdDataChartCurrentCapacityDownloadPost(cdDataChartCurrentCapacityRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Chart Current Capacity Route
   * @param {CdDataChartCurrentCapacityRequest} cdDataChartCurrentCapacityRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataChartCurrentCapacityPost(
    cdDataChartCurrentCapacityRequest: CdDataChartCurrentCapacityRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .cdDataChartCurrentCapacityPost(cdDataChartCurrentCapacityRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Chart Locations Route
   * @param {CdDataChartRequest} cdDataChartRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataChartLocationsPost(cdDataChartRequest: CdDataChartRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataChartLocationsPost(cdDataChartRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Chart Route
   * @param {CdDataChartRequest} cdDataChartRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataChartPost(cdDataChartRequest: CdDataChartRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataChartPost(cdDataChartRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Checkup Route
   * @param {CdDataCheckupRequest} cdDataCheckupRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataCheckupPost(cdDataCheckupRequest: CdDataCheckupRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataCheckupPost(cdDataCheckupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Download Checkup Route
   * @param {CdDataCheckupRequest} cdDataCheckupRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataDownloadCheckupPost(cdDataCheckupRequest: CdDataCheckupRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataDownloadCheckupPost(cdDataCheckupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Download Route
   * @param {CdDataChartRequest} cdDataChartRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataDownloadPost(cdDataChartRequest: CdDataChartRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataDownloadPost(cdDataChartRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Exists Route
   * @param {CdDataExistsRequest} cdDataExistsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataExistsPost(cdDataExistsRequest: CdDataExistsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataExistsPost(cdDataExistsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cd Data Register Route
   * @param {CdDataRegisterRequest} cdDataRegisterRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cdDataRegisterPost(cdDataRegisterRequest: CdDataRegisterRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .cdDataRegisterPost(cdDataRegisterRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Archive Route
   * @param {CrmCompanyArchiveRequest} crmCompanyArchiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyArchivePost(crmCompanyArchiveRequest: CrmCompanyArchiveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyArchivePost(crmCompanyArchiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Location Archive Route
   * @param {CrmCompanyLocationArchiveRequest} crmCompanyLocationArchiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyLocationArchivePost(
    crmCompanyLocationArchiveRequest: CrmCompanyLocationArchiveRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .crmCompanyLocationArchivePost(crmCompanyLocationArchiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Location One Route
   * @param {CrmCompanyLocationOneRequest} crmCompanyLocationOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyLocationOnePost(crmCompanyLocationOneRequest: CrmCompanyLocationOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyLocationOnePost(crmCompanyLocationOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Location Save Route
   * @param {CrmCompanyLocationSaveDto} crmCompanyLocationSaveDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyLocationSavePost(crmCompanyLocationSaveDto: CrmCompanyLocationSaveDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyLocationSavePost(crmCompanyLocationSaveDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Many Route
   * @param {CrmCompanyManyRequest} crmCompanyManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyManyPost(crmCompanyManyRequest: CrmCompanyManyRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyManyPost(crmCompanyManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company One Route
   * @param {CrmCompanyOneRequest} crmCompanyOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyOnePost(crmCompanyOneRequest: CrmCompanyOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyOnePost(crmCompanyOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Save Matrix Route
   * @param {CrmCompanySaveMatrixRequest} crmCompanySaveMatrixRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanySaveMatrixPost(crmCompanySaveMatrixRequest: CrmCompanySaveMatrixRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanySaveMatrixPost(crmCompanySaveMatrixRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Save Route
   * @param {CrmCompanySaveDto} crmCompanySaveDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanySavePost(crmCompanySaveDto: CrmCompanySaveDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanySavePost(crmCompanySaveDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Table Download Route
   * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyTableDownloadPost(crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyTableDownloadPost(crmCompanyTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Table Headers Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyTableHeadersPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyTableHeadersPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Company Table Rows Route
   * @param {CrmCompanyTableRowsRequest} crmCompanyTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmCompanyTableRowsPost(crmCompanyTableRowsRequest: CrmCompanyTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmCompanyTableRowsPost(crmCompanyTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note Archive Route
   * @param {CrmNoteArchiveRequest} crmNoteArchiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteArchivePost(crmNoteArchiveRequest: CrmNoteArchiveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmNoteArchivePost(crmNoteArchiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note Autocomplete Company Route
   * @param {CrmNoteAutocompleteCompanyRequest} crmNoteAutocompleteCompanyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteAutocompleteCompanyPost(
    crmNoteAutocompleteCompanyRequest: CrmNoteAutocompleteCompanyRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .crmNoteAutocompleteCompanyPost(crmNoteAutocompleteCompanyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note Autocomplete Matrix Row Route
   * @param {CrmNoteAutocompleteMatrixRowRequest} crmNoteAutocompleteMatrixRowRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteAutocompleteMatrixRowPost(
    crmNoteAutocompleteMatrixRowRequest: CrmNoteAutocompleteMatrixRowRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .crmNoteAutocompleteMatrixRowPost(crmNoteAutocompleteMatrixRowRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note Autocomplete Person Route
   * @param {CrmNoteAutocompletePersonRequest} crmNoteAutocompletePersonRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteAutocompletePersonPost(
    crmNoteAutocompletePersonRequest: CrmNoteAutocompletePersonRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .crmNoteAutocompletePersonPost(crmNoteAutocompletePersonRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note Many Route
   * @param {CrmNoteManyRequest} crmNoteManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteManyPost(crmNoteManyRequest: CrmNoteManyRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmNoteManyPost(crmNoteManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note One Route
   * @param {CrmNoteOneRequest} crmNoteOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteOnePost(crmNoteOneRequest: CrmNoteOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmNoteOnePost(crmNoteOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Note Save Route
   * @param {CrmNoteSaveRequest} crmNoteSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmNoteSavePost(crmNoteSaveRequest: CrmNoteSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmNoteSavePost(crmNoteSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Address Municipality Pick Route
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonAddressMunicipalityPickPost(body: object, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonAddressMunicipalityPickPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Archive Route
   * @param {CrmPersonArchiveRequest} crmPersonArchiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonArchivePost(crmPersonArchiveRequest: CrmPersonArchiveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonArchivePost(crmPersonArchiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Many Route
   * @param {CrmPersonManyRequest} crmPersonManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonManyPost(crmPersonManyRequest: CrmPersonManyRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonManyPost(crmPersonManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person One Route
   * @param {TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonOnePost(
    tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonOnePost(tagscoreApiAppsCrmPersonCrmPersonOneCrmPersonOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Save Route
   * @param {CrmPersonSaveRequest} crmPersonSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonSavePost(crmPersonSaveRequest: CrmPersonSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonSavePost(crmPersonSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Settings Route
   * @param {CrmPersonSettingsRequest} crmPersonSettingsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonSettingsPost(crmPersonSettingsRequest: CrmPersonSettingsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonSettingsPost(crmPersonSettingsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Table Download Route
   * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonTableDownloadPost(crmPersonTableRowsRequest: CrmPersonTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonTableDownloadPost(crmPersonTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Table Headers Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonTableHeadersPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonTableHeadersPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Table Rows Route
   * @param {CrmPersonTableRowsRequest} crmPersonTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonTableRowsPost(crmPersonTableRowsRequest: CrmPersonTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonTableRowsPost(crmPersonTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Tag History Route
   * @param {CrmPersonTagHistoryRequest} crmPersonTagHistoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonTagHistoryPost(crmPersonTagHistoryRequest: CrmPersonTagHistoryRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonTagHistoryPost(crmPersonTagHistoryRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Takeover Route
   * @param {TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest} tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonTakeoverPost(
    tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest: TagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .crmPersonTakeoverPost(tagscoreApiAppsCrmPersonCrmPersonTakeoverCrmPersonOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Crm Person Takeover Undo Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public crmPersonTakeoverUndoPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .crmPersonTakeoverUndoPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Download One Route
   * @param {GenericDownloadOneRequest} genericDownloadOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericDownloadOnePost(genericDownloadOneRequest: GenericDownloadOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericDownloadOnePost(genericDownloadOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Log Many Route
   * @param {GenericLogManyRequest} genericLogManyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericLogManyPost(genericLogManyRequest: GenericLogManyRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericLogManyPost(genericLogManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Log Session Login Checkup Download Route
   * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericLogSessionLoginCheckupDownloadPost(
    genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .genericLogSessionLoginCheckupDownloadPost(genericLogSessionLoginCheckupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Log Session Login Checkup Route
   * @param {GenericLogSessionLoginCheckupRequest} genericLogSessionLoginCheckupRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericLogSessionLoginCheckupPost(
    genericLogSessionLoginCheckupRequest: GenericLogSessionLoginCheckupRequest,
    options?: any,
  ) {
    return DefaultApiFp(this.configuration)
      .genericLogSessionLoginCheckupPost(genericLogSessionLoginCheckupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Log Table Download Route
   * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericLogTableDownloadPost(genericLogTableRowsRequest: GenericLogTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericLogTableDownloadPost(genericLogTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Log Table Headers Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericLogTableHeadersPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .genericLogTableHeadersPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Log Table Rows Route
   * @param {GenericLogTableRowsRequest} genericLogTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericLogTableRowsPost(genericLogTableRowsRequest: GenericLogTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericLogTableRowsPost(genericLogTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Manual Archive Route
   * @param {GenericManualArchiveRequest} genericManualArchiveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericManualArchivePost(genericManualArchiveRequest: GenericManualArchiveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericManualArchivePost(genericManualArchiveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Manual Many Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericManualManyPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .genericManualManyPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Manual One Route
   * @param {GenericManualOneRequest} genericManualOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericManualOnePost(genericManualOneRequest: GenericManualOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericManualOnePost(genericManualOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Manual Save Route
   * @param {GenericManualSaveRequest} genericManualSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericManualSavePost(genericManualSaveRequest: GenericManualSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericManualSavePost(genericManualSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Resource One Route
   * @param {GenericResourceOneRequest} genericResourceOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericResourceOnePost(genericResourceOneRequest: GenericResourceOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericResourceOnePost(genericResourceOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generic Resource Save Route
   * @param {any} upload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public genericResourceSavePost(upload: any, options?: any) {
    return DefaultApiFp(this.configuration)
      .genericResourceSavePost(upload, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Column Delete Route
   * @param {MatrixColumnOneRequest} matrixColumnOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixColumnDeletePost(matrixColumnOneRequest: MatrixColumnOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixColumnDeletePost(matrixColumnOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Column One Route
   * @param {MatrixColumnOneRequest} matrixColumnOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixColumnOnePost(matrixColumnOneRequest: MatrixColumnOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixColumnOnePost(matrixColumnOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Column Save Route
   * @param {MatrixColumnSaveDto} matrixColumnSaveDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixColumnSavePost(matrixColumnSaveDto: MatrixColumnSaveDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixColumnSavePost(matrixColumnSaveDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Core One Route
   * @param {MatrixCoreOneRequest} matrixCoreOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixCoreOnePost(matrixCoreOneRequest: MatrixCoreOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixCoreOnePost(matrixCoreOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Core Table Download Route
   * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixCoreTableDownloadPost(matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixCoreTableDownloadPost(matrixCoreTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Core Table Headers Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixCoreTableHeadersPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixCoreTableHeadersPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Core Table Rows Route
   * @param {MatrixCoreTableRowsRequest} matrixCoreTableRowsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixCoreTableRowsPost(matrixCoreTableRowsRequest: MatrixCoreTableRowsRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixCoreTableRowsPost(matrixCoreTableRowsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Row Delete Route
   * @param {MatrixRowOneRequest} matrixRowOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixRowDeletePost(matrixRowOneRequest: MatrixRowOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixRowDeletePost(matrixRowOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Row One Route
   * @param {MatrixRowOneRequest} matrixRowOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixRowOnePost(matrixRowOneRequest: MatrixRowOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixRowOnePost(matrixRowOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Row Save Route
   * @param {MatrixRowSaveRequest} matrixRowSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixRowSavePost(matrixRowSaveRequest: MatrixRowSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixRowSavePost(matrixRowSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Value One Route
   * @param {MatrixValueOneRequest} matrixValueOneRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixValueOnePost(matrixValueOneRequest: MatrixValueOneRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixValueOnePost(matrixValueOneRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Matrix Value Save Route
   * @param {MatrixValueSaveRequest} matrixValueSaveRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public matrixValueSavePost(matrixValueSaveRequest: MatrixValueSaveRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .matrixValueSavePost(matrixValueSaveRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Chart Flow Download Route
   * @param {TsChartDataDto} tsChartDataDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsChartFlowDownloadPost(tsChartDataDto: TsChartDataDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsChartFlowDownloadPost(tsChartDataDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Chart Flow Route
   * @param {TsChartDataDto} tsChartDataDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsChartFlowPost(tsChartDataDto: TsChartDataDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsChartFlowPost(tsChartDataDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Chart Matrixes Download Route
   * @param {TsChartDataDto} tsChartDataDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsChartMatrixesDownloadPost(tsChartDataDto: TsChartDataDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsChartMatrixesDownloadPost(tsChartDataDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Chart Matrixes Route
   * @param {TsChartDataDto} tsChartDataDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsChartMatrixesPost(tsChartDataDto: TsChartDataDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsChartMatrixesPost(tsChartDataDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Chart Totals Route
   * @param {TsChartDataDto} tsChartDataDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsChartTotalsPost(tsChartDataDto: TsChartDataDto, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsChartTotalsPost(tsChartDataDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Company Set By Email Route
   * @param {TsCompanySetByEmailRequest} tsCompanySetByEmailRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsCompanySetByEmailPost(tsCompanySetByEmailRequest: TsCompanySetByEmailRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsCompanySetByEmailPost(tsCompanySetByEmailRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Check Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionCheckPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionCheckPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Identify Route
   * @param {TsSessionIdentifyRequest} tsSessionIdentifyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionIdentifyPost(tsSessionIdentifyRequest: TsSessionIdentifyRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionIdentifyPost(tsSessionIdentifyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Login Route
   * @param {TsSessionLoginRequest} tsSessionLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionLoginPost(tsSessionLoginRequest: TsSessionLoginRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionLoginPost(tsSessionLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Logout Route
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionLogoutPost(options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionLogoutPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Reset Password Route
   * @param {TsSessionResetPasswordRequest} tsSessionResetPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionResetPasswordPost(tsSessionResetPasswordRequest: TsSessionResetPasswordRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionResetPasswordPost(tsSessionResetPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Sotp Generate Route
   * @param {TsSessionSotpGenerateRequest} tsSessionSotpGenerateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionSotpGeneratePost(tsSessionSotpGenerateRequest: TsSessionSotpGenerateRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionSotpGeneratePost(tsSessionSotpGenerateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ts Session Sotp Validate Route
   * @param {TsSessionSotpValidateRequest} tsSessionSotpValidateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public tsSessionSotpValidatePost(tsSessionSotpValidateRequest: TsSessionSotpValidateRequest, options?: any) {
    return DefaultApiFp(this.configuration)
      .tsSessionSotpValidatePost(tsSessionSotpValidateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
