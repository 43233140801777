import { BehaviorSubject } from "rxjs";
import React, { useMemo } from "react";
import produce from "immer";

type RxjsNextCallback<RxjsState> = (callback: (state: RxjsState) => void) => void;
type RxjsGetStateCallback<RxjsState> = () => RxjsState;

type RxjsStore<RxjsState> = {
  state: RxjsState;
  getState: RxjsGetStateCallback<RxjsState>;
  next: RxjsNextCallback<RxjsState>;
};

function useRxjsStore<RxjsState>(initial: RxjsState): RxjsStore<RxjsState> {
  const subject = new BehaviorSubject(initial);
  const [state, setState] = React.useState<RxjsState>(initial);
  const windowStorageKey = (initial as any).__window_storage;

  React.useLayoutEffect(() => {
    subject.subscribe((s) => setState(s));

    if (windowStorageKey) {
      subject.subscribe((s) => {
        (window as any)[windowStorageKey] = s;
      });
    }

    return () => subject.unsubscribe();
  }, []);

  const getState = React.useCallback((): RxjsState => {
    return subject.getValue();
  }, []);

  const next = React.useCallback((callback: (state: RxjsState) => void) => {
    subject.next(
      produce(getState(), (s: RxjsState) => {
        callback(s);
      }),
    );
  }, []);

  return {
    state,
    getState,
    next,
  };
}

export default useRxjsStore;
export type { RxjsStore, RxjsNextCallback, RxjsGetStateCallback };
