import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  useTheme,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";

type CriticalDataOverallOverviewTableFilterDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CriticalDataOverallOverviewTableFilterDialog: React.FC<CriticalDataOverallOverviewTableFilterDialogProps> = (
  props,
) => {
  const { isOpen, onClose } = props;
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={() => onClose()} fullWidth>
      <DialogTitle style={{ color: theme.palette.primary.main }}>Filters</DialogTitle>
      <DialogContent style={{ marginBottom: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label={"Processregisseur"} variant={"outlined"} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={"Dossier"} variant={"outlined"} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label={"Meting"} variant={"outlined"} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <CalendarToday style={{ color: theme.palette.primary.main }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              label={"Van"}
              variant={"outlined"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={"Tot en met"}
              variant={"outlined"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <CalendarToday style={{ color: theme.palette.primary.main }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={() => onClose()}>
          Sluiten
        </Button>
        <Button color={"primary"} variant={"contained"}>
          Toepassen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CriticalDataOverallOverviewTableFilterDialog;
