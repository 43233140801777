import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  addMonths,
  addQuarters,
  addYears,
  format,
  isMonday,
  parseISO,
  previousMonday,
  setDayOfYear,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
} from "date-fns";
import React, { useEffect, useMemo } from "react";
import { CdDataChartPeriodEnum } from "../../api";
import capdash_data from "../../core/capdash_data";
import provinces from "../../core/provinces";
import useAppStore from "../../core/useAppStore";
import useGenericStyles from "../../core/useGenericStyles";
import useRxjsForm from "../../core/useRxjsForm";
import AutocompleteFieldExt from "../../form/AutocompleteFieldExt";
import CheckboxFieldExt from "../../form/CheckboxFieldExt";
import DateFieldExt from "../../form/DateFieldExt";
import { CapDashDashboardStateFiltersData } from "./CapDashDashboard";

type Props = {
  initial: CapDashDashboardStateFiltersData;
  onClose: (data?: CapDashDashboardStateFiltersData) => void;
};

const CapDashDashboardDrawerFilters: React.FC<Props> = (props) => {
  const { dates, form, onClose } = useComponent(props);
  const gc = useGenericStyles();
  const app = useAppStore();

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Dashboard instellen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={gc.Grid_Padded}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Weergeven</Typography>
              <Typography variant="body2" color="textSecondary">
                Geef hier aan welke dashboards je graag wilt weergeven.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="chart_set"
                label="Statistieken"
                multiple
                items={[
                  { value: "session_login_checkup", label: "Controle inloghistorie" },
                  { value: "registry_checkup", label: "Controle ingevoerde data" },
                  { value: "care_access", label: "Toegang tot zorg" },
                  { value: "available", label: "Bedden Beschikbaar" },
                  { value: "waiting_duration", label: "Gemiddelde wachtduur dagen" },
                  { value: "current_capacity", label: "Actuele Bedcapaciteit" },
                  { value: "capacity", label: "Bedden Capaciteit" },
                  { value: "occupied", label: "Bedden Bezet" },
                  { value: "intake", label: "Instroom" },
                  { value: "outflow", label: "Uitstroom" },
                  { value: "reserved", label: "Gereserveerd" },
                  { value: "waiting", label: "Wachtlijstomvang" },
                  { value: "waiting_new", label: "Nieuwe aanmeldingen op wachtlijst" },
                  // { value: "waiting_failed_content", label: "Niet gelukte aanmeldingen (systeem)" },
                  // { value: "waiting_failed_financial", label: "Niet gelukte aanmeldingen (financieel)" },
                  // { value: "waiting_failed_system", label: "Niet gelukte aanmeldingen (inhoudelijk)" },
                ].filter(
                  (x) =>
                    ![
                      "session_login_checkup",
                      "registry_checkup",
                      "care_access",
                      "reserved",
                      "waiting_failed_content",
                      "waiting_failed_financial",
                      "waiting_failed_system",
                    ].includes(x.value) || app.session.person.role_id_set.includes(2),
                )}
              />
            </Grid>

            <Grid item md={6}>
              <AutocompleteFieldExt
                form={form}
                name="period_method"
                label="Periode"
                items={[
                  // { value: "YesterdayTillNow", label: "Gisteren en vandaag" },
                  { value: "Previous7Days", label: "Afgelopen 7 dagen" },
                  { value: "Previous4Weeks", label: "Afgelopen 4 weken" },
                  { value: "PreviousMonth", label: `Vorige maand (${format(dates.previousMonth, "MMMM")})` },
                  {
                    value: "PreviousQuarter",
                    label: `Afgelopen kwartaal (${format(dates.previousQuarter, "yyyy - Q")})`,
                  },
                  { value: "ThisQuarter", label: `Dit kwartaal (${format(dates.currentQuarter, "yyyy - Q")})` },
                  {
                    value: "Previous12Months",
                    label: `Afgelopen 12 maanden (vanaf ${format(dates.previousYear, "PP")})`,
                  },
                  { value: "ThisYear", label: `Dit jaar (vanaf ${format(dates.currentYear, "PP")})` },
                  { value: "Manual", label: "Zelf invoeren" },
                ]}
              />
            </Grid>

            <Grid item md={3}>
              {form.values.period_method === "Manual" ? (
                <DateFieldExt form={form} name="period_start" label="Vanaf datum" />
              ) : (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Vanaf datum"
                  value={format(
                    form.values.period_start ? parseISO(form.values.period_start) : new Date(),
                    "dd/MM/yyyy",
                  )}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Grid>
            <Grid item md={3}>
              {form.values.period_method === "Manual" ? (
                <DateFieldExt form={form} name="period_end" label="Tot en met datum" />
              ) : (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Tot en met datum"
                  value={format(form.values.period_end ? parseISO(form.values.period_end) : new Date(), "dd/MM/yyyy")}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Grid>

            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="cd_life_course_region_set"
                label="Levensloopregio"
                multiple
                items={capdash_data.cd_life_course_region.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="address_province_set"
                label="Provincie"
                multiple
                items={provinces.map((x) => ({ value: x, label: x }))}
              />
            </Grid>

            <Grid item md={10}>
              <AutocompleteFieldExt
                form={form}
                name="cd_care_type_set"
                label="Type zorg"
                multiple
                items={capdash_data.cd_care_type.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="cd_security_level_set"
                label="Beveiligingsniveau"
                multiple
                items={capdash_data.cd_security_level.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="cd_legal_regime_set"
                label="Rechtsregime"
                multiple
                items={capdash_data.cd_legal_regime.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="cd_primary_specialism_set"
                label="Primair specialisme"
                multiple
                items={capdash_data.cd_primary_specialism.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="cd_characteristic_specialism_set"
                label="Kenmerk specialisme"
                multiple
                items={capdash_data.cd_characteristic_specialism.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={10}>
              <AutocompleteFieldExt
                form={form}
                name="cd_contraindication_set"
                label="Contra-indicatie(s)"
                multiple
                items={capdash_data.cd_contraindication.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={10}>
              <AutocompleteFieldExt
                form={form}
                name="special_filter_set"
                label="Speciale filter(s)"
                multiple
                items={[
                  { label: "Negeer locaties met 0 waarden.", value: "ignore-0-valued" },
                  { label: "Negeer locaties waarbij eerste waarde 0 is.", value: "ignore-0-first-valued" },
                  { label: "Negeer locaties waarbij laatste waarde 0 is.", value: "ignore-0-last-valued" },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Instellen
          </Button>
        </DialogActions>
      </form>
    </Drawer>
  );
};

function useComponent(props: Props) {
  const dates = useMemo(
    () => ({
      previousMonth: startOfMonth(subMonths(new Date(), 1)),
      previousQuarter: startOfQuarter(subQuarters(new Date(), 1)),
      currentQuarter: startOfQuarter(new Date()),
      previousYear: subMonths(new Date(), 12),
      currentYear: setDayOfYear(new Date(), 1),
    }),
    [],
  );

  const form = useRxjsForm<CapDashDashboardStateFiltersData>({
    initial: props.initial,
    submit: async (values) => {
      onClose(values);
    },
  });

  const onClose = React.useCallback((data?: CapDashDashboardStateFiltersData) => {
    props.onClose(data);
  }, []);

  useEffect(() => {
    switch (form.values.period_method) {
      case CdDataChartPeriodEnum.Now:
        form.actions.setValues({
          period_start: format(new Date(), "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.Yesterday:
        form.actions.setValues({
          period_start: format(subDays(new Date(), 1), "yyyy-MM-dd"),
          period_end: format(subDays(new Date(), 1), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.YesterdayTillNow:
        form.actions.setValues({
          period_start: format(subDays(new Date(), 1), "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.Previous7Days:
        form.actions.setValues({
          period_start: format(subDays(new Date(), 7), "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.Previous4Weeks:
        const monday = isMonday(new Date()) ? new Date() : previousMonday(new Date());

        form.actions.setValues({
          period_start: format(subDays(monday, 7 * 3), "yyyy-MM-dd"),
          period_end: format(monday, "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.PreviousMonth:
        form.actions.setValues({
          period_start: format(dates.previousMonth, "yyyy-MM-dd"),
          period_end: format(subDays(addMonths(dates.previousMonth, 1), 1), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.PreviousQuarter:
        form.actions.setValues({
          period_start: format(dates.previousQuarter, "yyyy-MM-dd"),
          period_end: format(subDays(addQuarters(dates.previousQuarter, 1), 1), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.ThisQuarter:
        form.actions.setValues({
          period_start: format(dates.currentQuarter, "yyyy-MM-dd"),
          period_end: format(subDays(addQuarters(dates.currentQuarter, 1), 1), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.Previous12Months:
        form.actions.setValues({
          period_start: format(dates.previousYear, "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.ThisYear:
        form.actions.setValues({
          period_start: format(dates.currentYear, "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case CdDataChartPeriodEnum.Manual:
        if (props.initial.period_method !== "Manual") {
          form.actions.setValues({
            period_start: format(subDays(new Date(), 1), "yyyy-MM-dd"),
            period_end: format(new Date(), "yyyy-MM-dd"),
          });
        }
        break;
    }
  }, [form.values.period_method, props.initial.period_method]);

  return { dates, form, onClose };
}

export default CapDashDashboardDrawerFilters;
