import { Delete } from "@material-ui/icons";
import React from "react";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import useCrmPersonTableStore from "./useCrmPersonTableStore";
import useNavigate from "../../../core/useNavigate";
import useTableStyles from "../../../core/useTableStyles";
import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import { TableRowDto } from "../../../api";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import SearchIcon from "@material-ui/icons/Search";

const CrmPersonTableRowButtonsStart: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const navigate = useNavigate();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Openen.">
        <IconButton onClick={() => navigate(`/crm/person/view/${row.record_id}`)}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

const CrmPersonTableRowButtonsEnd: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const { actions } = useCrmPersonTableStore();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Aanpassen.">
        <IconButton onClick={() => actions.setDrawer({ name: "DrawerSave", record_id: row.record_id })}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <IconButtonDropExt
        items={[
          {
            icon: <Delete />,
            label: "Archiveren",
            onClick: () => actions.setDrawer({ name: "DialogArchive", record_id: row.record_id }),
          },
          {
            icon: <FlightTakeoffIcon />,
            label: "Profiel overnemen",
            onClick: () => actions.setDrawer({ name: "DialogTakeover", record_id: row.record_id }),
          },
          {
            icon: <ExitToAppIcon />,
            label: "Overal afmelden",
            onClick: () => actions.setDrawer({ name: "DialogSignoutEverywhere", record_id: row.record_id }),
          },
        ]}
      />
    </TableCell>
  );
};

export { CrmPersonTableRowButtonsStart, CrmPersonTableRowButtonsEnd };
