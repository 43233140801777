import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import * as React from "react";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";

type TextFieldExtProps = {
  form: RxjsForm<any>;

  name: string;
  label: string;
  type?: "text" | "email" | "password" | "number";
} & Omit<TextFieldProps, "name" | "label" | "type">;

const TextFieldExtComp: React.FC<TextFieldExtProps> = (props) => {
  const { form, name, label, type, helperText, ...other } = props;
  const { value, error, dirty } = extractRxjsField(form, name);

  return (
    <TextField
      name={name}
      type={type || "text"}
      label={label}
      value={value === 0 ? value : value || ""}
      error={dirty && !!error}
      helperText={dirty ? error : helperText}
      onChange={(e) => {
        if (type === "number") {
          const value = parseInt(e.currentTarget.value, 10);
          form.actions.setValue(name, isNaN(value) ? 0 : value, false);
        } else {
          form.actions.setValue(name, e.currentTarget.value, false);
        }
      }}
      onBlur={() => form.actions.setDirty(name)}
      variant="outlined"
      fullWidth
      {...other}
    />
  );
};

const TextFieldExt = React.memo(TextFieldExtComp, equalityRxjsFieldCompare);

export default TextFieldExt;
export type { TextFieldExtProps };
