const countries = [
  "Nederland",

  "Afghanistan",
  "Albanië",
  "Algerije",
  "Andorra",
  "Angola",
  "Antigua en Barbuda",
  "Argentinië",
  "Armenië",
  "Australië",
  "Azerbeidzjan",
  "Bahama's",
  "Bahrein",
  "Bangladesh",
  "Barbados",
  "België",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnië - Herzegovina",
  "Botswana",
  "Brazilië",
  "Brunei",
  "Bulgarije",
  "Burkina Faso",
  "Burundi",
  "Cambodja",
  "Canada",
  "Centraal-Afrikaanse Republiek",
  "Chili",
  "China",
  "Colombia",
  "Comoren",
  "Congo-Brazzaville",
  "Congo (Democratische Republiek)",
  "Costa Rica",
  "Cuba",
  "Cyprus",
  "Denemarken",
  "Djibouti",
  "Dominica",
  "Dominicaanse Republiek",
  "Duitsland",
  "Ecuador",
  "Egypte",
  "El Salvador",
  "Equatoriaal-Guinea",
  "Eritrea",
  "Estland",
  "Ethiopië",
  "Fiji",
  "Filipijnen",
  "Finland",
  "Frankrijk",
  "Gabon",
  "Gambia",
  "Georgië",
  "Ghana",
  "Grenada",
  "Griekenland",
  "Guatemala",
  "Guinee",
  "Guinee-Bissau",
  "Guyana",
  "Haïti",
  "Honduras",
  "Hongarije",
  "Ierland",
  "IJsland",
  "India",
  "Indonesië",
  "Irak",
  "Iran",
  "Israël",
  "Italië",
  "Ivoorkust",
  "Jamaica",
  "Japan",
  "Jemen",
  "Jordanië",
  "Kaapverdië",
  "Kameroen",
  "Kazachstan",
  "Kenia",
  "Kirgizië",
  "Kiribati",
  "Koeweit",
  "Kosovo",
  "Kroatië",
  "Laos",
  "Lesotho",
  "Letland",
  "Libanon",
  "Liberia",
  "Libië",
  "Liechtenstein",
  "Litouwen",
  "Luxemburg",
  "Madagaskar",
  "Malawi",
  "Maldiven",
  "Maleisië",
  "Mali",
  "Malta",
  "Marokko",
  "Marshalleilanden",
  "Mauritanië",
  "Mauritius",
  "Mexico",
  "Micronesië",
  "Moldavië",
  "Monaco",
  "Mongolië",
  "Montenegro",
  "Mozambique",
  "Myanmar",
  "Namibië",
  "Nauru",
  "Nepal",
  "Nicaragua",
  "Nieuw-Zeeland",
  "Niger",
  "Nigeria",
  "Noord-Korea",
  "Noord-Macedonië (voormalig Macedonië)",
  "Noorwegen",
  "Oeganda",
  "Oekraïne",
  "Oezbekistan",
  "Oman",
  "Oostenrijk",
  "Oost-Timor",
  "Pakistan",
  "Palau",
  "Palestina",
  "Panama",
  "Papoea-Nieuw-Guinea",
  "Paraguay",
  "Peru",
  "Polen",
  "Portugal",
  "Qatar",
  "Roemenië",
  "Rusland",
  "Rwanda",
  "Saint Kitts en Nevis",
  "Saint Lucia",
  "Saint Vincent en de Grenadines",
  "Salomonseilanden",
  "Samoa",
  "San Marino",
  "Sao Tomé en Principe",
  "Saoedi-Arabië",
  "Senegal",
  "Servië",
  "Seychellen",
  "Sierra Leone",
  "Singapore",
  "Slovenië",
  "Slowakije",
  "Soedan",
  "Somalië",
  "Spanje",
  "Sri Lanka",
  "Suriname",
  "Swaziland",
  "Syrië",
  "Tadzjikistan",
  "Taiwan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad en Tobago",
  "Tsjaad",
  "Tsjechië",
  "Tunesië",
  "Turkije",
  "Turkmenistan",
  "Tuvalu",
  "Uruguay",
  "Vanuatu",
  "Vaticaanstad",
  "Venezuela",
  "Verenigd Koninkrijk",
  "Verenigde Arabische Emiraten",
  "Verenigde Staten",
  "Vietnam",
  "Westelijke Sahara",
  "Wit-Rusland",
  "Zambia",
  "Zimbabwe",
  "Zuid-Afrika",
  "Zuid-Korea",
  "Zuid-Soedan",
  "Zweden",
  "Zwitserland",
];

export default countries;
