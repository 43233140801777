import { Button, DialogActions, DialogContent, DialogTitle, Drawer, ListItem, ListItemText } from "@material-ui/core";
import React, { FC } from "react";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useNavigate from "../../../core/useNavigate";

type CareDossierDrawerListingProps = {
  title?: string;
  dossierIdSet: number[];
  onClose: () => void | Promise<void>;
};

const CareDossierDrawerListing: FC<CareDossierDrawerListingProps> = (props) => {
  const navigate = useNavigate();
  const [careDossierSetData] = useApiResult(async () => {
    return await getApi().careDossierManyPost({ id_set: props.dossierIdSet });
  }, [props.dossierIdSet]);

  return (
    <Drawer open anchor="right" onClose={() => props.onClose()}>
      <DialogTitle>{props.title || "Overzicht van dossiers"}</DialogTitle>
      {careDossierSetData.busy && <DialogContent>Laden...</DialogContent>}
      {careDossierSetData.res && (
        <DialogContent>
          {careDossierSetData.res.data.records.map((row) => {
            return (
              <ListItem
                button
                onClick={() => {
                  props.onClose();
                  navigate(`/care/dossier/view/${row.id}`);
                }}
              >
                <ListItemText secondary={row.person.address_municipality}>{row.person.name}</ListItemText>
              </ListItem>
            );
          })}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => props.onClose()}>Sluiten</Button>
      </DialogActions>
    </Drawer>
  );
};

export default CareDossierDrawerListing;
export type { CareDossierDrawerListingProps };
