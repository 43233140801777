import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Refresh, SaveAltOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { CdDataChartTypeEnum, TsChartDataDto } from "../../../api";
import sleep from "../../../core/sleep";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useAppStore from "../../../core/useAppStore";
import useTranslations from "../../../core/useTranslations";
import CareDossierDrawerListing from "../../CareDossier/Drawers/CareDossierDrawerListing";

type Props = {
  dataDto: TsChartDataDto;
};

const TagScoreDashboardChartFlow: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const app = useAppStore();
  const [iteration, setIteration] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const [data] = useApiResult(async () => {
    await sleep(250);

    return (await getApi().tsChartFlowPost(props.dataDto)).data;
  }, [props.dataDto, iteration]);
  const [activeDossierIdSet, setActiveDossierIdSet] = useState<null | number[]>(null);

  return (
    <Card>
      <CardHeader
        title="In & Uitstroom"
        action={
          <>
            {!!data.res && (
              <Tooltip title="Download als Excel.">
                <span>
                  <IconButton disabled={data.busy} onClick={() => setDownloading(true)}>
                    <SaveAltOutlined />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Herlaad deze statistiek.">
              <span>
                <IconButton onClick={() => setIteration((x) => x + 1)} disabled={data.busy}>
                  <Refresh />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Fasen</TableCell>
            <TableCell variant="head" align="right">
              Actief
            </TableCell>
            <TableCell variant="head" align="right">
              Gestart
            </TableCell>
            <TableCell variant="head" align="right">
              Geëindigd
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.res?.item_set.map((item) => {
            const tag = app.data.tag_core_set.find((x) => x.id === item.core_tag_id);
            return (
              <TableRow key={tag.id} hover onClick={() => setActiveDossierIdSet(item.dossier_id_set)}>
                <TableCell component="th" scope="row">
                  {tag.name}
                </TableCell>
                <TableCell align="right">{item.dossier_count}</TableCell>
                <TableCell align="right">{item.started_count}</TableCell>
                <TableCell align="right">{item.ended_count}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {activeDossierIdSet !== null && (
        <CareDossierDrawerListing dossierIdSet={activeDossierIdSet} onClose={() => setActiveDossierIdSet(null)} />
      )}

      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () => (await getApi().tsChartFlowDownloadPost(props.dataDto)).data}
        />
      )}
    </Card>
  );
};

export default TagScoreDashboardChartFlow;
