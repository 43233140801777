import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

const data = [
  { name: "Risicofactor", number: null },
  { name: "Score", number: 1 },
  { name: "Score", number: 2 },
  { name: "Score", number: 3 },
  { name: "Score", number: 4 },
];

type ManagementMatrixAddScoreDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ManagementMatrixAddScoreDialog: React.FC<ManagementMatrixAddScoreDialogProps> = (props) => {
  const { isOpen, onClose } = props;
  const [arr, setArr] = useState(data);
  const theme = useTheme();

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          name: "Score",
          number: s.length + 1,
        },
      ];
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    const index = e.target.number;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].name = e.target.value;

      return newArr;
    });
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle style={{ color: theme.palette.primary.main }}>Risicofactor toevoegen</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {arr.map((data, i) => {
            return (
              <Grid item xs={12} key={i}>
                <TextField
                  onChange={handleChange}
                  fullWidth
                  label={`${data.name} ${data.number ? data.number : ""}`}
                  variant={"outlined"}
                />
              </Grid>
            );
          })}

          <ButtonGroup>
            <Add onClick={addInput} />
            <Typography>Score toevoegen</Typography>
          </ButtonGroup>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={() => onClose()}>
          Sluiten
        </Button>
        <Button color={"primary"} variant={"contained"}>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagementMatrixAddScoreDialog;
