import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";

type ManagementMatrixAddMatrixDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const data = [{ name: "Matrix toevoegen" }, { name: "Nulmeting" }, { name: "Doelmeting" }];

const ManagementMatrixAddMatrixDialog: React.FC<ManagementMatrixAddMatrixDialogProps> = (props) => {
  const { isOpen, onClose } = props;
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={() => onClose()} fullWidth>
      <DialogTitle>Matrix toevoegen</DialogTitle>
      <DialogContent style={{ marginBottom: 10 }}>
        <TextField variant={"outlined"} fullWidth label={"Matrix"} style={{ marginBottom: 20 }} />
        {data.map((data, index) => {
          return (
            <Grid container alignItems={"center"} justifyContent={"flex-start"} key={index}>
              <Grid item xs={1}>
                <Checkbox style={{ color: theme.palette.primary.main }} checked={true} />
              </Grid>
              <Grid item xs={3}>
                <Typography> {data.name}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={() => onClose()}>
          Sluiten
        </Button>
        <Button color={"primary"} variant={"contained"}>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagementMatrixAddMatrixDialog;
