const nationalities = [
  "Nederlands",
  "Afghaans",
  "Albanees",
  "Algerijns",
  "Amerikaans",
  "Angolees",
  "Argentijns",
  "Australisch",
  "Belgisch",
  "Bengalees",
  "Boliviaans",
  "Botswaans",
  "Braziliaans",
  "Brits",
  "Brits / Engels",
  "Brits / Noord-Iers",
  "Brits / Schots",
  "Bulgaars",
  "Cambodjaans",
  "Canadees",
  "Chileens",
  "Chinees",
  "Colombiaans",
  "Congolees",
  "Cubaans",
  "Cypriotisch",
  "Deens",
  "Duits",
  "Dominicaanse",
  "Ecuadoraans",
  "Egyptisch",
  "Estlands",
  "Ethiopisch",
  "Fijisch",
  "Filipijns",
  "Fins",
  "Frans",
  "Georgisch",
  "Ghanees",
  "Grieks",
  "Guatemalteeks",
  "Hongaars",
  "Iers",
  "IJslands",
  "Indiaas",
  "Indonesisch",
  "Iraaks",
  "Iraans",
  "Israëlisch",
  "Italiaans",
  "Ivoriaans",
  "Jamaicaans",
  "Japans",
  "Jemenitisch",
  "Jordaans",
  "Kazachs",
  "Kaapverdiaans",
  "Keniaans",
  "Koeweits",
  "Kroatisch",
  "Laotiaans",
  "Lets",
  "Libanees",
  "Liberiaans",
  "Litouws",
  "Malaggisch",
  "Maleisisch",
  "Marokkaans",
  "Mexicaan",
  "Mongools",
  "Mozambikaan",
  "Myanmarees",
  "Namibisch",
  "Nepalees",
  "Nieuw-Zeelands",
  "Nigerees",
  "Noord-Koreaans",
  "Noors",
  "Oegandees",
  "Oekraïens",
  "Oostenrijks",
  "Pakistaans",
  "Palestijns",
  "Paraguayaans",
  "Peruviaans",
  "Pools",
  "Portugees",
  "Roemeens",
  "Russisch",
  "Saoedi-Arabisch",
  "Servisch",
  "Singaporees",
  "Sloveens",
  "Slowaaks",
  "Soedanees",
  "Somalisch",
  "Spaans",
  "Sri Lankaans",
  "Surinaams",
  "Syrisch",
  "Taiwanees",
  "Tanziaans",
  "Thais",
  "Tsjech",
  "Tunesisch",
  "Turks",
  "Uruguayaans",
  "Venezolaans",
  "Vietnamees",
  "Wels",
  "Wit-Russisch",
  "Zambiaan",
  "Zimbabwaan",
  "Zuid-Afrikaan",
  "Zuid-Koreaans",
  "Zweeds",
  "Zwitsers",
];

export default nationalities;
