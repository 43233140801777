import { Chip, ChipProps, colors, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import FormatDate from "./FormatDate";
import useAppStore from "./useAppStore";
import useGenericStyles from "./useGenericStyles";

type Props = {
  tag_id: number;
  date_start?: string;
  date_end?: string;
} & Omit<ChipProps, "label" | "style" | "className">;

const TagCoreChip: React.FC<Props> = (props) => {
  const { tag_id, ...extra } = props;
  const gc = useGenericStyles();
  const theme = useTheme();
  const app = useAppStore();
  const tag = useMemo(() => app.data.tag_core_set.find((x) => x.id === tag_id), [tag_id]);
  const style = useMemo(() => {
    const muiColor = colors[tag.color_name][tag.color_hue];
    return {
      backgroundColor: muiColor,
      color: theme.palette.getContrastText(muiColor),
    };
  }, [tag]);

  let label = tag.name;

  const label_dates: string[] = [];

  return (
    <Chip
      label={
        <>
          {tag.name}
          {(props.date_start || props.date_end) && (
            <>
              {" "}
              (<FormatDate value={props.date_start} fmt="PP" /> t/m <FormatDate value={props.date_end} fmt="PP" />)
            </>
          )}
        </>
      }
      style={style}
      className={gc.Chip_Inline}
      {...extra}
    />
  );
};

export default TagCoreChip;
