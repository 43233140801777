import { Chip, IconButton, InputAdornment, TextField, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import { util } from "prettier";
import React from "react";
import { getApi } from "../core/useApi";
import useAppStore from "../core/useAppStore";
import useIsMounted from "../core/useIsMounted";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";
import useRxjsStore from "../core/useRxjsStore";

type Props = {
  form: RxjsForm<any>;

  name: string;
  label: string;

  multiple?: boolean;
};

type FileState = {
  id: number;
  name: string;
  moment: "idle" | "uploading" | "finished";
  record_id?: number;
  file?: File;
};

let component_uuid = 0;
let file_uuid = 0;

const UploadFieldExtComp: React.FC<Props> = (props) => {
  const app = useAppStore();
  const uuid = React.useMemo(() => component_uuid++, []);
  const { form, name, label, multiple } = props;
  const { value, error, dirty } = extractRxjsField(form, name);
  const theme = useTheme();
  const classes = useStyles();
  const isMounted = useIsMounted();

  const { state, getState, next } = useRxjsStore<{ files: FileState[]; height: number }>({ files: [], height: 1 });
  const chips = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    next((d) => {
      d.height = Math.max(chips.current?.clientHeight || 1, 50);
    });
  }, [state.files, chips.current?.clientHeight]);

  const actions = {
    delete: (file_id: number) => {
      next((d) => {
        const i = d.files.findIndex((x) => x.id === file_id);
        if (i > -1) d.files.splice(i, 1);
      });
    },
    upload: async (file: File) => {
      const fId = file_uuid++;

      next((d) => {
        if (!multiple) d.files = [];

        d.files.push({
          id: fId,
          name: file.name,
          moment: "idle",
          file,
        });
      });

      try {
        const res = (await getApi().genericResourceSavePost(file)).data;

        next((d) => {
          const i = d.files.findIndex((x) => x.id === fId);
          if (i > -1) {
            d.files[i].moment = "finished";
            d.files[i].record_id = res.id;
          }
        });
      } catch (err) {
        app.actions.getApiError(err);
        actions.delete(fId);
      }
    },
    load: async (file_id: number) => {
      const fId = file_uuid++;

      next((d) => {
        if (!multiple) d.files = [];

        d.files.push({
          id: fId,
          name: "Laden ...",
          moment: "idle",
          record_id: file_id,
          file: undefined,
        });
      });

      try {
        const res = (await getApi().genericResourceOnePost({ id: file_id })).data;

        next((d) => {
          const i = d.files.findIndex((x) => x.id === fId);
          if (i > -1) {
            d.files[i].name = res.original_filename;
            d.files[i].moment = "finished";
          }
        });
      } catch (err) {
        app.actions.getApiError(err);
        actions.delete(fId);
      }
    },
  };

  React.useEffect(() => {
    const record_id_set = state.files.filter((x) => x.moment === "finished").map((x) => x.record_id);

    if (multiple) {
      form.actions.setValue(name, record_id_set);
    } else if (record_id_set.length) {
      form.actions.setValue(name, record_id_set[0]);
    } else {
      form.actions.setValue(name, null);
    }
  }, [state.files]);

  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    for (const file of Array.from(e.currentTarget.files)) {
      actions.upload(file);
    }
  }, []);

  const busy = state.files.filter((x) => x.moment !== "finished").length > 0;

  React.useEffect(() => {
    const file_id_set: number[] = (!multiple ? [value] : value) || [];

    file_id_set
      .filter((x) => !!x)
      .filter((x) => state.files.findIndex((y) => y.record_id === x) === -1)
      .forEach((file_id) => {
        actions.load(file_id);
      });
  }, [value]);

  console.info("UploadFieldExt", name, value, state.files);

  return (
    <div className={classes.UploadField_Container}>
      <div className={classes.UploadField_Chips} ref={chips}>
        {state.files.map((file) => (
          <Chip key={file.id} label={file.name} onDelete={!busy && (() => actions.delete(file.id))} />
        ))}
      </div>
      <TextField
        fullWidth
        disabled
        variant="outlined"
        label={label}
        type="text"
        value={state.files.length ? " " : ""}
        InputProps={{
          style: { height: state.height + theme.spacing(1) },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={busy}>
                <label htmlFor={`UploadFieldExt--${uuid}`} style={{ display: "flex" }}>
                  <CloudUpload />
                  <input
                    multiple={multiple}
                    type="file"
                    style={{ position: "fixed", left: -1000 }}
                    id={`UploadFieldExt--${uuid}`}
                    onChange={onChange}
                  />
                </label>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  UploadField_Container: {
    position: "relative",
  },
  UploadField_Chips: {
    position: "absolute",
    left: theme.spacing(1),
    right: theme.spacing(1) + 50,
    top: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    zIndex: 2,
    paddingBottom: theme.spacing(0.5),

    "& .MuiChip-root": {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const UploadFieldExt = React.memo(UploadFieldExtComp, equalityRxjsFieldCompare);

// export default UploadFieldExtMemo;
export default UploadFieldExt;
