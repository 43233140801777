import { Chip } from "@material-ui/core";
import { addQuarters, format, parseISO, setDayOfYear, subMonths, subQuarters } from "date-fns";
import React from "react";
import { TsChartDataDto, TsChartDataPeriodMethodEnum } from "../../../api";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";

type Props = {
  dataDto: TsChartDataDto;
};

const TagScoreDashboardSentence: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const app = useAppStore();

  const [addressMunicipalitySet] = useApiResult(async () => {
    return (await getApi().crmPersonAddressMunicipalityPickPost({})).data;
  }, []);

  if (!addressMunicipalitySet.res) {
    return null;
  }

  const previousMonth = subMonths(new Date(), 1);
  const previousQuarter = subQuarters(new Date(), 1);
  const currentQuarter = addQuarters(previousQuarter, 1);
  const previousYear = subMonths(new Date(), 12);
  const currentYear = setDayOfYear(new Date(), 1);

  const period_set = [
    {
      value: TsChartDataPeriodMethodEnum.PreviousQuarter,
      label: `Afgelopen kwartaal (${format(previousQuarter, "yyyy - Q")})`,
    },
    { value: TsChartDataPeriodMethodEnum.ThisQuarter, label: `Dit kwartaal (${format(currentQuarter, "yyyy - Q")})` },
    {
      value: TsChartDataPeriodMethodEnum.Previous12Months,
      label: `Afgelopen 12 maanden (vanaf ${format(previousYear, "PP")})`,
    },
    {
      value: TsChartDataPeriodMethodEnum.PreviousYear,
      label: `Vorig jaar (vanaf ${format(previousYear, "PP")} 1-1 t/m 31-21)`,
    },
    { value: TsChartDataPeriodMethodEnum.ThisYear, label: `Dit jaar (vanaf ${format(currentYear, "PP")})` },
    { value: TsChartDataPeriodMethodEnum.Manual, label: "Zelf invoeren" },
  ];

  let period_method = period_set.find((x) => x.value === props.dataDto.period_method).label;
  if (props.dataDto.period_method === "Manual") {
    const period_start = props.dataDto.period_start ? parseISO(props.dataDto.period_start as any) : new Date();
    const period_end = props.dataDto.period_end ? parseISO(props.dataDto.period_end as any) : new Date();
    period_method += ` (${format(period_start, "PP")} `;
    period_method += `t/m ${format(period_end, "PP")})`;
  }

  const all_sets = [
    ...props.dataDto.region_set,
    ...props.dataDto.dossier_tag_set.map((x) => app.data.tag_core_set.filter((i) => i.id === x).map((x) => x.name)),
    ...props.dataDto.address_municipality_set,
  ];

  return (
    <div>
      <Chip label={period_method} className={gc.Chip_Inline} />
      {all_sets.map((label, rx) => (
        <Chip label={label} className={gc.Chip_Inline} key={rx} />
      ))}
    </div>
  );
};

export default TagScoreDashboardSentence;
