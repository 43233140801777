import * as React from "react";
import useRxjsForm from "../../core/useRxjsForm";
import { getApi } from "../../core/useApi";
import { DetailEnum } from "../../api";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import TextFieldExt from "../../form/TextFieldExt";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SendIcon from "@material-ui/icons/Send";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { DmzAuthenticatePageActions, useDmzAuthenticateStyles } from "./DmzAuthenticate";
import validator from "validator";
import DmzAuthenticateStepper from "./DmzAuthenticateStepper";
import isEmail = validator.isEmail;

const DmzAuthenticateScreenSessionIdentify: React.FC<{ actions: DmzAuthenticatePageActions }> = ({ actions }) => {
  const classes = useDmzAuthenticateStyles();

  const form = useRxjsForm<{ email: string }>({
    initial: { email: window.ENVIRONMENT === "production" ? "" : "ncorver@tagscore.nl" },
    submit: async (values) => {
      const res = (await getApi().tsSessionIdentifyPost(values)).data;

      actions.onLogin(values.email, res.company_set);
    },
    validate: (values) => {
      const errors: { [name: string]: string } = {};
      if (!values.email.length) {
        errors.email = "Het e-mailadres veld is verplicht.";
      } else if (!isEmail(values.email)) {
        errors.email = "Een geldig e-mailadres is verplicht.";
      }
      return errors;
    },
    default_errors: {
      [DetailEnum.NOTFOUND]: ["email", "Het account kon niet gevonden worden."],
    },
  });

  return (
    <Card className={classes.Form} component={"form"} onSubmit={form.actions.submit} raised>
      <CardContent>
        <Typography variant="h5" component="h2">
          Aanmelden
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Opzoeken van het account.
        </Typography>

        <DmzAuthenticateStepper step={0} steps={["E-mail", "Organisatie", "Wachtwoord"]} />

        <TextFieldExt form={form} name="email" type="email" label="E-mail adres" margin="normal" autoFocus />
      </CardContent>
      <CardContent>
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          color={!form.hasErrors ? "primary" : "default"}
          disabled={form.submitting}
          startIcon={form.submitting ? <HourglassEmptyIcon /> : !form.hasErrors ? <SendIcon /> : <ErrorOutlineIcon />}
        >
          Aanmelden
        </Button>
      </CardContent>
    </Card>
  );
};

export default DmzAuthenticateScreenSessionIdentify;
