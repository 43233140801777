import { AxiosError } from "axios";
import * as React from "react";
import { Configuration, DefaultApi, DetailEnum } from "../api";
import { AppStore } from "./useAppStore";

export function getServerUrl() {
  if (window.location.hostname === "localhost") {
    return "http://localhost:9565";
  } else if (window.location.hostname === "app.tagscore.corver.it") {
    return "https://api.tagscore.corver.it";
  } else if (window.location.hostname === "app.tagscore.nl") {
    return "https://api.tagscore.nl";
  } else {
    throw new Error(`No definition for ${window.location.hostname}`);
  }
}

export function getApi() {
  if (window.location.hostname === "localhost") {
    return new DefaultApi(new Configuration(), getServerUrl());
  } else if (window.location.hostname === "app.tagscore.corver.it") {
    return new DefaultApi(new Configuration(), getServerUrl());
  } else if (window.location.hostname === "app.tagscore.nl") {
    return new DefaultApi(new Configuration(), getServerUrl());
  } else {
    throw new Error(`No definition for ${window.location.hostname}`);
  }
}

export async function callApi<T = any>(app: AppStore, callback: (api: DefaultApi) => Promise<T | undefined>) {
  try {
    return await callback(getApi());
  } catch (err) {
    app.actions.getApiError(err);
    return undefined;
  }
}

export default function useApi(): DefaultApi {
  return React.useMemo(getApi, []);
}

export function getApiError(err: any): [number | undefined, string | undefined, any | undefined] {
  if (err.response) {
    const res: AxiosError = err;
    const status: number = res.response.status;
    const detail = res.response.data.detail || DetailEnum.NOK;
    const headers = res.response.headers;

    return [status, detail, headers];
  } else {
    console.error(err);
  }

  return [undefined, undefined, undefined];
}
