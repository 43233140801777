import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import { GenericManualDto } from "../../../api";
import { getApi } from "../../../core/useApi";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsForm from "../../../core/useRxjsForm";

type Props = {
  open: boolean;
  record_id?: number;
  onClose: (success?: boolean) => Promise<void>;
};

const GenericManualsDialogArchive: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const generic_manual = useRxjsFetch<GenericManualDto | null>(async () => {
    if (props.record_id) return (await getApi().genericManualOnePost({ id: props.record_id })).data;

    return null;
  }, [props.record_id]);

  const form = useRxjsForm({
    initial: {},
    submit: async () => {
      await getApi().genericManualArchivePost({ id: props.record_id });
      await onClose(true);
    },
  });

  return (
    <Dialog open={open} onClose={() => form.submitting === false && onClose()} fullWidth maxWidth="sm">
      <form onSubmit={form.actions.submit} noValidate>
        <DialogTitle disableTypography>
          <Typography variant="h6">Handleiding archiveren</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {generic_manual.record?.resource.original_filename}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Je staat op het punt om deze handleiding te archiveren. Het archiveren kan niet meer ongedaan gemaakt
            worden, voor nu.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()} color="default" disabled={form.submitting}>
            Annuleren
          </Button>
          <Button type="submit" color="primary" disabled={form.submitting}>
            Archiveren
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GenericManualsDialogArchive;
