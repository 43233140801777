import React, { useMemo, useState } from "react";
import { CdDataChartResponse, CdDataChartTypeEnum } from "../../../api";
import sleep from "../../../core/sleep";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import useApi, { getApi } from "../../../core/useApi";
import useAsyncEffect from "../../../core/useAsyncEffect";
import { CapDashDashboardStateFiltersData } from "../CapDashDashboard";
import CDDGenericLineChart from "./CDDGenericLineChart";

type Props = {
  chart: string;
  filters_data: CapDashDashboardStateFiltersData;
};

const CapDashDashboardChartGeneric: React.FC<Props> = (props) => {
  const api = useApi();

  const header = useMemo(
    () =>
      ({
        available: "Bedden Beschikbaar",
        waiting_duration: "Gemiddelde wachtduur dagen",
        current_capacity: "Actuele Bedcapaciteit",
        capacity: "Bedden Capaciteit",
        occupied: "Bedden Bezet",
        intake: "Instroom",
        outflow: "Uitstroom",
        reserved: "Gereserveerd",
        waiting: "Wachtlijstomvang",
        waiting_new: "Nieuwe aanmeldingen op wachtlijst",
        waiting_failed_content: "Niet gelukte aanmeldingen (systeem)",
        waiting_failed_financial: "Niet gelukte aanmeldingen (financieel)",
        waiting_failed_system: "Niet gelukte aanmeldingen (inhoudelijk)",
      }[props.chart]),
    [props.chart],
  );

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [reload, setReload] = useState(0);
  const [iteration, setIteration] = useState(0);
  const [chart_data, setChartData] = useState<CdDataChartResponse>({
    data_set: [],
  });

  useAsyncEffect(async () => {
    setLoading(true);

    await sleep(Math.random() * 250);
    const res = await api.cdDataChartPost({ chart: props.chart as CdDataChartTypeEnum, ...props.filters_data });

    setChartData(res.data);
    setIteration((prev) => prev + 1);
    setLoading(false);
  }, [props.filters_data, reload]);

  return (
    <>
      <CDDGenericLineChart
        header={header}
        loading={loading}
        onDownload={() => setDownloading(true)}
        onReload={() => setReload(reload + 1)}
        chart={{
          iteration: iteration,
          data: chart_data.data_set,
        }}
      />
      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () =>
            (await getApi().cdDataDownloadPost({ ...props.filters_data, chart: props.chart as CdDataChartTypeEnum }))
              .data
          }
        />
      )}
    </>
  );
};

export default CapDashDashboardChartGeneric;
