import React from "react";
import sleep from "../../../core/sleep";
import useApi, { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useApp from "../../../core/useAppStore";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { CrmPersonDto } from "../../../api";
import CloseIcon from "@material-ui/icons/Close";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import useNavigate from "../../../core/useNavigate";

type Props = {
  onClose: () => void;
  record_id: number;
};

const CrmPersonDialogTakeover: React.FC<Props> = (props) => {
  const app = useApp();
  const navigate = useNavigate();
  const { onClose } = props;
  const [busy, setBusy] = React.useState(false);

  const [{ res: record }] = useApiResult<CrmPersonDto>(
    async () => {
      return (await getApi().crmPersonOnePost({ id: props.record_id })).data;
    },
    [props.record_id],
    { run_on_startup: true },
  );

  const onTakeoverClick = React.useCallback(async () => {
    if (busy || !record) return;

    setBusy(true);

    try {
      await sleep(500);

      const res = await getApi().crmPersonTakeoverPost({ id: record.id });
      await app.actions.setAuthentication(res.data, () => {
        navigate("/profile/dashboard");
      });
    } catch (err) {
      app.actions.getApiError(err);
      console.error(err);
      setBusy(false);
    }
  }, [busy, record]);

  return (
    <Dialog open={true}>
      <DialogTitle>Profiel overnemen</DialogTitle>
      {record && (
        <DialogContent>
          <Typography variant="body1" paragraph>
            Je staat op het punt om het profiel van <strong>{record.name}</strong> over te nemen. Na het overnemen
            zullen acties die je uitvoerd direct worden gelinkt aan het profiel van {record.name}, let hier goed op!
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={onClose} color="primary" disabled={busy}>
          Sluiten
        </Button>
        <Button
          startIcon={<FlightTakeoffIcon />}
          variant="contained"
          onClick={onTakeoverClick}
          color="primary"
          disabled={busy}
        >
          Overnemen.
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrmPersonDialogTakeover;
