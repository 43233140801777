import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Chip,
  colors,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMore, History, PlayArrow } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { CareDossierDataHistoryResponse, CareDossierDataMarkRequest, CareDossierDataOneResponse } from "../../../api";
import FormatDate from "../../../core/FormatDate";
import FormatDateDistance from "../../../core/FormatDateDistance";
import { getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsFetch from "../../../core/useRxjsFetch";
import { FormatCrmPerson } from "../../CrmPersonTable/Ext/CrmPersonExtDataTable";
import CrmDataScreenExtDisplay from "../Ext/CrmDataScreenExtDisplay";

type Props = {
  screen_id: number;

  endpoint: {
    person_id: number;
    dossier_id: number;
  };

  onClose: () => void | Promise<void>;
};

const CrmDataScreenDrawerHistory: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { screen, record, busy, closing, onMarkData, onClose } = useComponent(props);

  return (
    <Drawer open anchor="right">
      <DialogTitle>{screen.name}</DialogTitle>
      {record && (
        <DialogContent style={{ minWidth: "60vw" }}>
          {record.data_set.map((row, rx) => (
            <Accordion defaultExpanded={row.is_current || row.is_history}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography variant="h6">
                    {"Op "}
                    <FormatDate value={row.created_at} />
                    <small>
                      {" ("}
                      <FormatDateDistance value={row.created_at} />
                      {" geleden)"}
                    </small>
                    {" door "}
                    <FormatCrmPerson person_id={row.created_by_id} hideLink />
                  </Typography>

                  <div style={{ marginLeft: "auto" }}>
                    {row.is_history && (
                      <Chip
                        label="Geschiedenis"
                        style={{ backgroundColor: colors.deepPurple[500], color: "white" }}
                        className={gc.Chip_Inline}
                      />
                    )}
                    {row.is_current && (
                      <Chip
                        label="Actueel"
                        style={{ backgroundColor: colors.blue[500], color: "white" }}
                        className={gc.Chip_Inline}
                      />
                    )}
                  </div>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <CrmDataScreenExtDisplay screen={screen} data={row} />
              </AccordionDetails>
              <AccordionActions>
                <ButtonGroup>
                  <Tooltip title="Moment markeren als geschiedenis.">
                    <Button
                      startIcon={<History />}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={busy || row.is_history}
                      onClick={() => onMarkData({ record_id: row.id, is_history: true })}
                    >
                      Geschiedenis
                    </Button>
                  </Tooltip>
                  <Tooltip title="Moment markeren als actueel.">
                    <Button
                      startIcon={<PlayArrow />}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={busy || row.is_current}
                      onClick={() => onMarkData({ record_id: row.id, is_current: true })}
                    >
                      Actueel
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </AccordionActions>
            </Accordion>
          ))}
        </DialogContent>
      )}

      <DialogActions>
        <Button type="button" onClick={() => onClose()} disabled={busy || closing}>
          Sluiten
        </Button>
      </DialogActions>
    </Drawer>
  );
};

function useComponent(props: Props) {
  const app = useAppStore();
  const { screen_id } = props;
  const [busy, setBusy] = useState(false);
  const [closing, setClosing] = React.useState(false);
  const isMounted = useIsMounted();

  const screen = useMemo(() => {
    return app.data.crm_data_screen_set.find((x) => x.id === screen_id);
  }, [screen_id]);

  // Load the record from the server.
  const { record, load } = useRxjsFetch<CareDossierDataHistoryResponse>(async () => {
    return (await getApi().careDossierDataHistoryPost({ screen_id, ...props.endpoint })).data;
  }, [props.endpoint.dossier_id, props.screen_id]);

  const onMarkData = React.useCallback(
    async (data: CareDossierDataMarkRequest) => {
      if (busy) return;

      setBusy(true);
      (await getApi().careDossierDataMarkPost(data)).data;
      await load();
      setBusy(false);
    },
    [busy],
  );

  const onClose = React.useCallback(async () => {
    setClosing(true);
    await props.onClose();
    if (isMounted) setClosing(false);
  }, []);

  return { screen, record, busy, closing, onMarkData, onClose };
}

export default CrmDataScreenDrawerHistory;
