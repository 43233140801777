import React from "react";
import useApp from "../../core/useAppStore";
import { Box, Button, Card, CardActions, CardContent, Grid, LinearProgress, Typography } from "@material-ui/core";
import sleep from "../../core/sleep";
import useNavigate from "../../core/useNavigate";
import useApi from "../../core/useApi";
import { makeStyles } from "@material-ui/core/styles";

const MAX_DELAY_S_10 = 10;

const ProfileLogout: React.FC = () => {
  const classes = useStyles();
  const app = useApp();
  const api = useApi();
  const navigate = useNavigate();
  const [busy, setBusy] = React.useState(false);
  const [timeout_delay, setTimeoutDelay] = React.useState(MAX_DELAY_S_10);

  const logout = React.useCallback(async () => {
    setBusy(true);

    await sleep(250);
    await api.tsSessionLogoutPost();

    window.location.href = "/profile/dashboard";
  }, []);

  React.useEffect(() => {
    if (timeout_delay > 0) {
      const timeout = setTimeout(() => {
        setTimeoutDelay((d) => d - 1);
      }, 100);

      return () => clearTimeout(timeout);
    } else {
      logout();
    }
  }, [timeout_delay]);

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item md={3}>
        <Box marginTop={2}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Afmelden
              </Typography>
              <Typography color="textSecondary" paragraph>
                {app.session.person.name}
              </Typography>
              <Typography paragraph variant="body2">
                We melden je sessie af op de server. Hierna zul je opnieuw moeten aanmelden in TagScore om gebruik te
                kunnen maken van de applicatie.
              </Typography>

              <LinearProgress
                variant="determinate"
                value={((MAX_DELAY_S_10 - timeout_delay) / MAX_DELAY_S_10) * 100}
                className={classes.progress}
              />
            </CardContent>
            <CardActions>
              <Button size="small" disabled={busy} onClick={() => navigate("/profile/dashboard")}>
                Annuleren
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  progress: {
    transition: "transform 75ms linear",
  },
}));

export default ProfileLogout;
