import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  lighten,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Add, ArrowBack, Delete, Edit, MoreVert } from "@material-ui/icons";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { MatrixCoreDtoExt } from "../../../api";
import DataTableExt from "../../../core/DataTableExt";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import SkeletonLoading from "../../../skeleton/SkeletonLoading";
import SkeletonPage from "../../../skeleton/SkeletonPage";
import MatrixColumnDeleteDialog from "../Drawers/MatrixColumnDeleteDialog";
import MatrixColumnSaveDrawer from "../Drawers/MatrixColumnSaveDrawer";
import MatrixRowDeleteDialog from "../Drawers/MatrixRowDeleteDialog";
import MatrixRowSaveDrawer from "../Drawers/MatrixRowSaveDrawer";
import MatrixValueSaveDrawer from "../Drawers/MatrixValueSaveDrawer";

const MatrixCoreView: React.FC = () => {
  const _t = useTranslations();
  const { state, actions } = useComponent();
  const app = useAppStore();
  const record = state.record;
  const theme = useTheme();
  const gc = useGenericStyles();

  if (!record) {
    return (
      <SkeletonPage>
        <SkeletonLoading label="De pagina is aan het laden" />
      </SkeletonPage>
    );
  }

  return (
    <SkeletonPage>
      <Card>
        {state.busy && <LinearProgress style={{ position: "absolute", width: "100%", height: 3 }} />}
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Tooltip title="Vorige pagina." placement="right">
                <IconButton onClick={() => window.history.back()}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" component="h2">
                <small>#{record.id}.</small> {record.name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButtonDropExt
                icon={<MoreVert />}
                items={[
                  {
                    icon: <Edit />,
                    label: "Aanpassen",
                    onClick: () => {},
                  },
                  {
                    icon: <Add />,
                    label: `Regel toevoegen`,
                    onClick: () => {},
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Gegevens
                  </Typography>

                  <DataTableExt
                    data={[
                      ["Naam", record.name],
                      ["Heeft Tags?", record.has_tags ? "Ja" : "Nee"],
                      ["Nul-meting?", record.has_zero_mark ? "Ja" : "Nee"],
                      ["Vervolg-meting?", record.has_continuation_mark ? "Ja" : "Nee"],
                      ["Doel-meting?", record.has_target_mark ? "Ja" : "Nee"],
                      ["Notities?", record.has_notes ? "Ja" : "Nee"],
                      ["Verberg lege regels?", record.has_hide_empty_rows ? "Ja" : "Nee"],
                    ]}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item md>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Kolommen
                  </Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pos.</TableCell>
                        <TableCell>Naam</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>Standaard?</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {record.column_set.map((row) => (
                        <TableRow>
                          <TableCell width={1}>#{row.position}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.label}</TableCell>
                          <TableCell>{row.score}</TableCell>
                          <TableCell>{row.is_zero_default ? "Ja" : "Nee"}</TableCell>
                          <TableCell width={1}>
                            <ButtonGroup>
                              <IconButton onClick={() => actions.setDrawer({ name: "ColumnSave", record_id: row.id })}>
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => actions.setDrawer({ name: "ColumnDelete", record_id: row.id })}
                              >
                                <Delete />
                              </IconButton>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => actions.setDrawer({ name: "ColumnSave" })}
                  >
                    Toevoegen
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Matrix
                  </Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        {record.column_set.map((row) => (
                          <TableCell>
                            {row.name}
                            <br />
                            <strong>{row.label}</strong>
                          </TableCell>
                        ))}
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {record.row_set.map((row, rx) => {
                        const background = rx % 2 === 0 && lighten(theme.palette.primary.light, 0.9);
                        return (
                          <>
                            <TableRow style={{ background }}>
                              <TableCell colSpan={record.column_set.length}>
                                #{row.position} - <strong>{row.name}</strong>
                              </TableCell>

                              <TableCell width={1} rowSpan={3}>
                                <ButtonGroup>
                                  <IconButton onClick={() => actions.setDrawer({ name: "RowSave", record_id: row.id })}>
                                    <Edit />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => actions.setDrawer({ name: "RowDelete", record_id: row.id })}
                                  >
                                    <Delete />
                                  </IconButton>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ background }}>
                              {record.column_set.map((column) => {
                                const value = record.value_set.find(
                                  (x) => x.row_id === row.id && x.column_id === column.id,
                                );

                                if (!value)
                                  return (
                                    <TableCell>
                                      <em>Geen invulling aan gegeven!</em>

                                      <IconButton
                                        onClick={() => {
                                          actions.setDrawer({
                                            name: "ValueSave",
                                            row_id: row.id,
                                            column_id: column.id,
                                          });
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </TableCell>
                                  );

                                return (
                                  <TableCell style={{ verticalAlign: "top" }} align="left">
                                    <IconButton
                                      onClick={() => {
                                        actions.setDrawer({
                                          name: "ValueSave",
                                          row_id: row.id,
                                          column_id: column.id,
                                          record_id: value.id,
                                        });
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                    {value.has_own_score && <Chip label={`Score: ${value.score}`} />}
                                    <ReactMarkdown source={value.description} />
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                            <TableRow style={{ background }}>
                              <TableCell colSpan={record.column_set.length}>
                                <ReactMarkdown source={row.description} />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
                <CardActions>
                  <Button variant="outlined" startIcon={<Add />} onClick={() => actions.setDrawer({ name: "RowSave" })}>
                    Toevoegen
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {state.drawer && (
        <>
          {state.drawer.name === "ColumnSave" && (
            <MatrixColumnSaveDrawer
              matrix_id={record.id}
              record_id={state.drawer.record_id}
              onClose={async (success?: boolean) => {
                if (success) {
                  await actions.load();
                }

                actions.setDrawer(undefined);
              }}
            />
          )}
          {state.drawer.name === "ColumnDelete" && (
            <MatrixColumnDeleteDialog
              record_id={state.drawer.record_id}
              onClose={async (success?: boolean) => {
                if (success) {
                  await actions.load();
                }

                actions.setDrawer(undefined);
              }}
            />
          )}
          {state.drawer.name === "RowSave" && (
            <MatrixRowSaveDrawer
              matrix_id={record.id}
              record_id={state.drawer.record_id}
              onClose={async (success?: boolean) => {
                if (success) {
                  await actions.load();
                }

                actions.setDrawer(undefined);
              }}
            />
          )}
          {state.drawer.name === "RowDelete" && (
            <MatrixRowDeleteDialog
              record_id={state.drawer.record_id}
              onClose={async (success?: boolean) => {
                if (success) {
                  await actions.load();
                }

                actions.setDrawer(undefined);
              }}
            />
          )}
          {state.drawer.name === "ValueSave" && (
            <MatrixValueSaveDrawer
              record_id={state.drawer.record_id}
              matrix_id={record.id}
              column_id={state.drawer.column_id}
              row_id={state.drawer.row_id}
              onClose={async (success?: boolean) => {
                if (success) {
                  await actions.load();
                }

                actions.setDrawer(undefined);
              }}
            />
          )}
        </>
      )}
    </SkeletonPage>
  );
};

type State = {
  busy: boolean;
  record?: MatrixCoreDtoExt;
  drawer?:
    | { name: "ColumnSave"; record_id?: number }
    | { name: "ColumnDelete"; record_id: number }
    | { name: "ValueSave"; record_id?: number; row_id: number; column_id: number }
    | { name: "RowSave"; record_id?: number }
    | { name: "RowDelete"; record_id: number };
};

function useComponent() {
  const app = useAppStore();
  const params = useParams<{ record_id: string }>();
  const record_id = parseInt(params.record_id);

  const { state, next } = useRxjsStore<State>({
    busy: true,
    record: undefined,
  });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));
        const rec = await callApi(app, async (api) => (await api.matrixCoreOnePost({ id: record_id })).data);
        next((d) => {
          d.busy = false;
          d.record = rec;
        });
      },
      setDrawer: (value?: State["drawer"]) => {
        next((d) => (d.drawer = value));
      },
    }),
    [record_id],
  );

  useAsyncEffect(actions.load, [record_id]);

  return { state, actions };
}

export default MatrixCoreView;
