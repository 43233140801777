import React from "react";
import _ from "underscore";
import { GenericLogTableRowsRequest } from "../../api";
import useAppStore from "../../core/useAppStore";
import useDebouncedValueCallback from "../../core/useDebouncedValueCallback";
import { Box, Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import TextFieldExt from "../../form/TextFieldExt";
import useRxjsForm from "../../core/useRxjsForm";
import useTableStyles from "../../core/useTableStyles";
import useLogsSessionloginTableStore from "./useLogsSessionloginTableStore";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import FilterListIcon from "@material-ui/icons/FilterList";
import GetAppIcon from "@material-ui/icons/GetApp";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import AutocompleteFieldExt from "../../form/AutocompleteFieldExt";

type Props = {
  initial: Values;
  onChange: (props: Partial<GenericLogTableRowsRequest>) => void;
};

type Values = {
  search: string;
};

const LogsSessionloginTableToolbar: React.FC<Props> = (props) => {
  const form = useRxjsForm<Values>({ initial: props.initial });
  const table = useLogsSessionloginTableStore();
  const classes = useTableStyles();
  const app = useAppStore();
  const [, setFirst] = useDebouncedValueCallback((v) => props.onChange(v), form.values, 250);

  // Refresh the form if our props get updated.
  React.useEffect(() => {
    const keys: any = ["search", "is_archived"];
    const equal = _.isEqual(_.pick(form.values, keys), _.pick(props.initial, keys));

    if (!equal) {
      setFirst(true);
      form.actions.setValues(props.initial);
    }
  }, [props.initial]);

  const has_filters = React.useMemo(() => {
    const keys = ["search", "header_set", "offset", "limit"];
    const values = _.values(_.omit(table.req, keys));

    return values.some((x) => !_.isEmpty(x));
  }, [table.req]);

  return (
    <React.Fragment>
      <Hidden smDown>
        <Box display="flex" flexDirection="row" ml={1}>
          <Tooltip title="Filters legen.">
            <IconButton onClick={() => table.actions.reset()}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid
          container
          component="form"
          noValidate
          onSubmit={form.actions.submit}
          className={classes.TableToolbarForm}
          spacing={2}
        >
          <Grid item xs={12} md>
            <TextFieldExt form={form} name="search" label="Zoeken ..." autoFocus />
          </Grid>
          <Grid item xs={12} md={4}>
            <AutocompleteFieldExt
              form={form}
              name="role_id_set"
              label="Rol"
              multiple
              items={app.data.crm_role_set.map((x) => ({
                label: x.name,
                value: x.id,
              }))}
            />
          </Grid>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
};

export default LogsSessionloginTableToolbar;
