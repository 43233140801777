import { LocationState, Path } from "history";
import { useHistory } from "react-router-dom";

export default function useNavigate(replace: boolean = false): (path: Path, state?: LocationState) => void {
  const history = useHistory();

  if (replace) {
    return history.replace;
  }

  return history.push;
}
