import * as React from "react";
import { DetailEnum, TsCompanyDto } from "../../api";
import useApp from "../../core/useAppStore";
import useNavigate from "../../core/useNavigate";
import useRxjsForm from "../../core/useRxjsForm";
import { getApi } from "../../core/useApi";
import { Button, Card, CardContent, Grid, Link, TextField, Typography } from "@material-ui/core";
import TextFieldExt from "../../form/TextFieldExt";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SendIcon from "@material-ui/icons/Send";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { DmzAuthenticatePageActions, useDmzAuthenticateStyles } from "./DmzAuthenticate";
import { makeStyles } from "@material-ui/core/styles";
import useGenericStyles from "../../core/useGenericStyles";
import DmzAuthenticateStepper from "./DmzAuthenticateStepper";

const DmzAuthenticateScreenSessionLogin: React.FC<{
  redirect: string;
  email: string;
  company_id: number;
  company_set: TsCompanyDto[];
  actions: DmzAuthenticatePageActions;
}> = ({ redirect, email, company_id, company_set, actions }) => {
  const classes = useDmzAuthenticateStyles();
  const gc = useGenericStyles();
  const app = useApp();
  const navigate = useNavigate();
  const form = useRxjsForm<{ password: string }>({
    initial: { password: "" },
    submit: async ({ password }) => {
      const res = await getApi().tsSessionLoginPost({
        email,
        password,
        company_id,
      });

      app.actions.addMessage(`Dag ${res.data.person.name}, welkom terug!`);
      await app.actions.setAuthentication(res.data, () => {
        if (!redirect.startsWith("/dmz")) {
          navigate(redirect);
        } else {
          navigate("/profile/dashboard");
        }
      });
    },
    default_errors: {
      [DetailEnum.ACCOUNTPASSWORDINVALID]: ["password", "Het wachtwoord is incorrect."],
    },
  });

  const company = company_set.find((x) => x.id === company_id);

  return (
    <Card className={classes.Form} component={"form"} onSubmit={form.actions.submit}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Aanmelden
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Wachtwoord van account controleren.
        </Typography>

        <DmzAuthenticateStepper step={2} steps={["E-mail", "Organisatie", "Wachtwoord"]} />

        <Typography variant="body1" paragraph>
          U gaat nu inloggen met het account <strong className={gc.Text_NoWrap}>{email}</strong> bij de organisatie{" "}
          <strong className={gc.Text_NoWrap}>{company.name}</strong>. De eerste controle die we uitvoeren is het
          wachtwoord. U kunt deze hieronder invoeren.
        </Typography>

        <TextFieldExt
          form={form}
          name="password"
          label="Voer uw wachtwoord in."
          type="password"
          margin="normal"
          autoFocus
        />
      </CardContent>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              color={!form.hasErrors ? "primary" : "default"}
              disabled={form.submitting}
              startIcon={
                form.submitting ? <HourglassEmptyIcon /> : !form.hasErrors ? <SendIcon /> : <ErrorOutlineIcon />
              }
            >
              Aanmelden
            </Button>
          </Grid>

          <Grid item xs>
            <Link
              href="#session-login"
              onClick={(e) => ~e.preventDefault() && actions.setScreen("reset-setup")}
              variant="body2"
            >
              Wachtwoord vergeten?
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="#session-identify"
              onClick={(e) => ~e.preventDefault() && actions.setScreen("session-identify")}
              variant="body2"
            >
              Ander e-mailadres.
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DmzAuthenticateScreenSessionLogin;
