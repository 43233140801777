import React from "react";
import { createStyles } from "@material-ui/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

const SkeletonPage: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.SkeletonPage_Container}>{children}</div>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    SkeletonPage_Container: {
      padding: theme.spacing(3),
    },
  }),
);

export default SkeletonPage;
