import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@material-ui/core";

type CriticalDataOwnOverviewTableCloseMeasurementDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  data: any;
};

const CriticalDataOwnOverviewTableMeasurementDialog: React.FC<CriticalDataOwnOverviewTableCloseMeasurementDialogProps> =
  (props) => {
    const { isOpen, onClose, data } = props;
    const theme = useTheme();

    if (data === null) return null;

    return (
      <Dialog open={isOpen} onClose={() => onClose()} fullWidth>
        <DialogTitle style={{ color: theme.palette.primary.main }}>
          {data.open === true && "De meting sluiten"}
          {data.open === false && "De meting heropenen"}
        </DialogTitle>
        <DialogContent>
          {data.open === true && (
            <Typography>
              U staat op het punt om de meting van&nbsp;
              <span style={{ color: theme.palette.warning.light }}>{data.name}</span> te sluiten. Klik op de knop meting
              sluiten om de meting te sluiten.
            </Typography>
          )}
          {data.open === false && (
            <Typography>
              De meting voor <span style={{ color: theme.palette.success.main }}>{data.name}</span> is gesloten. Wilt u
              deze meting heropenen? Klik dan op heropenen.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={"text"} onClick={() => onClose()}>
            Sluiten
          </Button>
          <Button style={{ backgroundColor: theme.palette.primary.main, color: "#fff" }} variant={"contained"}>
            {data.open === true && "Meting sluiten"}
            {data.open === false && "Meting heropenen"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default CriticalDataOwnOverviewTableMeasurementDialog;
