import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, ArrowBack, Edit, Loyalty, MoreVert, PlayArrow, Stop } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { CrmCompanyDto } from "../../../api";
import DataTableExt from "../../../core/DataTableExt";
import HasPlugin from "../../../core/HasPlugin";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import { FormatValue } from "../../../core/TableCellValue";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import SkeletonLoading from "../../../skeleton/SkeletonLoading";
import SkeletonPage from "../../../skeleton/SkeletonPage";
import CrmCompanyArchiveDialog from "../Drawers/CrmCompanyArchiveDialog";
import CrmCompanyLocationArchiveDialog from "../Drawers/CrmCompanyLocationArchiveDialog";
import CrmCompanyLocationSaveDrawer from "../Drawers/CrmCompanyLocationSaveDrawer";
import CrmCompanySaveDrawer from "../Drawers/CrmCompanySaveDrawer";
import CrmCompanySaveMatrixDrawer from "../Drawers/CrmCompanySaveMatrixDrawer";
import CrmCompanyDataTableExt from "../Ext/CrmCompanyDataTableExt";
import CrmCompanyLocationShortCode from "../Ext/CrmCompanyLocationShortCode";

const CrmCompanyView: React.FC = () => {
  const _t = useTranslations();
  const { state, actions } = useComponent();
  const app = useAppStore();
  const company = state.record;
  const gc = useGenericStyles();

  if (!company) {
    return (
      <SkeletonPage>
        <SkeletonLoading label="De pagina is aan het laden" />
      </SkeletonPage>
    );
  }

  const matrix_core_set = app.data.matrix_core_set.filter((x) => x.has_responsibles);

  return (
    <SkeletonPage>
      <Card>
        {state.busy && <LinearProgress style={{ position: "absolute", width: "100%", height: 3 }} />}
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Tooltip title="Vorige pagina." placement="right">
                <IconButton onClick={() => window.history.back()}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" component="h2">
                <small>#{company.id}.</small> {company.name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButtonDropExt
                icon={<MoreVert />}
                items={[
                  {
                    icon: <Edit />,
                    label: "Aanpassen",
                    onClick: () => actions.setDrawer({ name: "Save" }),
                  },
                  {
                    icon: company.is_archived ? <PlayArrow /> : <Stop />,
                    label: company.is_archived ? "Heractiveren" : "Archiveren",
                    onClick: () => actions.setDrawer({ name: "Archive" }),
                  },
                  {
                    icon: <Add />,
                    label: `${_t("Locatie")} toevoegen`,
                    onClick: () => actions.setDrawer({ name: "LocationSave" }),
                  },
                ]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md>
              <Card variant="outlined">
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Gegevens
                  </Typography>
                </CardContent>
                <CrmCompanyDataTableExt company={company} />
              </Card>
            </Grid>
            <HasPlugin name="capdash" negate>
              <Grid item md>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      {_t("Locaties")}
                    </Typography>
                  </CardContent>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>{_t("Locatie")}</TableCell>
                        <TableCell>
                          <Tooltip title="Bed capaciteit totaal beschikbaar">
                            <em>Ct.</em>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Bed capaciteit bezet door externe">
                            <em>Ce.</em>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={`Bed capaciteit in gebruik door ${app.session.company.name}`}>
                            <em>Cg.</em>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title={`Bed capaciteit actueel beschikbaar voor ${app.session.company.name}`}>
                            <em>Cb.</em>
                          </Tooltip>
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {company.location_set.map((location) => (
                        <TableRow>
                          <TableCell className={gc.TableCell_Shrink}>
                            <Tooltip title={location.is_archived ? "Gearchiveerd" : "Actief"}>
                              {location.is_archived ? <Stop /> : <PlayArrow />}
                            </Tooltip>
                          </TableCell>
                          <TableCell>{location.name}</TableCell>
                          <TableCell className={gc.TableCell_Shrink}>{location.capacity_total}</TableCell>
                          <TableCell className={gc.TableCell_Shrink}>{location.capacity_external}</TableCell>
                          <TableCell className={gc.TableCell_Shrink}>{location.capacity_taken}</TableCell>
                          <TableCell className={gc.TableCell_Shrink}>{location.capacity_available}</TableCell>
                          <TableCell className={gc.TableCell_Shrink}>
                            <IconButtonDropExt
                              items={[
                                {
                                  icon: <Edit />,
                                  label: "Aanpassen",
                                  onClick: () =>
                                    actions.setDrawer({
                                      name: "LocationSave",
                                      record_id: location.id,
                                    }),
                                },
                                {
                                  icon: location.is_archived ? <PlayArrow /> : <Stop />,
                                  label: "Archiveren",
                                  onClick: () =>
                                    actions.setDrawer({
                                      name: "LocationArchive",
                                      record_id: location.id,
                                    }),
                                },
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <CardActions>
                    <ButtonGroup>
                      <Button
                        variant="text"
                        startIcon={<Add />}
                        size="small"
                        onClick={() => actions.setDrawer({ name: "LocationSave" })}
                      >
                        {_t("Locatie")} toevoegen
                      </Button>
                    </ButtonGroup>
                  </CardActions>
                </Card>
              </Grid>
            </HasPlugin>
            <HasPlugin name="capdash">
              <Grid item md>
                <Card style={{ marginBottom: 10 }}>
                  <CardContent>
                    <Typography variant="h5">{_t("Locaties")}</Typography>
                    <Box mt={1}>
                      <Chip
                        label={`Alle`}
                        className={gc.Chip_Inline}
                        onClick={() => actions.setLocationFilter("all")}
                        variant={state.locationFilter === "all" ? "default" : "outlined"}
                      />
                      <Chip
                        label={`Actief`}
                        className={gc.Chip_Inline}
                        onClick={() => actions.setLocationFilter("active")}
                        variant={state.locationFilter === "active" ? "default" : "outlined"}
                      />
                      <Chip
                        label={`Gearchiveerd`}
                        className={gc.Chip_Inline}
                        onClick={() => actions.setLocationFilter("archived")}
                        variant={state.locationFilter === "archived" ? "default" : "outlined"}
                      />
                    </Box>
                  </CardContent>
                </Card>

                {company.location_set
                  .filter(
                    (x) => state.locationFilter === "all" || x.is_archived === (state.locationFilter === "archived"),
                  )
                  .map((location) => {
                    return (
                      <Accordion key={location.id}>
                        <AccordionSummary>
                          <Typography variant="h6" className={gc.Accordion_HeadingPrimary__Large}>
                            <CrmCompanyLocationShortCode location={location} />
                          </Typography>
                          <Typography variant="h6" className={gc.Accordion_HeadingSecondary__Small}>
                            {location.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item md>
                              <Card variant="outlined">
                                <DataTableExt
                                  data={[
                                    [
                                      "Gearchiveerd",
                                      company.is_archived && <strong>Deze {_t("ketenpartner")} is gearchiveerd</strong>,
                                    ],
                                    ["Naam", location.name],
                                    ["Levensloopregio", location.cd_life_course_region],
                                    ["Beveiligingsniveau", location.cd_security_level],
                                    ["Kenmerk", location.cd_characteristic_specialism],
                                    ["Primair specialistme", location.cd_primary_specialism],
                                    ["Rechtsregime", location.cd_legal_regime_set.join(", ")],
                                    ["Type zorg", location.cd_care_type],
                                    ["Bed capaciteit", location.cd_care_capacity],
                                    ["Contra indicatie(s)", location.cd_contraindication_set.join(", ")],
                                    [
                                      "Toelichting op specialisme",
                                      location.cd_characteristic_specialism_explanation,
                                      { preserve_whitespace: true },
                                    ],
                                  ]}
                                />
                              </Card>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                        <AccordionActions>
                          <Button
                            startIcon={location.is_archived ? <PlayArrow /> : <Stop />}
                            onClick={() =>
                              actions.setDrawer({
                                name: "LocationArchive",
                                record_id: location.id,
                              })
                            }
                          >
                            {location.is_archived ? "Heropenen" : "Archiveren"}
                          </Button>
                          <Button
                            startIcon={<Edit />}
                            onClick={() =>
                              actions.setDrawer({
                                name: "LocationSave",
                                record_id: location.id,
                              })
                            }
                          >
                            Aanpassen
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    );
                  })}
              </Grid>
            </HasPlugin>

            <Grid item xs={12}>
              {matrix_core_set.map((matrix_core) => {
                const crm_company_matrix = company.crm_company_matrix_set.find(
                  (x) => x.matrix_core_id === matrix_core.id,
                );
                const is_scored = crm_company_matrix?.is_scored;
                return (
                  <Box marginTop={2}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          {matrix_core.name}
                        </Typography>
                        {!is_scored && (
                          <Typography color="error">
                            Deze zorg-matrix is niet gescored voor deze {_t("ketenpartner")}. De {_t("ketenpartner")}{" "}
                            zal hierdoor een lagere score krijgen bij het automatisch matchen tijdens het koppelen.
                          </Typography>
                        )}
                        {is_scored && (
                          <>
                            <Table>
                              <TableBody>
                                {crm_company_matrix.crm_company_matrix_value_set.map((crm_company_matrix_value) => {
                                  const matrix_row = matrix_core.row_set.find(
                                    (x) => x.id === crm_company_matrix_value.matrix_row_id,
                                  );
                                  const matrix_column = matrix_core.column_set.find(
                                    (x) => x.id === crm_company_matrix_value.matrix_column_id,
                                  );
                                  const matrix_value = matrix_core.value_set.find(
                                    (x) => x.id === crm_company_matrix_value.matrix_value_id,
                                  );

                                  return (
                                    <TableRow>
                                      <TableCell>{matrix_row.name}</TableCell>
                                      <TableCell className={gc.TableCell_Shrink}>
                                        {matrix_column.name}
                                        <br />
                                        {matrix_column.label}
                                      </TableCell>
                                      <TableCell>
                                        {matrix_value.description}
                                        {matrix_value.score}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="text"
                          startIcon={<Loyalty />}
                          size="small"
                          onClick={() => actions.setDrawer({ name: "SaveMatrix", matrix_core_id: matrix_core.id })}
                        >
                          Beheer score
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {state.drawer && (
        <>
          {state.drawer.name === "Save" && (
            <CrmCompanySaveDrawer
              record_id={company.id}
              onClose={async (success) => {
                if (success) await actions.load();
                actions.setDrawer();
              }}
            />
          )}
          {state.drawer.name === "Archive" && (
            <CrmCompanyArchiveDialog
              record_id={company.id}
              onClose={async (success) => {
                if (success) await actions.load();
                actions.setDrawer();
              }}
            />
          )}
          {state.drawer.name === "LocationSave" && (
            <CrmCompanyLocationSaveDrawer
              record_id={state.drawer.record_id}
              company_id={company.id}
              onClose={async (success) => {
                if (success) await actions.load();
                actions.setDrawer();
              }}
            />
          )}
          {state.drawer.name === "LocationArchive" && (
            <CrmCompanyLocationArchiveDialog
              record_id={state.drawer.record_id}
              onClose={async (success) => {
                if (success) await actions.load();
                actions.setDrawer();
              }}
            />
          )}
          {state.drawer.name === "SaveMatrix" && (
            <CrmCompanySaveMatrixDrawer
              crm_company_id={company.id}
              matrix_core_id={state.drawer.matrix_core_id}
              onClose={async (success) => {
                if (success) await actions.load();
                actions.setDrawer();
              }}
            />
          )}
        </>
      )}
    </SkeletonPage>
  );
};

type State = {
  busy: boolean;
  record?: CrmCompanyDto;
  locationFilter: "all" | "active" | "archived";
  drawer?:
    | { name: "Save" }
    | { name: "Archive" }
    | { name: "LocationSave"; record_id?: number }
    | { name: "LocationArchive"; record_id: number }
    | { name: "SaveMatrix"; matrix_core_id: number };
};

function useComponent() {
  const app = useAppStore();
  const params = useParams<{ record_id: string }>();
  const record_id = parseInt(params.record_id);

  const { state, next } = useRxjsStore<State>({
    busy: true,
    locationFilter: "active",
    record: undefined,
  });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));
        const rec = await callApi(app, async (api) => (await api.crmCompanyOnePost({ id: record_id })).data);
        next((d) => {
          d.busy = false;
          d.record = rec;
        });
      },
      setDrawer: (value?: State["drawer"]) => {
        next((d) => (d.drawer = value));
      },
      setLocationFilter: (value: State["locationFilter"]) => {
        next((d) => (d.locationFilter = value));
      },
    }),
    [record_id],
  );

  useAsyncEffect(actions.load, [record_id]);

  return { state, actions };
}

export default CrmCompanyView;
