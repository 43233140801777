import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import ReactQuill from "react-quill";
import _ from "underscore";
import {
  CareDossierTagDto,
  CareMatrixRowDto,
  CareMatrixRowTagDto,
  CrmPersonManyResponse,
  TagCategoryAppliesToEnum,
  TagCategoryDto,
} from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useIsMounted from "../../../core/useIsMounted";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import DateFieldExt from "../../../form/DateFieldExt";

type Props = {
  care_matrix_row_tag_id: number;
  care_matrix_row_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const CareMatrixDrawerRowTag: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { app, state, form, actions } = useComponent(props);

  const tag_category_id_set = useMemo(
    () =>
      app.data.tag_category_set
        .filter((x) => x.applies_to_set.indexOf(TagCategoryAppliesToEnum.CAREMATRIX) > -1)
        .map((x) => x.id),
    [],
  );
  const tag_category_map: { [name: number]: TagCategoryDto } = useMemo(
    () =>
      _.object(
        app.data.tag_category_set.filter((x) => _.contains(tag_category_id_set, x.id)).map((x) => [x.id, x]),
      ) as any,
    [],
  );
  const tag_set = useMemo(
    () => app.data.tag_core_set.filter((x) => _.contains(tag_category_id_set, x.category_id)),
    [],
  );

  const tag = useMemo(() => app.data.tag_core_set.find((x) => x.id === form.values.tag_id), [form.values.tag_id]);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Tag</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteFieldExt
                form={form}
                name="tag_id"
                label="Tag"
                items={tag_set.map((x) => ({
                  value: x.id,
                  label: x.name,
                  extra: { tag: x, category: tag_category_map[x.category_id] },
                }))}
                autocompleteProps={{
                  groupBy: (x) => x.extra.category.name,
                }}
              />
            </Grid>

            {tag && (
              <>
                {(tag.has_start || tag.has_end) && (
                  <Grid item container spacing={2}>
                    {tag.has_start && (
                      <Grid item md={6}>
                        <DateFieldExt form={form} label="Start-datum" name="date_start" />
                      </Grid>
                    )}
                    {tag.has_end && (
                      <Grid item md={6}>
                        <DateFieldExt form={form} label="Eind-datum" name="date_end" />
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" disabled={state.busy} onClick={() => actions.onClose()}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={state.busy}>
            Opslaan
          </Button>
        </DialogActions>
      </form>
      <DialogBusy busy={state.busy} />
    </Drawer>
  );
};

type State = {
  booted: boolean;
  busy: boolean;
  care_matrix_row_tag?: CareMatrixRowTagDto;
  care_matrix_row?: CareMatrixRowDto;
};

type Values = {
  tag_id: number;
  date_start: string;
  date_end: string;
  owner_id_set: number[];
};

function useComponent(props: Props) {
  const app = useAppStore();
  const isMounted = useIsMounted();
  const { state, getState, next } = useRxjsStore<State>({ booted: false, busy: false });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));

        const care_matrix_row = await callApi(
          app,
          async (api) => (await api.careMatrixRowOnePost({ id: props.care_matrix_row_id })).data,
        );

        const care_matrix_row_tag = care_matrix_row.tag_set.find((x) => x.id === props.care_matrix_row_tag_id);

        next((d) => {
          d.busy = false;
          d.care_matrix_row = care_matrix_row;
          d.care_matrix_row_tag = care_matrix_row_tag;
        });
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));
        await props.onClose(success);
        if (isMounted()) next((d) => (d.busy = false));
      },
      submit: async (values: Values) => {
        next((d) => (d.busy = true));

        const res = await callApi(
          app,
          async (api) =>
            (
              await api.careMatrixRowTagSavePost({
                id: props.care_matrix_row_tag_id,
                care_matrix_row_id: props.care_matrix_row_id,
                ...values,
              })
            ).data,
        );

        if (res !== undefined) {
          await actions.onClose(true);
        } else {
          next((d) => (d.busy = false));
        }
      },
    }),
    [props.care_matrix_row_id],
  );

  const form = useRxjsForm<Values>({
    initial: {
      tag_id: null,
      date_start: null,
      date_end: null,
      owner_id_set: [],
    },
    submit: async (values) => {
      await actions.submit(values);
    },
  });

  useAsyncEffect(actions.load, [props.care_matrix_row_id]);
  useEffect(() => {
    form.actions.setValues(
      state.care_matrix_row_tag ? _.pick(state.care_matrix_row_tag, Object.keys(form.initial) as any) : form.initial,
    );
  }, [state.care_matrix_row?.target]);

  return { app, state, form, actions };
}

export default CareMatrixDrawerRowTag;
