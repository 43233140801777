import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";
import clsx from "clsx";
import { Style_SkeletonDrawer_Width } from "./Drawer/useSkeletonDrawerStyles";
import useApp from "../core/useAppStore";

const SkeletonMain: React.FC = ({ children }) => {
  const app = useApp();
  const classes = useStyles();

  return (
    <main
      className={clsx(classes.SkeletonMain_Container, {
        [classes.SkeletonMain_ContainerClosed]: !(app.is_authorized && app.drawer.is_open),
      })}
    >
      <div className={classes.SkeletonMain_ToolbarClipper} />

      {children}
    </main>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    SkeletonMain_Container: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    SkeletonMain_ContainerClosed: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: -Style_SkeletonDrawer_Width,
    },

    SkeletonMain_ToolbarClipper: theme.mixins.toolbar,
  }),
);

export default SkeletonMain;
