import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { CrmNoteDto, CrmNoteManyResponse, CrmNoteModeEnum } from "../../../api";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useDebounce from "../../../core/useDebounce";
import useDebouncedCallback from "../../../core/useDebouncedCallback";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import CrmNoteDialogArchive from "../../CrmNote/Drawers/CrmNoteDialogArchive";
import { CareDossierViewExtNotesProps } from "./CareDossierViewExtNotes";
import CareDossierViewExtNotesWriter from "./CareDossierViewExtNotesWriter";
import CareDossierViewExtNoteView from "./CareDossierViewExtNoteView";

const CareDossierViewExtNotesDrawer: React.FC<CareDossierViewExtNotesProps> = (props) => {
  const _t = useTranslations();
  const { state, actions } = useCareDossierViewExtDrawerComponent(props);
  const gc = useGenericStyles();

  return (
    <>
      <DialogTitle>
        <Grid container spacing={1} alignItems={"center"} style={{ paddingTop: 10 }} justifyContent={"space-between"}>
          <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
            Notities en Afspraken
          </Typography>

          <Button startIcon={<CloseIcon />} color="primary" onClick={() => props.actions.toggleNotes()}>
            Sluiten
          </Button>
        </Grid>
      </DialogTitle>

      <Paper square>
        <Tabs
          value={state.tab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, v) => {
            actions.setTab(v);
          }}
        >
          <Tab label={`Notities (${state.record_set.length} / ${state.notes?.records.length})`} value="notes" />
          {state.crm_note_id_set.map((crm_note_id, rx) => (
            <Tab
              key={`write-${rx}`}
              label={crm_note_id ? `Aanpassen #${crm_note_id}` : "Nieuw"}
              value={`write-${rx}`}
            />
          ))}
        </Tabs>
      </Paper>

      {state.crm_note_id_set.map((crm_note_id, rx) => {
        return (
          <CareDossierViewExtNotesWriter
            key={`${crm_note_id || "x"}-${rx}`}
            id={crm_note_id}
            hidden={state.tab !== `write-${rx}`}
            connected_care_dossier_id={props.dossier.id}
            involved_company_id_set={props.dossier.person.involved_company_id_set}
            involved_person_id_set={props.dossier.person.involved_person_id_set}
            onClose={async (success?: boolean) => {
              if (success) {
                await actions.loadNotes();
              }
              actions.closeWriter(rx);
            }}
          />
        );
      })}

      <DialogContent style={{ display: state.tab !== "notes" ? "none" : "flex", flexDirection: "column" }}>
        <DialogContent>
          <Box mb={2}>
            <Box>
              <TextField
                name="search"
                label="Zoeken op titel of omschrijving"
                variant="outlined"
                fullWidth
                value={state.search}
                onChange={(evt) => actions.setSearch(evt.target.value)}
              />
              <Box mt={1}>
                <Typography variant="subtitle2">Status filters:</Typography>
                <Chip
                  label={`Alles (${state.counts.all})`}
                  className={gc.Chip_Inline}
                  onClick={() => actions.setFilterClosed("all")}
                  variant={state.filter_closed === "all" ? "default" : "outlined"}
                />
                <Chip
                  label={`Openstaand (${state.counts.open})`}
                  className={gc.Chip_Inline}
                  onClick={() => actions.setFilterClosed("open")}
                  variant={state.filter_closed === "open" ? "default" : "outlined"}
                />
                <Chip
                  label={`Afgerond (${state.counts.closed})`}
                  className={gc.Chip_Inline}
                  onClick={() => actions.setFilterClosed("closed")}
                  variant={state.filter_closed === "closed" ? "default" : "outlined"}
                />
              </Box>
            </Box>

            <Box>
              <Typography variant="subtitle2">Type filters:</Typography>
              <Chip
                label={`Alles (${state.counts.all})`}
                className={gc.Chip_Inline}
                onClick={() => actions.setFilterMode("all")}
                variant={state.filter_mode === "all" ? "default" : "outlined"}
              />
              <Chip
                label={`Notities (${state.counts.mode_dossier})`}
                className={gc.Chip_Inline}
                onClick={() => actions.setFilterMode(CrmNoteModeEnum.DOSSIER)}
                variant={state.filter_mode === CrmNoteModeEnum.DOSSIER ? "default" : "outlined"}
              />
              <Chip
                label={`Afspraken (${state.counts.mode_matrix_row})`}
                className={gc.Chip_Inline}
                onClick={() => actions.setFilterMode(CrmNoteModeEnum.MATRIXROW)}
                variant={state.filter_mode === CrmNoteModeEnum.MATRIXROW ? "default" : "outlined"}
              />
            </Box>
          </Box>

          {state.notes &&
            state.record_set.map((note, rx) => {
              return (
                <CareDossierViewExtNoteView
                  note={note}
                  isDefaultExpanded={rx === 0}
                  openWriter={actions.openWriter}
                  setDialog={actions.setDialog}
                />
              );
            })}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => actions.openWriter()}>
            Notitie toevoegen
          </Button>
        </DialogActions>
      </DialogContent>

      {state.dialog?.name === "archive" && (
        <CrmNoteDialogArchive
          crm_note_id={state.dialog.crm_note_id}
          onClose={async (success?: boolean) => {
            actions.setDialog();
            if (success) {
              await actions.loadNotes();
            }
          }}
        />
      )}
    </>
  );
};

export type CareDossierViewExtNotesDrawerState = {
  tab: string;
  notes?: CrmNoteManyResponse;
  dialog?:
    | {
        name: "note";
        crm_note_id?: number;
      }
    | {
        name: "archive";
        crm_note_id?: number;
      };
  record_set: CrmNoteDto[];

  search: string;
  filter_mode: "all" | CrmNoteModeEnum;
  filter_closed: "all" | "open" | "closed";

  crm_note_id_set: (number | undefined)[];

  counts: {
    open: number;
    closed: number;
    all: number;
    mode_dossier: number;
    mode_matrix_row: number;
  };
};

function useCareDossierViewExtDrawerComponent(props: CareDossierViewExtNotesProps) {
  const { state, next } = useRxjsStore<CareDossierViewExtNotesDrawerState>({
    tab: "notes",
    crm_note_id_set: [],

    counts: {
      open: 0,
      closed: 0,
      all: 0,
      mode_dossier: 0,
      mode_matrix_row: 0,
    },
    search: "",
    filter_mode: "all",
    filter_closed: "all",
    record_set: [],
  });
  const app = useAppStore();
  const actions = useMemo(
    () => ({
      loadNotes: async () => {
        const res = await callApi(
          app,
          async (api) =>
            await api.crmNoteManyPost({
              connected_care_dossier_id: props.dossier.id,
              closed_state: [],
              is_closed: -1,
            }),
        );
        next((d) => {
          d.notes = res.data;

          const hasNotesDraft = d.crm_note_id_set.length > 0;
          window.localStorage.setItem("notes.draft", JSON.stringify(hasNotesDraft));
          window.dispatchEvent(new CustomEvent("notes.draft", { detail: hasNotesDraft }));
        });
      },
      setSearch: (value: string) => {
        next((s) => (s.search = value));
      },
      setFilterMode: (value: CareDossierViewExtNotesDrawerState["filter_mode"]) => {
        next((d) => (d.filter_mode = value));
      },
      setFilterClosed: (value: CareDossierViewExtNotesDrawerState["filter_closed"]) => {
        next((d) => (d.filter_closed = value));
      },
      setDialog: (value?: CareDossierViewExtNotesDrawerState["dialog"]) => {
        next((d) => (d.dialog = value));
      },
      openWriter: (crm_note_id?: number) => {
        next((d) => {
          d.crm_note_id_set.push(crm_note_id);
          d.tab = `write-${d.crm_note_id_set.length - 1}`;

          const hasNotesDraft = d.crm_note_id_set.length > 0;
          window.localStorage.setItem("notes.draft", JSON.stringify(hasNotesDraft));
          window.dispatchEvent(new CustomEvent("notes.draft", { detail: hasNotesDraft }));
        });
      },
      closeWriter: (rx: number) => {
        next((d) => {
          d.crm_note_id_set.splice(rx, 1);
          d.tab = "notes";

          const hasNotesDraft = d.crm_note_id_set.length > 0;
          window.localStorage.setItem("notes.draft", JSON.stringify(hasNotesDraft));
          window.dispatchEvent(new CustomEvent("notes.draft", { detail: hasNotesDraft }));
        });
      },
      setTab: (value: string) => {
        next((d) => (d.tab = value));
      },
    }),
    [props.dossier.id],
  );

  const [searchD] = useDebounce(state.search, 250);

  useEffect(() => {
    if (!state.notes) {
      next((d) => {
        d.counts = { open: 0, closed: 0, all: 0, mode_dossier: 0, mode_matrix_row: 0 };
        d.record_set = [];
      });
    } else {
      const partial_closed: { open?: number; closed?: number } = state.notes.records
        ? _.countBy(state.notes.records, (x) => (!x.closed_at ? "open" : "closed"))
        : {};

      const partial_mode: { dossier?: number; matrix_row?: number } = state.notes.records
        ? _.countBy(state.notes.records, (x) => x.mode.toLowerCase())
        : {};

      const record_set = state.notes.records.filter((x) => {
        if (state.filter_closed !== "all" && !!x.closed_at === (state.filter_closed === "open")) return false;
        if (state.filter_mode !== "all" && x.mode !== state.filter_mode) return false;

        let searchValid = false;

        if (searchD !== "") {
          if (x.subject.toLowerCase().includes(searchD)) {
            searchValid = true;
          } else if (x.document.toLowerCase().includes(searchD)) {
            searchValid = true;
          }
        } else {
          searchValid = true;
        }

        if (!searchValid) return false;

        return true;
      });

      next((d) => {
        d.counts = {
          open: partial_closed.open || 0,
          closed: partial_closed.closed || 0,
          all: (partial_closed.open || 0) + (partial_closed.closed || 0),
          mode_dossier: partial_mode.dossier || 0,
          mode_matrix_row: partial_mode.matrix_row || 0,
        };
        d.record_set = record_set;
      });
    }
  }, [state.notes, searchD, state.filter_closed, state.filter_mode]);

  useAsyncEffect(actions.loadNotes, [props.dossier.id]);

  return { state, actions };
}

export default CareDossierViewExtNotesDrawer;
