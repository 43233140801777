import { Button, Card, CardContent, TextField, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SendIcon from "@material-ui/icons/Send";
import React from "react";
import { DetailEnum, TsCompanyDto, TsSessionResetPasswordRequest, TsSessionSotpValidateRequest } from "../../api";
import { getApi } from "../../core/useApi";
import useAppStore from "../../core/useAppStore";
import useRxjsForm from "../../core/useRxjsForm";
import TextFieldExt from "../../form/TextFieldExt";
import { DmzAuthenticatePageActions, useDmzAuthenticateStyles } from "./DmzAuthenticate";

type Props = {
  email: string;
  company_id: number;
  company_set: TsCompanyDto[];
  sotp: TsSessionSotpValidateRequest;
  actions: DmzAuthenticatePageActions;
};

type Values = {
  password: string;
};

const DmzAuthenticateScreenResetFinalize: React.FC<Props> = (props) => {
  const { email, company_id, company_set, sotp } = props;
  const classes = useDmzAuthenticateStyles();
  const { form } = useComponent(props);

  const company = company_set.find((x) => x.id === company_id);

  return (
    <Card className={classes.Form} component={"form"} onSubmit={form.actions.submit}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Herstellen
        </Typography>
        <Typography color="textSecondary" paragraph>
          De TAN-code is gevalideerd, je mag nu een nieuw wachtwoord gaan invoeren.
        </Typography>

        <TextField disabled label="E-mailadres" defaultValue={email} variant="outlined" fullWidth margin="normal" />
        <TextField
          disabled
          label="Organisatie"
          defaultValue={company.name}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          disabled
          label={`TAN-code ${sotp.sotp_id}`}
          defaultValue={sotp.sotp_password}
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <TextFieldExt
          form={form}
          name="password"
          label="Uw nieuwe wachtwoord."
          type="password"
          margin="normal"
          autoFocus
        />
      </CardContent>
      <CardContent>
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          color={!form.hasErrors ? "primary" : "default"}
          disabled={form.submitting}
          startIcon={form.submitting ? <HourglassEmptyIcon /> : !form.hasErrors ? <SendIcon /> : <ErrorOutlineIcon />}
        >
          Aanmelden
        </Button>
      </CardContent>
    </Card>
  );
};

function useComponent(props: Props) {
  const app = useAppStore();

  const form = useRxjsForm<Values>({
    initial: {
      password: "",
    },
    submit: async (values) => {
      const req: TsSessionResetPasswordRequest = {
        ...props.sotp,
        company_id: props.company_id,
        email: props.email,
        password: values.password,
      };

      const res = (await getApi().tsSessionResetPasswordPost(req)).data;

      app.actions.addMessage(<span>Je wachtwoord is nu aangepast.</span>, "success");
      await app.actions.setAuthentication(res);
    },
    default_errors: {
      [DetailEnum.NOTFOUND]: ["email", "Dit account kon niet gevonden worden."],
    },
  });

  return { form };
}

export default DmzAuthenticateScreenResetFinalize;
