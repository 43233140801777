import {
  Box,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
} from "@material-ui/core";
import { Add, Close, GetApp, RefreshOutlined, ViewColumn } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import React, { useCallback, useState } from "react";
import useNavigate from "../../core/useNavigate";

import ManagementMatrixAddMatrixDialog from "./ManagementMatrixAddMatrixDialog";

const data = [
  { name: "Statische Fare Risicofactoren" },
  { name: "Dynamische Fare Risicofactoren" },
  { name: "Zelfredzaamheid-Matrix" },
];

const ManagementMatrixTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onChange = (event, child) => {
    console.log(event.target.value);
    console.log(child);
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  return (
    <>
      <Box component={Paper} style={{ margin: 20 }}>
        {isOpen && <ManagementMatrixAddMatrixDialog isOpen={isOpen} onClose={() => onClose()} />}

        <Grid container spacing={1} alignItems={"center"} style={{ paddingTop: 10 }} justifyContent={"space-between"}>
          <Grid item xs={1}>
            <ButtonGroup>
              <RefreshOutlined onClick={() => window.location.reload()} />

              <Close />
            </ButtonGroup>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth variant={"outlined"} label={"zoeken"} />
          </Grid>
          <Grid item xs={3}>
            <Select variant={"outlined"} fullWidth native={false} onChange={onChange} defaultValue="Filter">
              <option value="Filter">Filter</option>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <ButtonGroup style={{ marginLeft: 30 }}>
              <Add onClick={() => setIsOpen(true)} />
              <GetApp />
              <ViewColumn />
            </ButtonGroup>
          </Grid>
        </Grid>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ color: theme.palette.primary.main }}>Matrix</TableCell>
              <TableCell />
            </TableRow>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{data.name}</TableCell>
                  <TableCell align={"right"}>
                    <IconButton onClick={() => navigate(`/management/matrix/view/${i}`)}>
                      <SearchIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default ManagementMatrixTable;
