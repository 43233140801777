import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatISO } from "date-fns";
import React from "react";
import _ from "underscore";
import { CareDossierOneResponse, CareDossierSaveRequest } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsForm from "../../../core/useRxjsForm";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";

// region Types

type Props = {
  onClose: (success: boolean) => void | Promise<void>;
  record_id?: number;
  person_id?: number;
};

type Values = CareDossierSaveRequest;

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

// endregion
// region Component

const CareDossierDrawerSave: React.FC<Props> = (props) => {
  const app = useAppStore();
  const classes = useStyles();
  const gc = useGenericStyles();
  const { busy, record, form, onClose } = useComponent(props);
  const tag_category = React.useMemo(() => app.data.tag_category_set.find((v) => v.name_slug === "personen"), []);
  const tag_core_set = React.useMemo(
    () => app.data.tag_core_set.filter((v) => v.category_id === tag_category.id),
    [tag_category.id],
  );

  const tag_client = React.useMemo(() => tag_core_set.find((v) => v.name_slug === "client"), [tag_core_set]);
  const is_client = React.useMemo(
    () => form.values.tag_id_set.indexOf(tag_client.id) > -1,
    [tag_client, form.values.tag_id_set],
  );

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Persoon {record ? "aanpassen" : "toevoegen"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Opzet</Typography>
              <Typography variant="body2" color="textSecondary">
                Geef met de tags aan om wat voor type persoon het gaat. De tags zorgen voor extra functionaliteiten bij
                het invoeren van gegevens, inloggen, en/of het bijhouden van dossiers.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="tag_id_set"
                label="Tags"
                items={tag_core_set.map((v) => ({ value: v.id, label: `#${v.id}. ${v.name}` }))}
                multiple
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose(false)}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>
      </form>

      <DialogBusy busy={busy} />
    </Drawer>
  );
};

// endregion
// region Store

function useComponent(props: Props) {
  const { record_id, person_id } = props;
  const [closing, setClosing] = React.useState(false);

  // Load the record from the server.
  const { record } = useRxjsFetch<CareDossierOneResponse>(async () => {
    return record_id ? (await getApi().careDossierOnePost({ id: record_id })).data : null;
  }, [record_id]);

  const form = useRxjsForm<Values>({
    initial: {
      id: undefined,
      person_id: person_id || -1,
      created_at: formatISO(Date.now()),
      tag_id_set: [],
    },
    submit: async (values) => {
      let data = _.clone(values);

      ["email", "birth", "end_of_detention_at", "wlz_expires_at", "residence_permit_expires_at", "deceased_at"].map(
        (field) => {
          if (data[field] === "") data[field] = null;
        },
      );

      await getApi().careDossierSavePost(data);
      await onClose(true);
    },
  });

  const onClose = React.useCallback(async (success: boolean) => {
    setClosing(true);
    await props.onClose(success);
    setClosing(false);
  }, []);

  // As soon as the record is loaded, inject the information into the form.
  React.useEffect(() => {
    form.actions.setValues(record ? _.pick(record, Object.keys(form.initial) as any) : form.initial);
  }, [record?.id]);

  const busy = React.useMemo(() => closing || form.submitting, [closing, form.submitting]);

  return { busy, record, form, onClose };
}

// endregion

export default CareDossierDrawerSave;
