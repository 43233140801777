import { Box } from "@material-ui/core";
import { BubbleChart } from "@material-ui/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "underscore";
import useAppStore from "./useAppStore";
import { useMeasure } from "./useMeasure";

type Props = {
  height: number;
  children: (dimensions: { width: number; height: number }) => JSX.Element;
};

const ChartResponsive: React.FC<Props> = (props) => {
  const app = useAppStore();
  const [measureRef, bounds] = useMeasure();
  const [width, setWidth] = useState<number>(-1);
  const [isResizing, setResizing] = useState(false);
  const shouldRender = useMemo(() => !isResizing && width === bounds.width, [isResizing, width, bounds.width]);

  useEffect(() => {
    let timeout = null;
    const onResize = () => {
      setResizing(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setResizing(false);
      }, 250);
    };

    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [app.drawer.is_open]);

  const debounce = useCallback(
    _.debounce((width: number) => {
      setWidth(width);
    }, 150),
    [],
  );

  useEffect(() => {
    debounce(bounds.width);
  }, [bounds.width]);

  return (
    <div ref={measureRef} style={{ width: "100%", height: props.height }}>
      {shouldRender && props.children({ width, height: props.height })}
      {!shouldRender && (
        <Box
          width="100%"
          height={props.height}
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid #ddd"
          bgcolor="#eee"
        >
          <BubbleChart />
        </Box>
      )}
    </div>
  );
};

export default ChartResponsive;
