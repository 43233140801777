import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { getApi } from "../../core/useApi";
import useApiResult from "../../core/useApiResult";

export function CareDossierTriggersToggleDialog({
  recordId,
  onClose,
  onSave,
  open,
}: {
  recordId: number;
  onClose: () => void;
  onSave: () => Promise<void>;
  open: boolean;
}) {
  const [careDossierTrigger, reload] = useApiResult(async () => {
    try {
      return (
        await getApi().careDossierTriggerOnePost({
          id: recordId,
        })
      ).data;
    } catch (e) {
      return null;
    }
  }, [recordId]);

  const isClosed = !!careDossierTrigger.res?.closed_at;

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogTitle>{isClosed ? "De meting heropenen" : "De meting sluiten"}</DialogTitle>
      <DialogContent>
        {!isClosed && (
          <Typography>
            U staat op het punt om de meting van&nbsp;
            {careDossierTrigger.res?.crm_person.name} te sluiten. Klik op de knop meting sluiten om de meting te
            sluiten.
          </Typography>
        )}
        {isClosed && (
          <Typography>
            De meting voor {careDossierTrigger.res?.crm_person.name} is gesloten. Wilt u deze meting heropenen? Klik dan
            op heropenen.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={onClose}>
          Sluiten
        </Button>
        <Button startIcon={<Close />} onClick={() => onSave()} variant="contained" color="primary" autoFocus>
          {isClosed ? "Meting heropenen" : "Meting sluiten"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
