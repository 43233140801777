import React, { useCallback, useState } from "react";
import {
  Box,
  ButtonGroup,
  Checkbox,
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
} from "@material-ui/core";
import { Add, Close, Delete, Edit, GetApp, RefreshOutlined, Search, ViewColumn } from "@material-ui/icons";
import ManagementMatrixAddScoreDialog from "./ManagementMatrixAddScoreDialog";
import ManagementMatrixScoreTableDeleteRow from "./ManagementMatrixScoreTableDeleteRow";
import ManagementMatrixOverviewScoreDialog from "./ManagementMatrixOverviewScoreDialog";
import ManagementMatrixEditScoreDialog from "./ManagementMatrixEditScoreDialog";

const data = [
  {
    id: 2,
    name: "Leeftijd eerste politie contact",
    scoreOne: "lorem ipsum",
    scoreTwo: "lorem ipsum",
    scoreThree: "lorem ipsum",
    scoreFour: "lorem ipsum",
  },
  {
    id: 5,
    name: "Aantal eerdere en huidige veroordelingen",
    scoreOne: "lorem ipsum",
    scoreTwo: "lorem ipsum",
    scoreThree: "lorem ipsum",
    scoreFour: "lorem ipsum",
  },
  {
    id: 6,
    name: "Diversiteit(dreigend) delict gedrag",
    scoreOne: "lorem ipsum",
    scoreTwo: "lorem ipsum",
    scoreThree: "lorem ipsum",
    scoreFour: "lorem ipsum",
  },
  {
    id: 9,
    name: "Regel overtredend gedrag in het verleden",
    scoreOne: "lorem ipsum",
    scoreTwo: "lorem ipsum",
    scoreThree: "lorem ipsum",
    scoreFour: "lorem ipsum",
  },
];

const ManagementMatrixScoreTable = () => {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState(data);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isOverviewDialogOpen, setIsOverviewDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const theme = useTheme();

  const deleterRow = (id) => {
    const newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
  };

  const onCloseAddDialog = useCallback(() => {
    setIsAddDialogOpen(false);
  }, []);

  const onCloseDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const onCloseOverviewDialog = useCallback(() => {
    setIsOverviewDialogOpen(false);
  }, []);

  const onCloseEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onChange = (event, child) => {
    console.log(event.target.value);
    console.log(child);
  };
  console.log(isChecked);
  return (
    <Box>
      {isEditDialogOpen && <ManagementMatrixEditScoreDialog isOpen={isEditDialogOpen} onClose={onCloseEditDialog} />}
      {isOverviewDialogOpen && (
        <ManagementMatrixOverviewScoreDialog isOpen={isOverviewDialogOpen} onClose={() => onCloseOverviewDialog()} />
      )}
      {isAddDialogOpen && (
        <ManagementMatrixAddScoreDialog
          isOpen={isAddDialogOpen}
          onClose={() => {
            onCloseAddDialog();
          }}
        />
      )}

      {isDeleteDialogOpen && (
        <ManagementMatrixScoreTableDeleteRow
          isOpen={isDeleteDialogOpen}
          onClose={() => onCloseDeleteDialog()}
          deleterRow={deleterRow}
          name={rows[0].name}
        />
      )}
      <Box component={Paper} style={{ margin: 20 }}>
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          style={{ paddingTop: 10, paddingBottom: 10 }}
          justifyContent={"space-between"}
        >
          <Grid item xs={1}>
            <ButtonGroup>
              <RefreshOutlined onClick={() => window.location.reload()} />

              <Close />
            </ButtonGroup>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth={true} variant={"outlined"} label={"zoeken"} />
          </Grid>
          <Grid item xs={3}>
            <Select variant={"outlined"} fullWidth={true} native={false} onChange={onChange} defaultValue="Filter">
              <option value="Filter">Filter</option>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <ButtonGroup style={{ marginLeft: 8 }}>
              <Add onClick={() => setIsAddDialogOpen(true)} />
              <Delete onClick={isChecked ? () => setIsDeleteDialogOpen(true) : () => setIsDeleteDialogOpen(false)} />
              <GetApp />
              <ViewColumn />
            </ButtonGroup>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ color: theme.palette.primary.main }}>Risicofactoren</TableCell>
              <TableCell style={{ color: theme.palette.primary.main }}>Score 1</TableCell>
              <TableCell style={{ color: theme.palette.primary.main }}>Score 2</TableCell>
              <TableCell style={{ color: theme.palette.primary.main }}>Score 3</TableCell>
              <TableCell style={{ color: theme.palette.primary.main }}>Score 4</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => {
              return (
                <TableRow key={data.id}>
                  <TableCell>
                    <Checkbox checked={isChecked[data.id]} onClick={() => setIsChecked(!isChecked)} color={"primary"} />
                  </TableCell>
                  <TableCell>{data.name}</TableCell>
                  <TableCell>{data.scoreOne}</TableCell>
                  <TableCell>{data.scoreTwo}</TableCell>
                  <TableCell>{data.scoreThree}</TableCell>
                  <TableCell>{data.scoreFour}</TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Search
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => setIsOverviewDialogOpen(true)}
                      />
                      <Edit style={{ color: theme.palette.primary.main }} onClick={() => setIsEditDialogOpen(true)} />
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ManagementMatrixScoreTable;
