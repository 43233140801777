// region Types

import React, { useContext } from "react";
import { MatrixCoreTableRowsRequest, GenericDownloadDto, TableHeadersResponse, TableRowsResponse } from "../../../api";
import { getApi } from "../../../core/useApi";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../../core/useRxjsStore";
import useTableActions from "../../../core/useTableActions";
import SkeletonLoading from "../../../skeleton/SkeletonLoading";
import SkeletonPage from "../../../skeleton/SkeletonPage";

type State = {
  booted: boolean;
  loading: boolean;
  sequence: number;

  header_set: TableHeadersResponse["header_set"];
  res?: TableRowsResponse;
  req: MatrixCoreTableRowsRequest;

  drawer:
    | undefined
    | {
        name: "TableHeaders";
      }
    | {
        name: "TableDownload";
        busy?: boolean;
        download?: GenericDownloadDto;
      }
    | {
        name: "Save";
        record_id?: number;
      }
    | {
        name: "Archive";
        record_id: number;
      };
};

// endregion

type Context = State & { actions: ReturnType<typeof useActions> };
const Context = React.createContext<Context>(null);

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>) {
  const actions = {
    ...useTableActions<MatrixCoreTableRowsRequest, State>({
      next,
      getState,
      loadHeaders: async () => {
        return (await getApi().matrixCoreTableHeadersPost()).data;
      },
      loadTable: async () => {
        return (await getApi().matrixCoreTableRowsPost(getState().req)).data;
      },
    }),
    reset: () => {
      actions.setRequest({
        offset: 0,
        search: "",
      });
    },
    setDrawer: (value?: State["drawer"]) => {
      next((d) => (d.drawer = value));
    },
  };

  return actions;
}

export const MatrixCoreTableProvider: React.FC = (props) => {
  const STORE_VERSION = 1;

  const { state, getState, next } = useRxjsStore<State>({
    booted: false,
    loading: false,
    sequence: 0,
    header_set: [],
    res: undefined,
    req: { header_set: [], limit: 25, offset: 0, search: "" },
    drawer: undefined,
  });

  const actions = useActions(next, getState);

  useAsyncEffect(async () => {
    const previous_version = JSON.parse(localStorage.getItem("crm-company-table--version") || "-1");
    const previous_data = JSON.parse(localStorage.getItem("crm-company-table--data") || "null");

    await actions.boot(previous_version === STORE_VERSION && previous_data);
  }, []);

  useAsyncEffect(async () => {
    if (state.booted) {
      await actions.load();

      localStorage.setItem("crm-company-table--version", JSON.stringify(STORE_VERSION));
      localStorage.setItem("crm-company-table--data", JSON.stringify(getState().req));
    }
  }, [state.booted, state.sequence]);

  return (
    <Context.Provider value={{ ...state, actions }}>
      {state.booted && !!state.res && props.children ? (
        props.children
      ) : (
        <SkeletonPage>
          <SkeletonLoading label="de pagina is aan het laden" />
        </SkeletonPage>
      )}
    </Context.Provider>
  );
};

export default function useMatrixCoreTable(): Context {
  return useContext(Context);
}
