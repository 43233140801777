import { FormHelperText, TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import React, { ChangeEvent } from "react";
import _ from "underscore";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";

type Item = {
  value: number | string;
  label: string;
  position?: number;
  extra?: any;
};

type Props<
  T = any,
  Multiple extends boolean | undefined = boolean,
  DisableClearable extends boolean | undefined = boolean,
  FreeSolo extends boolean | undefined = boolean
> = {
  form: RxjsForm<any>;

  name: string;
  label: string;
  items: Item[];
  multiple?: boolean;

  autocompleteProps?: Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "options" | "getOptionLabel" | "filterSelectedOptions" | "value" | "multiple" | "onChange" | "renderInput"
  >;
  textFieldProps?: Omit<TextFieldProps, "name" | "label" | "variant" | "placeholder">;
};

const AutocompleteFieldExtComp: React.FC<Props> = (props) => {
  const { name, label, items: propItems, multiple, form, autocompleteProps, textFieldProps } = props;
  const { value, error, dirty } = extractRxjsField(form, name);

  const items = propItems || [];

  const item_map = React.useMemo(() => _.object(items.map((x) => [x.value, x])), [items]);

  const mapped_value = multiple
    ? value
      ? value.map((v) => item_map[v])
      : []
    : value === undefined
    ? null
    : item_map[value] || null;

  const onChange = React.useCallback((e: ChangeEvent, v: any) => {
    let set = v === null || v === undefined ? null : multiple ? v.map((x) => x.value) : v.value;

    if (set === null || set === undefined) {
      set = multiple ? [] : "";
    }

    form.actions.setValue(name, set);
  }, []);

  return (
    <>
      <Autocomplete
        options={items}
        getOptionLabel={(option) => (option ? option.label : "Undefined?")}
        filterSelectedOptions
        value={mapped_value}
        multiple={multiple}
        onChange={onChange}
        {...autocompleteProps}
        renderInput={(params) => (
          <TextField variant="outlined" label={label} placeholder="..." {...textFieldProps} {...params} />
        )}
      />
      {dirty && error && <FormHelperText>{error}</FormHelperText>}
    </>
  );
};

const AutocompleteFieldExt = React.memo(AutocompleteFieldExtComp, (prev, next) => {
  const equal = equalityRxjsFieldCompare(prev, next);

  return equal && _.isEqual(prev.items, next.items);
});

export default AutocompleteFieldExt;
