import React from "react";

export default function useAsyncEffect(
  callable: (interrupted: boolean) => Promise<void>,
  deps?: React.DependencyList
) {
  React.useEffect(() => {
    let interrupted = false;

    // noinspection JSIgnoredPromiseFromCall
    callable(interrupted);

    return () => {
      interrupted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
