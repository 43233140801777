import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";

type Props = {
  form: RxjsForm<any>;

  name: string;
  label: React.ReactNode;
  helperText?: React.ReactNode;

  items: (PropItem | false | undefined | null)[];
};

type PropItem = {
  label: React.ReactNode;
  value: any;
};

const RadioFieldExtComp: React.FC<Props> = (props) => {
  const { name, label, items, form, helperText } = props;
  const { value, error, dirty } = extractRxjsField(form, name);

  const classes = useStyles();

  const onRadioChange = React.useCallback(
    (item: PropItem) => {
      form.actions.setValue(name, item.value);
    },
    [value],
  );

  return (
    <FormControl error={!!error} margin="normal" className={classes.FormControl}>
      <FormLabel>{label}</FormLabel>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormGroup className={classes.FormGroup}>
        {items
          .filter((x) => !!x)
          .map((item: PropItem) => {
            const checked = value === item.value;
            return (
              <FormControlLabel
                key={item.value}
                control={<Radio />}
                label={item.label}
                checked={checked}
                value={item.value}
                onChange={() => onRadioChange(item)}
              />
            );
          })}
      </FormGroup>
      {dirty && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  FormControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  FormGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

const RadioFieldExt = React.memo(RadioFieldExtComp, equalityRxjsFieldCompare);

export default RadioFieldExt;
