import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Search } from "@material-ui/icons";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { CrmCompanyDto, CrmCompanySaveDto } from "../../../api";
import countries from "../../../core/countries";
import DialogBusy from "../../../core/DialogBusy";
import HasPlugin from "../../../core/HasPlugin";
import provinces from "../../../core/provinces";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useResolveZipcode from "../../../core/useResolveZipcode";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";

type Props = {
  record_id?: number;
  onClose: (success?: boolean) => Promise<void>;
};

const CrmCompanySaveDrawer: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const gc = useGenericStyles();
  const { state, form, actions } = useComponent(props);
  const [isResolvingZipcode, onResolveZipcode] = useResolveZipcode(
    form.values.address_zipcode,
    form.values.address_housenumber,
    (res) => {
      form.actions.setValues({
        address_zipcode: res.zipcode,
        address_housenumber: res.housenumber,
        address_street: res.street,
        address_city: res.city,
        address_country: res.country,
        address_province: res.province,
      });
    },
  );

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>
          {_t("Ketenpartner")} {state.record ? "aanpassen" : "toevoegen"}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldExt form={form} name="name" label="Naam" />
            </Grid>
            <HasPlugin name="dossier">
              <Grid item xs={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="employable_regions"
                  label="Regio's"
                  multiple
                  items={[
                    { value: "Alkmaar", label: "Alkmaar" },
                    { value: "West Friesland", label: "West Friesland" },
                    { value: "Kop van Noord Holland", label: "Kop van Noord Holland" },
                  ]}
                />
              </Grid>
            </HasPlugin>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Centrale gegevens</Typography>
              <Typography variant="body2" color="textSecondary">
                Centrale gegevens waarmee de {_t("ketenpartner")} bereikt kan worden.
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextFieldExt form={form} name="contact_name" label="Contactpersoon" />
            </Grid>
            <Grid item xs={4}>
              <TextFieldExt form={form} name="contact_role" label="Functie" />
            </Grid>
            <Grid item xs={6}>
              <TextFieldExt form={form} name="contact_email" label="E-mailadres" />
            </Grid>
            <Grid item xs={6}>
              <TextFieldExt form={form} name="contact_phone" label="Telefoonnummer" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Adres</Typography>
              <Typography variant="body2" color="textSecondary">
                Adres gegevens van de {_t("ketenpartner")}.
              </Typography>
            </Grid>
            <Grid item md={2}>
              <AutocompleteFieldExt
                form={form}
                name="address_country"
                label="Land"
                items={countries.map((x) => ({ value: x, label: x }))}
              />
            </Grid>
            <Grid item md={3}>
              <TextFieldExt form={form} name="address_zipcode" label="Postcode" />
            </Grid>
            <Grid item md={3}>
              <TextFieldExt form={form} name="address_housenumber" label="Huisnummer" />
            </Grid>
            <Grid item md>
              <TextFieldExt form={form} name="address_affix" label="Toevoeging" />
            </Grid>
            <Grid item>
              <IconButton onClick={() => onResolveZipcode()} disabled={isResolvingZipcode}>
                {isResolvingZipcode ? <CircularProgress size={20} /> : <Search />}
              </IconButton>
            </Grid>

            <Grid item md={6}>
              <TextFieldExt form={form} name="address_street" label="Straat" />
            </Grid>
            <Grid item md={6}>
              <TextFieldExt form={form} name="address_city" label="Stad" />
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="address_province"
                label="Provincie"
                items={provinces.map((x) => ({ value: x, label: x }))}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Bereikbaarheid</Typography>
              <Typography variant="body2" color="textSecondary">
                Bereikbare tijden van de {_t("ketenpartner")}.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <TextFieldExt form={form} name="opening_hours" label="Bereikbaarheidsinformatie" type="text" multiline />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => actions.onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>

        <DialogBusy busy={state.busy} />
      </form>
    </Drawer>
  );
};

type State = {
  busy: boolean;
  record?: CrmCompanyDto;
};

function useComponent(props: Props) {
  const app = useAppStore();
  const { record_id, onClose } = props;
  const { state, getState, next } = useRxjsStore<State>({
    busy: false,
  });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));
        const record = await callApi(
          app,
          async (api) => record_id && (await api.crmCompanyOnePost({ id: record_id })).data,
        );
        next((d) => {
          d.busy = false;
          d.record = record;
        });
      },

      save: async (values: CrmCompanySaveDto) => {
        const res = await callApi(app, async (api) => await api.crmCompanySavePost(values));

        if (res.data?.id) await actions.onClose(true);
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));

        try {
          await onClose(success);
        } catch (e) {
          console.error(e);
          next((d) => (d.busy = false));
        }
      },
    }),
    [record_id],
  );

  const form = useRxjsForm<CrmCompanySaveDto>({
    initial: {
      id: record_id,
      name: "",
      contact_name: "",
      contact_role: "",
      contact_email: "",
      contact_phone: "",
      employable_regions: [],
      address_province: "",
      address_country: "nl",
      address_zipcode: "",
      address_housenumber: "",
      address_affix: "",
      address_street: "",
      address_city: "",
      opening_hours: "",
    },
    submit: (values) => actions.save(values),
  });

  useAsyncEffect(actions.load, [record_id]);

  useEffect(() => {
    console.info(form.initial, state.record);
    form.actions.setValues(state.record ? _.pick(state.record, Object.keys(form.initial) as any) : form.initial);
  }, [state.record?.id]);

  return { state, form, actions };
}

export default CrmCompanySaveDrawer;
