import React from "react";
import useDebounce from "./useDebounce";

export default function useDebouncedValueCallback<Value = any>(
  callback: (v: Value) => void,
  value: Value,
  delay: number
): [Value, (first: boolean) => void] {
  const [first, setFirst] = React.useState(true);
  const [debounced] = useDebounce(value, delay);

  React.useEffect(() => {
    first ? setFirst(false) : callback(debounced);
  }, [debounced]);

  return [debounced, setFirst];
}
