import { Typography } from "@material-ui/core";
import React from "react";
import { CrmDataScreenFieldDto, CrmDataScreenFieldTypeEnum } from "../../../api";
import { RxjsForm } from "../../../core/useRxjsForm";
import CheckboxFieldExt from "../../../form/CheckboxFieldExt";
import RadioFieldExt from "../../../form/RadioFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";
import CrmDataScreenExtFieldStaticText from "./CrmDataScreenExtFieldStaticText";

type DefaultFieldProps = {
  form: RxjsForm<any>;
  field: CrmDataScreenFieldDto;
};

type Props = DefaultFieldProps;

const CrmDataScreenExtFieldFactory: React.FC<Props> = (props) => {
  const { field } = props;

  switch (field.type) {
    case CrmDataScreenFieldTypeEnum.STATICTEXT:
      return <CrmDataScreenExtFieldStaticText {...props} />;
    case CrmDataScreenFieldTypeEnum.TEXTSHORT:
      return <CrmDataScreenExtFieldTextShort {...props} />;
    case CrmDataScreenFieldTypeEnum.TEXTLONG:
      return <CrmDataScreenExtFieldTextLong {...props} />;
    case CrmDataScreenFieldTypeEnum.CHOICESINGLE:
      return <CrmDataScreenExtFieldChoiceSingle {...props} />;
    case CrmDataScreenFieldTypeEnum.CHOICEMULTIPLE:
      return <CrmDataScreenExtFieldChoiceMultiple {...props} />;
    case CrmDataScreenFieldTypeEnum.DATEPAST:
      break;
    case CrmDataScreenFieldTypeEnum.DATEALL:
      break;
    case CrmDataScreenFieldTypeEnum.UPLOAD:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALNAME:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALADDRESS:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALPHONE:
      break;
    case CrmDataScreenFieldTypeEnum.PERSONALEMAIL:
      break;
  }

  return (
    <div>
      <Typography variant="h6">{field.name}</Typography>
      <Typography variant="body2">{field.type} - missing</Typography>
    </div>
  );
};

const CrmDataScreenExtFieldTextShort: React.FC<DefaultFieldProps> = (props) => {
  const { form, field } = props;

  return (
    <TextFieldExt form={form} name={field.identifier} label={field.name} type="text" helperText={field.description} />
  );
};

const CrmDataScreenExtFieldTextLong: React.FC<DefaultFieldProps> = (props) => {
  const { form, field } = props;

  return (
    <TextFieldExt
      form={form}
      name={field.identifier}
      label={field.name}
      type="text"
      helperText={field.description}
      multiline
    />
  );
};

const CrmDataScreenExtFieldChoiceSingle: React.FC<DefaultFieldProps> = (props) => {
  const { form, field } = props;

  return (
    <RadioFieldExt
      form={form}
      name={field.identifier}
      label={field.name}
      helperText={field.description}
      items={field.option_set.map((option) => ({ label: option.label, value: option.value }))}
    />
  );
};

const CrmDataScreenExtFieldChoiceMultiple: React.FC<DefaultFieldProps> = (props) => {
  const { form, field } = props;

  return (
    <CheckboxFieldExt
      form={form}
      name={field.identifier}
      label={field.name}
      helperText={field.description}
      items={field.option_set.map((option) => ({ label: option.label, value: option.value }))}
    />
  );
};

export default CrmDataScreenExtFieldFactory;
