import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { FC, useMemo } from "react";
import { CrmNoteDto, CrmNoteModeEnum } from "../../../api";
import FormatDate from "../../../core/FormatDate";
import GenericResourceLink from "../../../core/GenericResourceLink";
import getTranslation from "../../../core/getTranslation";
import QuillContent from "../../../core/QuillContent";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useTranslations from "../../../core/useTranslations";

type Props = {
  isDefaultExpanded: boolean;
  note: CrmNoteDto;
  openWriter: any;
  setDialog: any;
  writerIsOpen?: boolean;
};

const CareDossierViewExtNoteView: FC<Props> = (props) => {
  const { note } = props;
  const _t = useTranslations();
  const gc = useGenericStyles();
  const app = useAppStore();
  const matrix_core = useMemo(
    () => app.data.matrix_core_set.find((x) => x.id === note.matrix_core_id),
    [note.matrix_core_id],
  );
  const matrix_row = useMemo(() => matrix_core?.row_set.find((x) => x.id === note.matrix_row_id), [note.matrix_row_id]);

  return (
    <Accordion defaultExpanded={props.isDefaultExpanded}>
      <AccordionSummary>
        <Typography variant="subtitle2">
          <strong>
            {note.mode === CrmNoteModeEnum.DOSSIER && "Notitie "}
            {note.mode === CrmNoteModeEnum.MATRIXROW && "Afspraak "}
            {note.mode === CrmNoteModeEnum.TARGET && "Doel "}#{note.id}
          </strong>{" "}
          op <FormatDate fmt="PP p" value={note.created_at} emptyLabel="geen" /> door {note.created_by.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table size="small">
          <TableBody>
            {note.connected_matrix_row_id && (
              <TableRow>
                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                  Risicofactor
                </TableCell>
                <TableCell style={{ borderBottom: 0 }}>{matrix_row?.name}</TableCell>
              </TableRow>
            )}
            {note.crm_company_id_set.length > 0 && (
              <TableRow>
                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                  {_t("Ketenpartner(s)")}
                </TableCell>
                <TableCell style={{ borderBottom: 0 }}>
                  {note.crm_company_id_set.map((id) => (
                    <Chip
                      key={id}
                      label={app.data.crm_company_set.find((x) => x.id === id)?.name || "Onbekend"}
                      className={gc.Chip_Inline}
                    />
                  ))}
                </TableCell>
              </TableRow>
            )}
            {note.crm_person_id_set.length > 0 && (
              <TableRow>
                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                  Actiehouder(s)
                </TableCell>
                <TableCell style={{ borderBottom: 0 }}>
                  {note.crm_person_id_set.map((id) => (
                    <Chip
                      key={id}
                      label={app.data.crm_person_set.find((x) => x.id === id).name}
                      className={gc.Chip_Inline}
                    />
                  ))}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                Overlegdatum
              </TableCell>
              <TableCell style={{ borderBottom: 0 }}>
                <FormatDate fmt="PP" value={note.started_at} emptyLabel="geen" />
              </TableCell>
            </TableRow>
            {note.mode === CrmNoteModeEnum.MATRIXROW && (
              <TableRow>
                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                  Beoogde einddatum
                </TableCell>
                <TableCell style={{ borderBottom: 0 }}>
                  <FormatDate fmt="PP" value={note.ending_at} emptyLabel="geen" />
                </TableCell>
              </TableRow>
            )}
            {note.closed_at && (
              <TableRow>
                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                  Werkelijke einddatum
                </TableCell>
                <TableCell style={{ borderBottom: 0 }}>
                  <FormatDate fmt="PP" value={note.closed_at} emptyLabel="heden" />
                </TableCell>
              </TableRow>
            )}
            {note.closed_state && (
              <TableRow>
                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                  Resultaat
                </TableCell>
                <TableCell style={{ borderBottom: 0 }}>{note.closed_state}</TableCell>
              </TableRow>
            )}
            {note.resource_set.map((resource) => (
              <TableRow key={resource.id}>
                <TableCell colSpan={2}>
                  <GenericResourceLink resource={resource} />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2} style={{ verticalAlign: "top" }}>
                <Typography variant="h5">{note.subject}</Typography>
                <QuillContent content={note.document} />
              </TableCell>
            </TableRow>
            {note.mode === CrmNoteModeEnum.MATRIXROW && (
              <TableRow>
                <TableCell className={gc.TableCell_Shrink} colSpan={2}>
                  {getTranslation(`crm_note_closed_state.${note.closed_state}`)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={gc.TableCell_Shrink} colSpan={2}>
                <Button
                  startIcon={<Edit />}
                  onClick={() => {
                    props.openWriter(note.id);
                    // props.setDialog({ name: "note", crm_note_id: note.id });
                  }}
                  disabled={props.writerIsOpen}
                >
                  Aanpassen
                </Button>
                <Button
                  startIcon={<Delete />}
                  onClick={() => {
                    props.setDialog({ name: "archive", crm_note_id: note.id });
                  }}
                  disabled={props.writerIsOpen}
                >
                  Archiveren
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default CareDossierViewExtNoteView;
