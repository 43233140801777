import { format, parseISO } from "date-fns";
import { nl } from "date-fns/locale";
import React from "react";
import _ from "underscore";

type Props = {
  value?: string | Date;
  fmt?: string;
  emptyLabel?: string;
};

const FormatDate: React.FC<Props> = ({ value, fmt, emptyLabel }) => {
  const formatted = React.useMemo(() => {
    if (!value) return emptyLabel || "";

    const date = _.isDate(value) ? value : parseISO(value);
    return format(date, fmt || "PP p", { locale: nl });
  }, [value]);

  return <>{formatted}</>;
};

export default FormatDate;
