import React from "react";
import { useDmzAuthenticateStyles } from "./DmzAuthenticate";
import { Card, CardContent, Typography } from "@material-ui/core";

const DmzAuthenticateScreenNotifyBooting: React.FC = () => {
  const classes = useDmzAuthenticateStyles();
  return (
    <Card className={classes.Form}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Laden
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          TagScore is aan het laden.
        </Typography>

        <Typography variant="body1" paragraph>
          Een klein moment alstublieft, we laden even snel wat gegevens van de server en dan kunt u verder!
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DmzAuthenticateScreenNotifyBooting;
