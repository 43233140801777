import {
  Card,
  CardContent,
  CardHeader,
  colors,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Cancel, Check, Refresh, SaveAltOutlined } from "@material-ui/icons";
import { addDays, eachDayOfInterval, format, formatISO, isMonday, parse, parseISO, previousMonday } from "date-fns";
import { nl } from "date-fns/locale";
import React, { useMemo, useState } from "react";
import { CdDataChartTypeEnum, CdDataCheckupLocationDto } from "../../../api";
import sleep from "../../../core/sleep";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useCrmCompanyLocation from "../../../core/useCrmCompanyLocation";
import useTranslations from "../../../core/useTranslations";
import CrmCompanyInfoDrawer from "../../CrmCompany/Ext/CrmCompanyInfoDrawer";
import CrmCompanyLocationShortCode from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";
import { CapDashDashboardStateFiltersData } from "../CapDashDashboard";

type Props = {
  filters_data: CapDashDashboardStateFiltersData;
};

const CapDashDashboardChartRegistryCheckup: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const [iteration, setIteration] = useState(0);
  const [filterCrmLocationIdSet, setFilterCrmLocationIdSet] = useState<number[] | undefined>(undefined);

  const periodStart = parseISO(props.filters_data.period_start);
  const periodEnd = parseISO(props.filters_data.period_end);

  const firstMonday = isMonday(periodStart) ? periodStart : previousMonday(periodStart);
  const lastMonday = isMonday(periodEnd) ? periodEnd : previousMonday(periodEnd);

  const [data] = useApiResult(async () => {
    await sleep(250);

    const filter_crm_location_id_set = (
      await getApi().cdDataChartLocationsPost({
        chart: CdDataChartTypeEnum.RegistryCheckup,
        ...props.filters_data,
        period_start: formatISO(firstMonday, { representation: "date" }),
        period_end: formatISO(lastMonday, { representation: "date" }),
      })
    ).data.location_id_set;

    setFilterCrmLocationIdSet(filter_crm_location_id_set);

    return (
      await getApi().cdDataCheckupPost({
        day_after: formatISO(firstMonday, { representation: "date" }),
        day_before: formatISO(lastMonday, { representation: "date" }),
        crm_company_location_id_set: filter_crm_location_id_set,
      })
    ).data;
  }, [props.filters_data, iteration]);

  const daysFormattedUser = useMemo(
    () =>
      eachDayOfInterval(
        {
          start: firstMonday,
          end: lastMonday,
        },
        { step: 7 },
      )
        .reverse()
        .map((x) => format(x, "RRRR / I")),
    [props.filters_data.period_start, props.filters_data.period_end],
  );

  const [downloading, setDownloading] = useState(false);

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Controle ingevoerde data"
        action={
          <>
            {!!data.res && (
              <Tooltip title="Download als Excel.">
                <IconButton onClick={() => setDownloading(true)} disabled={data.busy}>
                  <SaveAltOutlined />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Herlaad deze statistiek.">
              <IconButton onClick={() => setIteration((x) => x + 1)} disabled={data.busy}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      {data.busy && (
        <>
          <LinearProgress style={{ width: "100%", height: 3 }} variant="indeterminate" />
          <CardContent>
            <Typography variant="body2">
              We zijn de statistiek aan het laden, een ogenblik geduld alstublieft.
            </Typography>
          </CardContent>
        </>
      )}
      {!!data.res && (
        <div style={{ overflow: "hidden", position: "relative", width: "100%", height: 600 }}>
          <TableContainer style={{ position: "absolute", height: "100%", overflowX: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="300px" style={{ left: 0, position: "sticky", zIndex: 3 }} variant="head">
                    {_t("Locatie")}
                  </TableCell>
                  {daysFormattedUser.map((day) => (
                    <TableCell align="center">{day}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.res.location_set.map((dataLocation, rx) => {
                  return <CDDCRCTableRow key={rx} daysFormattedData={daysFormattedUser} dataLocation={dataLocation} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () =>
            (
              await getApi().cdDataDownloadCheckupPost({
                day_after: props.filters_data.period_start,
                day_before: props.filters_data.period_end,
                crm_company_location_id_set: filterCrmLocationIdSet,
              })
            ).data
          }
        />
      )}
    </Card>
  );
};

const CDDCRCTableRow: React.FC<{
  daysFormattedData: string[];
  dataLocation: CdDataCheckupLocationDto;
}> = ({ daysFormattedData, dataLocation }) => {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const location = useCrmCompanyLocation(dataLocation.crm_company_location_id);

  return (
    <TableRow>
      <TableCell style={{ left: 0, position: "sticky", backgroundColor: "#fff" }}>
        <div style={{ width: 300, cursor: "help" }} onClick={() => setInfoOpen(true)}>
          <strong>{location.company.name}</strong>
          <br />
          <CrmCompanyLocationShortCode location={location} />
        </div>

        <CrmCompanyInfoDrawer
          open={isInfoOpen}
          onClose={() => setInfoOpen(false)}
          company={location.company}
          location={location}
        />
      </TableCell>
      {daysFormattedData.map((day) => {
        const data = dataLocation.day_set.find((x) => x.key === day);
        const isAutoStamped = data?.auto_stamped === true;

        return (
          <TableCell
            style={{
              // backgroundColor: colors[has_day ? "green" : "red"][100],
              color: colors[isAutoStamped ? "red" : "green"][500],
              textAlign: "center",
            }}
          >
            {isAutoStamped ? <Cancel /> : <Check />}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

function useComponent(props: Props) {
  return {};
}

export default CapDashDashboardChartRegistryCheckup;
