import React from "react";
import _ from "underscore";
import useAppStore from "../../../core/useAppStore";
import useTranslations from "../../../core/useTranslations";
import SelectFieldExt from "../../../form/SelectFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";
import Typography from "@material-ui/core/Typography";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsForm from "../../../core/useRxjsForm";
import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import { CrmPersonTableRowsRequest } from "../../../api";
import { makeStyles } from "@material-ui/core/styles";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";

type Props = {
  onClose: (req?: Values) => void;
  req: CrmPersonTableRowsRequest;
};

type Values = Omit<CrmPersonTableRowsRequest, "header_set">;

const CrmPersonDrawerTableFilter: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const gc = useGenericStyles();
  const classes = useStyles();
  const app = useAppStore();
  const isBB = app.session?.company.slug === "bb";

  const { req, onClose } = props;
  const form = useRxjsForm<Values>({
    initial: _.omit(req, ["header_set"]),
    submit: async (values) => {
      onClose(values);
    },
  });

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Resultaat filteren</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Dingen</Typography>
              <Typography variant="body2" color="textSecondary">
                Dingen, met woorden enzo.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <TextFieldExt form={form} name="search" label="Zoeken op naam / e-mailadres ..." />
            </Grid>
            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="is_archived"
                label="Archief"
                items={[
                  { value: -1, label: "Niet filteren." },
                  { value: 0, label: "Niet gearchiveerd." },
                  { value: 1, label: "Wel gearchiveerd." },
                ]}
              />
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="tag_id_set"
                label="Persoontype"
                multiple
                items={app.data.tag_core_set
                  .filter((x) => x.category_id === 1)
                  .map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
              />
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="role_id_set"
                label="Rol"
                multiple
                items={app.data.crm_role_set.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
              />
            </Grid>
            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="company_id_set"
                label={_t("Ketenpartner(s)")}
                multiple
                items={app.data.crm_company_set.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
              />
            </Grid>
            {!isBB && (
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="involved_company_id_set"
                  label={_t("Betrokken ketenpartner(s)")}
                  multiple
                  items={app.data.crm_company_set.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Filteren
          </Button>
        </DialogActions>
      </form>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

export default CrmPersonDrawerTableFilter;
