import { makeStyles } from "@material-ui/core/styles";

const useTableStyles = makeStyles((theme) => ({
  TableView: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - 64px - ${theme.spacing(6)}px)`,
    margin: theme.spacing(3),
  },
  TableContainer: {
    flexGrow: 1,
  },
  TableToolbar: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  TableToolbarForm: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  Table: {
    minWidth: 650,
  },
  TableCellShrink: {
    width: 1,
    whiteSpace: "nowrap",
  },
  TablePagination: {
    overflow: "initial",

    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
}));

export default useTableStyles;
