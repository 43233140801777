import React from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useApp from "../core/useAppStore";

export default function SkeletonSnackbar() {
  const app = useApp();
  const classes = useStyles();
  const expires_at: number = Math.min(...app.messages.map((x) => x.expires_at!));

  React.useEffect(() => {
    if (!isFinite(expires_at)) return;

    const timeout = expires_at - +new Date();

    if (timeout < 0) {
      app.actions.purgeMessages();
    } else {
      const timer = setTimeout(() => {
        app.actions.purgeMessages();
      }, timeout + 50);

      return () => clearTimeout(timer);
    }
  }, [app, expires_at]);

  return (
    <div className={classes.Snackbar_Container}>
      {app.messages.map((message, rx) => (
        <Alert severity={message.severity} key={rx}>
          {message.content}
        </Alert>
      ))}
    </div>
  );
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  Snackbar_Container: {
    position: "fixed",
    right: 10,
    top: 80,
    zIndex: 99999,

    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
