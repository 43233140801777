import React, { useCallback, useState } from "react";
import {
  Box,
  ButtonGroup,
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check, Close, FilterList, GetApp, OpenInNew, RefreshOutlined, ViewColumn } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CriticalDataOverallOverviewTableMeasurementDialog from "./CriticalDataOverallOverviewTableMeasurementDialog";
import CriticalDataOverallOverviewTableFilterDialog from "./CriticalDataOverallOverviewTableFilterDialog";

const rows = [
  {
    director: "Horace Frazier",
    name: "Justin Rempel MD",
    measurement: "Einde detentie",
    date: new Date().toLocaleDateString(),
    open: true,
  },
  {
    director: "Caitlan Tierney",
    name: "Gina Deckow",
    measurement: "Einde reclasseringstoezicht",
    date: new Date().toLocaleDateString(),
    open: true,
  },
  {
    director: "Lyla-Rose Odonnell",
    name: "Orville Jacobi",
    measurement: "Einde tbs-maatregel",
    date: new Date().toLocaleDateString(),
    open: true,
  },
  {
    director: "Farah Sargent",
    name: "Randolph Kihn",
    measurement: "Einde isd-maatregel",
    date: new Date().toLocaleDateString(),
    open: true,
  },
  {
    director: "Francesca Rios",
    name: "Ann Langworth",
    measurement: "Einde WMO-indicatie",
    date: new Date().toLocaleDateString(),
    open: true,
  },
  {
    director: "Kareem Flores",
    name: "Rhonda Kulas",
    date: new Date().toLocaleDateString(),
    measurement: "Einde WLZ indicatie",
    open: false,
  },
  {
    director: "Michalina Clay",
    name: "Sean Krajcik III",
    date: new Date().toLocaleDateString(),
    measurement: "Einde Bw indicatie",
    open: false,
  },
  {
    director: "Kierran Colley",
    name: "Jean Dustin",
    date: new Date().toLocaleDateString(),
    measurement: "Eerste meting Fare en ZRM",
    open: false,
  },
  {
    director: "Nada Nieves",
    name: "Will van Hek",
    date: new Date().toLocaleDateString(),
    measurement: "Vervolgmeting Fare en ZRMe",
    open: false,
  },
];

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#DFD4F2",
  },
  tableCellBorderRight: {
    borderRight: "solid 1px #808080",
  },
  close: {
    color: theme.palette.error.main,
  },
  check: {
    color: theme.palette.success.main,
  },
  openInNewIcon: {
    color: theme.palette.primary.main,
  },
}));

const CriticalDataOverallOverviewTable = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isMeasurementDialogOpen, setIsMeasurementDialogOpen] = useState<null | number>(null);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onCloseMeasurementDialog = useCallback(() => {
    setIsMeasurementDialogOpen(null);
  }, []);

  const onCloseFilterDialog = useCallback(() => {
    setIsFilterDialogOpen(false);
  }, []);

  console.log(isMeasurementDialogOpen, " measure");

  return (
    <Box>
      {isFilterDialogOpen && (
        <CriticalDataOverallOverviewTableFilterDialog isOpen={isFilterDialogOpen} onClose={onCloseFilterDialog} />
      )}
      <CriticalDataOverallOverviewTableMeasurementDialog
        isOpen={isMeasurementDialogOpen !== null}
        onClose={onCloseMeasurementDialog}
        data={isMeasurementDialogOpen !== null ? rows[isMeasurementDialogOpen] : null}
      />
      <Box component={Paper} style={{ margin: 20 }}>
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          style={{ paddingTop: 10, paddingBottom: 10 }}
          justifyContent={"space-between"}
        >
          <Grid item xs={1}>
            <ButtonGroup>
              <RefreshOutlined />

              <Close />
            </ButtonGroup>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth={true} variant={"outlined"} label={"zoeken"} />
          </Grid>
          <Grid item xs={3}>
            <Select variant={"outlined"} fullWidth={true} native={false} defaultValue="Filter">
              <option value="Filter">Filter</option>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <ButtonGroup style={{ marginLeft: 8 }}>
              <GetApp />
              <FilterList onClick={() => setIsFilterDialogOpen(true)} />
              <ViewColumn />
            </ButtonGroup>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.tableCellBorderRight}>Processregisseur</TableCell>
                <TableCell className={classes.tableCellBorderRight}>Dossier</TableCell>
                <TableCell className={classes.tableCellBorderRight}>Meting</TableCell>
                <TableCell className={classes.tableCellBorderRight}>Datum</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCellBorderRight}>
                      <Grid container spacing={1} justifyContent={"space-between"}>
                        <Grid>{row.director}</Grid>
                        <Grid>
                          <Link to="/critical/own-overview/table">
                            <OpenInNew className={classes.openInNewIcon} />
                          </Link>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.tableCellBorderRight}>
                      <Grid container spacing={1} justifyContent={"space-between"}>
                        <Grid>{row.name}</Grid>
                        <Grid>
                          <OpenInNew className={classes.openInNewIcon} />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.tableCellBorderRight}>{row.measurement}</TableCell>
                    <TableCell className={classes.tableCellBorderRight}>{row.date}</TableCell>
                    <TableCell>
                      {row.open ? (
                        <Grid container spacing={1} justifyContent={"space-between"}>
                          <Grid>
                            <Close className={classes.close} onClick={() => setIsMeasurementDialogOpen(index)} />
                          </Grid>
                          <Grid>
                            <Typography className={classes.close}>Open</Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={1} justifyContent={"space-between"}>
                          <Grid>
                            <Check className={classes.check} onClick={() => setIsMeasurementDialogOpen(index)} />
                          </Grid>
                          <Grid>
                            <Typography className={classes.check}>Gesloten</Typography>
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default CriticalDataOverallOverviewTable;
