import { makeStyles } from "@material-ui/core/styles";
import { amber, blue, green, grey, orange, red, teal } from "@material-ui/core/colors";

const useGenericStyles = makeStyles((theme) => ({
  ToolbarClipper: theme.mixins.toolbar,

  DrawerContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",

    width: "80vw",
    [theme.breakpoints.down("md")]: {
      width: "95vw",
    },
  },

  Password_Visible: {
    fontFamily: "Inconsolata, monospace",
    fontSize: "1.3rem",
  },

  Password_Weak: {
    color: `${theme.palette.error.main} !important`,
    "& .MuiLinearProgress-bar": {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
  },
  Password_Ok: {
    color: `${theme.palette.info.main} !important`,
    "& .MuiLinearProgress-bar": {
      backgroundColor: `${theme.palette.info.main} !important`,
    },
  },
  Password_Strong: {
    color: `${theme.palette.success.main} !important`,
    "& .MuiLinearProgress-bar": {
      backgroundColor: `${theme.palette.success.main} !important`,
    },
  },

  Text_NoWrap: {
    whiteSpace: "nowrap",
  },

  Chip_Inline: {
    margin: "0.15rem 0.25rem 0.15rem 0",
    fontSize: "0.75rem",

    "& .MuiChip-label": {
      padding: "0px 9px",
    },
  },

  TableCell_Shrink: {
    width: 1,
    whiteSpace: "nowrap",
  },
  TableCell_Center: {
    textAlign: "center",
  },
  TableCell_Right: {
    textAlign: "right",
  },
  TableCell_Danger: {
    backgroundColor: red[500],
    color: theme.palette.getContrastText(red[500]),
  },
  TableCell_Warning: {
    backgroundColor: orange[500],
    color: theme.palette.getContrastText(orange[500]),
  },
  TableCell_Success: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  TableCell_Muted: {
    color: grey[500],
  },
  TableRow_Highlight: {
    backgroundColor: blue[100],
    borderTop: `2px solid ${blue[500]}`,
  },

  Avatar_Color_CrmPerson: {
    backgroundColor: teal[500],
    color: theme.palette.getContrastText(teal[500]),
  },
  Avatar_Color_CrmParticipant: {
    backgroundColor: blue[500],
    color: theme.palette.getContrastText(blue[500]),
  },
  Avatar_Color_ShopOrder: {
    backgroundColor: amber[500],
    color: theme.palette.getContrastText(amber[500]),
  },

  Accordion_HeadingPrimary: {
    flexBasis: "44%",
    flexShrink: 0,
  },
  Accordion_HeadingPrimary__Large: {
    flexBasis: "75%",
    flexShrink: 0,
    fontSize: "1rem",
  },
  Accordion_HeadingSecondary: {
    color: theme.palette.text.secondary,
  },
  Accordion_HeadingSecondary__Small: {
    color: theme.palette.text.secondary,
    fontSize: "0.8rem",
    alignSelf: "center",
  },

  Quill_ContentHolder: {
    "& p, & h1, & h2, & h3, & ul, & ol": {
      margin: 0,
    },
  },

  Grid_Padded: {
    marginBottom: theme.spacing(2),
  },
}));

export default useGenericStyles;
