import { Chip, Link } from "@material-ui/core";
import React, { useMemo } from "react";
import { CrmCompanyDto, CrmCompanyLocationDto, CrmPersonDto, CrmPersonDtoPartial } from "../../../api";
import CrmRoleChip from "../../../core/CrmRoleChip";
import DataTableExt from "../../../core/DataTableExt";
import FormatDate from "../../../core/FormatDate";
import FormatDateDistance from "../../../core/FormatDateDistance";
import FormatHidden from "../../../core/FormatHidden";
import GenericResourceLink from "../../../core/GenericResourceLink";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useNavigate from "../../../core/useNavigate";
import useTranslations from "../../../core/useTranslations";
import { getCrmCompanyLocationShortCode } from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";
import { FormatCrmCompany, FormatCrmPerson } from "./CrmPersonExtDataTable";

type Props = {
  person: CrmPersonDtoPartial;
  onClose?: () => void;
};

const CrmPersonPartialExtDataTable: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const { person } = props;
  const gc = useGenericStyles();

  const data: [string, React.ReactNode][] = React.useMemo(() => {
    return [
      [
        "",
        person.is_archived && (
          <>
            Deze persoon is <strong>gearchiveerd</strong>.
          </>
        ),
      ],
      ["Naam", person.name],
      ["E-mailadres", person.email],
      ["Mobielnummer", person.mobile],
      ["Telefoonnummer", person.phone],
      [
        "Geboortedatum",
        person.birth && (
          <>
            <FormatDate value={person.birth} fmt="PP" />{" "}
            <small>
              (<FormatDateDistance value={person.birth} unit="year" />)
            </small>
          </>
        ),
      ],
      [
        person.process_person_id_set.length === 1 ? "Procesregisseur" : "Procesregisseurs",
        person.process_person_id_set.length > 0 && (
          <>
            {person.process_person_id_set.map((x) => (
              <Chip label={<FormatCrmPerson person_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        _t("Rol(len)"),
        person.role_id_set.length > 0 && (
          <>
            {person.role_id_set.map((x) => (
              <CrmRoleChip crm_role_id={x} />
            ))}
          </>
        ),
      ],
      [
        _t("Ketenpartner organisatie(s)"),
        person.company_id_set.length > 0 && (
          <>
            {person.company_id_set.map((x) => (
              <Chip label={<FormatCrmCompany company_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        "Aanmeldende organisatie(s)",
        person.notifying_company_id_set.length > 0 && (
          <>
            {person.notifying_company_id_set.map((x) => (
              <Chip label={<FormatCrmCompany company_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        person.involved_company_id_set.length === 1 ? _t("Betrokken ketenpartner") : _t("Betrokken ketenpartners"),
        person.involved_company_id_set.length > 0 && (
          <>
            {person.involved_company_id_set.map((x) => (
              <Chip label={<FormatCrmCompany company_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        person.involved_person_id_set.length === 1 ? "Betrokken contactpersoon" : "Betrokken contactpersonen",
        person.involved_person_id_set.length > 0 && (
          <>
            {person.involved_person_id_set.map((x) => (
              <Chip label={<FormatCrmPerson person_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
    ];
  }, [person]);

  return <DataTableExt data={data} />;
};

export default CrmPersonPartialExtDataTable;
