import React from "react";
import { CrmCompanyLocationDto } from "../../../api";

type Props = {
  location: CrmCompanyLocationDto;
};

const CrmCompanyLocationShortCode: React.FC<Props> = (props) => {
  const { location } = props;
  return <span>{getCrmCompanyLocationShortCode(location)}</span>;
};

function getCrmCompanyLocationShortCode(location: CrmCompanyLocationDto) {
  const code: string[] = [
    location.cd_primary_specialism,
    location.cd_characteristic_specialism,
    `Bev.N. ${location.cd_security_level}`,
    location.cd_care_type,
  ];

  return code.join(" - ");
}

export default CrmCompanyLocationShortCode;
export { getCrmCompanyLocationShortCode };
