import React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Typography } from "@material-ui/core";
import { CareDossierOneResponse } from "../../../api";
import useGenericStyles from "../../../core/useGenericStyles";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import CareDossierExtDataTable from "../Ext/CareDossierExtDataTable";

type Props = {
  onClose: () => void;
  record_id: number;
};

const CareDossierDrawerView: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { record_id, onClose } = props;

  const [{ res: record }] = useApiResult<CareDossierOneResponse>(
    async () => {
      return (await getApi().careDossierOnePost({ id: record_id })).data;
    },
    [record_id],
    { run_on_startup: true },
  );

  return (
    <Drawer open anchor="right">
      <div className={gc.DrawerContainer}>
        <DialogTitle>Persoon</DialogTitle>
        {record && (
          <DialogContent>
            <Typography variant="h5" component="h2">
              {record.person.name}
            </Typography>

            <Typography paragraph variant="body2">
              Hieronder vind je alle informatie van het account van {record.person.name}, je kunt via de knop rechts
              naast de kop de onderstaande informatie bewerken.
            </Typography>

            <CareDossierExtDataTable dossier={record} onClose={onClose} />
          </DialogContent>
        )}
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Sluiten
          </Button>
        </DialogActions>
      </div>
    </Drawer>
  );
};

export default CareDossierDrawerView;
