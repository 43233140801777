import {
  Box,
  CardContent,
  Grid,
  Paper,
  PropTypes,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import clsx from "clsx";
import React from "react";
import _ from "underscore";
import { GenericPagination, TableHeaderDto, TableRowDto } from "../api";
import TableCellValue from "./TableCellValue";
import useTableStyles from "./useTableStyles";
import useTranslations from "./useTranslations";
import Alignment = PropTypes.Alignment;

type Props = {
  loading: boolean;
  title?: string;
  toolbar?: React.ReactNode;
  header_set: TableHeaderDto[];
  row_set: TableRowDto[];
  pagination: GenericPagination;
  setPagination: (pagination: { offset?: number; limit?: number }) => void;
  RowButtonsStart?: React.FC<{ row: TableRowDto }>;
  RowButtonsEnd?: React.FC<{ row: TableRowDto }>;
  shrink?: boolean;
};

const TableExt: React.FC<Props> = ({
  loading,
  title,
  toolbar,
  header_set,
  row_set,
  pagination,
  setPagination,
  RowButtonsStart,
  RowButtonsEnd,
  shrink,
}) => {
  const classes = useTableStyles();
  const _t = useTranslations();

  const paginationProps: { disabled?: boolean } = React.useMemo(() => (loading ? { disabled: true } : {}), [loading]);

  return (
    <Paper className={!shrink && classes.TableView}>
      {title && (
        <CardContent>
          <Typography variant="h5">{title}</Typography>
        </CardContent>
      )}
      {toolbar && <Toolbar className={classes.TableToolbar}>{toolbar}</Toolbar>}

      <TableContainer className={classes.TableContainer}>
        <Table className={classes.Table} stickyHeader size={"small"}>
          <TableHead>
            <TableRow>
              {RowButtonsStart && <TableCell className={classes.TableCellShrink} />}

              {header_set.map((header) => (
                <TableCell
                  key={header.name}
                  align={header.opt_align as Alignment}
                  style={{
                    width:
                      header.display_width && !header.opt_shrink && !header.opt_grow
                        ? header.display_width * 16
                        : "auto",
                    minWidth: header.display_width && !header.opt_shrink ? header.display_width * 16 : "auto",
                  }}
                  className={clsx({
                    [classes.TableCellShrink]: header.opt_shrink,
                  })}
                >
                  {_t(header.title)}
                </TableCell>
              ))}

              {RowButtonsEnd && <TableCell className={classes.TableCellShrink} />}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableExtRows
              header_set={header_set}
              row_set={row_set}
              RowButtonsStart={RowButtonsStart}
              RowButtonsEnd={RowButtonsEnd}
            />

            {row_set.length === 0 && (
              <TableRow>
                <TableCell colSpan={header_set.length + (RowButtonsStart ? 1 : 0) + (RowButtonsEnd ? 1 : 0)}>
                  Geen Records Gevonden...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container>
        <Grid item md={6}>
          <TablePagination
            className={classes.TablePagination}
            SelectProps={paginationProps}
            nextIconButtonProps={paginationProps}
            backIconButtonProps={paginationProps}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.limit}
            page={pagination.offset / pagination.limit}
            labelRowsPerPage="Rijen per pagina"
            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} van de ${count !== -1 ? count : "velen"}`}
            backIconButtonText="Vorige pagina"
            nextIconButtonText="Volgende pagina"
            onPageChange={(e, p) => {
              setPagination({ offset: p * pagination.limit });
            }}
            onRowsPerPageChange={(e) => {
              setPagination({ limit: parseInt(e.target.value) });
            }}
          />
        </Grid>

        <Grid item>
          {loading && (
            <Box display="flex" alignItems="center" paddingLeft={2} padding={1} style={{ height: "100%" }}>
              <HourglassEmptyIcon fontSize="small" />
              <Typography variant="body2" style={{ marginLeft: 8 }}>
                laden, kan even duren!
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

type TableExtRowsProps = {
  header_set: TableHeaderDto[];
  row_set: TableRowDto[];
  RowButtonsStart?: React.FC<{ row: TableRowDto }>;
  RowButtonsEnd?: React.FC<{ row: TableRowDto }>;
};

const TableExtRowsComponent: React.FC<TableExtRowsProps> = ({
  header_set,
  row_set,
  RowButtonsStart,
  RowButtonsEnd,
}) => {
  const classes = useTableStyles();

  return (
    <>
      {row_set.map((row, rx) => (
        <TableRow key={rx}>
          {RowButtonsStart && <RowButtonsStart row={row} />}
          {header_set.map((header, hx) => (
            <TableCell
              key={header.name}
              align={header.opt_align as Alignment}
              className={clsx({
                [classes.TableCellShrink]: header.opt_shrink,
              })}
            >
              <TableCellValue header={header} value={row.values[hx]} />
            </TableCell>
          ))}
          {RowButtonsEnd && <RowButtonsEnd row={row} />}
        </TableRow>
      ))}
    </>
  );
};

const TableExtRows = React.memo<TableExtRowsProps>(TableExtRowsComponent, _.isEqual);

export default TableExt;
