import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";

type ManagementMatrixScoreTableDeleteRowProps = {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  deleterRow: (id) => void;
};

const ManagementMatrixScoreTableDeleteRow: React.FC<ManagementMatrixScoreTableDeleteRowProps> = (props) => {
  const { isOpen, onClose, deleterRow, name } = props;
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle style={{ color: theme.palette.primary.main }}>Verwijderen</DialogTitle>
      <DialogContent>
        <Box pt={2} pb={4}>
          <Typography>Weet u zeker dat u {name} wil verwijderen? </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Sluiten</Button>
        <Button color={"primary"} variant={"contained"} onClick={() => deleterRow(2)}>
          Verwijderen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagementMatrixScoreTableDeleteRow;
