import React from "react";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  steps: string[];
  step: number;
  error?: boolean;
};

const DmzAuthenticateStepper: React.FC<Props> = ({ steps, step, error }) => {
  const classes = useStyles();

  return (
    <Stepper activeStep={step} alternativeLabel className={classes.stepper}>
      {steps.map((v, x) => (
        <Step completed={x <= step - 1} key={x}>
          <StepLabel error={error && x === step}>{v}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: 14,

    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: 4,
      fontSize: 12,
      fontWeight: 300,
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      fontWeight: "500 !important",
    },
  },
}));
export default DmzAuthenticateStepper;
