import * as React from "react";
import sleep from "./sleep";
import useAppStore from "./useAppStore";
import useAsyncEffect from "./useAsyncEffect";

export default function useApiResult<Res = any>(
  callback: () => Promise<Res>,
  deps: React.DependencyList,
  options?: {
    watch?: boolean;
    on_error?: (status_code: number, detail: string) => void;
    run_on_startup?: boolean;
    delay?: number;
  },
): [{ res: Res | null; err: [number, string] | null; busy: boolean }, () => Promise<void>] {
  const app = useAppStore();
  const [busy, setBusy] = React.useState(false);
  const [res, setResponse] = React.useState<Res | null>(null);
  const [err, setError] = React.useState<[number, string] | null>(null);

  const { watch, on_error, run_on_startup, delay } = options || { watch: true };

  const request = React.useCallback(callback, deps);
  const execute = React.useCallback(async () => {
    try {
      setBusy(true);
      setError(null);
      await sleep(delay || 150);
      setResponse(await request());
    } catch (err) {
      const [status_code, detail] = app.actions.getApiError(err);

      setError([status_code, detail]);

      if (on_error) {
        on_error(status_code, detail);
      }
    }

    setBusy(false);
  }, deps);

  React.useEffect(() => {
    if (run_on_startup) {
      execute();
    }
  }, []);

  useAsyncEffect(async () => {
    if (watch) {
      await execute();
    }
  }, [watch, ...deps]);

  return [{ res, err, busy }, execute];
}
