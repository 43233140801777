import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useEffect } from "react";
import { DetailEnum, GenericManualDto } from "../../../api";
import { getApi } from "../../../core/useApi";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsForm from "../../../core/useRxjsForm";
import UploadFieldExt from "../../../form/UploadFieldExt";

type Props = {
  open: boolean;
  record_id?: number;
  onClose: (success?: boolean) => Promise<void>;
};

const GenericManualsDialogSave: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const generic_manual = useRxjsFetch<GenericManualDto | null>(async () => {
    if (props.record_id) return (await getApi().genericManualOnePost({ id: props.record_id })).data;

    return null;
  }, [props.record_id]);

  const form = useRxjsForm<{ resource_id: number }>({
    initial: { resource_id: null },
    submit: async (values) => {
      await getApi().genericManualSavePost({ ...values, id: props.record_id });
      await onClose(true);
    },
  });

  useEffect(() => {
    if (generic_manual.record) {
      form.actions.setValue("resource_id", generic_manual.record.resource.id);
    } else {
      form.actions.setValue("resource_id", null);
    }
  }, [generic_manual.record]);

  return (
    <Dialog open={open} onClose={() => form.submitting === false && onClose()} fullWidth maxWidth="sm">
      <form onSubmit={form.actions.submit} noValidate>
        <DialogTitle>Handleiding {props.record_id ? "aanpassen" : "toevoegen"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zoek het bestand op wat je wilt uploaden. Na het toevoegen wordt deze automatisch beschikbaar gesteld.
          </DialogContentText>
          <UploadFieldExt label="Bestand" form={form} name="resource_id" />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()} color="default" disabled={form.submitting}>
            Annuleren
          </Button>
          <Button type="submit" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

function useComponent(props: Props) {
  return {};
}

export default GenericManualsDialogSave;
