import { Box, Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { ShowChart } from "@material-ui/icons";
import {
  addQuarters,
  format,
  setDayOfYear,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
} from "date-fns";
import React, { useEffect, useMemo } from "react";
import { TsChartDataChartEnum, TsChartDataDto, TsChartDataPeriodMethodEnum } from "../../../api";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../../core/useRxjsStore";
import SkeletonPage from "../../../skeleton/SkeletonPage";
import TagScoreDashboardChartFlow from "./TagScoreDashboardChartFlow";
import TagScoreDashboardChartMatrixes from "./TagScoreDashboardChartMatrixes";
import TagScoreDashboardDrawerFilters from "./TagScoreDashboardDrawerFilters";
import TagScoreDashboardSentence from "./TagScoreDashboardSentence";

type Props = {};

const TagScoreDashboard: React.FC<Props> = (props) => {
  const { state, actions } = useComponent(props);

  return (
    <SkeletonPage>
      <Box mb={2}>
        <Card>
          <CardHeader
            title="TagScore - Dashboard"
            subheader="Dashboarding op basis van onze tags."
            action={
              <Button
                startIcon={<ShowChart />}
                variant="contained"
                onClick={() => actions.setDrawer({ name: "TagScoreDashboardFilter", data: state.filters_data })}
              >
                instellen
              </Button>
            }
          />
          <CardContent>
            <TagScoreDashboardSentence dataDto={state.filters_data} />
          </CardContent>
        </Card>
      </Box>

      {state.booted && (
        <Grid container spacing={1}>
          {state.filters_data.chart_set.map((chart_name, rx) => {
            switch (chart_name) {
              case TsChartDataChartEnum.Flows:
                return (
                  <Grid item xs={12}>
                    <TagScoreDashboardChartFlow dataDto={state.filters_data} />
                  </Grid>
                );
              case TsChartDataChartEnum.Matrixes:
                return (
                  <Grid item xs={12}>
                    <TagScoreDashboardChartMatrixes dataDto={state.filters_data} />
                  </Grid>
                );
            }
          })}
        </Grid>
      )}

      {state.drawer?.name === "TagScoreDashboardFilter" && (
        <Grid item xs={12}>
          <TagScoreDashboardDrawerFilters initial={state.filters_data} onClose={actions.setFiltersData} />
        </Grid>
      )}
    </SkeletonPage>
  );
};

type State = {
  drawer?: { name: "TagScoreDashboardFilter"; data: StateFiltersData };
  booted: boolean;
  filters_data: StateFiltersData;
};

type StateFiltersData = Omit<TsChartDataDto, "chart"> & { chart_set: TsChartDataChartEnum[] };

function useComponent(props: Props) {
  const app = useAppStore();

  const { state, next, getState } = useRxjsStore<State>({
    drawer: undefined,
    booted: false,
    filters_data: {
      chart_set: [TsChartDataChartEnum.Flows, TsChartDataChartEnum.Matrixes],
      period_method: TsChartDataPeriodMethodEnum.ThisQuarter,
      period_start: null,
      period_end: null,
      region_set: [],
      dossier_tag_set: [],
      address_municipality_set: [],
    },
  });

  const actions = useActions(next, getState, state);

  return { app, state, actions };
}

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>, state: State) {
  const actions = useMemo(
    () => ({
      boot: async () => {
        const data = window.localStorage.getItem("TagScoreDashboard-filters");
        if (data) {
          try {
            const parsed = JSON.parse(data);

            return next((d) => {
              d.filters_data = parsed;
              d.booted = true;
            });
          } catch (e) {
            window.localStorage.removeItem("TagScoreDashboard-filters");
          }
        }

        return next((d) => {
          d.booted = true;
        });
      },
      setDrawer: async (drawer?: State["drawer"]) => {
        next((d) => {
          d.drawer = drawer;
        });
      },
      setFiltersData: async (data?: StateFiltersData) => {
        next((d) => {
          d.drawer = undefined;

          if (data !== undefined) {
            window.localStorage.setItem("TagScoreDashboard-filters", JSON.stringify(data));
            d.filters_data = data;
          }
        });
      },
    }),
    [],
  );

  useAsyncEffect(actions.boot, []);

  const dates = useMemo(
    () => ({
      previousMonth: startOfMonth(subMonths(new Date(), 1)),
      previousQuarter: startOfQuarter(subQuarters(new Date(), 1)),
      currentQuarter: startOfQuarter(new Date()),
      previousYear: subMonths(new Date(), 12),
      currentYear: setDayOfYear(new Date(), 1),
    }),
    [],
  );

  useEffect(() => {
    switch (state.filters_data.period_method) {
      case TsChartDataPeriodMethodEnum.Previous12Months:
        next((s) => {
          s.filters_data.period_start = format(dates.previousYear, "yyyy-MM-dd");
          s.filters_data.period_end = format(new Date(), "yyyy-MM-dd");
          s.booted = true;
        });
        break;
      case TsChartDataPeriodMethodEnum.PreviousQuarter:
        next((s) => {
          s.filters_data.period_start = format(dates.previousQuarter, "yyyy-MM-dd");
          s.filters_data.period_end = format(subDays(addQuarters(dates.previousQuarter, 1), 1), "yyyy-MM-dd");
          s.booted = true;
        });
        break;
      case TsChartDataPeriodMethodEnum.ThisYear:
        next((s) => {
          s.filters_data.period_start = format(dates.currentYear, "yyyy-MM-dd");
          s.filters_data.period_end = format(new Date(), "yyyy-MM-dd");
          s.booted = true;
        });
        break;
      case TsChartDataPeriodMethodEnum.ThisQuarter:
        next((s) => {
          s.filters_data.period_start = format(dates.currentQuarter, "yyyy-MM-dd");
          s.filters_data.period_end = format(subDays(addQuarters(dates.currentQuarter, 1), 1), "yyyy-MM-dd");
          s.booted = true;
        });
        break;
    }
  }, [dates, state.filters_data.period_method]);

  return actions;
}

export default TagScoreDashboard;
