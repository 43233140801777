import { formatDistance, formatDistanceStrict, parseISO } from "date-fns";
import { nl } from "date-fns/locale";
import React from "react";

type Props = {
  value?: string;
  till?: string;
  unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
  emptyLabel?: string;
};

const FormatDateDistance: React.FC<Props> = (props) => {
  const { value, till, unit, emptyLabel } = props;
  const [update, setUpdate] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatted = React.useMemo(() => {
    if (!value) return emptyLabel || "";

    const date_from = parseISO(value);
    const date_till = till ? parseISO(till) : Date.now();

    if (unit) {
      return formatDistanceStrict(date_from, date_till, { locale: nl, unit });
    } else {
      return formatDistance(date_from, date_till, { locale: nl, includeSeconds: true });
    }
  }, [value, update, emptyLabel]);

  return <>{formatted}</>;
};

export default FormatDateDistance;
