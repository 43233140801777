import { Dialog, DialogContent, DialogTitle, Drawer } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

type Props = {
  busy: boolean;
  title?: React.ReactNode;
  content?: React.ReactNode;
};

const DialogBusy: React.FC<Props> = (props) => {
  const { busy, title, content } = props;

  return (
    <Dialog open={busy} maxWidth="xs">
      <DialogTitle>{title || "Verwerken"}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {content || "De applicatie verwerkt de gegevens, een ogenblik geduld alstublieft."}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

function useComponent(props: Props) {
  return {};
}

export default DialogBusy;
