const capdash_data = {
  cd_life_course_region: [
    "Amsterdam",
    "Brabant",
    "Limburg",
    "Midden-Nederland",
    "Noord-Holland",
    "Noord-Nederland",
    "Overijssel",
    "Rotterdam",
    "West-Noord-Brabant / Zeeland",
  ],
  cd_care_type: ["Crisis", "Regulier", "Langdurige zorg"],
  cd_security_level: ["1", "1-hoog", "1-laag", "2", "2-hoog", "2-laag", "3", "4"],
  cd_legal_regime: ["Wvggz", "WZD", "WFz", "Bvt"],
  cd_primary_specialism: ["Psychiatrie", "Verslaving", "EVBG", "KIB / LIZ", "SGLVG"],
  cd_characteristic_specialism: ["FPA", "FPC", "FPK", "EVBG", "FVA", "FVK", "KIB / LIZ", "SGLVG"],
  cd_contraindication: ["Zeden", "Leeftijd < 18 jaar", "Verslaving", "LVB", "Vrouw-zijn"],
};

export default capdash_data;
