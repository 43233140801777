import { amber, blue, deepPurple } from "@material-ui/core/colors";

const isLive = window.location.hostname === "app.tagscore.nl";

const theme = {
  primary: isLive ? blue[500] : deepPurple[500],
  secondary: amber[700],
};

export default theme;
