import React from "react";
import _ from "underscore";
import useCrmPersonTableStore, { CrmPersonTableProvider } from "./useCrmPersonTableStore";
import TableExt from "../../../core/TableExt";
import CrmPersonTableToolbar from "./CrmPersonTableToolbar";
import { CrmPersonTableRowButtonsEnd, CrmPersonTableRowButtonsStart } from "./CrmPersonTableButtons";
import CrmPersonTableDrawers from "./CrmPersonTableDrawers";

const CrmPersonTable: React.FC = () => {
  return (
    <CrmPersonTableProvider>
      <Render />
    </CrmPersonTableProvider>
  );
};

const Render: React.FC = () => {
  const table = useCrmPersonTableStore();

  const headers = React.useMemo(
    () => table.req.header_set.map((x) => table.header_set.find((h) => h.name === x)).filter((x) => !!x),
    [table.req.header_set],
  );

  const initial = React.useMemo(() => _.pick(table.req, ["search", "is_archived"]), [table.booted, table.req]);

  return (
    <>
      <TableExt
        loading={table.loading}
        title="Personen"
        header_set={headers}
        row_set={table.res.row_set}
        pagination={table.res.pagination}
        setPagination={(x) => table.actions.setPagination(x)}
        toolbar={
          <CrmPersonTableToolbar
            initial={initial}
            onChange={(props) => {
              table.actions.setRequest(props);
            }}
          />
        }
        RowButtonsStart={CrmPersonTableRowButtonsStart}
        RowButtonsEnd={CrmPersonTableRowButtonsEnd}
      />
      <CrmPersonTableDrawers />
    </>
  );
};

export default CrmPersonTable;
