import {
  Card,
  CardContent,
  CardHeader,
  colors,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { RadioButtonChecked, Refresh, SaveAltOutlined } from "@material-ui/icons";
import { Line } from "@nivo/line";
import React, { useEffect, useMemo, useState } from "react";
import _ from "underscore";
import { ChartDataDto } from "../../../api";
import ChartResponsive from "../../../core/ChartResponsive";
import useAppStore from "../../../core/useAppStore";
import useCrmCompanyLocation from "../../../core/useCrmCompanyLocation";
import CrmCompanyInfoDrawer from "../../CrmCompany/Ext/CrmCompanyInfoDrawer";
import CrmCompanyLocationShortCode from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";

export type CDDChartData = {
  iteration: number;
  data: ChartDataDto[];
};

type Props = {
  header: string;
  chart: CDDChartData;
  loading?: boolean;
  onDownload?: () => void;
  onReload?: () => void;
};

const CDDGenericLineChart: React.FC<Props> = (props) => {
  return (
    <Card style={{ overflow: "initial" }}>
      <CardHeader
        title={props.header}
        action={
          <>
            <Tooltip title="Download als Excel.">
              <IconButton onClick={props.onDownload}>
                <SaveAltOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Herlaad deze statistiek.">
              <IconButton onClick={props.onReload}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      {props.loading && (
        <>
          <LinearProgress style={{ width: "100%", height: 3 }} variant="indeterminate" />
          <CardContent>
            <Typography variant="body2">
              We zijn de statistiek aan het laden, een ogenblik geduld alstublieft.
            </Typography>
          </CardContent>
        </>
      )}
      {!props.loading && props.chart.data.length === 0 && (
        <>
          <CardContent>
            <Typography variant="body2">Er is geen data gevonden die voldoet aan de opgegeven filters.</Typography>
          </CardContent>
        </>
      )}
      {!props.loading && props.chart.data.length > 0 && (
        <>
          <CardContent>
            <ChartRaw data={props.chart.data} iteration={props.chart.iteration} />
          </CardContent>
        </>
      )}
    </Card>
  );
};

function ChartRaw({ data, iteration }: { data: CDDChartData["data"]; iteration: number }) {
  const uuid = useMemo(() => `${+new Date()}-${Math.round(Math.random() * 1000000)}`, []);
  const [locationId, setLocationId] = useState<undefined | number>();
  const [activeDay, setActiveDay] = useState<string | undefined>();
  const app = useAppStore();
  const location = useCrmCompanyLocation(locationId);

  const companyColorSetup: {
    [name: number]: {
      color: { [name: string]: string };
      location_hue: { [name: number]: string };
    };
  } = useMemo(() => {
    const colorList: { [name: string]: string }[] = _.flatten(
      _.times(20, () => [
        colors.amber,
        colors.blue,
        colors.blueGrey,
        colors.brown,
        // colors.common,
        colors.cyan,
        colors.deepOrange,
        colors.deepPurple,
        colors.green,
        // colors.grey,
        colors.indigo,
        colors.lightBlue,
        colors.lightGreen,
        colors.lime,
        colors.orange,
        colors.pink,
        colors.purple,
        colors.red,
        colors.teal,
        colors.yellow,
      ]),
    );

    const colorHue = ["500", "200", "700", "300", "600", "100", "400", "800", "900", "50"];
    let crmCompanyVisited = {};
    let isActiveDaySet = activeDay !== undefined;

    data.map((item) => {
      if (!isActiveDaySet) {
        isActiveDaySet = true;
        setActiveDay(item.data[0].x);
      }

      if (crmCompanyVisited[item.crm_company_id] === undefined) {
        crmCompanyVisited[item.crm_company_id] = {
          color: colorList[Object.keys(crmCompanyVisited).length],
          location_hue: {},
        };
      }

      const group = crmCompanyVisited[item.crm_company_id];

      if (group.location_hue[item.crm_company_location_id] === undefined) {
        group.location_hue[item.crm_company_location_id] = colorHue[Object.keys(group.location_hue).length];
      }

      return group.color[group.location_hue[item.crm_company_location_id]];
    });

    return crmCompanyVisited;
  }, [data]);

  const legendColors = useMemo(() => {
    return data.map((item) => {
      const group = companyColorSetup[item.crm_company_id];
      return group.color[group.location_hue[item.crm_company_location_id]];
    });
  }, [data]);

  const legend = useMemo(
    () =>
      _.flatten(
        Object.keys(companyColorSetup).map((crm_company_id) => {
          const group = companyColorSetup[crm_company_id];
          const crm_company = app.data.crm_company_set.find((x) => x.id === parseInt(crm_company_id, 10));

          return Object.keys(group.location_hue).map((crm_company_location_id) => {
            const crm_company_location = crm_company.location_set.find(
              (x) => x.id === parseInt(crm_company_location_id, 10),
            );
            const hue = group.location_hue[crm_company_location_id];

            return {
              crm_company,
              crm_company_location,
              color: group.color[hue],
            };
          });
        }),
      ).map((item) => {
        const category = data.find((x) => x.crm_company_location_id === item.crm_company_location.id);
        const point = category.data.find((x) => x.x === activeDay);
        const value = point?.l || point?.y || 0;

        return { ...item, value };
      }),
    [companyColorSetup, data, activeDay],
  );

  useEffect(() => {
    const key = `update.${uuid}`;
    const update = (e: CustomEvent<string>) => {
      if (activeDay !== e.detail) {
        setActiveDay(e.detail);
      }
    };

    window.addEventListener(key, update);
    return () => window.removeEventListener(key, update);
  }, [activeDay]);

  return (
    <>
      <ChartWithCache data={data} legendColors={legendColors} uuid={uuid} />

      <Typography>Week: {activeDay || "er is nog geen week geselecteerd..."}</Typography>

      {data && (
        <Grid container>
          {_.chunk(legend, Math.ceil(data.length / 2)).map((item_set, rx) => (
            <Grid item xs key={rx}>
              <Table size="small">
                <TableBody>
                  {item_set.map((item, rx) => (
                    <TableRow key={rx}>
                      <TableCell>
                        <Link onClick={() => setLocationId(item.crm_company_location.id)}>
                          <span style={{ fontSize: 14 }}>
                            <span style={{ color: item.color, marginRight: 6 }}>
                              <RadioButtonChecked style={{ fontSize: 16 }} />
                            </span>
                            {item.crm_company.name}
                            <CrmCompanyLocationShortCode location={item.crm_company_location} />
                          </span>
                        </Link>
                      </TableCell>
                      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                        {item.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          ))}
        </Grid>
      )}

      <CrmCompanyInfoDrawer
        open={!!location}
        onClose={() => setLocationId(undefined)}
        company={location && location.company}
        location={location}
      />
    </>
  );
}

const Chart: React.FC<{ uuid: string; data: any; legendColors: any }> = ({ uuid, data, legendColors }) => {
  const lowest = useMemo(() => Math.min(...data.map((serie) => Math.min(...serie.data.map((item) => item.y)))), [data]);
  const highest = useMemo(
    () => Math.max(...data.map((serie) => Math.max(...serie.data.map((item) => item.y)))),
    [data],
  );
  const diff = highest - lowest;

  return (
    <ChartResponsive height={400}>
      {({ width, height }) => (
        <Line
          width={width}
          height={height}
          data={data}
          margin={{ top: 10, right: 50, bottom: 60, left: 50 }}
          yScale={{ type: "linear", min: "auto", max: "auto", reverse: false }}
          curve="monotoneX"
          animate={false}
          colors={legendColors}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -20,
            legend: "Datum",
            legendOffset: 30,
            legendPosition: "end",
            tickValues: 3,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: diff < 10 ? 1 : undefined,
            legend: "Aantallen",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={4}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            window.dispatchEvent(
              new CustomEvent<string>(`update.${uuid}`, { detail: slice.points[0]?.data.x as string }),
            );

            return null;
          }}
        />
      )}
    </ChartResponsive>
  );
};

const ChartWithCache = React.memo(Chart, (prev, next) => {
  return prev.data === next.data;
});

export default CDDGenericLineChart;
