import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Refresh, SaveAltOutlined } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { CdDataChartTypeEnum, RecordDto } from "../../../api";
import sleep from "../../../core/sleep";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useAppStore from "../../../core/useAppStore";
import useCrmCompanyLocation from "../../../core/useCrmCompanyLocation";
import useGenericStyles from "../../../core/useGenericStyles";
import useTranslations from "../../../core/useTranslations";
import waiting_failed_lookup from "../../../core/waiting_failed_lookup";
import CrmCompanyInfoDrawer from "../../CrmCompany/Ext/CrmCompanyInfoDrawer";
import CrmCompanyLocationShortCode from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";
import { CapDashDashboardStateFiltersData } from "../CapDashDashboard";

type Props = {
  filters_data: CapDashDashboardStateFiltersData;
};

const CapDashDashboardChartCareAccess: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const [filterCrmLocationIdSet, setFilterCrmLocationIdSet] = useState<number[] | undefined>(undefined);
  const [downloading, setDownloading] = useState(false);
  const [iteration, setIteration] = useState(0);
  const app = useAppStore();

  const [data] = useApiResult(async () => {
    await sleep(250);

    const filter_crm_location_id_set = (
      await getApi().cdDataChartLocationsPost({ chart: CdDataChartTypeEnum.CareAccess, ...props.filters_data })
    ).data.location_id_set;

    setFilterCrmLocationIdSet(filter_crm_location_id_set);

    return (
      await getApi().cdDataChartCareAccessPost({
        day_after: props.filters_data.period_start,
        day_before: props.filters_data.period_end,
        crm_company_location_id_set: filter_crm_location_id_set,
      })
    ).data;
  }, [props.filters_data, iteration]);

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Toegang tot zorg"
        action={
          <>
            {!!data.res && (
              <Tooltip title="Download als Excel.">
                <IconButton onClick={() => setDownloading(true)} disabled={data.busy}>
                  <SaveAltOutlined />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Herlaad deze statistiek.">
              <IconButton onClick={() => setIteration((x) => x + 1)} disabled={data.busy}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </>
        }
      />

      {data.busy && (
        <>
          <LinearProgress style={{ width: "100%", height: 3 }} variant="indeterminate" />
          <CardContent>
            <Typography variant="body2">
              We zijn de statistiek aan het laden, een ogenblik geduld alstublieft.
            </Typography>
          </CardContent>
        </>
      )}

      {!!data.res && (
        <div style={{ overflow: "hidden", position: "relative", width: "100%", height: 600 }}>
          <TableContainer style={{ position: "absolute", height: "100%", overflowX: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    width="300px"
                    style={{ left: 0, position: "sticky", zIndex: 3 }}
                    variant="head"
                    rowSpan={2}
                  >
                    {_t("Locatie")}
                  </TableCell>
                  <TableCell colSpan={3}>Totalen</TableCell>
                  {Object.entries(waiting_failed_lookup).map(([name, item_set]) => (
                    <TableCell colSpan={item_set.length}>
                      <span>Niet gelukte aanmeldingen - {_t(`waiting_failed_lookup.${name}`)}</span>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell style={{ top: 58 }}>Nieuw op wachtlijst</TableCell>
                  <TableCell style={{ top: 58 }}>Niet gelukte aanmeldingen</TableCell>
                  <TableCell style={{ top: 58 }}>
                    <Tooltip title="Verhouding niet op ewachtlijst versus niet gelukte aanmeldingen.">
                      <span>Verhouding</span>
                    </Tooltip>
                  </TableCell>

                  {Object.entries(waiting_failed_lookup).map(([name, item_set]) =>
                    item_set.map((item) => (
                      <TableCell style={{ top: 58 }}>
                        <Tooltip title={item.label}>
                          <span>{item.short}</span>
                        </Tooltip>
                      </TableCell>
                    )),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.res.row_set.map((row: RecordDto) => (
                  <CapDashDashboardChartCareAccessTableRow row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {downloading && (
        <TableDrawerDownload
          onClose={() => setDownloading(false)}
          onDownloadStart={async () =>
            (
              await getApi().cdDataChartCareAccessDownloadPost({
                day_after: props.filters_data.period_start,
                day_before: props.filters_data.period_end,
                crm_company_location_id_set: filterCrmLocationIdSet,
              })
            ).data
          }
        />
      )}
    </Card>
  );
};

const CapDashDashboardChartCareAccessTableRow: React.FC<{ row: RecordDto }> = ({ row }) => {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const location = useCrmCompanyLocation(row.crm_company_location_id);
  const gc = useGenericStyles();

  return (
    <TableRow>
      <TableCell style={{ left: 0, position: "sticky", backgroundColor: "#fff" }}>
        <div style={{ width: 300, cursor: "help" }} onClick={() => setInfoOpen(true)}>
          <strong>{location.company.name}</strong>
          <br />
          <CrmCompanyLocationShortCode location={location} />
        </div>

        <CrmCompanyInfoDrawer
          open={isInfoOpen}
          onClose={() => setInfoOpen(false)}
          company={location.company}
          location={location}
        />
      </TableCell>
      <TableCell className={gc.TableCell_Center}>{row.sum_waiting_new}</TableCell>
      <TableCell className={gc.TableCell_Center}>{row.sum_waiting_failed}</TableCell>
      <TableCell
        className={clsx(
          gc.TableCell_Shrink,
          gc.TableCell_Center,
          row.sum_waiting_failed < row.sum_waiting_new && gc.TableCell_Success,
          row.sum_waiting_failed === row.sum_waiting_new && gc.TableCell_Warning,
          row.sum_waiting_failed > row.sum_waiting_new && gc.TableCell_Danger,
        )}
      >
        {row.sum_waiting_new} / {row.sum_waiting_failed}
      </TableCell>

      {Object.entries(waiting_failed_lookup).map(([name, item_set]) =>
        item_set.map((item) => {
          const value = row[`waiting_failed_${name}`][item.value];
          return (
            <TableCell className={clsx(gc.TableCell_Center, value === 0 && gc.TableCell_Muted)}>{value}</TableCell>
          );
        }),
      )}
    </TableRow>
  );
};

export default CapDashDashboardChartCareAccess;
