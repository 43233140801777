import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import {
  isSkeletonDrawerMenuItemButton,
  isSkeletonDrawerMenuItemWithChildren,
  SkeletonDrawerMenuItem,
} from "./SkeletonDrawerTypes";
import clsx from "clsx";
import SkeletonDrawerMenuListItem from "./SkeletonDrawerMenuListItem";
import useSkeletonDrawerStyles from "./useSkeletonDrawerStyles";
import { useLocation } from "react-router-dom";

type Props = {
  item: SkeletonDrawerMenuItem;
  depth?: number;
};

export default function SkeletonDrawerMenuList(props: Props) {
  const { item, depth } = props;

  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const classes = useSkeletonDrawerStyles();

  const class_name = (classes as any)[`ListItem_Depth_${depth || 0}`];

  React.useEffect(() => {
    function recursive_match(item: SkeletonDrawerMenuItem) {
      if (isSkeletonDrawerMenuItemButton(item)) {
        return location.pathname.startsWith(item.path);
      } else {
        return item.children.filter((child) => !!child).some(recursive_match);
      }
    }

    const open = recursive_match(item);

    setOpen(open);
  }, [item, location.pathname]);

  function onClick() {
    setOpen(!open);
  }

  if (isSkeletonDrawerMenuItemWithChildren(item)) {
    return (
      <>
        <ListItem button onClick={onClick} className={clsx([class_name, open && classes.ListItem_Open])}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText inset={!item.icon} primary={item.label} />
          <div
            className={clsx(classes.SkeletonDrawer_IconExpander, {
              [classes.SkeletonDrawer_IconExpanderOn_180]: open,
            })}
          >
            <ExpandMoreIcon />
          </div>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children
              .filter((child) => !!child)
              .map((child, cx) => {
                if (isSkeletonDrawerMenuItemButton(child)) {
                  return <SkeletonDrawerMenuListItem key={cx} item={child} depth={(depth || 0) + 1} />;
                } else if (isSkeletonDrawerMenuItemWithChildren(child)) {
                  return <SkeletonDrawerMenuList key={cx} item={child} depth={(depth || 0) + 1} />;
                }

                return null;
              })}
          </List>
        </Collapse>
      </>
    );
  } else {
    return <SkeletonDrawerMenuListItem item={item} depth={depth || 0} />;
  }
}
