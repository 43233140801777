import { Box, Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { ShowChart } from "@material-ui/icons";
import React, { useMemo } from "react";
import { CdDataChartPeriodEnum, CdDataChartRequest, CdDataChartTypeEnum } from "../../api";
import useAppStore from "../../core/useAppStore";
import useAsyncEffect from "../../core/useAsyncEffect";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../core/useRxjsStore";
import SkeletonPage from "../../skeleton/SkeletonPage";
import CapDashDashboardDrawerFilters from "./CapDashDashboardDrawerFilters";
import CapDashDashboardFiltersSentence from "./CapDashDashboardFiltersSentence";
import CapDashDashboardChartCurrentCapacity from "./Charts/CapDashDashboardChartCurrentCapacity";
import CapDashDashboardChartGeneric from "./Charts/CapDashDashboardChartGeneric";
import CapDashDashboardChartRegistryCheckup from "./Charts/CapDashDashboardChartRegistryCheckup";
import CapDashDashboardChartCareAccess from "./Charts/CapDashDashboardChartCareAccess";
import SessionLoginHistory from "./Charts/SessionLoginHistory";

type Props = {};

const CapDashDashboard: React.FC<Props> = (props) => {
  const { state, actions } = useComponent(props);
  return (
    <SkeletonPage>
      <Box mb={2}>
        <Card>
          <CardHeader
            title="CapDash - Dashboard"
            subheader="Het dashboard op de invoer van de data door zorgaanbieders."
            action={
              <Button
                startIcon={<ShowChart />}
                variant="contained"
                onClick={() => actions.setDrawer({ name: "CapDashDashboardDrawerFilters", data: state.filters_data })}
              >
                instellen
              </Button>
            }
          />
          <CardContent>
            <CapDashDashboardFiltersSentence filters_data={state.filters_data} />
          </CardContent>
        </Card>
      </Box>

      {state.booted && (
        <Grid container spacing={1}>
          {state.filters_data.chart_set.map((chart_name, rx) => {
            const generic_set = [
              "available",
              "waiting_duration",
              "capacity",
              "occupied",
              "intake",
              "outflow",
              "reserved",
              "waiting",
              "waiting_new",
              "waiting_failed_content",
              "waiting_failed_financial",
              "waiting_failed_system",
            ];

            if (generic_set.includes(chart_name)) {
              return (
                <Grid item xs={12} key={rx}>
                  <CapDashDashboardChartGeneric chart={chart_name} filters_data={state.filters_data} />
                </Grid>
              );
            } else if (chart_name === "current_capacity") {
              return (
                <Grid item xs={12} key={rx}>
                  <CapDashDashboardChartCurrentCapacity filters_data={state.filters_data} />
                </Grid>
              );
            } else if (chart_name === "registry_checkup") {
              return (
                <Grid item xs={12} key={rx}>
                  <CapDashDashboardChartRegistryCheckup filters_data={state.filters_data} />
                </Grid>
              );
            } else if (chart_name === "session_login_checkup") {
              return (
                <Grid item xs={12} key={rx}>
                  <SessionLoginHistory filters_data={state.filters_data} />
                </Grid>
              );
            } else if (chart_name === "care_access") {
              return (
                <Grid item xs={12} key={rx}>
                  <CapDashDashboardChartCareAccess filters_data={state.filters_data} />
                </Grid>
              );
            }

            return null;
          })}
        </Grid>
      )}

      {state.drawer?.name === "CapDashDashboardDrawerFilters" && (
        <Grid item xs={12}>
          <CapDashDashboardDrawerFilters
            initial={state.drawer.data}
            onClose={(data?: StateFiltersData) => {
              actions.setFiltersData(data);
            }}
          />
        </Grid>
      )}
    </SkeletonPage>
  );
};

// region Types

type State = {
  drawer: undefined | { name: "CapDashDashboardDrawerFilters"; data: StateFiltersData };
  booted: boolean;
  filters_data: StateFiltersData;
};

type StateFiltersData = Omit<CdDataChartRequest, "chart"> & { chart_set: CdDataChartTypeEnum[] };

// endregion

function useComponent(props: Props) {
  const app = useAppStore();

  const { state, next, getState } = useRxjsStore<State>({
    drawer: undefined,
    booted: false,
    filters_data: {
      chart_set: [CdDataChartTypeEnum.Available],
      period_method: CdDataChartPeriodEnum.Previous4Weeks,
      period_start: undefined,
      period_end: undefined,
      crm_company_id_set: [],
      cd_life_course_region_set: [],
      cd_care_type_set: [],
      cd_care_capacity_set: [],
      cd_security_level_set: [],
      cd_legal_regime_set: [],
      cd_primary_specialism_set: [],
      cd_characteristic_specialism_set: [],
      cd_contraindication_set: [],
    },
  });

  const actions = useActions(next, getState, state);

  return { app, state, actions };
}

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>, state: State) {
  const actions = useMemo(
    () => ({
      boot: async () => {
        const data = window.localStorage.getItem("CapDashDasboard-filters");
        if (data) {
          try {
            const parsed = JSON.parse(data);

            return next((d) => {
              d.filters_data = parsed;
              d.booted = true;
            });
          } catch (e) {
            window.localStorage.removeItem("CapDashDasboard-filters");
          }
        }

        return next((d) => {
          d.booted = true;
        });
      },
      setDrawer: async (drawer?: State["drawer"]) => {
        next((d) => {
          d.drawer = drawer;
        });
      },
      setFiltersData: async (data?: StateFiltersData) => {
        next((d) => {
          d.drawer = undefined;

          if (data !== undefined) {
            window.localStorage.setItem("CapDashDasboard-filters", JSON.stringify(data));
            d.filters_data = data;
          }
        });
      },
    }),
    [],
  );

  useAsyncEffect(actions.boot, []);

  return actions;
}

export default CapDashDashboard;
export type { StateFiltersData as CapDashDashboardStateFiltersData };
