import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { MatrixColumnDto, MatrixColumnSaveDto } from "../../../api";
import DialogBusy from "../../../core/DialogBusy";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsForm from "../../../core/useRxjsForm";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import SwitchFieldExt from "../../../form/SwitchFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";

type Props = {
  record_id?: number;
  matrix_id: number;
  onClose: (success?: boolean) => Promise<void>;
};

const MatrixColumnSaveDrawer: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const gc = useGenericStyles();
  const { state, form, actions } = useComponent(props);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Kolom {state.record ? "aanpassen" : "toevoegen"}</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldExt form={form} name="name" label="Naam" />
            </Grid>
            <Grid item xs={12}>
              <TextFieldExt form={form} name="label" label="Label" />
            </Grid>

            <Grid item xs={12}>
              <TextFieldExt form={form} name="score" label="Score" />
            </Grid>
            <Grid item xs={12}>
              <TextFieldExt form={form} name="position" label="Positie" />
            </Grid>

            <Grid item xs={12}>
              <SwitchFieldExt
                form={form}
                name="is_zero_default"
                onLabel="Is standaard keuze bij lege waarde"
                offLabel="Niet de standaard keuze bij lege waarde"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => actions.onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>

        <DialogBusy busy={state.busy} />
      </form>
    </Drawer>
  );
};

type State = {
  busy: boolean;
  record?: MatrixColumnDto;
};

function useComponent(props: Props) {
  const app = useAppStore();
  const { record_id, matrix_id, onClose } = props;
  const { state, getState, next } = useRxjsStore<State>({
    busy: false,
  });

  const actions = useMemo(
    () => ({
      load: async () => {
        next((d) => (d.busy = true));
        const record = await callApi(
          app,
          async (api) => record_id && (await api.matrixColumnOnePost({ id: record_id })).data,
        );
        next((d) => {
          d.busy = false;
          d.record = record;
        });
      },

      save: async (values: MatrixColumnSaveDto) => {
        const res = await callApi(app, async (api) => await api.matrixColumnSavePost(values));

        if (res.data?.id) await actions.onClose(true);
      },

      onClose: async (success?: boolean) => {
        next((d) => (d.busy = true));

        try {
          await onClose(success);
        } catch (e) {
          console.error(e);
          next((d) => (d.busy = false));
        }
      },
    }),
    [record_id],
  );

  const form = useRxjsForm<MatrixColumnSaveDto>({
    initial: {
      id: record_id,
      matrix_id,
      position: 0,
      name: "",
      label: "",
      score: 0,
      is_zero_default: false,
    },
    submit: (values) => actions.save(values),
  });

  useAsyncEffect(actions.load, [record_id]);

  useEffect(() => {
    console.info(form.initial, state.record);
    form.actions.setValues(state.record ? _.pick(state.record, Object.keys(form.initial) as any) : form.initial);
  }, [state.record?.id]);

  return { state, form, actions };
}

export default MatrixColumnSaveDrawer;
