import React from "react";
import { ApiWebResolveZipcodeResponse } from "../api";
import { callApi } from "./useApi";
import useAppStore from "./useAppStore";

function useResolveZipcode(
  zipcode: string,
  housenumber: string,
  callback: (res: ApiWebResolveZipcodeResponse) => void,
): [boolean, () => void] {
  const app = useAppStore();
  const [isResolving, setResolving] = React.useState(false);

  const execute = React.useCallback(async () => {
    if (isResolving) return;
    setResolving(true);

    const res = await callApi(
      app,
      async (api) =>
        (
          await api.apiWebResolveZipcodePost({
            zipcode,
            housenumber,
          })
        ).data,
    );

    if (res) {
      callback(res);
      app.actions.addMessage("De gegevens zijn automatisch overgenomen.", "success");
    } else {
      app.actions.addMessage("De postcode kon niet gevonden worden.", "error");
    }

    setResolving(false);
  }, [isResolving, zipcode, housenumber]);

  return [isResolving, execute];
}

export default useResolveZipcode;
