import React from "react";
import TableDrawerDownload from "../../core/TableDrawerDownload";
import TableDrawerHeaders from "../../core/TableDrawerHeaders";
import { getApi } from "../../core/useApi";
import { CareDossierTriggersToggleDialog } from "./CareDossierTriggersToggleDialog";
import useCareDossierTriggersTableStore from "./useCareDossierTriggersTableStore";

const CareDossierTableDrawers: React.FC = () => {
  const table = useCareDossierTriggersTableStore();

  return (
    <>
      {table.drawer?.name === "TableHeaders" && (
        <TableDrawerHeaders
          open={true}
          header_set={table.header_set}
          used_set={table.req.header_set || ["id", "name"]}
          onClose={() => table.actions.setDrawer(undefined)}
          onSave={(header_set) => {
            table.actions.setRequest({ header_set });
            table.actions.setDrawer(undefined);
          }}
        />
      )}
      {table.drawer?.name === "TableDownload" && (
        <TableDrawerDownload
          onClose={() => table.actions.setDrawer(undefined)}
          onDownloadStart={async () => (await getApi().careDossierTriggerTableDownloadPost(table.req)).data}
        />
      )}
      {table.drawer?.name === "Toggle" && (
        <CareDossierTriggersToggleDialog
          recordId={table.drawer.record_id}
          open={true}
          onClose={() => table.actions.setDrawer(undefined)}
          onSave={async () => {
            if (table.drawer?.name !== "Toggle") return;

            await getApi().careDossierTriggerTogglePost({ id: table.drawer.record_id });
            await table.actions.load();
            table.actions.setDrawer(undefined);
          }}
        />
      )}
    </>
  );
};

export default CareDossierTableDrawers;
