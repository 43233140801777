import React from "react";
import _ from "underscore";
import TableExt from "../../core/TableExt";
import useCareDossierTriggersTableStore, { CareDossierTriggersTableProvider } from "./useCareDossierTriggersTableStore";
import CareDossierTriggersToolbar from "./CareDossierTriggersTableToolbar";
import { CareDossierTriggersTableRowButtonsEnd } from "./CareDossierTriggersTableButtons";
import CareDossierTriggersTableDrawers from "./CareDossierTriggersTableDrawers";

const CareDossierTriggersTable: React.FC = () => {
  return (
    <CareDossierTriggersTableProvider>
      <Render />
    </CareDossierTriggersTableProvider>
  );
};

const Render: React.FC = () => {
  const table = useCareDossierTriggersTableStore();

  const headers = React.useMemo(
    () => table.req.header_set.map((x) => table.header_set.find((h) => h.name === x)).filter((x) => !!x),
    [table.req.header_set],
  );

  const initial = React.useMemo(
    () => _.pick(table.req, ["crm_person_id_set", "process_person_id_set", "is_closed"]),
    [table.booted, table.req],
  );

  return (
    <>
      <TableExt
        loading={table.loading}
        title="Dossiers"
        header_set={headers}
        row_set={table.res.row_set}
        pagination={table.res.pagination}
        setPagination={(x) => table.actions.setPagination(x)}
        toolbar={
          <CareDossierTriggersToolbar
            initial={initial}
            onChange={(props) => {
              table.actions.setRequest(props);
            }}
          />
        }
        RowButtonsEnd={CareDossierTriggersTableRowButtonsEnd}
      />
      <CareDossierTriggersTableDrawers />
    </>
  );
};

export default CareDossierTriggersTable;
