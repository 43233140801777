import { FormControlLabel, FormHelperText, Switch, SwitchProps } from "@material-ui/core";
import React from "react";
import { equalityRxjsFieldCompare, extractRxjsField, RxjsForm } from "../core/useRxjsForm";

type Props = {
  form: RxjsForm<any>;

  name: string;
  onLabel: string;
  offLabel: string;
  inverted?: boolean;
} & Omit<SwitchProps, "name" | "form" | "onChange" | "checked">;

const SwitchFieldExtComp: React.FC<Props> = (props) => {
  const { form, name, onLabel, offLabel, inverted, type, ...other } = props;
  const { value, error, dirty } = extractRxjsField(form, name);

  function mutate(value: boolean) {
    return inverted ? !value : !!value;
  }

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={mutate(value)}
            onChange={(e) => form.actions.setValue(name, mutate(e.target.checked))}
            name={name}
            {...other}
          />
        }
        label={mutate(value) ? onLabel : offLabel}
      />
      {dirty && error && <FormHelperText>{error}</FormHelperText>}
    </>
  );
};

const SwitchFieldExt = React.memo(SwitchFieldExtComp, equalityRxjsFieldCompare);

export default SwitchFieldExt;
