import * as React from "react";
import { ApiWebDataResponse, DefaultApi, DetailEnum, TsSessionLoginResponse } from "../api";
import useNavigate from "./useNavigate";
import useStore, { BaseActions, BaseActionsProps } from "./useStore";
import useApi, { getApi, getApiError } from "./useApi";
import globalAxios from "axios";
import useAsyncEffect from "./useAsyncEffect";
import { Color as AlertColor } from "@material-ui/lab/Alert";
import _ from "underscore";
import { useMediaQuery } from "@material-ui/core";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "./useRxjsStore";

type AppStore = State & { actions: ReturnType<typeof useActions> };

type State = {
  messages: StateMessage[];
  title?: string;
  drawer: {
    is_open: boolean;
  };
  theme: {
    is_dark: boolean;
  };
  overlay: StateOverlay;
  session: TsSessionLoginResponse | null;
  is_authorized: boolean;
  is_booted: boolean;
  data: ApiWebDataResponse | null;

  togglerMode?: "notes";
  togglerOpenHint: string;
  togglerCloseHint: string;
};

type StateMessage = {
  uuid: string;
  created_at: number;
  expires_at: number;
  content: React.ReactNode;
  severity: AlertColor;
};

type StateOverlay = {
  which: undefined;
  data: undefined;
};

const Context = React.createContext<AppStore>(null as AppStore);

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>) {
  function setHandledToken(value: string | null) {
    if (value) {
      window.localStorage.setItem("Authentication.token", value);
      globalAxios.defaults.headers["X-SESSION-TOKEN"] = value;
    } else {
      window.localStorage.removeItem("Authentication.token");
      delete globalAxios.defaults.headers["X-SESSION-TOKEN"];
    }
  }

  const actions = {
    boot: async () => {
      const token = window.localStorage.getItem("Authentication.token");

      if (token !== null) {
        setHandledToken(token);
        await actions.checkAuthentication();
      } else {
        await actions.setAuthentication(null);
      }

      next((d) => (d.is_booted = true));
    },
    loadData: async () => {
      try {
        const res = (await getApi().apiWebDataPost()).data;

        next((d) => (d.data = res));
        (window as any).DOWNLOADED_DATA_SET = res;

        console.info(window.DEFINED_VERSION, res.version);
      } catch (err) {
        actions.getApiError(err);
      }
    },
    setThemeIsDark: (value?: boolean) => {
      next((d) => (d.theme.is_dark = value === undefined ? !d.theme.is_dark : value));
    },
    setAuthentication: async (session: TsSessionLoginResponse | null, callback?: () => void) => {
      next((d) => {
        d.session = session;
        if (!session) d.is_authorized = false;
      });

      setHandledToken(session?.token);

      if (session) {
        await actions.loadData();
        next((d) => (d.is_authorized = true));
      }

      if (callback) callback();
    },
    checkAuthentication: async () => {
      try {
        const res = await getApi().tsSessionCheckPost();

        await actions.setAuthentication(res.data);
      } catch (err) {
        const [status, detail] = getApiError(err);

        console.error("Failure validating stored token", status, detail);
        await actions.setAuthentication(null);
      }
    },

    addMessage: (content: React.ReactNode, severity: AlertColor = "info", timeout_s: number = 3) => {
      const created_at = +new Date();
      next((d) => {
        d.messages.push({
          uuid: `${created_at}-${Math.floor(Math.random() * 10000)}`,
          created_at,
          expires_at: created_at + timeout_s * 1000,
          content,
          severity,
        });
      });
    },
    purgeMessages: (uuid_set?: string[]) => {
      const now = +new Date();

      next((d) => {
        d.messages = d.messages.filter((message) => {
          return message.expires_at > now || (uuid_set !== undefined && _.contains(uuid_set, message.uuid));
        });
      });
    },

    toggleDrawer: (value?: boolean) => {
      next((d) => {
        d.drawer.is_open = value === undefined ? !d.drawer.is_open : value;
      });
    },

    getApiError: (err: any): [number | undefined, string | undefined, any | undefined] => {
      const [status, detail, headers] = getApiError(err);
      console.error("ApiError", status, detail, headers);

      if (status >= 500) {
        actions.addMessage(
          `Foutmelding op de server (${status}), kijk de invoer goed na en probeer het nogmaals. Wil het niet lukken, neem dan contact op met de technische dienst.`,
          "error",
          6,
        );
      } else if (status === 401) {
        actions.setAuthentication(null); // fire and forget.
        actions.addMessage("Je sessie is ongeldig, wil je opnieuw inloggen?", "error", 6);
      } else if (status === 418) {
        actions.addMessage(detail, "error", 6);
      } else {
        const message =
          {
            [DetailEnum.NOK]: "Er ging iets niet goed, NOK bereikt.",
            [DetailEnum.NI]: "Oeps, dit is nog niet gebouwd door ons, excuus!",
            [DetailEnum.EXPIRED]: "Je sessie is verlopen, wil je opnieuw inloggen?",
            [DetailEnum.CAPDASHREGISTERNODATAAFTERNOTHING]:
              "Je kunt voor deze week niet aangeven dat er geen data is om te registreren, " +
              "op deze week moet data worden geregistreerd.",
          }[detail] || detail;

        if (message) {
          actions.addMessage(message, "error", 6);
        }
      }

      return [status, detail, headers];
    },

    updateTitle: (value?: string) => {
      next((d) => (d.title = value));
    },

    setRightToggler: (mode: State["togglerMode"], togglerOpenHint: string, togglerCloseHint: string) => {
      next((d) => {
        d.togglerMode = mode;
        d.togglerOpenHint = togglerOpenHint;
        d.togglerCloseHint = togglerCloseHint;
      });
    },
    removeRightToggler: () => {
      next((d) => (d.togglerMode = undefined));
    },
  };

  return actions;
}

function useAppStore(): AppStore {
  return React.useContext(Context);
}

function getAppState(): State {
  const state = (window as any)["_AppStorage"] as State;

  if (state === undefined) {
    throw new Error("AppStorage is not stored in the window.");
  }

  return state;
}

const initial: State = {
  messages: [],
  overlay: { which: undefined, data: undefined },
  drawer: {
    is_open: false,
  },
  theme: {
    is_dark: false,
  },
  session: null,
  is_authorized: false,
  is_booted: false,
  data: null,

  togglerMode: undefined,
  togglerOpenHint: "",
  togglerCloseHint: "",
};

const AppStore: React.FC = ({ children }) => {
  const is_small = useMediaQuery("(max-width: 1280px)");
  const { state, next, getState } = useRxjsStore<State>({ ...initial, __window_storage: "_AppStorage" } as any);

  const actions = useActions(next, getState);

  useAsyncEffect(async () => {
    if (state.is_booted === false) {
      await actions.boot();
    } else if (!is_small) {
      actions.toggleDrawer(false);
    }
  }, [state.is_booted]);

  return <Context.Provider value={{ ...state, actions }}>{children}</Context.Provider>;
};

export default useAppStore;
export { AppStore, getAppState };
