import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Search } from "@material-ui/icons";
import React from "react";
import _ from "underscore";
import { CrmCompanyDto, CrmCompanyLocationDto, CrmPersonDto, CrmPersonSaveRequest } from "../../../api";
import countries from "../../../core/countries";
import DialogBusy from "../../../core/DialogBusy";
import { useHasPlugin } from "../../../core/HasPlugin";
import nationalities from "../../../core/nationalities";
import { callApi, getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsForm from "../../../core/useRxjsForm";
import useTranslations from "../../../core/useTranslations";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import DateFieldExt from "../../../form/DateFieldExt";
import SwitchFieldExt from "../../../form/SwitchFieldExt";
import TextFieldExt from "../../../form/TextFieldExt";
import UploadFieldExt from "../../../form/UploadFieldExt";
import { getCrmCompanyLocationShortCode } from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";

// region Types

type Props = {
  onClose: (success: boolean, record_id?: number) => void | Promise<void>;
  record_id?: number;
};

type Values = CrmPersonSaveRequest;

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

// endregion
// region Component

const CrmPersonDrawerSave: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const app = useAppStore();
  const classes = useStyles();
  const gc = useGenericStyles();
  const { busy, record, form, onClose, isResolvingZipcode, onResolveZipcode } = useComponent(props);
  const hasCapDash = useHasPlugin("capdash");
  const tag_category = React.useMemo(() => app.data.tag_category_set.find((v) => v.name_slug === "personen"), []);
  const tag_core_set = React.useMemo(
    () => app.data.tag_core_set.filter((v) => v.category_id === tag_category.id),
    [tag_category.id],
  );

  const tag_client = React.useMemo(() => tag_core_set.find((v) => v.name_slug === "client"), [tag_core_set]);
  // TODO: Make this an option of the tag.
  const tag_employee = React.useMemo(
    () => tag_core_set.find((v) => v.name_slug === "medewerker" || v.name_slug === "gebruiker"),
    [tag_core_set],
  );
  // TODO: Make this an option of the tag.
  const tag_company_contact = React.useMemo(
    () => tag_core_set.find((v) => v.name_slug === "ketenpartner-contact"),
    [tag_core_set],
  );
  // TODO: Make this an option of the tag.

  const is_client = React.useMemo(
    () => tag_client && form.values.tag_id_set.indexOf(tag_client.id) > -1,
    [tag_client, form.values.tag_id_set],
  );
  const is_employee = React.useMemo(
    () => tag_employee && form.values.tag_id_set.indexOf(tag_employee.id) > -1,
    [tag_employee, form.values.tag_id_set],
  );
  const is_company_contact = React.useMemo(
    () => tag_company_contact && form.values.tag_id_set.indexOf(tag_company_contact.id) > -1,
    [tag_company_contact, form.values.tag_id_set],
  );

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Persoon {record ? "aanpassen" : "toevoegen"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Opzet</Typography>
              <Typography variant="body2" color="textSecondary">
                Geef met de tags aan om wat voor type persoon het gaat. De tags zorgen voor extra functionaliteiten bij
                het invoeren van gegevens, inloggen, en/of het bijhouden van dossiers.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="tag_id_set"
                label="Tags"
                items={tag_core_set.map((v) => ({ value: v.id, label: `#${v.id}. ${v.name}` }))}
                multiple
              />
            </Grid>

            {is_employee && (
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="role_id_set"
                  label="Rollen"
                  items={app.data.crm_role_set.map((v) => ({ value: v.id, label: v.name }))}
                  multiple
                />
              </Grid>
            )}

            <Grid item md={12}>
              <Typography variant="subtitle1">Identificatie</Typography>
              <Typography variant="body2" color="textSecondary">
                De onderstaande gegevens worden gebruikt om de persoon te identificeren. We gebruiken alle velden om
                controles op dubbelingen uit te voeren.
              </Typography>
            </Grid>

            <Grid item md={12}>
              <TextFieldExt form={form} name="name" label="Naam" />
            </Grid>
            <Grid item md={12}>
              <TextFieldExt form={form} name="email" label="E-mailadres" />
            </Grid>
            <Grid item md={6}>
              <TextFieldExt form={form} name="mobile" label="Mobielnummer" />
            </Grid>
            <Grid item md={6}>
              <TextFieldExt form={form} name="phone" label="Telefoonnummer" />
            </Grid>

            {!hasCapDash && (is_employee || is_company_contact) && (
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="company_id_set"
                  label={`${_t("Ketenpartner")} organisatie(s)`}
                  multiple
                  items={app.data.crm_company_set.map((company) => ({ value: company.id, label: company.name }))}
                />
              </Grid>
            )}

            {hasCapDash && (
              <Grid item md={12}>
                <AutocompleteFieldExt
                  form={form}
                  name="location_id_set"
                  label={`${_t("Gekoppelde ketenpartner(s)")}`}
                  multiple
                  items={app.data.crm_company_set
                    .reduce(
                      (prev, curr) => prev.concat(curr.location_set.map((loc) => ({ ...loc, company: curr }))),
                      [] as (CrmCompanyLocationDto & { company: CrmCompanyDto })[],
                    )
                    .map((location) => {
                      const shortCode = getCrmCompanyLocationShortCode(location);
                      const label = `${location.company.name} - ${location.name} | ${shortCode}`;
                      return {
                        value: location.id,
                        label,
                      };
                    })}
                />
              </Grid>
            )}

            {(is_company_contact || hasCapDash) && (
              <Grid item md={12}>
                <TextFieldExt form={form} name="remarks" label="Opmerking(en)" multiline />
              </Grid>
            )}

            {is_client && (
              <>
                <Grid item md={6}>
                  <DateFieldExt form={form} name="birth" label="Geboortedatum" />
                </Grid>
                <Grid item md={6}>
                  <DateFieldExt form={form} name="started_on" label="Startdatum" />
                </Grid>

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="process_person_id_set"
                    label="Procesregisseur(s)"
                    multiple
                    items={app.data.crm_person_set
                      .filter((x) => x.role_id_set.includes(2))
                      .map((x) => ({ value: x.id, label: x.name }))}
                  />
                </Grid>

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="case_manager_person_id_set"
                    label="Casemanager(s)"
                    multiple
                    items={app.data.crm_person_set
                      .filter((x) => x.role_id_set.includes(2))
                      .map((x) => ({ value: x.id, label: x.name }))}
                  />
                </Grid>

                <Grid item md={12}>
                  <Typography variant="subtitle1">Adres</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Het primaire adres van {form.values.name || "deze persoon"}.
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <SwitchFieldExt
                    form={form}
                    name="has_no_fixed_address"
                    onLabel="Geen vaste verblijfplaats"
                    offLabel="Vaste verblijfplaats"
                  />
                </Grid>

                {form.values.has_no_fixed_address && (
                  <Grid item md={12}>
                    <TextFieldExt form={form} name="address_often_stays" label="Waar verblijft deze persoon vaak?" />
                  </Grid>
                )}

                <Grid item md={2}>
                  <AutocompleteFieldExt
                    form={form}
                    name="address_country"
                    label="Land"
                    items={countries.map((x) => ({ value: x, label: x }))}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextFieldExt form={form} name="address_zipcode" label="Postcode" />
                </Grid>
                <Grid item md={3}>
                  <TextFieldExt form={form} name="address_housenumber" label="Huisnummer" />
                </Grid>
                <Grid item md>
                  <TextFieldExt form={form} name="address_affix" label="Toevoeging" />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => onResolveZipcode()} disabled={isResolvingZipcode}>
                    {isResolvingZipcode ? <CircularProgress size={20} /> : <Search />}
                  </IconButton>
                </Grid>

                <Grid item md={6}>
                  <TextFieldExt form={form} name="address_street" label="Straat" />
                </Grid>
                <Grid item md={6}>
                  <TextFieldExt form={form} name="address_city" label="Stad" />
                </Grid>

                <Grid item md={6}>
                  <TextFieldExt
                    form={form}
                    name="address_municipality"
                    label="Woonplaats - verantwoordelijke gemeente"
                  />
                </Grid>
                <Grid item md={6}>
                  <AutocompleteFieldExt
                    form={form}
                    name="address_region"
                    label="Regio"
                    items={[
                      { value: "Alkmaar", label: "Alkmaar" },
                      { value: "West Friesland", label: "West Friesland" },
                      { value: "Kop van Noord Holland", label: "Kop van Noord Holland" },
                    ]}
                  />
                </Grid>

                <Grid item md={12}>
                  <Typography variant="subtitle1">Cliënt informatie</Typography>
                  <Typography variant="body2" color="textSecondary">
                    De onderstaande gegevens zijn niet verplicht om in te vullen en worden ook maar sporadisch gebruikt
                    in de applicatie.
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="notifying_company_id_set"
                    label="Aanmeldende organisatie"
                    multiple
                    items={app.data.crm_company_set.map((company) => ({ value: company.id, label: company.name }))}
                  />
                </Grid>

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="involved_company_id_set"
                    label={`${_t("Betrokken ketenpartner(s)")}`}
                    multiple
                    items={app.data.crm_company_set.map((company) => ({ value: company.id, label: company.name }))}
                  />
                </Grid>
                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="involved_person_id_set"
                    label="Betrokken contactpersoon(en)"
                    multiple
                    items={app.data.crm_person_set
                      .filter((x) => _.intersection(x.company_id_set, form.values.involved_company_id_set).length > 0)
                      .map((x) => ({ value: x.id, label: x.name }))}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextFieldExt form={form} name="csn" label="Burgerservicenummer" />
                </Grid>

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="end_of_detention"
                    label="Einde detentie"
                    items={[
                      { value: 0, label: "Einddatum niet van toepassing" },
                      { value: 1, label: "Einddatum onbekend" },
                      // { value: 2, label: "Indicatie met voor onbepaalde tijd" },
                      { value: 3, label: "Einddatum bekend" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>
                {form.values.end_of_detention === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt form={form} name="end_of_detention_at" label="Einddatum detentie" />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="wlz_indicated"
                    label="WLZ indicatie"
                    items={[
                      { value: 0, label: "Indicatie is niet aanwezig" },
                      { value: 1, label: "Indicatie met onbekende geldigheidsduur" },
                      { value: 2, label: "Indicatie voor onbepaalde tijd" },
                      { value: 3, label: "Indicatie met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.wlz_indicated !== 0 && (
                  <>
                    {form.values.wlz_indicated === 3 && (
                      <Grid item md={6}>
                        <DateFieldExt form={form} name="wlz_expires_at" label="Indicatie geldig tot" />
                      </Grid>
                    )}
                    <Grid item md={6}>
                      <UploadFieldExt form={form} name="wlz_resource_id" label="Indicatie document" />
                    </Grid>
                  </>
                )}

                <Grid item md={12}>
                  <SwitchFieldExt
                    form={form}
                    name="healthcare_present"
                    onLabel="Zorgverzekering is aanwezig"
                    offLabel="Zorgverzekering is niet aanwezig"
                  />
                </Grid>
                {form.values.healthcare_present && (
                  <Grid item md={12}>
                    <AutocompleteFieldExt
                      form={form}
                      name="healthcare"
                      label="Zorgverzekering"
                      items={[
                        { value: "AGIS - ACHMEA", label: "AGIS - ACHMEA" },
                        { value: "ANNO12", label: "ANNO12" },
                        { value: "ASR - ASR", label: "ASR - ASR" },
                        { value: "Aevitae - ASR", label: "Aevitae - ASR" },
                        { value: "Aevitae - COOPERATIE VGZ", label: "Aevitae - COOPERATIE VGZ" },
                        { value: "Aevitae - EUCARE", label: "Aevitae - EUCARE" },
                        { value: "Agis", label: "Agis" },
                        { value: "Anderzorg - MENZIS", label: "Anderzorg - MENZIS" },
                        { value: "Avéro Achmea - ACHMEA", label: "Avéro Achmea - ACHMEA" },
                        { value: "Azivo - MENZIS", label: "Azivo - MENZIS" },
                        { value: "CZ - CZ", label: "CZ - CZ" },
                        { value: "DSW - DSW-STAD HOLLAND", label: "DSW - DSW-STAD HOLLAND" },
                        {
                          value: "De Friesland Zorgverzekeraar - ACHMEA",
                          label: "De Friesland Zorgverzekeraar - ACHMEA",
                        },
                        { value: "De Friesland Zorgverzekeraar", label: "De Friesland Zorgverzekeraar" },
                        { value: "Eno Zorgverzekeraar - ENO", label: "Eno Zorgverzekeraar - ENO" },
                        { value: "FBTO - ACHMEA", label: "FBTO - ACHMEA" },
                        { value: "IZA - COOPERATIE VGZ", label: "IZA - COOPERATIE VGZ" },
                        { value: "Interpolis - ACHMEA", label: "Interpolis - ACHMEA" },
                        { value: "Menzis - MENZIS", label: "Menzis - MENZIS" },
                        { value: "Nationale-Nederlanden - CZ", label: "Nationale-Nederlanden - CZ" },
                        { value: "OHRA - CZ", label: "OHRA - CZ" },
                        { value: "OHRA - CZ", label: "OHRA - CZ" },
                        { value: "ONVZ  - ONVZ", label: "ONVZ  - ONVZ" },
                        { value: "OZF - ACHMEA", label: "OZF - ACHMEA" },
                        {
                          value: "Stad Holland Zorgverzekeraar. - DSW-STAD HOLLAND",
                          label: "Stad Holland Zorgverzekeraar. - DSW-STAD HOLLAND",
                        },
                        {
                          value: "Stichting Ziektekostenverzekering Krijgsmacht",
                          label: "Stichting Ziektekostenverzekering Krijgsmacht",
                        },
                        { value: "UMC - COOPERATIE VGZ", label: "UMC - COOPERATIE VGZ" },
                        { value: "Univé Zorg - COOPERATIE VGZ", label: "Univé Zorg - COOPERATIE VGZ" },
                        { value: "VGZ - COOPERATIE VGZ", label: "VGZ - COOPERATIE VGZ" },
                        { value: "VGZ Cares - COOPERATIE VGZ", label: "VGZ Cares - COOPERATIE VGZ" },
                        { value: "VGZ voor de zorg  - COOPERATIE VGZ", label: "VGZ voor de zorg  - COOPERATIE VGZ" },
                        { value: "ZEKUR - COOPERATIE VGZ", label: "ZEKUR - COOPERATIE VGZ" },
                        { value: "Zilveren Kruis  - ACHMEA", label: "Zilveren Kruis  - ACHMEA" },
                        {
                          value: "Zorg en Zekerheid - ZORG EN ZEKERHEID",
                          label: "Zorg en Zekerheid - ZORG EN ZEKERHEID",
                        },
                        { value: "iptiQ Life S.A.", label: "iptiQ Life S.A." },
                      ]}
                    />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="care_authorization_present"
                    label="Zorgmachtiging"
                    items={[
                      { value: 0, label: "Machtiging is niet aanwezig" },
                      { value: 1, label: "Machtiging met onbekende geldigheidsduur" },
                      { value: 2, label: "Machtiging voor onbepaalde tijd" },
                      { value: 3, label: "Machtiging met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.care_authorization_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt form={form} name="care_authorization_expires_at" label="Indicatie geldig tot" />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="ifzo_indication_present"
                    label="IFZO-indicatie"
                    items={[
                      { value: 0, label: "Indicatie is niet aanwezig" },
                      { value: 1, label: "Indicatie met onbekende geldigheidsduur" },
                      { value: 2, label: "Indicatie voor onbepaalde tijd" },
                      { value: 3, label: "Indicatie met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.ifzo_indication_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt
                      form={form}
                      name="ifzo_indication_expires_at"
                      label={
                        form.values.ifzo_indication_expires_at === null
                          ? "Indicatie geldig voor onbepaalde tijd"
                          : "Indicatie geldig tot"
                      }
                    />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="wmo_indication_present"
                    label="WMO-indicatie"
                    items={[
                      { value: 0, label: "Indicatie is niet aanwezig" },
                      { value: 1, label: "Indicatie met onbekende geldigheidsduur" },
                      { value: 2, label: "Indicatie voor onbepaalde tijd" },
                      { value: 3, label: "Indicatie met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.wmo_indication_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt form={form} name="wmo_indication_expires_at" label="Indicatie geldig tot" />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="bw_indication_present"
                    label="BW-indicatie"
                    items={[
                      { value: 0, label: "Indicatie is niet aanwezig" },
                      { value: 1, label: "Indicatie met onbekende geldigheidsduur" },
                      { value: 2, label: "Indicatie voor onbepaalde tijd" },
                      { value: 3, label: "Indicatie met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.bw_indication_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt form={form} name="bw_indication_expires_at" label="Indicatie geldig tot" />
                  </Grid>
                )}

                <Grid item md={12}>
                  <SwitchFieldExt
                    form={form}
                    name="curator_present"
                    onLabel="Curator, mentor of bewindvoerder is aanwezig"
                    offLabel="Curator, mentor of bewindvoerder is niet aanwezig"
                  />
                </Grid>
                {form.values.curator_present && (
                  <>
                    <Grid item md={6}>
                      <TextFieldExt form={form} name="curator_name" label="Naam" />
                    </Grid>
                  </>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="probation_supervision_present"
                    label="Reclasseringstoezicht"
                    items={[
                      { value: 0, label: "Reclasseringstoezicht is niet aanwezig" },
                      { value: 1, label: "Reclasseringstoezicht met onbekende geldigheidsduur" },
                      { value: 2, label: "Reclasseringstoezicht voor onbepaalde tijd" },
                      { value: 3, label: "Reclasseringstoezicht met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.probation_supervision_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt
                      form={form}
                      name="probation_supervision_expires_at"
                      label={
                        form.values.probation_supervision_expires_at === null
                          ? "Indicatie geldig voor onbepaalde tijd"
                          : "Indicatie geldig tot"
                      }
                    />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="isd_measure_present"
                    label="ISD-maatregel"
                    items={[
                      { value: 0, label: "ISD-maatregel is niet aanwezig" },
                      { value: 1, label: "ISD-maatregel met onbekende geldigheidsduur" },
                      { value: 2, label: "ISD-maatregel voor onbepaalde tijd" },
                      { value: 3, label: "ISD-maatregel met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.isd_measure_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt form={form} name="isd_measure_expires_at" label="Indicatie geldig tot" />
                  </Grid>
                )}

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="tbs_measure_present"
                    label="TBS-maatregel"
                    items={[
                      { value: 0, label: "TBS-maatregel is niet aanwezig" },
                      { value: 1, label: "TBS-maatregel met onbekende geldigheidsduur" },
                      { value: 2, label: "TBS-maatregel voor onbepaalde tijd" },
                      { value: 3, label: "TBS-maatregel met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.tbs_measure_present === 3 && (
                  <Grid item md={6}>
                    <DateFieldExt form={form} name="tbs_measure_expires_at" label="Indicatie geldig tot" />
                  </Grid>
                )}

                <Grid item md={12}>
                  <SwitchFieldExt
                    form={form}
                    name="nationality_has_secondary"
                    onLabel="Dubbele nationaliteit"
                    offLabel="Geen dubbele nationaliteit"
                  />
                </Grid>
                <Grid item md={form.values.nationality_has_secondary ? 6 : 12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="nationality_primary"
                    label="Nationaliteit"
                    items={nationalities.map((x) => ({ value: x, label: x }))}
                  />
                </Grid>
                {form.values.nationality_has_secondary && (
                  <Grid item md={6}>
                    <AutocompleteFieldExt
                      form={form}
                      name="nationality_secondary"
                      label="Tweede nationaliteit"
                      items={nationalities.map((x) => ({ value: x, label: x }))}
                    />
                  </Grid>
                )}

                <Grid item md={12}>
                  <UploadFieldExt form={form} name="csn_resource_id" label="ID document" />
                </Grid>

                <Grid item md={12}>
                  <AutocompleteFieldExt
                    form={form}
                    name="has_residence_permit"
                    label="Verblijfsdocument"
                    items={[
                      { value: 0, label: "Verblijfsdocument is niet aanwezig" },
                      { value: 1, label: "Verblijfsdocument met onbekende geldigheidsduur" },
                      { value: 2, label: "Verblijfsdocument voor onbepaalde tijd" },
                      { value: 3, label: "Verblijfsdocument met geldigheidsdatum" },
                    ]}
                    autocompleteProps={{
                      disableClearable: true,
                    }}
                  />
                </Grid>

                {form.values.has_residence_permit !== 0 && (
                  <>
                    {form.values.has_residence_permit === 3 && (
                      <Grid item md={6}>
                        <DateFieldExt
                          form={form}
                          name="residence_permit_expires_at"
                          label="Verblijfsdocument geldig tot"
                        />
                      </Grid>
                    )}
                    <Grid item md={6}>
                      <UploadFieldExt
                        form={form}
                        name="residence_permit_resource_id"
                        label="Verblijfsdocument bestand"
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={12}>
                  <SwitchFieldExt
                    form={form}
                    name="has_deceased"
                    onLabel="De betrokkene is overleden"
                    offLabel="De betrokkene is niet overleden"
                  />
                </Grid>
                {form.values.has_deceased && (
                  <Grid item md={12}>
                    <DateFieldExt form={form} name="deceased_at" label="Overleden op" />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose(false)}>
            Sluiten
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
            Opslaan
          </Button>
        </DialogActions>
      </form>

      <DialogBusy busy={busy} />
    </Drawer>
  );
};

// endregion
// region Store

function useComponent(props: Props) {
  const app = useAppStore();
  const { record_id } = props;
  const [closing, setClosing] = React.useState(false);
  const [isResolvingZipcode, setIsResolvingZipcode] = React.useState(false);

  // Load the record from the server.
  const { record } = useRxjsFetch<CrmPersonDto>(async () => {
    return record_id ? (await getApi().crmPersonOnePost({ id: record_id })).data : null;
  }, [record_id]);

  const form = useRxjsForm<Values>({
    initial: {
      id: undefined,
      name: "",
      email: "",
      tag_id_set: [],
      role_id_set: [],
      company_id_set: [],
      location_id_set: [],
      notifying_company_id_set: [],
      involved_company_id_set: [],
      involved_person_id_set: [],
      process_person_id_set: [],
      case_manager_person_id_set: [],
      mobile: "",
      phone: "",
      birth: "",
      started_on: "",
      remarks: "",
      has_no_fixed_address: false,
      address_country: "nl",
      address_zipcode: "",
      address_housenumber: "",
      address_affix: "",
      address_street: "",
      address_city: "",
      address_municipality: "",
      address_region: "",
      address_often_stays: "",
      csn: "",
      healthcare_present: false,
      healthcare: "",
      care_authorization_present: 0,
      care_authorization_expires_at: "",
      ifzo_indication_present: 0,
      ifzo_indication_expires_at: "",
      wmo_indication_present: 0,
      wmo_indication_expires_at: "",
      bw_indication_present: 0,
      bw_indication_expires_at: "",
      curator_present: false,
      curator_name: "",
      probation_supervision_present: 0,
      probation_supervision_expires_at: "",
      isd_measure_present: 0,
      isd_measure_expires_at: "",
      tbs_measure_present: 0,
      tbs_measure_expires_at: "",
      current_detention_present: false,
      current_detention_expires_at: "",
      end_of_detention: 0,
      end_of_detention_at: "",
      wlz_indicated: 0,
      wlz_expires_at: "",
      wlz_resource_id: null,
      nationality_has_secondary: false,
      nationality_primary: "",
      nationality_secondary: "",
      csn_resource_id: null,
      has_residence_permit: 0,
      residence_permit_expires_at: "",
      residence_permit_resource_id: null,
      has_deceased: false,
      deceased_at: "",
    },
    submit: async (values) => {
      let data = _.clone(values);

      [
        "email",
        "birth",
        "started_on",
        "wlz_expires_at",
        "end_of_detention_at",
        "residence_permit_expires_at",
        "deceased_at",
        "care_authorization_expires_at",
        "ifzo_indication_expires_at",
        "wmo_indication_expires_at",
        "bw_indication_expires_at",
        "probation_supervision_expires_at",
        "isd_measure_expires_at",
        "tbs_measure_expires_at",
        "current_detention_expires_at",
      ].map((field) => {
        if (data[field] === "") data[field] = null;
      });

      const res = await callApi(
        app,
        async (api) =>
          (
            await api.crmPersonSavePost(data)
          ).data,
      );
      await onClose(true, res.id);

    },
  });

  const onClose = React.useCallback(async (success: boolean, record_id?: number) => {
    setClosing(true);
    await props.onClose(success, record_id);
    setClosing(false);
  }, []);

  const onResolveZipcode = React.useCallback(async () => {
    if (isResolvingZipcode) return;

    const res = await callApi(
      app,
      async (api) =>
        (
          await api.apiWebResolveZipcodePost({
            zipcode: form.values.address_zipcode,
            housenumber: form.values.address_housenumber,
          })
        ).data,
    );

    if (res) {
      form.actions.setValues({
        address_zipcode: res.zipcode,
        address_housenumber: res.housenumber,
        address_street: res.street,
        address_city: res.city,
        address_country: res.country,
      });
      app.actions.addMessage("De gegevens zijn automatisch overgenomen.", "success");
    } else {
      app.actions.addMessage("De postcode kon niet gevonden worden.", "error");
    }
  }, [isResolvingZipcode, form.values.address_zipcode, form.values.address_housenumber]);

  // As soon as the record is loaded, inject the information into the form.
  React.useEffect(() => {
    form.actions.setValues(record ? _.pick(record, Object.keys(form.initial) as any) : form.initial);
  }, [record?.id]);

  const busy = React.useMemo(() => closing || form.submitting, [closing, form.submitting]);

  return { busy, record, form, onClose, isResolvingZipcode, onResolveZipcode };
}

// endregion

export default CrmPersonDrawerSave;
