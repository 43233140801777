import { Chip, Link } from "@material-ui/core";
import React, { useMemo } from "react";
import { CrmCompanyDto, CrmCompanyLocationDto, CrmPersonDto } from "../../../api";
import CrmRoleChip from "../../../core/CrmRoleChip";
import DataTableExt from "../../../core/DataTableExt";
import FormatDate from "../../../core/FormatDate";
import FormatDateDistance from "../../../core/FormatDateDistance";
import FormatHidden from "../../../core/FormatHidden";
import GenericResourceLink from "../../../core/GenericResourceLink";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useNavigate from "../../../core/useNavigate";
import useTranslations from "../../../core/useTranslations";
import { getCrmCompanyLocationShortCode } from "../../CrmCompany/Ext/CrmCompanyLocationShortCode";

type Props = {
  person: CrmPersonDto;
  onClose?: () => void;
};

function formatDurationChoise(choice: number, validUntil?: string) {
  switch (choice) {
    case 1:
      return "Duur onbekend";
    case 2:
      return "Duur onbepaald";
    case 3:
      return <FormatDate value={validUntil} fmt="PP" emptyLabel="Niet ingevoerd." />;
    case 0:
      return null;
  }
}

const CrmPersonExtDataTable: React.FC<Props> = (props) => {
  const _t = useTranslations();
  const { person } = props;
  const gc = useGenericStyles();

  const data: [string, React.ReactNode][] = React.useMemo(() => {
    return [
      [
        "",
        person.is_archived && (
          <>
            Deze persoon is <strong>gearchiveerd</strong>.
          </>
        ),
      ],
      ["Nr", person.id],
      ["Naam", person.name],
      ["E-mailadres", person.email],
      ["Mobielnummer", person.mobile],
      ["Telefoonnummer", person.phone],
      [
        "Geboortedatum",
        person.birth && (
          <>
            <FormatDate value={person.birth} fmt="PP" />{" "}
            <small>
              (<FormatDateDistance value={person.birth} unit="year" />)
            </small>
          </>
        ),
      ],
      [
        "Startdatum",
        person.started_on && (
          <>
            <FormatDate value={person.started_on} fmt="PP" />{" "}
            <small>
              (<FormatDateDistance value={person.started_on} unit="day" />)
            </small>
          </>
        ),
      ],
      [
        person.process_person_id_set.length === 1 ? "Procesregisseur" : "Procesregisseurs",
        person.process_person_id_set.length > 0 && (
          <>
            {person.process_person_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmPerson person_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        person.case_manager_person_id_set.length === 1 ? "Casemanager" : "Casemanagers",
        person.case_manager_person_id_set.length > 0 && (
          <>
            {person.case_manager_person_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmPerson person_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      ["Verblijfplaats", person.has_no_fixed_address && <strong>Geen vaste verblijfplaats.</strong>],
      ["Verblijft vaak", person.has_no_fixed_address && person.address_often_stays],
      [
        "Adres",
        person.address_zipcode && (
          <>
            {person.address_street} {person.address_housenumber}
            {person.address_affix && ` ${person.address_affix}`},<br />
            {person.address_zipcode} {person.address_city},<br />
            {person.address_country}
          </>
        ),
      ],
      [
        _t("Rol(len)"),
        person.role_id_set.length > 0 && (
          <>
            {person.role_id_set.map((x) => (
              <CrmRoleChip key={x} crm_role_id={x} />
            ))}
          </>
        ),
      ],
      [
        _t("Gekoppelde ketenpartner(s)"),
        person.location_id_set.length > 0 && (
          <>
            {person.location_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmCompanyLocation location_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        _t("Ketenpartner organisatie(s)"),
        person.company_id_set.length > 0 && (
          <>
            {person.company_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmCompany company_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        "Aanmeldende organisatie(s)",
        person.notifying_company_id_set.length > 0 && (
          <>
            {person.notifying_company_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmCompany company_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        person.involved_company_id_set.length === 1 ? _t("Betrokken ketenpartner") : _t("Betrokken ketenpartners"),
        person.involved_company_id_set.length > 0 && (
          <>
            {person.involved_company_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmCompany company_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      [
        person.involved_person_id_set.length === 1 ? "Betrokken contactpersoon" : "Betrokken contactpersonen",
        person.involved_person_id_set.length > 0 && (
          <>
            {person.involved_person_id_set.map((x) => (
              <Chip key={x} label={<FormatCrmPerson person_id={x} />} className={gc.Chip_Inline} />
            ))}
          </>
        ),
      ],
      ["Burgerservicenummer", person.csn && <FormatHidden value={person.csn} />],
      ["Zorgverzekering", person.healthcare_present && person.healthcare],
      ["Zorgmachtiging", formatDurationChoise(person.care_authorization_present, person.care_authorization_expires_at)],
      ["IFZO-indicatie", formatDurationChoise(person.ifzo_indication_present, person.ifzo_indication_expires_at)],
      ["WMO-indicatie", formatDurationChoise(person.wmo_indication_present, person.wmo_indication_expires_at)],
      ["BW-indicatie", formatDurationChoise(person.bw_indication_present, person.bw_indication_expires_at)],
      ["Curator, mentor of bewindvoerder", person.curator_present && person.curator_name],
      [
        "Reclasseringstoezicht",
        formatDurationChoise(person.probation_supervision_present, person.probation_supervision_expires_at),
      ],
      ["ISD-maatregel", formatDurationChoise(person.isd_measure_present, person.isd_measure_expires_at)],
      ["TBS-maatregel", formatDurationChoise(person.tbs_measure_present, person.tbs_measure_expires_at)],
      [
        "Actuele maatregel",
        person.current_detention_present && (
          <FormatDate value={person.current_detention_expires_at} fmt="PP" emptyLabel="Verloop niet ingesteld." />
        ),
      ],
      [
        "Einde detentie",
        person.end_of_detention && (
          <FormatDate value={person.end_of_detention_at} fmt="PP" emptyLabel="Geldig voor onbepaalde tijd." />
        ),
      ],
      [
        "WLZ indicatie",
        person.wlz_indicated !== 0 && (
          <>
            {formatDurationChoise(person.wlz_indicated, person.wlz_expires_at)}
            {", "}
            {person.wlz_resource_id ? (
              <GenericResourceLink resource={person.wlz_resource} />
            ) : (
              "geen document geüpload."
            )}
          </>
        ),
      ],
      ["Nationaliteit", person.nationality_primary],
      ["Tweede nationaliteit", person.nationality_has_secondary && person.nationality_secondary],
      ["ID Document", person.csn_resource && <GenericResourceLink resource={person.csn_resource} />],
      [
        "Verblijfsdocument",
        person.has_residence_permit !== 0 && (
          <>
            {formatDurationChoise(person.has_residence_permit, person.residence_permit_expires_at)}
            {", "}
            {person.wlz_resource_id ? (
              <GenericResourceLink resource={person.residence_permit_resource} />
            ) : (
              "geen document geüpload."
            )}
          </>
        ),
      ],
    ];
  }, [person]);

  return <DataTableExt data={data} />;
};

// TODO: Move to component file!
export const FormatCrmCompany: React.FC<{ company_id: number }> = ({ company_id }) => {
  const app = useAppStore();
  const company = useMemo(() => app.data.crm_company_set.find((x) => x.id === company_id), [company_id]);
  const navigate = useNavigate();

  if (!company) return null;

  return <Link onClick={() => navigate(`/crm/company/view/${company.id}`)}>{company.name}</Link>;
};

// TODO: Move to component file!
export const FormatCrmCompanyLocation: React.FC<{ location_id: number }> = ({ location_id }) => {
  const app = useAppStore();
  const company_slug = app.session.company.slug;
  const locations = useMemo(
    () =>
      app.data.crm_company_set.reduce(
        (prev, curr) => prev.concat(curr.location_set.map((loc) => ({ ...loc, company: curr }))),
        [] as (CrmCompanyLocationDto & { company: CrmCompanyDto })[],
      ),
    [],
  );
  const location = useMemo(() => locations.find((x) => x.id === location_id), [location_id]);
  const navigate = useNavigate();

  if (!location) return null;

  const shortCode = getCrmCompanyLocationShortCode(location);
  const label =
    company_slug === "bb"
      ? `${location.company.name} - ${shortCode}`
      : `${location.company.name} - ${location.name} | ${shortCode}`;

  return <Link onClick={() => navigate(`/crm/company/view/${location.company.id}`)}>{label}</Link>;
};

// TODO: Move to component file!
export const FormatCrmPerson: React.FC<{ person_id: number; hideLink?: boolean }> = ({ person_id, hideLink }) => {
  const app = useAppStore();
  const person = useMemo(() => app.data.crm_person_set.find((x) => x.id === person_id), [person_id]);
  const navigate = useNavigate();

  if (!person) return null;

  if (hideLink) {
    return <span data-id={person.id}>{person.name}</span>;
  }

  return <Link onClick={() => navigate(`/crm/person/view/${person.id}`)}>{person.name}</Link>;
};

export default CrmPersonExtDataTable;
