import { IconButton, TableCell, Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { TableRowDto } from "../../../api";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import useNavigate from "../../../core/useNavigate";
import useTableStyles from "../../../core/useTableStyles";
import useMatrixCoreTable from "./useMatrixCoreTable";

const MatrixCoreTableRowButtonsStart: React.FC<{ row: TableRowDto }> = ({ row }) => {
  const classes = useTableStyles();
  const navigate = useNavigate();

  if (!row.record_id) return null;

  return (
    <TableCell className={classes.TableCellShrink}>
      <Tooltip title="Openen.">
        <IconButton onClick={() => navigate(`/matrix/core/view/${row.record_id}`)}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export { MatrixCoreTableRowButtonsStart };
