import React from "react";
import _ from "underscore";
import TableExt from "../../../core/TableExt";
import useTranslations from "../../../core/useTranslations";
import { CrmCompanyTableRowButtonsEnd, CrmCompanyTableRowButtonsStart } from "./CrmCompanyTableButtons";
import CrmCompanyTableDrawers from "./CrmCompanyTableDrawers";
import CrmCompanyTableToolbar from "./CrmCompanyTableToolbar";
import useCrmCompanyTable, { CrmCompanyTableProvider } from "./useCrmCompanyTable";

type Props = {};

const CrmCompanyTable: React.FC<Props> = (props) => {
  return (
    <CrmCompanyTableProvider>
      <Render />
    </CrmCompanyTableProvider>
  );
};

const Render: React.FC = () => {
  const table = useCrmCompanyTable();
  const _t = useTranslations();

  const headers = React.useMemo(
    () => table.req.header_set.map((x) => table.header_set.find((h) => h.name === x)).filter((x) => !!x),
    [table.req.header_set],
  );

  const initial = React.useMemo(() => _.pick(table.req, ["search"]), [table.booted, table.req]);

  return (
    <>
      <TableExt
        loading={table.loading}
        title={_t("Ketenpartners")}
        header_set={headers}
        row_set={table.res.row_set}
        pagination={table.res.pagination}
        setPagination={(x) => table.actions.setPagination(x)}
        toolbar={
          <CrmCompanyTableToolbar
            initial={initial}
            onChange={(props) => {
              table.actions.setRequest(props);
            }}
          />
        }
        RowButtonsStart={CrmCompanyTableRowButtonsStart}
        RowButtonsEnd={CrmCompanyTableRowButtonsEnd}
      />
      <CrmCompanyTableDrawers />
    </>
  );
};

export default CrmCompanyTable;
