import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  addMonths,
  addQuarters,
  format,
  parseISO,
  setDayOfYear,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
} from "date-fns";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { CdDataChartPeriodEnum, TsChartDataDto, TsChartDataPeriodMethodEnum } from "../../../api";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsForm from "../../../core/useRxjsForm";
import AutocompleteFieldExt from "../../../form/AutocompleteFieldExt";
import DateFieldExt from "../../../form/DateFieldExt";

type Props = {
  initial: TsChartDataDto;
  onClose: (data?: TsChartDataDto) => void;
};

const TagScoreDashboardDrawerFilters: React.FC<Props> = (props) => {
  const { dates, form, onClose } = useComponent(props);
  const gc = useGenericStyles();
  const app = useAppStore();

  const [addressMunicipalitySet] = useApiResult(async () => {
    return (await getApi().crmPersonAddressMunicipalityPickPost({})).data;
  }, []);

  return (
    <Drawer open anchor="right">
      <form onSubmit={form.actions.submit} noValidate className={gc.DrawerContainer}>
        <DialogTitle>Dashboard instellen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={gc.Grid_Padded}>
            <Grid item md={12}>
              <Typography variant="subtitle1">Weergeven</Typography>
              <Typography variant="body2" color="textSecondary">
                Geef hier aan welke dashboards je graag wilt weergeven.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <AutocompleteFieldExt
                form={form}
                name="chart_set"
                label="Statistieken"
                multiple
                items={[
                  // { value: "Totals", label: "Totalen" },
                  { value: "Flows", label: "In- & Uitstromen" },
                  { value: "Matrixes", label: "Ondersteuningsgebieden & betrokken ketenpartners" },
                ]}
              />
            </Grid>
            <Grid item md={6}>
              <AutocompleteFieldExt
                form={form}
                name="period_method"
                label="Periode"
                items={[
                  {
                    value: "PreviousQuarter",
                    label: `Afgelopen kwartaal (${format(dates.previousQuarter, "yyyy - Q")})`,
                  },
                  { value: "ThisQuarter", label: `Dit kwartaal (${format(dates.currentQuarter, "yyyy - Q")})` },
                  {
                    value: "Previous12Months",
                    label: `Afgelopen 12 maanden (vanaf ${format(dates.previousYear, "PP")})`,
                  },
                  {
                    value: "PreviousYear",
                    label: `Vorig jaar (vanaf ${format(dates.previousYear, "PP")} 1-1 t/m 31-21)`,
                  },
                  { value: "ThisYear", label: `Dit jaar (vanaf ${format(dates.currentYear, "PP")})` },
                  { value: "Manual", label: "Zelf invoeren" },
                ]}
              />
            </Grid>
            <Grid item md={3}>
              {form.values.period_method === "Manual" ? (
                <DateFieldExt form={form} name="period_start" label="Vanaf datum" />
              ) : (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Vanaf datum"
                  value={format(
                    form.values.period_start ? parseISO(form.values.period_start) : new Date(),
                    "dd/MM/yyyy",
                  )}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Grid>
            <Grid item md={3}>
              {form.values.period_method === "Manual" ? (
                <DateFieldExt form={form} name="period_end" label="Tot en met datum" />
              ) : (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Tot en met datum"
                  value={format(form.values.period_end ? parseISO(form.values.period_end) : new Date(), "dd/MM/yyyy")}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Grid>

            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="region_set"
                label="Regio's"
                multiple
                items={[
                  { value: "Alkmaar", label: "Alkmaar" },
                  { value: "West Friesland", label: "West Friesland" },
                  { value: "Kop van Noord Holland", label: "Kop van Noord Holland" },
                ]}
              />
            </Grid>
            <Grid item md={5}>
              <AutocompleteFieldExt
                form={form}
                name="dossier_tag_set"
                label="Tags"
                multiple
                items={app.data.tag_core_set
                  .filter((x) => _.contains([3, 4], x.category_id))
                  .map((x) => ({ value: x.id, label: x.name }))}
              />
            </Grid>
            <Grid item md={10}>
              <AutocompleteFieldExt
                form={form}
                name="address_municipality_set"
                label="Verantwoordelijke gemeente"
                multiple
                items={addressMunicipalitySet.res?.item_set.map((x) => ({ value: x, label: x })) || []}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Annuleren
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Instellen
          </Button>
        </DialogActions>
      </form>
    </Drawer>
  );
};

function useComponent(props: Props) {
  const dates = useMemo(
    () => ({
      previousMonth: startOfMonth(subMonths(new Date(), 1)),
      previousQuarter: startOfQuarter(subQuarters(new Date(), 1)),
      currentQuarter: startOfQuarter(new Date()),
      previousYear: subMonths(new Date(), 12),
      currentYear: setDayOfYear(new Date(), 1),
    }),
    [],
  );

  const form = useRxjsForm<TsChartDataDto>({
    initial: props.initial,
    submit: async (values) => {
      onClose(values);
    },
  });

  const onClose = React.useCallback((data?: TsChartDataDto) => {
    props.onClose(data);
  }, []);

  useEffect(() => {
    switch (form.values.period_method) {
      case TsChartDataPeriodMethodEnum.Previous12Months:
        form.actions.setValues({
          period_start: format(dates.previousYear, "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case TsChartDataPeriodMethodEnum.PreviousQuarter:
        form.actions.setValues({
          period_start: format(dates.previousQuarter, "yyyy-MM-dd"),
          period_end: format(subDays(addQuarters(dates.previousQuarter, 1), 1), "yyyy-MM-dd"),
        });
        break;
      case TsChartDataPeriodMethodEnum.PreviousYear:
        form.actions.setValues({
          period_start: format(dates.previousYear, "yyyy-01-01"),
          period_end: format(dates.previousYear, "yyyy-12-31"),
        });
        break;
      case TsChartDataPeriodMethodEnum.ThisYear:
        form.actions.setValues({
          period_start: format(dates.currentYear, "yyyy-MM-dd"),
          period_end: format(new Date(), "yyyy-MM-dd"),
        });
        break;
      case TsChartDataPeriodMethodEnum.ThisQuarter:
        form.actions.setValues({
          period_start: format(dates.currentQuarter, "yyyy-MM-dd"),
          period_end: format(subDays(addQuarters(dates.currentQuarter, 1), 1), "yyyy-MM-dd"),
        });
        break;
    }
  }, [form.values.period_method, props.initial.period_method]);

  return { dates, form, onClose };
}

export default TagScoreDashboardDrawerFilters;
