import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete, Edit, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useMemo } from "react";
import _ from "underscore";
import { CareDossierOneResponse, CrmNoteDto, CrmNoteManyResponse, CrmNoteModeEnum } from "../../../api";
import FormatDate from "../../../core/FormatDate";
import GenericResourceLink from "../../../core/GenericResourceLink";
import getTranslation from "../../../core/getTranslation";
import IconButtonDropExt from "../../../core/IconButtonDropExt";
import QuillContent from "../../../core/QuillContent";
import { callApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useAsyncEffect from "../../../core/useAsyncEffect";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsStore from "../../../core/useRxjsStore";
import useTranslations from "../../../core/useTranslations";
import CrmNoteDialogArchive from "../../CrmNote/Drawers/CrmNoteDialogArchive";
import CrmNoteDrawerSave from "../../CrmNote/Drawers/CrmNoteDrawerSave";
import { CareDossierViewActions } from "../CareDossierView";

export type CareDossierViewExtNotesProps = {
  actions: CareDossierViewActions;
  dossier: CareDossierOneResponse;
};

export type CareDossierViewExtNotesState = {
  expanded: boolean;
  notes?: CrmNoteManyResponse;
  dialog?:
    | {
        name: "note";
        crm_note_id?: number;
      }
    | {
        name: "archive";
        crm_note_id?: number;
      };
  record_set: CrmNoteDto[];
  filter_mode: "all" | CrmNoteModeEnum;
  filter_closed: "all" | "open" | "closed";

  counts: {
    open: number;
    closed: number;
    all: number;
    mode_dossier: number;
    mode_matrix_row: number;
  };
};

const CareDossierViewExtNotes: React.FC<CareDossierViewExtNotesProps> = (props) => {
  const _t = useTranslations();
  const { state, actions } = useCareDossierViewExtNotesComponent(props);
  const gc = useGenericStyles();
  const app = useAppStore();

  return (
    <Accordion expanded={state.expanded} onChange={() => actions.toggleExpanded()}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
          Notities en Afspraken
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Chip
            label={`Alles (${state.counts.all})`}
            className={gc.Chip_Inline}
            onClick={() => actions.setFilterClosed("all")}
            variant={state.filter_closed === "all" ? "default" : "outlined"}
          />
          <Chip
            label={`Openstaand (${state.counts.open})`}
            className={gc.Chip_Inline}
            onClick={() => actions.setFilterClosed("open")}
            variant={state.filter_closed === "open" ? "default" : "outlined"}
          />
          <Chip
            label={`Afgerond (${state.counts.closed})`}
            className={gc.Chip_Inline}
            onClick={() => actions.setFilterClosed("closed")}
            variant={state.filter_closed === "closed" ? "default" : "outlined"}
          />
        </Box>
        <Box ml={3}>
          <Chip
            label={`Alles (${state.counts.all})`}
            className={gc.Chip_Inline}
            onClick={() => actions.setFilterMode("all")}
            variant={state.filter_mode === "all" ? "default" : "outlined"}
          />
          <Chip
            label={`Notities (${state.counts.mode_dossier})`}
            className={gc.Chip_Inline}
            onClick={() => actions.setFilterMode(CrmNoteModeEnum.DOSSIER)}
            variant={state.filter_mode === CrmNoteModeEnum.DOSSIER ? "default" : "outlined"}
          />
          <Chip
            label={`Afspraken (${state.counts.mode_matrix_row})`}
            className={gc.Chip_Inline}
            onClick={() => actions.setFilterMode(CrmNoteModeEnum.MATRIXROW)}
            variant={state.filter_mode === CrmNoteModeEnum.MATRIXROW ? "default" : "outlined"}
          />
        </Box>
      </AccordionDetails>

      <AccordionDetails>
        <Button variant="contained" color="primary" onClick={() => actions.setDialog({ name: "note" })}>
          Toevoegen
        </Button>
      </AccordionDetails>
      <AccordionDetails>
        <Table>
          {state.notes && (
            <TableBody>
              {state.record_set.map((note) => {
                const matrix_core = app.data.matrix_core_set.find((x) => x.id === note.matrix_core_id);
                const matrix_row = matrix_core?.row_set.find((x) => x.id === note.matrix_row_id);

                return (
                  <TableRow key={note.id}>
                    <TableCell className={gc.TableCell_Shrink} style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                              Auteur
                            </TableCell>
                            <TableCell style={{ borderBottom: 0 }}>{note.created_by.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                              Type
                            </TableCell>
                            <TableCell style={{ borderBottom: 0 }}>
                              {note.mode === CrmNoteModeEnum.DOSSIER && "Notitie"}
                              {note.mode === CrmNoteModeEnum.MATRIXROW && "Afspraak"}
                              {note.mode === CrmNoteModeEnum.TARGET && "Doel"}
                            </TableCell>
                          </TableRow>
                          {note.connected_matrix_row_id && (
                            <TableRow>
                              <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                                Risicofactor
                              </TableCell>
                              <TableCell style={{ borderBottom: 0 }}>{matrix_row?.name}</TableCell>
                            </TableRow>
                          )}
                          {note.crm_company_id_set && (
                            <>
                              <TableRow>
                                <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink} colSpan={2}>
                                  {_t("Ketenpartner(s)")}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: 0 }} colSpan={2}>
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {note.crm_company_id_set.map((id) => (
                                      <Chip
                                        key={id}
                                        label={app.data.crm_company_set.find((x) => x.id === id)?.name || "Onbekend"}
                                        className={gc.Chip_Inline}
                                      />
                                    ))}
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                          {note.crm_person_id_set && (
                            <TableRow>
                              <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                                Actiehouder(s)
                              </TableCell>
                              <TableCell style={{ borderBottom: 0 }}>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {note.crm_person_id_set.map((id) => (
                                    <Chip
                                      key={id}
                                      label={app.data.crm_person_set.find((x) => x.id === id).name}
                                      className={gc.Chip_Inline}
                                    />
                                  ))}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                              Overlegdatum
                            </TableCell>
                            <TableCell style={{ borderBottom: 0 }}>
                              <FormatDate fmt="PP" value={note.started_at} emptyLabel="geen" />
                            </TableCell>
                          </TableRow>
                          {note.mode === CrmNoteModeEnum.MATRIXROW && (
                            <TableRow>
                              <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                                Beoogde einddatum
                              </TableCell>
                              <TableCell style={{ borderBottom: 0 }}>
                                <FormatDate fmt="PP" value={note.ending_at} emptyLabel="geen" />
                              </TableCell>
                            </TableRow>
                          )}
                          {note.closed_at && (
                            <TableRow>
                              <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                                Werkelijke einddatum
                              </TableCell>
                              <TableCell style={{ borderBottom: 0 }}>
                                <FormatDate fmt="PP" value={note.closed_at} emptyLabel="heden" />
                              </TableCell>
                            </TableRow>
                          )}
                          {note.closed_state && (
                            <TableRow>
                              <TableCell style={{ borderBottom: 0 }} className={gc.TableCell_Shrink}>
                                Resultaat
                              </TableCell>
                              <TableCell style={{ borderBottom: 0 }}>{note.closed_state}</TableCell>
                            </TableRow>
                          )}
                          {note.resource_set.map((resource) => (
                            <TableRow key={resource.id}>
                              <TableCell colSpan={2}>
                                <GenericResourceLink resource={resource} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell
                      colSpan={note.mode === CrmNoteModeEnum.MATRIXROW ? 1 : 2}
                      style={{ verticalAlign: "top" }}
                    >
                      <Typography variant="h5">{note.subject}</Typography>
                      <QuillContent content={note.document} />
                    </TableCell>
                    {note.mode === CrmNoteModeEnum.MATRIXROW && (
                      <TableCell className={gc.TableCell_Shrink}>
                        {getTranslation(`crm_note_closed_state.${note.closed_state}`)}
                      </TableCell>
                    )}
                    <TableCell className={gc.TableCell_Shrink}>
                      <IconButtonDropExt
                        items={[
                          {
                            icon: <Edit />,
                            label: "Aanpassen",
                            onClick: () => {
                              actions.setDialog({ name: "note", crm_note_id: note.id });
                            },
                          },
                          {
                            icon: <Delete />,
                            label: "Archiveren",
                            onClick: () => {
                              actions.setDialog({ name: "archive", crm_note_id: note.id });
                            },
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {!state.notes.records?.length && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <em>Er zijn geen afspraken</em>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>

        {state.dialog?.name === "note" && (
          <CrmNoteDrawerSave
            id={state.dialog.crm_note_id}
            connected_care_dossier_id={props.dossier.id}
            involved_company_id_set={props.dossier.person.involved_company_id_set}
            involved_person_id_set={props.dossier.person.involved_person_id_set}
            onClose={async (success?: boolean) => {
              actions.setDialog();
              if (success) {
                await actions.loadNotes();
              }
            }}
          />
        )}
        {state.dialog?.name === "archive" && (
          <CrmNoteDialogArchive
            crm_note_id={state.dialog.crm_note_id}
            onClose={async (success?: boolean) => {
              actions.setDialog();
              if (success) {
                await actions.loadNotes();
              }
            }}
          />
        )}
      </AccordionDetails>
      <AccordionDetails>
        <Button variant="contained" color="primary" onClick={() => actions.setDialog({ name: "note" })}>
          Toevoegen
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export function useCareDossierViewExtNotesComponent(props: CareDossierViewExtNotesProps) {
  const { state, next } = useRxjsStore<CareDossierViewExtNotesState>({
    expanded: false,
    counts: {
      open: 0,
      closed: 0,
      all: 0,
      mode_dossier: 0,
      mode_matrix_row: 0,
    },
    filter_mode: "all",
    filter_closed: "all",
    record_set: [],
  });
  const app = useAppStore();
  const actions = useMemo(
    () => ({
      toggleExpanded: () => {
        next((d) => (d.expanded = !d.expanded));
      },
      loadNotes: async () => {
        const res = await callApi(
          app,
          async (api) =>
            await api.crmNoteManyPost({
              connected_care_dossier_id: props.dossier.id,
              closed_state: [],
              is_closed: -1,
            }),
        );
        next((d) => (d.notes = res.data));
      },
      setFilterMode: (value: CareDossierViewExtNotesState["filter_mode"]) => {
        next((d) => (d.filter_mode = value));
      },
      setFilterClosed: (value: CareDossierViewExtNotesState["filter_closed"]) => {
        next((d) => (d.filter_closed = value));
      },
      setDialog: (value?: CareDossierViewExtNotesState["dialog"]) => {
        next((d) => (d.dialog = value));
      },
    }),
    [props.dossier.id],
  );

  useEffect(() => {
    if (!state.notes) {
      next((d) => {
        d.counts = { open: 0, closed: 0, all: 0, mode_dossier: 0, mode_matrix_row: 0 };
        d.record_set = [];
      });
    } else {
      const partial_closed: { open?: number; closed?: number } = state.notes.records
        ? _.countBy(state.notes.records, (x) => (!x.closed_at ? "open" : "closed"))
        : {};

      const partial_mode: { dossier?: number; matrix_row?: number } = state.notes.records
        ? _.countBy(state.notes.records, (x) => x.mode.toLowerCase())
        : {};

      const record_set = state.notes.records.filter(
        (x) =>
          (state.filter_closed === "all" || !x.closed_at === (state.filter_closed === "open")) &&
          (state.filter_mode === "all" || x.mode === state.filter_mode),
      );

      next((d) => {
        d.counts = {
          open: partial_closed.open || 0,
          closed: partial_closed.closed || 0,
          all: (partial_closed.open || 0) + (partial_closed.closed || 0),
          mode_dossier: partial_mode.dossier || 0,
          mode_matrix_row: partial_mode.matrix_row || 0,
        };
        d.record_set = record_set;
      });
    }
  }, [state.notes, state.filter_closed, state.filter_mode]);

  useAsyncEffect(actions.loadNotes, [props.dossier.id]);

  return { state, actions };
}

export default CareDossierViewExtNotes;
