import React from "react";
import useAppStore from "../core/useAppStore";
import SkeletonAppBar from "./SkeletonAppBar";
import SkeletonDrawer from "./Drawer/SkeletonDrawer";
import SkeletonMain from "./SkeletonMain";
import SkeletonSnackbar from "./SkeletonSnackbar";
import { Helmet } from "react-helmet";

const SkeletonApp: React.FC = ({ children }) => {
  const app = useAppStore();

  return (
    <div style={{ display: "flex" }}>
      <SkeletonAppBar />
      <SkeletonDrawer />
      <SkeletonSnackbar />

      <SkeletonMain>{children}</SkeletonMain>

      <Helmet>
        <title>TagScore - {app.session?.company.name || "ongeautoriseerd"}</title>
      </Helmet>
    </div>
  );
};

export default SkeletonApp;
