import React from "react";
import { Table, TableBody, TableCell, TableRow, useTheme } from "@material-ui/core";
import useGenericStyles from "./useGenericStyles";
import clsx from "clsx";

type Options = {
  preserve_whitespace?: boolean;
  hasAlignRight?: boolean;
  hasHeaderColor?: boolean;
};
type Props = {
  data: [string, React.ReactNode, Options?][];
};

const DataTableExt: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const theme = useTheme();

  return (
    <Table>
      <TableBody>
        {props.data
          .filter(([, v]) => !!v)
          .map(([h, v, o], rx) => (
            <TableRow key={rx}>
              <TableCell
                style={{ color: o?.hasHeaderColor ? theme.palette.primary.main : "#000" }}
                className={gc.TableCell_Shrink}
              >
                {h}
              </TableCell>
              <TableCell
                align={o?.hasAlignRight ? "right" : "left"}
                style={{ whiteSpace: o?.preserve_whitespace ? "pre-line" : "normal" }}
              >
                {v}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default DataTableExt;
