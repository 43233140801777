import React from "react";
import {
  CareDossierTriggerTableRowsRequest,
  GenericDownloadDto,
  TableHeadersResponse,
  TableRowsResponse,
} from "../../api";
import { getApi } from "../../core/useApi";
import useAsyncEffect from "../../core/useAsyncEffect";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../core/useRxjsStore";
import useTableActions from "../../core/useTableActions";
import SkeletonLoading from "../../skeleton/SkeletonLoading";
import SkeletonPage from "../../skeleton/SkeletonPage";

// region Types

type State = {
  booted: boolean;
  loading: boolean;
  sequence: number;

  header_set: TableHeadersResponse["header_set"];
  res?: TableRowsResponse;
  req: CareDossierTriggerTableRowsRequest;

  drawer:
    | undefined
    | {
        name: "TableHeaders";
      }
    | {
        name: "TableDownload";
      }
    | {
        name: "Toggle";
        record_id: number;
      };
};

// endregion

type Context = State & { actions: ReturnType<typeof useActions> };
const Context = React.createContext<Context>(null);

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>) {
  const actions = {
    ...useTableActions<CareDossierTriggerTableRowsRequest, State>({
      next,
      getState,
      loadHeaders: async () => {
        return (await getApi().careDossierTriggerTableHeadersPost()).data;
      },
      loadTable: async () => {
        return (await getApi().careDossierTriggerTableRowsPost(getState().req)).data;
      },
    }),
    reset: () => {
      actions.setRequest({
        offset: 0,
        crm_person_id_set: [],
        process_person_id_set: [],
        is_closed: 0,
      });
    },
    setDrawer: (value: State["drawer"]) => {
      next((d) => (d.drawer = value));
    },
  };

  return actions;
}

const Provider: React.FC = (props) => {
  const STORE_VERSION = 3;

  const { state, next, getState } = useRxjsStore<State>({
    booted: false,
    loading: false,
    sequence: 0,
    header_set: [],
    req: {
      header_set: [],
      offset: 0,
      limit: 10,
      crm_person_id_set: [],
      process_person_id_set: [],
      is_closed: 0,
    },
    drawer: undefined,
  });

  const actions = useActions(next, getState);

  useAsyncEffect(async () => {
    const previous_version = JSON.parse(localStorage.getItem("care-dossier-triggers-table--version") || "-1");
    const previous_data = JSON.parse(localStorage.getItem("care-dossier-triggers-table--data") || "null");

    await actions.boot(previous_version === STORE_VERSION && previous_data);
  }, []);

  useAsyncEffect(async () => {
    if (state.booted) {
      await actions.load();

      console.info("Saving item");

      localStorage.setItem("care-dossier-triggers-table--version", JSON.stringify(STORE_VERSION));
      localStorage.setItem("care-dossier-triggers-table--data", JSON.stringify(getState().req));
    }
  }, [state.booted, state.sequence]);

  return (
    <Context.Provider value={{ ...state, actions }}>
      {state.booted && !!state.res && props.children ? (
        props.children
      ) : (
        <SkeletonPage>
          <SkeletonLoading label="de pagina is aan het laden" />
        </SkeletonPage>
      )}
    </Context.Provider>
  );
};

function useCareDossierTriggersTableStore(): Context {
  return React.useContext(Context);
}

export default useCareDossierTriggersTableStore;
export { Provider as CareDossierTriggersTableProvider };
