import { TableHeadersResponse, TableRowsResponse } from "../api";
import _ from "underscore";
import { RxjsGetStateCallback, RxjsNextCallback } from "./useRxjsStore";
import useAppStore from "./useAppStore";

type TableRowsRequest = {
  header_set: string[];
  offset: number;
  limit: number;
};

type TableActionsState<Request> = {
  booted: boolean;
  loading: boolean;
  sequence: number;

  header_set: TableHeadersResponse["header_set"];
  res?: TableRowsResponse;
  req: Request;
};

type Params<State> = {
  next: RxjsNextCallback<State>;
  getState: RxjsGetStateCallback<State>;
  loadHeaders: () => Promise<TableHeadersResponse>;
  loadTable: () => Promise<TableRowsResponse>;
};

function useTableActions<Request extends TableRowsRequest, State extends TableActionsState<Request>>({
  next,
  getState,
  loadHeaders,
  loadTable,
}: Params<State>) {
  const app = useAppStore();

  return {
    boot: async (previous?: Request) => {
      try {
        const headers = await loadHeaders();
        const default_header_set = headers.header_set.filter((h) => h.opt_default).map((h) => h.name);

        next((d) => {
          d.booted = true;
          d.header_set = headers.header_set;

          if (previous) {
            d.req = previous;
          } else {
            d.req.header_set = default_header_set;
          }
        });
      } catch (err) {
        app.actions.getApiError(err);
      }
    },
    load: async () => {
      if (getState().loading) return;

      next((d) => (d.loading = true));

      try {
        const res = await loadTable();

        next((d) => (d.res = res));
      } catch (err) {
        app.actions.getApiError(err);
      }

      next((d) => (d.loading = false));
    },
    setRequest: (req: Partial<Request>) => {
      next((d) => {
        _.assign(d.req, req);

        d.req.offset = 0;
        d.sequence += 1;
      });
    },
    setPagination: (props: { offset?: number; limit?: number }) => {
      next((d) => {
        if (props.limit !== undefined) {
          d.req.limit = Math.max(props.limit, 10);
          d.req.offset = 0;
        }

        if (props.offset !== undefined) {
          d.req.offset = Math.max(props.offset, 0);
        }

        d.sequence += 1;
      });
    },
  };
}

export default useTableActions;
export type { TableRowsRequest, TableActionsState };
