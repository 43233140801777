import { Box, Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import GetAppIcon from "@material-ui/icons/GetApp";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import React from "react";
import _ from "underscore";
import { MatrixCoreTableRowsRequest } from "../../../api";
import useDebouncedValueCallback from "../../../core/useDebouncedValueCallback";
import useRxjsForm from "../../../core/useRxjsForm";
import useTableStyles from "../../../core/useTableStyles";
import TextFieldExt from "../../../form/TextFieldExt";
import useMatrixCoreTable from "./useMatrixCoreTable";

type Props = {
  initial: Values;
  discardAdd?: boolean;
  discardDownload?: boolean;
  discardColumns?: boolean;
  onChange: (props: Partial<MatrixCoreTableRowsRequest>) => void;
};

type Values = {
  search: string;
};

const MatrixCoreTableToolbar: React.FC<Props> = (props) => {
  const form = useRxjsForm<Values>({ initial: props.initial });
  const table = useMatrixCoreTable();
  const classes = useTableStyles();

  const [, setFirst] = useDebouncedValueCallback((v) => props.onChange(v), form.values, 250);

  // Refresh the form if our props get updated.
  React.useEffect(() => {
    const keys: any = ["search", "is_archived"];
    const equal = _.isEqual(_.pick(form.values, keys), _.pick(props.initial, keys));

    if (!equal) {
      setFirst(true);
      form.actions.setValues(props.initial);
    }
  }, [props.initial]);

  return (
    <React.Fragment>
      <Hidden smDown>
        <Box display="flex" flexDirection="row" ml={1}>
          <Tooltip title="Filters legen.">
            <IconButton onClick={() => table.actions.reset()}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Grid
          container
          component="form"
          noValidate
          onSubmit={form.actions.submit}
          className={classes.TableToolbarForm}
          spacing={2}
        >
          <Grid item xs={12} md>
            <TextFieldExt form={form} name="search" label="Zoeken ..." size="small" autoFocus />
          </Grid>
        </Grid>
      </Hidden>

      <Box display="flex" flexDirection="row">
        {!props.discardAdd && (
          <Tooltip title="Toevoegen.">
            <IconButton onClick={() => table.actions.setDrawer({ name: "Save" })}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {!props.discardDownload && (
          <Tooltip title="Resultaat exporteren.">
            <IconButton onClick={() => table.actions.setDrawer({ name: "TableDownload" })}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        )}
        {!props.discardColumns && (
          <Tooltip title="Kolommen selecteren.">
            <IconButton onClick={() => table.actions.setDrawer({ name: "TableHeaders" })}>
              <ViewWeekIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </React.Fragment>
  );
};

export default MatrixCoreTableToolbar;
