import React from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Typography } from "@material-ui/core";
import useGenericStyles from "../../../core/useGenericStyles";
import { getApi } from "../../../core/useApi";
import useApiResult from "../../../core/useApiResult";
import { CrmPersonDto } from "../../../api";
import CrmPersonExtDataTable from "../Ext/CrmPersonExtDataTable";

type Props = {
  onClose: () => void;
  record_id: number;
};

const CrmPersonDrawerView: React.FC<Props> = (props) => {
  const gc = useGenericStyles();
  const { record_id, onClose } = props;

  const [{ res: record }] = useApiResult<CrmPersonDto>(
    async () => {
      return (await getApi().crmPersonOnePost({ id: record_id })).data;
    },
    [record_id],
    { run_on_startup: true },
  );

  return (
    <Drawer open anchor="right">
      <div className={gc.DrawerContainer}>
        <DialogTitle>Persoon</DialogTitle>
        {record && (
          <DialogContent>
            <Typography variant="h5" component="h2">
              {record.name}
            </Typography>

            <Typography paragraph variant="body2">
              Hieronder vind je alle informatie van het account van {record.name}, je kunt via de knop rechts naast de
              kop de onderstaande informatie bewerken.
            </Typography>

            <CrmPersonExtDataTable person={record} onClose={onClose} />
          </DialogContent>
        )}
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Sluiten
          </Button>
        </DialogActions>
      </div>
    </Drawer>
  );
};

export default CrmPersonDrawerView;
