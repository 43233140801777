import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Archive, Edit, GetApp, PictureAsPdf } from "@material-ui/icons";
import React, { useState } from "react";
import FormatDate from "../../../core/FormatDate";
import { getApi } from "../../../core/useApi";
import useAppStore from "../../../core/useAppStore";
import useGenericStyles from "../../../core/useGenericStyles";
import useRxjsFetch from "../../../core/useRxjsFetch";
import useRxjsStore from "../../../core/useRxjsStore";
import SkeletonPage from "../../../skeleton/SkeletonPage";
import GenericManualsDialogArchive from "./GenericManualsDialogArchive";
import GenericManualsDialogSave from "./GenericManualsDialogSave";

type Props = {};

const GenericManualsOverview: React.FC<Props> = (props) => {
  const app = useAppStore();
  const gc = useGenericStyles();
  const company_slug = app.session.company.slug;

  const state = useComponent(props);

  return (
    <SkeletonPage>
      <Card>
        <Toolbar>
          <Box flex="1 1 100%">
            <Typography variant="subtitle1">Handleidingen</Typography>
          </Box>
          <Button startIcon={<Add />} onClick={() => state.setDialog({ name: "save" })}>
            toevoegen
          </Button>
        </Toolbar>
        <CardContent style={{ padding: 0 }}>
          <Table>
            {state.generic_manual_set.booted &&
              state.generic_manual_set.record.record_set.map((generic_manual) => {
                const { id, private_token, original_filename } = generic_manual.resource;

                const base = `/api/app/company:${company_slug}/resource/stream/${id}/${private_token}/${original_filename}`;
                const query = new URLSearchParams({ session_token: app.session.token });
                const url = base + "?" + query.toString();

                return (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <IconButton href={url} target="_blank">
                          <GetApp />
                        </IconButton>

                        <Link href={url} target="_blank">
                          {generic_manual.resource.original_filename}
                        </Link>
                      </TableCell>
                      <TableCell className={gc.TableCell_Shrink}>
                        <Typography variant="body2" color="textSecondary">
                          <FormatDate value={generic_manual.updated_at} fmt="PP" />, versie {generic_manual.version}
                        </Typography>
                      </TableCell>
                      <TableCell className={gc.TableCell_Shrink}>
                        <Tooltip title="Handleiding archiveren.">
                          <IconButton
                            onClick={() => state.setDialog({ name: "archive", record_id: generic_manual.id })}
                          >
                            <Archive />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Handleiding aanpassen.">
                          <IconButton onClick={() => state.setDialog({ name: "save", record_id: generic_manual.id })}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
          </Table>
        </CardContent>
      </Card>

      <GenericManualsDialogSave
        open={state.dialog?.name === "save"}
        record_id={state.dialog?.record_id}
        onClose={async (success?: boolean) => {
          state.setDialog(undefined);
          if (success) {
            await state.generic_manual_set.load();
          }
        }}
      />

      <GenericManualsDialogArchive
        open={state.dialog?.name === "archive"}
        record_id={state.dialog?.record_id}
        onClose={async (success?: boolean) => {
          state.setDialog(undefined);
          if (success) {
            await state.generic_manual_set.load();
          }
        }}
      />
    </SkeletonPage>
  );
};

type Dialog = undefined | { name: "save"; record_id?: number } | { name: "archive"; record_id: number };

function useComponent(props: Props) {
  const [dialog, setDialog] = useState<Dialog>(undefined);
  const generic_manual_set = useRxjsFetch(async () => {
    return (await getApi().genericManualManyPost()).data;
  }, []);

  return { dialog, setDialog, generic_manual_set };
}

export default GenericManualsOverview;
