import { Box, Chip, colors, Grid, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import { ResponsiveRadar } from "@nivo/radar";
import { format, parseISO } from "date-fns";
import { nl } from "date-fns/locale";
import React, { useMemo, useState } from "react";
import _ from "underscore";
import { CareMatrixMarkDto } from "../../../api";
import useAppStore from "../../../core/useAppStore";

type Props = {
  matrix_core_id: number;
  mark_set: CareMatrixMarkDto[];
  zero?: CareMatrixMarkDto;
  target?: CareMatrixMarkDto;
  current?: CareMatrixMarkDto;
};

const CareMatrixExtRadarChart: React.FC<Props> = (props) => {
  const app = useAppStore();

  const has_zero = !!props.zero;
  const has_target = !!props.target;

  const matrix_core = useMemo(
    () => app.data.matrix_core_set.find((x) => x.id === props.matrix_core_id),
    [props.matrix_core_id],
  );

  const markSet = useMemo(
    () =>
      [
        props.zero,
        ...props.mark_set.filter((x) => !_.contains([props.zero?.id, props.target?.id], x.id)),
        props.target,
      ].filter((x) => !!x),
    [props.zero, props.mark_set, props.target],
  );
  const descriptionSet = useMemo(
    () =>
      [props.zero, ...props.mark_set.filter((x) => !_.contains([props.zero?.id, props.target?.id], x.id)), props.target]
        .filter((x) => !!x)
        .map((x) => x.description),
    [props.zero, props.mark_set, props.target],
  );

  const [hideIxSet, setHideIxSet] = useState<number[]>(
    markSet.length > 1 ? _.times(markSet.length - 2, (n) => n + 1) : [],
  );

  const nameSet = useMemo(
    () =>
      markSet.map((mark, rx) => {
        const createdAtFmt = format(parseISO(mark.created_at), "PP", { locale: nl });

        if (rx === 0 && has_zero) return "Nul meting";
        else if (rx === markSet.length - 1 && has_target) return "Doel meting";
        else return `Meting ${createdAtFmt} (#${mark.id})`;
      }),

    [markSet],
  );

  const data = useMemo(
    () =>
      markSet
        .map((mark, rx) => {
          const name = nameSet[rx];

          return {
            id: mark.id,
            name,
            created_at: mark.created_at,
            values: matrix_core.row_set.map((matrix_row) => {
              const value = mark.values_json.values.find((x) => x.row_id === matrix_row.id);
              return { name: matrix_row.name, score: value?.score || 0 };
            }),
          };
        })
        .filter((x, ix) => !_.contains(hideIxSet, ix)),
    [markSet, nameSet, hideIxSet],
  );

  const dataInChart = useMemo(
    () =>
      matrix_core.row_set.map((matrix_row) => {
        let build: any = {};
        build._row_name = matrix_row.name;

        data.forEach((row, rx) => {
          build[row.name] = row.values.find((x) => x.name === matrix_row.name)?.score || 0;
        });

        return build;
      }),
    [matrix_core.row_set, data],
  );

  const keys = Object.keys(dataInChart[0]).filter((x) => !x.startsWith("_"));

  const colorList: { [name: string]: string }[] = useMemo(
    () =>
      _.flatten(
        _.times(20, () => [
          colors.blue,
          colors.lime,
          colors.orange,
          colors.pink,
          colors.purple,
          colors.red,
          colors.teal,
          colors.amber,
          colors.brown,
          // colors.common,
          colors.cyan,
          colors.deepOrange,
          colors.deepPurple,
          // colors.green,
          // colors.grey,
          colors.indigo,
          colors.lightBlue,
          colors.lightGreen,
          colors.yellow,
        ]),
      ),
    [],
  );

  const colorSet = nameSet.map((k, ix) => {
    return ix === nameSet.length - 1 ? colors.green[500] : colorList[ix][500];
  });

  const pickedColorSet = colorSet.filter((c, ix) => !_.includes(hideIxSet, ix));

  if (dataInChart.length === 0) return <div />;

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" flexDirection="row" width="100%">
        <Box flexGrow={1} height={500}>
          <ResponsiveRadar
            data={dataInChart}
            keys={keys}
            indexBy="_row_name"
            maxValue={5}
            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
            curve="linearClosed"
            borderWidth={2}
            borderColor={{ from: "color" }}
            gridLevels={5}
            gridShape="circular"
            gridLabelOffset={36}
            enableDots={true}
            dotSize={10}
            dotColor={{ theme: "background" }}
            dotBorderWidth={2}
            dotBorderColor={{ from: "color" }}
            enableDotLabel={true}
            dotLabel="value"
            dotLabelYOffset={-12}
            colors={pickedColorSet}
            fillOpacity={0.25}
            blendMode="multiply"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={true}
            legends={[
              {
                anchor: "top-left",
                direction: "column",
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </Box>

        <Box flexShrink={1}>
          <Table size="small">
            <TableBody>
              {nameSet.map((key, ix) => {
                const isHidden = hideIxSet.includes(ix);
                const color = colorSet[ix];

                return (
                  <TableRow
                    onClick={() => {
                      if (isHidden) {
                        setHideIxSet((x) => _.without(x, ix));
                      } else {
                        setHideIxSet((x) => [...x, ix]);
                      }
                    }}
                  >
                    <TableCell>
                      <Tooltip title={descriptionSet[ix]} arrow>
                        <Chip
                          variant={isHidden ? "outlined" : "default"}
                          label={key}
                          style={{
                            borderColor: color,
                            backgroundColor: isHidden ? undefined : color,
                            color: isHidden ? color : colors.grey[50],
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>

      <br />
      <br />

      <Grid container spacing={2}>
        {matrix_core.column_set.map((column) => (
          <Grid item xs key={column.id}>
            <Typography color="textSecondary" variant="subtitle2">
              {column.name}
            </Typography>
            <Typography variant="subtitle1">{column.label}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CareMatrixExtRadarChart;
