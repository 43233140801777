import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  colors,
  Grid,
  IconButton,
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ArrowBack, Delete, Edit, Link, MoreVert, Stop } from "@material-ui/icons";
import React from "react";
import { useParams } from "react-router-dom";
import { CrmPersonDto, CrmPersonTagHistoryResponse, TableRowDto, TableRowsResponse } from "../../api";
import FormatDate from "../../core/FormatDate";
import FormatDateDistance from "../../core/FormatDateDistance";
import { hasPlugin } from "../../core/HasPlugin";
import IconButtonDropExt from "../../core/IconButtonDropExt";
import { FormatValue } from "../../core/TableCellValue";
import TableExt from "../../core/TableExt";
import { callApi, getApi } from "../../core/useApi";
import useApiResult from "../../core/useApiResult";
import useAppStore from "../../core/useAppStore";
import useAsyncEffect from "../../core/useAsyncEffect";
import useNavigate from "../../core/useNavigate";
import useRxjsStore from "../../core/useRxjsStore";
import SkeletonLoading from "../../skeleton/SkeletonLoading";
import SkeletonPage from "../../skeleton/SkeletonPage";
import CareDossierDrawerCreate from "../CareDossier/Drawers/CareDossierDrawerCreate";
import CrmPersonDialogArchive from "../CrmPersonTable/Drawer/CrmPersonDialogArchive";
import CrmPersonDrawerSave from "../CrmPersonTable/Drawer/CrmPersonDrawerSave";
import CrmPersonExtDataTable from "../CrmPersonTable/Ext/CrmPersonExtDataTable";

type Params = {
  record_id: string;
};

const CrmPersonView: React.FC = () => {
  const params = useParams<Params>();
  const theme = useTheme();
  const navigate = useNavigate();
  const { app, state, actions } = useComponent(params);

  if (!state.booted) {
    return (
      <SkeletonPage>
        <SkeletonLoading label="De pagina is aan het laden" />
      </SkeletonPage>
    );
  }

  const { busy, person } = state;

  return (
    <SkeletonPage>
      <Card>
        {busy && <LinearProgress style={{ position: "absolute", width: "100%", height: 3 }} />}
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Tooltip title="Vorige pagina." placement="right">
                <IconButton onClick={() => window.history.back()}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" component="h2">
                <small>#{person.id}.</small> {person.name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButtonDropExt
                icon={<MoreVert />}
                items={[
                  !person.is_archived && {
                    icon: <Edit />,
                    label: "Gegevens",
                    onClick: () => actions.setDrawer({ name: "Save" }),
                  },
                  !person.is_archived && {
                    icon: <Link />,
                    label: "Contactpersoon koppelen",
                    onClick: () => actions.setDrawer(),
                  },
                  {
                    icon: <Delete />,
                    label: person.is_archived ? "Heropenen" : "Archiveren",
                    onClick: () => actions.setDrawer({ name: "Archive" }),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </CardContent>

        <AppBar position="static" color={person.is_archived ? "secondary" : "primary"}>
          <Tabs value={state.tab} onChange={(e, v) => actions.setTab(v)} aria-label="simple tabs example">
            <Tab label="Basisinformatie" value="generic" />
            <Tab label="Logs" value="logs" />
          </Tabs>
        </AppBar>

        <CardContent>
          <Box mt={1}>
            {state.tab === "generic" && (
              <div role="tabpanel">
                <Grid container spacing={2}>
                  <Grid item md>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          Persoonsinformatie.
                        </Typography>

                        <CrmPersonExtDataTable person={person} />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid container item md direction="column" spacing={2}>
                    <Grid item>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Taginformatie.
                          </Typography>

                          <Table size="small">
                            <TableBody>
                              {state.tag_set?.tag_set.map((crm_person_tag) => {
                                const tag_core = app.data.tag_core_set.find((v) => v.id === crm_person_tag.tag_id);
                                const backgroundColor = colors[tag_core.color_name][tag_core.color_hue];
                                const color = theme.palette.getContrastText(backgroundColor);

                                return (
                                  <TableRow key={crm_person_tag.id}>
                                    <TableCell>
                                      <Chip label={tag_core.name} style={{ backgroundColor, color }} />
                                    </TableCell>
                                    <TableCell>
                                      <FormatDate value={crm_person_tag.date_start} fmt="PP" />
                                      {" - "}
                                      <FormatDate value={crm_person_tag.date_end} fmt="PP" emptyLabel="heden" />
                                    </TableCell>
                                    <TableCell>
                                      <FormatDateDistance
                                        value={crm_person_tag.date_start}
                                        till={crm_person_tag.date_end}
                                        unit="day"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {!person.is_archived && (
                                        <IconButtonDropExt
                                          items={[
                                            { icon: <Edit />, label: "Aanpassen", onClick: () => {} },
                                            { icon: <Stop />, label: "Archiveren", onClick: () => {} },
                                            { icon: <Delete />, label: "Verwijderen", onClick: () => {} },
                                          ]}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </CardContent>
                      </Card>
                    </Grid>
                    {hasPlugin("dossier") && (
                      <Grid item>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Dossier(s).
                            </Typography>

                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Tags</TableCell>
                                  <TableCell>Start</TableCell>
                                  <TableCell>Archief?</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {state.dossiers?.row_set.map((row) => (
                                  <TableRow
                                    hover
                                    key={row.record_id}
                                    onClick={() => navigate(`/care/dossier/view/${row.record_id}`)}
                                  >
                                    <TableCell>
                                      <FormatValue value={row.values[1]} fmt="tag_set" />
                                    </TableCell>
                                    <TableCell>
                                      <FormatValue value={row.values[2]} fmt="date" />
                                    </TableCell>
                                    <TableCell>
                                      <FormatValue value={row.values[3]} fmt="boolean" />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </CardContent>
                          <CardActions>
                            <Button onClick={() => actions.setDrawer({ name: "DossierCreate" })}>
                              Dossier starten
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
            {state.tab === "logs" && <CrmPersonViewLogs person={person} />}
          </Box>
        </CardContent>
      </Card>

      {state.drawer?.name === "Save" && (
        <CrmPersonDrawerSave
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer(undefined);
          }}
          record_id={person.id}
        />
      )}
      {state.drawer?.name === "Archive" && (
        <CrmPersonDialogArchive
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer(undefined);
          }}
          record_id={person.id}
        />
      )}
      {state.drawer?.name === "DossierCreate" && (
        <CareDossierDrawerCreate
          person_id={person.id}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer(undefined);
          }}
        />
      )}
    </SkeletonPage>
  );
};

const CrmPersonViewLogs: React.FC<{ person: CrmPersonDto }> = (props) => {
  const { person } = props;

  const [{ res: generic_logs, busy }] = useApiResult(async () => {
    return (await getApi().genericLogManyPost({ crm_person_id: person.id })).data;
  }, [person.id]);

  return (
    <div role="tabpanel">
      <Card variant="outlined">
        {busy && <LinearProgress style={{ position: "absolute", width: "100%", height: 3 }} />}

        <CardContent>
          <Typography variant="h6" gutterBottom>
            Logs van {person.name}.
          </Typography>

          {generic_logs && (
            <TableExt
              loading={busy}
              header_set={[
                { name: "created_at", title: "Moment", opt_shrink: true, display_modifier: "datetime" },
                { name: "created_by.name", title: "Door" },
                { name: "what", title: "Actie", display_modifier: "translation" },
                { name: "object_json", title: "data", display_modifier: "json" },
              ]}
              row_set={generic_logs.records.map(
                (r): TableRowDto => ({
                  record_id: r.id,
                  values: [r.created_at, r.created_by?.name || "TagScore[API]", r.what, r.object_json],
                }),
              )}
              pagination={generic_logs.pagination}
              setPagination={() => {}}
              shrink
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

type State = {
  record_id: string;
  booted: boolean;
  busy: boolean;
  tab: "generic" | "logs";
  person?: CrmPersonDto;
  tag_set?: CrmPersonTagHistoryResponse;
  dossiers?: TableRowsResponse;
  drawer: undefined | { name: "Save" } | { name: "Archive" } | { name: "DossierCreate" };
};

type Actions = ReturnType<typeof useComponent>["actions"];

function useComponent(params: Params) {
  const app = useAppStore();

  const { state, next, getState } = useRxjsStore<State>({
    record_id: params.record_id,
    tab: "generic",
    booted: false,
    busy: false,
    drawer: undefined,
  });

  const actions = {
    boot: async (record_id: string) => {
      if (record_id)
        next((d) => {
          d.record_id = record_id;
        });

      await actions.load();

      next((d) => (d.booted = true));
    },
    setBusy: (v?: boolean) => {
      next((d) => (d.busy = v === undefined ? !d.busy : v));
    },
    setTab: (v: State["tab"]) => {
      next((d) => (d.tab = v));
    },
    setDrawer: (v?: State["drawer"]) => {
      next((d) => (d.drawer = v));
    },
    load: async () => {
      actions.setBusy(true);

      const person = await callApi(app, async (api) => {
        return (await api.crmPersonOnePost({ id: parseInt(getState().record_id) })).data;
      });

      const tag_set = await callApi(app, async (api) => {
        return (await api.crmPersonTagHistoryPost({ id: person.id })).data;
      });

      const dossiers = await callApi(app, async (api) => {
        return (
          await api.careDossierTableRowsPost({
            header_set: ["id", "tag_set", "created_at", "is_archived"],
            is_archived: -1,
            offset: 0,
            limit: 10,
            search: "",
            tag_id_set: [],
            person_id_set: [person.id],
          })
        ).data;
      });

      next((d) => {
        d.busy = false;
        d.person = person;
        d.tag_set = tag_set;
        d.dossiers = dossiers;
      });
    },
  };

  useAsyncEffect(async () => actions.boot(params.record_id), [params.record_id]);

  return { app, state, actions };
}

export default CrmPersonView;
