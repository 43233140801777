import { Button, Card, CardContent, LinearProgress, TextField, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SendIcon from "@material-ui/icons/Send";
import React from "react";
import {
  DetailEnum,
  TsCompanyDto,
  TsSessionSotpReasonEnum,
  TsSessionSotpGenerateResponse,
  TsSessionSotpValidateRequest,
} from "../../api";
import { getApi } from "../../core/useApi";
import useApiResult from "../../core/useApiResult";
import useAppStore from "../../core/useAppStore";
import useRxjsForm from "../../core/useRxjsForm";
import TextFieldExt from "../../form/TextFieldExt";
import { DmzAuthenticatePageActions, useDmzAuthenticateStyles } from "./DmzAuthenticate";

type Props = {
  email: string;
  company_id: number;
  company_set: TsCompanyDto[];
  actions: DmzAuthenticatePageActions;
};

type Values = {
  sotp_password: string;
};

const DmzAuthenticateScreenResetSetup: React.FC<Props> = (props) => {
  const { email, company_id, company_set } = props;
  const classes = useDmzAuthenticateStyles();
  const { form, sotp } = useComponent(props);

  const company = company_set.find((x) => x.id === company_id);

  return (
    <Card className={classes.Form} component={"form"} onSubmit={form.actions.submit}>
      <CardContent>
        {sotp.res && (
          <>
            <Typography variant="h5" component="h2">
              Herstellen
            </Typography>
            <Typography color="textSecondary" paragraph>
              Er is een e-mail verstuurd naar {email}, hier staat een TAN-code in welke je hier moet invoeren. Het kan
              soms een minuutje of twee duren voordat de e-mail is gearriveerd in je mailbox.
            </Typography>

            <TextField disabled label="E-mailadres" defaultValue={email} variant="outlined" fullWidth margin="normal" />
            <TextField
              disabled
              label="Organisatie"
              defaultValue={company.name}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextFieldExt
              form={form}
              name="sotp_password"
              label={`TAN-code ${sotp.res.id}`}
              margin="normal"
              autoFocus
            />
          </>
        )}

        {!sotp && (
          <>
            <Typography color="textSecondary">Herstel - bezig.</Typography>

            <Typography variant="body2" paragraph>
              We hebben je account gevonden. We zijn nu een code aan het versturen naar het volgende e-mailadres:
              <strong>{props.email}</strong>.
            </Typography>

            <LinearProgress variant="indeterminate" />
          </>
        )}
        {sotp.err && (
          <>
            <Typography color="textSecondary">Herstel - foutmelding.</Typography>

            <Typography variant="subtitle2" color="error">
              {sotp.err[1]}
            </Typography>
            <Typography variant="body2" paragraph>
              Er is wat mis gegaan bij het genereren van de authenticatie-code, sorry. Wil je het opnieuw proberen?
            </Typography>
          </>
        )}
      </CardContent>
      <CardContent>
        <Button
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          color={!form.hasErrors ? "primary" : "default"}
          disabled={form.submitting}
          startIcon={form.submitting ? <HourglassEmptyIcon /> : !form.hasErrors ? <SendIcon /> : <ErrorOutlineIcon />}
        >
          Aanmelden
        </Button>
      </CardContent>
    </Card>
  );
};

function useComponent(props: Props) {
  const app = useAppStore();

  const [sotp] = useApiResult<TsSessionSotpGenerateResponse>(
    async () => {
      return (
        await getApi().tsSessionSotpGeneratePost({
          company_id: props.company_id,
          email: props.email,
          reason: TsSessionSotpReasonEnum.OFPASSWORDFORGOT,
        })
      ).data;
    },
    [props.email],
    { watch: true },
  );

  const form = useRxjsForm<Values>({
    initial: {
      sotp_password: "",
    },
    submit: async (values) => {
      const req: TsSessionSotpValidateRequest = {
        sotp_id: sotp.res.id,
        sotp_token: sotp.res.token,
        sotp_password: values.sotp_password,
      };

      await getApi().tsSessionSotpValidatePost(req);

      app.actions.addMessage(
        <span>De authenticatie-code was goed, je kunt nu een nieuw wachtwoord invoeren!</span>,
        "success",
      );
      props.actions.onSotpValidate(req);
    },
    default_errors: {
      [DetailEnum.NOTFOUND]: ["email", "Dit account kon niet gevonden worden."],
    },
  });

  return { form, sotp };
}

export default DmzAuthenticateScreenResetSetup;
