import React from "react";
import TableDrawerDownload from "../../../core/TableDrawerDownload";
import TableDrawerHeaders from "../../../core/TableDrawerHeaders";
import { getApi } from "../../../core/useApi";
import MatrixCoreArchiveDialog from "../Drawers/MatrixCoreArchiveDialog";
import useMatrixCoreTable from "./useMatrixCoreTable";

const MatrixCoreTableDrawers: React.FC = () => {
  const table = useMatrixCoreTable();
  const { drawer, actions } = table;

  return (
    <>
      {drawer?.name === "TableHeaders" && (
        <TableDrawerHeaders
          open={true}
          header_set={table.header_set}
          used_set={table.req.header_set || ["id", "name"]}
          onClose={() => actions.setDrawer(undefined)}
          onSave={(header_set) => {
            actions.setRequest({ header_set });
            actions.setDrawer(undefined);
          }}
        />
      )}
      {table.drawer?.name === "TableDownload" && (
        <TableDrawerDownload
          onClose={() => table.actions.setDrawer(undefined)}
          onDownloadStart={async () => (await getApi().matrixCoreTableDownloadPost(table.req)).data}
        />
      )}
      {drawer?.name === "Archive" && (
        <MatrixCoreArchiveDialog
          record_id={drawer.record_id}
          onClose={async (success) => {
            if (success) await actions.load();
            actions.setDrawer();
          }}
        />
      )}
    </>
  );
};

export default MatrixCoreTableDrawers;
