import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  colors,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Add, ArrowBack, Delete, Edit, ExpandMore, MoreVert, SettingsBackupRestore } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { last } from "rxjs/operators";
import _ from "underscore";
import {
  CareDossierDataDto,
  CareDossierOneResponse,
  CareDossierTagHistoryResponse,
  CareMatrixMarkMomentEnum,
  CrmDataScreenBoundEnum,
  CrmDataScreenDto,
  CrmPersonDtoPartial,
} from "../../api";
import FormatDate from "../../core/FormatDate";
import FormatDateDistance from "../../core/FormatDateDistance";
import IconButtonDropExt from "../../core/IconButtonDropExt";
import TagCoreChip from "../../core/TagCoreChip";
import { callApi } from "../../core/useApi";
import useAppStore, { AppStore } from "../../core/useAppStore";
import useAsyncEffect from "../../core/useAsyncEffect";
import useGenericStyles from "../../core/useGenericStyles";
import useRxjsStore, { RxjsGetStateCallback, RxjsNextCallback } from "../../core/useRxjsStore";
import SkeletonLoading from "../../skeleton/SkeletonLoading";
import SkeletonPage from "../../skeleton/SkeletonPage";
import CareDossierDialogArchive from "../CareDossier/Drawers/CareDossierDialogArchive";
import CareDossierTagSaveDrawer from "../CareDossier/Drawers/CareDossierTagSaveDrawer";
import CareMatrixDrawerMark from "../CareMatrix/Drawers/CareMatrixDrawerMark";
import CareMatrixDrawerRowResponsible from "../CareMatrix/Drawers/CareMatrixDrawerRowResponsible";
import CareMatrixDrawerRowTag from "../CareMatrix/Drawers/CareMatrixDrawerRowTag";
import CareMatrixDrawerRowTarget from "../CareMatrix/Drawers/CareMatrixDrawerRowTarget";
import CrmDataScreenDrawerHistory from "../CrmDataScreen/Drawers/CrmDataScreenDrawerHistory";
import CrmDataScreenDrawerWrite from "../CrmDataScreen/Drawers/CrmDataScreenDrawerWrite";
import CrmDataScreenExtDisplay from "../CrmDataScreen/Ext/CrmDataScreenExtDisplay";
import CrmPersonDrawerSave from "../CrmPersonTable/Drawer/CrmPersonDrawerSave";
import CrmPersonExtDataTable from "../CrmPersonTable/Ext/CrmPersonExtDataTable";
import CareDossierViewExtCareMatrix from "./Ext/CareDossierViewExtCareMatrix";
import CareDossierViewExtNotes from "./Ext/CareDossierViewExtNotes";
import CareDossierViewExtNotesDrawer from "./Ext/CareDossierViewExtNotesDrawer";

type Params = {
  record_id: string;
};

function useCareDossierChannel(app: AppStore, dossier_id: number) {
  const { sendMessage, lastMessage, readyState } = useWebSocket("ws://localhost:9565/care/dossier/channel", {
    reconnectAttempts: 5,
    reconnectInterval: 3000,
    shouldReconnect: (closeEvent) => true,
  });
  const [authenticated, setAuthenticated] = useState(false);
  const [crm_person_set, setCrmPersonSet] = useState<
    { crm_person_id: number; crm_person: CrmPersonDtoPartial; uuid: string }[]
  >([]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      console.info("Socket.ReadyState, authenticating");

      sendMessage(
        JSON.stringify({
          message: "authenticate",
          company_slug: app.session.company.slug,
          token: app.session.token,
        }),
      );
    } else {
      console.warn("Socket.ReadyState", readyState);
      setAuthenticated(false);
    }
  }, [readyState]);

  useEffect(() => {
    if (!lastMessage) return;

    try {
      const data = JSON.parse(lastMessage.data);

      if (data.kind !== "message") {
        console.error(data.kind, data);
      } else {
        switch (data.message) {
          case "authentication-completed":
            setAuthenticated(true);
            break;
          case "ping":
            if (data.crm_person_id_set) {
              setCrmPersonSet(
                _.sortBy(
                  data.crm_person_id_set.map((v) => {
                    const [uuid, crm_person_id_s] = v.split(".");
                    const crm_person_id = parseInt(crm_person_id_s, 10);
                    return {
                      crm_person_id,
                      crm_person: app.data.crm_person_set.find((v) => v.id === crm_person_id),
                      uuid,
                    };
                  }),
                  (value) => {
                    const me = value.crm_person_id === app.session.person.id ? 0 : 1;
                    return `${me}.${value.crm_person.name_slug}`;
                  },
                ),
              );
            }

            break;
          default:
            console.info("Unknown message", data.kind, data);
        }
      }
    } catch (e) {
      console.error("Error parsing", lastMessage.data, e);
      return;
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!authenticated) return;

    sendMessage(
      JSON.stringify({
        message: "join",
        dossier_id: dossier_id,
      }),
    );
  }, [authenticated, dossier_id]);

  return crm_person_set;
}

const CareDossierView: React.FC = () => {
  const params = useParams<Params>();
  const gc = useGenericStyles();
  const { app, state, actions } = useComponent(params);
  const theme = useTheme();

  const crm_person_set = useCareDossierChannel(app, parseInt(params.record_id, 10));

  if (!state.booted) {
    return (
      <SkeletonPage>
        <SkeletonLoading label="De pagina is aan het laden" />
      </SkeletonPage>
    );
  }

  const { busy, dossier } = state;

  return (
    <SkeletonPage>
      <Box mr={state.notesOpen ? "30vw" : 0}>
        <Card>
          {busy && <LinearProgress style={{ position: "absolute", width: "100%", height: 3 }} />}
          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Tooltip title="Vorige pagina." placement="right">
                  <IconButton onClick={() => window.history.back()}>
                    <ArrowBack />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Typography variant="h5" component="h2">
                  <small>#{dossier.id}.</small> Dossier bij {dossier.person.name}
                </Typography>
                {crm_person_set.length > 1 && (
                  <>
                    <Typography variant="body2" style={{ color: colors.grey["700"] }}>
                      Dit dossier staat open bij de volgende gebruikers ({crm_person_set.length}):
                    </Typography>
                    {crm_person_set.map((v) => {
                      const backgroundColor =
                        v.crm_person_id === app.session.person.id ? colors.lightGreen["500"] : colors.pink["500"];
                      const color = theme.palette.getContrastText(backgroundColor);
                      const style = { backgroundColor, color };

                      return <Chip key={v.uuid} style={style} label={v.crm_person.name} className={gc.Chip_Inline} />;
                    })}
                  </>
                )}
              </Grid>
              <Grid item>
                <IconButtonDropExt
                  icon={<MoreVert />}
                  items={[
                    {
                      icon: <Delete />,
                      label: dossier.is_archived ? "Heropenen" : "Archiveren",
                      onClick: () => actions.setDrawer({ name: "Archive" }),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>

          <AppBar position="static" color={dossier.is_archived ? "secondary" : "primary"}>
            <Tabs value={state.tab} onChange={(e, v) => actions.setTab(v)} aria-label="simple tabs example">
              <Tab label="Basisinformatie" value="generic" />
              <Tab label="Logs" value="logs" />
            </Tabs>
          </AppBar>
        </Card>
        <Box mt={3}>
          {state.tab === "generic" && (
            <div role="tabpanel">
              <Grid container spacing={2}>
                <Grid item md>
                  {/* region: Person details */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
                        Persoonsgegevens
                      </Typography>
                      <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
                        {dossier.person.name},{" "}
                        <small>
                          <FormatDate value={dossier.person.birth} fmt="PP" />, te {dossier.person.address_city}
                        </small>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CrmPersonExtDataTable person={dossier.person} />
                    </AccordionDetails>
                    <Divider />
                    <AccordionDetails>
                      <ButtonGroup variant="contained" color="primary">
                        <Button
                          startIcon={<Edit />}
                          onClick={() => {
                            actions.setDrawer({ name: "CrmPersonSave" });
                          }}
                        >
                          Gegevens aanpassen
                        </Button>
                      </ButtonGroup>
                    </AccordionDetails>
                  </Accordion>
                  {/* endregion: Person details */}
                  {/* region: Dossier Tags */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
                        Tags
                      </Typography>
                      <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
                        {dossier.tag_set.length
                          ? dossier.tag_set.map((dossier_tag) => (
                              <Chip
                                key={dossier_tag.id}
                                label={app.data.tag_core_set.find((x) => x.id === dossier_tag.tag_id).name}
                                className={gc.Chip_Inline}
                              />
                            ))
                          : "leeg"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Tag</TableCell>
                            <TableCell>Start</TableCell>
                            <TableCell>Einde</TableCell>
                            <TableCell>Duur</TableCell>
                            <TableCell>Medewerker(s)</TableCell>
                            <TableCell>&nbsp;</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dossier.tag_set.map((dossier_tag) => {
                            const tag = app.data.tag_core_set.find((x) => x.id === dossier_tag.tag_id);

                            return (
                              <TableRow key={dossier_tag.id}>
                                <TableCell>
                                  <TagCoreChip tag_id={tag.id} />
                                </TableCell>
                                <TableCell className={gc.TableCell_Shrink}>
                                  <FormatDate value={dossier_tag.date_start} fmt="PP" emptyLabel="-" />
                                </TableCell>
                                <TableCell className={gc.TableCell_Shrink}>
                                  <FormatDate value={dossier_tag.date_end} fmt="PP" emptyLabel="-" />
                                </TableCell>
                                <TableCell className={gc.TableCell_Shrink}>
                                  <FormatDateDistance
                                    value={dossier_tag.date_start}
                                    till={dossier_tag.date_end}
                                    unit="day"
                                    emptyLabel="-"
                                  />
                                </TableCell>
                                <TableCell>
                                  {dossier_tag.crm_person_owner_set.map((x) => (
                                    <Chip label={x.name} className={gc.Chip_Inline} />
                                  ))}
                                </TableCell>
                                <TableCell className={gc.TableCell_Shrink}>
                                  <ButtonGroup>
                                    <Tooltip title="Tag aanpassen">
                                      <IconButton
                                        onClick={() =>
                                          actions.setDrawer({
                                            name: "CareDossierTagSave",
                                            care_matrix_tag_id: dossier_tag.id,
                                          })
                                        }
                                      >
                                        <Edit />
                                      </IconButton>
                                    </Tooltip>
                                  </ButtonGroup>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                    <Divider />
                    <AccordionDetails>
                      <ButtonGroup>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<Add />}
                          onClick={() =>
                            actions.setDrawer({
                              name: "CareDossierTagSave",
                            })
                          }
                        >
                          Tag toevoegen
                        </Button>
                      </ButtonGroup>
                    </AccordionDetails>
                  </Accordion>
                  {/* endregion: Dossier Tags */}
                  {/* region: Crm Data Screen */}
                  {app.data.crm_data_screen_set
                    .filter((x) => x.bound === CrmDataScreenBoundEnum.CAREDOSSIER)
                    .map((screen) => {
                      const latest = state.dossier.latest_dossier_data_set.find((x) => x.screen_id === screen.id);
                      const current = state.dossier.current_dossier_data_set.find((x) => x.screen_id === screen.id);
                      const history = state.dossier.history_dossier_data_set.find((x) => x.screen_id === screen.id);

                      return (
                        <CareDossierViewExtCrmData
                          screen={screen}
                          latest={latest}
                          current={current}
                          history={history}
                          onEditClick={() =>
                            actions.setDrawer({
                              name: "CrmDataScreenWrite",
                              screen_id: screen.id,
                              record_id: latest?.id,
                            })
                          }
                          onHistoryClick={() =>
                            actions.setDrawer({
                              name: "CrmDataScreenHistory",
                              screen_id: screen.id,
                            })
                          }
                        />
                      );
                    })}
                  {/* endregion: Crm Data Screen */}
                  {/* region: Care Matrix */}
                  {app.data.matrix_core_set.map((matrix_core) => {
                    const cm = state.dossier.care_matrix_set.find((x) => x.matrix_core_id === matrix_core.id);

                    return (
                      <CareDossierViewExtCareMatrix
                        key={matrix_core.id}
                        matrix_core_id={matrix_core.id}
                        care_matrix={cm}
                        actions={actions}
                        dossier_id={state.dossier.id}
                        person_id={state.dossier.person.id}
                      />
                    );
                  })}
                  {/* endregion: Care Matrix */}
                  {/* region: Notes */}
                  <CareDossierViewExtNotes actions={actions} dossier={state.dossier} />
                  {/* endregion: Notes */}
                </Grid>
              </Grid>
            </div>
          )}
          {state.tab === "logs" && <div role="tabpanel">Y</div>}
        </Box>
      </Box>

      <Drawer variant="temporary" anchor="right" open={state.notesOpen} keepMounted>
        <div className={gc.DrawerContainer} style={{ width: "90vw" }}>
          <CareDossierViewExtNotesDrawer actions={actions} dossier={state.dossier} />
        </div>
      </Drawer>

      {state.drawer?.name === "Archive" && (
        <CareDossierDialogArchive
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer(undefined);
          }}
          record_id={dossier.id}
        />
      )}
      {state.drawer?.name === "CrmPersonSave" && (
        <CrmPersonDrawerSave
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer(undefined);
          }}
          record_id={dossier.person.id}
        />
      )}
      {state.drawer?.name === "CrmDataScreenWrite" && (
        <CrmDataScreenDrawerWrite
          screen_id={state.drawer.screen_id}
          record_id={state.drawer.record_id}
          endpoint={{
            which: "careDossierData",
            person_id: state.dossier.person.id,
            dossier_id: state.dossier.id,
          }}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer();
          }}
        />
      )}
      {state.drawer?.name === "CrmDataScreenHistory" && (
        <CrmDataScreenDrawerHistory
          screen_id={state.drawer.screen_id}
          endpoint={{
            person_id: state.dossier.person.id,
            dossier_id: state.dossier.id,
          }}
          onClose={async () => {
            await actions.load();
            actions.setDrawer();
          }}
        />
      )}
      {state.drawer?.name === "CareMatrixMark" && (
        <CareMatrixDrawerMark
          moment={state.drawer.moment}
          record_id={state.drawer.record_id}
          matrix_core_id={state.drawer.matrix_core_id}
          person_id={state.drawer.person_id}
          dossier_id={state.drawer.dossier_id}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer();
          }}
        />
      )}
      {state.drawer?.name === "CareMatrixRowTarget" && (
        <CareMatrixDrawerRowTarget
          care_matrix_row_id={state.drawer.care_matrix_row_id}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer();
          }}
        />
      )}
      {state.drawer?.name === "CareDossierTagSave" && (
        <CareDossierTagSaveDrawer
          care_dossier_id={state.dossier.id}
          care_dossier_tag_id={state.drawer.care_matrix_tag_id}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer();
          }}
        />
      )}
      {state.drawer?.name === "CareMatrixRowResponsible" && (
        <CareMatrixDrawerRowResponsible
          care_dossier_id={state.dossier.id}
          id={state.drawer.id}
          care_matrix_row_id={state.drawer.care_matrix_row_id}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer();
          }}
        />
      )}
      {state.drawer?.name === "CareMatrixRowTag" && (
        <CareMatrixDrawerRowTag
          care_matrix_row_tag_id={state.drawer.care_matrix_row_tag_id}
          care_matrix_row_id={state.drawer.care_matrix_row_id}
          onClose={async (success) => {
            success && (await actions.load());
            actions.setDrawer();
          }}
        />
      )}
    </SkeletonPage>
  );
};

const CareDossierViewExtCrmData: React.FC<{
  screen: CrmDataScreenDto;
  current?: CareDossierDataDto;
  history?: CareDossierDataDto;
  latest?: CareDossierDataDto;
  onEditClick: () => void;
  onHistoryClick: () => void;
}> = (props) => {
  const gc = useGenericStyles();
  const { screen, current, latest, history } = props;
  const [tab, setTab] = useState("current");

  return (
    <Accordion key={screen.id}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6" className={gc.Accordion_HeadingPrimary}>
          {screen.name}
        </Typography>
        <Typography variant="h6" className={gc.Accordion_HeadingSecondary}>
          {!!latest?.fields_json ? "ingevuld" : "leeg"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box flexGrow={1}>
          <AppBar position="static" variant="outlined">
            <Tabs value={tab} variant="fullWidth" onChange={(e, nv) => setTab(nv)}>
              {latest && current && latest.id !== current.id && <Tab value="latest" label="Laatste aanpassing" />}
              {current && (
                <Tab value="current" label={`Actueel ${latest.id === current.id ? "(laatste versie)" : ""}`} />
              )}
              {history && <Tab value="history" label="Geschiedenis" />}
            </Tabs>
          </AppBar>
          {tab === "latest" && latest && <CrmDataScreenExtDisplay screen={screen} data={latest} />}
          {tab === "current" && current && <CrmDataScreenExtDisplay screen={screen} data={current} />}
          {tab === "history" && history && <CrmDataScreenExtDisplay screen={screen} data={history} />}
        </Box>
      </AccordionDetails>
      <Divider />
      <AccordionDetails>
        <ButtonGroup variant="contained" color="primary">
          <Button startIcon={<Edit />} onClick={() => props.onEditClick()}>
            {latest ? "Gegevens aanpassen" : "Gegevens instellen"}
          </Button>
          {!!latest && (
            <Button startIcon={<SettingsBackupRestore />} onClick={() => props.onHistoryClick()}>
              Versiebeheer
            </Button>
          )}
        </ButtonGroup>
      </AccordionDetails>
    </Accordion>
  );
};

type State = {
  record_id: string;
  booted: boolean;
  busy: boolean;
  tab: "generic" | "logs";
  notesOpen: boolean;
  dossier?: CareDossierOneResponse;
  tag_set?: CareDossierTagHistoryResponse;
  sequence: number;
  drawer:
    | undefined
    | { name: "Save" }
    | { name: "Archive" }
    | { name: "CrmPersonSave" }
    | { name: "CrmDataScreenWrite"; screen_id: number; record_id?: number }
    | { name: "CrmDataScreenHistory"; screen_id: number }
    | {
        name: "CareMatrixMark";
        moment: CareMatrixMarkMomentEnum;
        matrix_core_id: number;
        record_id?: number;
        person_id: number;
        dossier_id: number;
      }
    | {
        name: "CareMatrixRowTarget";
        care_matrix_row_id: number;
      }
    | {
        name: "CareMatrixRowResponsible";
        id?: number;
        care_matrix_row_id: number;
      }
    | {
        name: "CareMatrixRowTag";
        care_matrix_row_id: number;
        care_matrix_row_tag_id?: number;
      }
    | {
        name: "CareMatrixRowNote";
      }
    | {
        name: "CareDossierTagSave";
        care_matrix_tag_id?: number;
      };
};

function useComponent(params: Params) {
  const app = useAppStore();

  const { state, next, getState } = useRxjsStore<State>({
    record_id: params.record_id,
    tab: "generic",
    booted: false,
    busy: false,
    drawer: undefined,
    notesOpen: true,
    sequence: 0,
  });

  const actions = useActions(next, getState);

  useAsyncEffect(async () => actions.boot(params.record_id), [params.record_id]);

  return { app, state, actions };
}

type Actions = ReturnType<typeof useActions>;

function useActions(next: RxjsNextCallback<State>, getState: RxjsGetStateCallback<State>) {
  const app = useAppStore();
  const actions = useMemo(
    () => ({
      boot: async (record_id: string) => {
        if (record_id)
          next((d) => {
            d.record_id = record_id;
          });

        await actions.load();

        next((d) => (d.booted = true));
      },
      setBusy: (v?: boolean) => {
        next((d) => (d.busy = v === undefined ? !d.busy : v));
      },
      setTab: (v: State["tab"]) => {
        next((d) => (d.tab = v));
      },
      setDrawer: (v?: State["drawer"]) => {
        next((d) => (d.drawer = v));
      },
      load: async () => {
        actions.setBusy(true);

        const dossier = await callApi(app, async (api) => {
          return (await api.careDossierOnePost({ id: parseInt(getState().record_id) })).data;
        });

        const tag_set = await callApi(app, async (api) => {
          return (await api.careDossierTagHistoryPost({ id: dossier.id })).data;
        });

        app.actions.updateTitle(`Dossier van ${dossier.person.name}`);
        app.actions.setRightToggler("notes", `Open de notities`, "Sluit de notities");
        actions.toggleNotes(false);

        next((d) => {
          d.busy = false;
          d.dossier = dossier;
          d.tag_set = tag_set;
          d.sequence = d.sequence + 1;
        });
      },

      toggleNotes: (to?: boolean) => {
        next((d) => (d.notesOpen = to === undefined ? !d.notesOpen : to));
      },
    }),
    [],
  );

  useEventListener("app-bar.toggle-right", () => actions.toggleNotes());

  return actions;
}

export const useEventListener = (eventName: string, listener: (e: CustomEvent<any>) => void) => {
  useEffect(() => {
    window.addEventListener(eventName, listener);
    return () => window.removeEventListener(eventName, listener);
  });
};

export default CareDossierView;
export type { Actions as CareDossierViewActions };
