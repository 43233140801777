import { CrmNoteClosedStateEnum, GenericLogWhatEnum } from "../api";

export default function getTranslation(value: any) {
  switch (value) {
    case GenericLogWhatEnum.GENERIC:
      return "Generiek";
    case GenericLogWhatEnum.SESSIONLOGIN:
      return "Ingelogd";
    case GenericLogWhatEnum.SESSIONTAKEOVER:
      return "Overgenomen";
    case GenericLogWhatEnum.CRMPERSONSAVECREATE:
      return "Persoon aangemaakt";
    case GenericLogWhatEnum.CRMPERSONSAVEUPDATE:
      return "Persoon aangepast";
    case GenericLogWhatEnum.CRMPERSONTAGOPEN:
      return "Persoon-tag geopend";
    case GenericLogWhatEnum.CRMPERSONTAGCLOSE:
      return "Persoon-tag gesloten";
    case GenericLogWhatEnum.CRMPERSONTAGMUTATE:
      return "Persoon-tag aangepast";
    case `crm_note_closed_state.${CrmNoteClosedStateEnum.ISACHIEVED}`:
      return "Behaald";
    case `crm_note_closed_state.${CrmNoteClosedStateEnum.NOTACHIEVED}`:
      return "Niet behaald";
    case `crm_note_closed_state.${CrmNoteClosedStateEnum.NOLONGERAPPLICABLE}`:
      return "Niet langer van toepassing";
    case `crm_note_closed_state.null`:
      return "Open";

    default:
      return value;
  }
}
